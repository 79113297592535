<template>
  <v-container>
    <v-card>
      <v-toolbar flat color="pink" dark>
        <v-icon class="mr-3">mdi-account-hard-hat</v-icon>
        <v-toolbar-title>Admin Dashboard</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        Started: {{serverinfo.started}} <br/>
        Registered users: {{serverinfo.users}}<br/>
        Central user DB: {{serverinfo.userDB}} bytes<br/>
        UWEG App DB: {{serverinfo.clients[0].appDB}} bytes<br/>
        UWEG User DB: {{serverinfo.clients[0].userDB}} bytes<br/>
        UWEG Map DB: {{serverinfo.clients[0].mapDB}} bytes<br/>
      </v-card-text>
      <v-card-text v-for="entry in serverinfo.lastOnline" :key="entry.user">
        {{entry.user}}: {{entry.lastOnline | dateFormat}}
      </v-card-text>
      <!-- <v-card-actions>
        <v-btn @click="createChart">Graph</v-btn>
        <v-btn @click="createUsageChart">Usage</v-btn>
      </v-card-actions> -->
      <!--<v-container>
        <canvas id="my-test-chart"></canvas>
      </v-container>-->
    </v-card>
    <!-- <email></email> -->
    <!-- <v-card class="mt-3">
      <v-card-title>Export</v-card-title>
      <v-card-text>
        <a href="/api/v1/costcenters" download>Kostenstellen</a><br/>
        <a href="/api/v1/users" download>Benutzer</a><br/>
        <a href="/api/v1/tasks/all" download>Tasks</a><br/>
      </v-card-text>
    </v-card> -->
    <!-- <v-card>
      <v-card-text v-for="entri in logdata2" :key="entri.ts">
        <pre>
          "timestamp": {{entri.timestamp | dateFormatSimple}}
        "ts": {{entri.ts}}
        "method": {{entri.method}}
        {{entri.host}}
        {{entri.path}}
        {{entri.uri}}
        {{entri.subdomain}}
        {{entri.domain}}
        {{entri.tld}}
        {{entri.user}}
        {{entri.userid}}
        </pre>
      </v-card-text>
    </v-card> -->
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
//import Chart from "leaflet";
//import Chart from "chart.js";
export default {
  name: "AdminView",
  data: () => ({
    serverinfo: [],
    serverinfoInterval: null,
    logdata: [],
    logdata2: [],
    chartData: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
        label: 'Example dataset',
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        pointRadius: 0,
        data: [0, 10, 5, 2, 20, 30, 45]
      }]
    },
    chartOptions: {
      responsive: true,
    },
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  watch: {
    serverinfo: {
      handler: function(newVal) {
        console.log(newVal)
      },
      deep: true,
    }
  },
  methods: {
    //createChart(chartId, chartData) {
    createChart() {
      //const ctx = document.getElementById(chartId).getContext('2d');
      /*let chartCanvas = document.getElementById('my-test-chart');
      if (chartCanvas !== null) {
        let ctx = document.getElementById('my-test-chart').getContext('2d');
        let myChart = new Chart(ctx, {
          type: 'line', //chartData.type,
          data: this.chartData, //chartData.data,
          options: this.chartOptions, //chartData.options,
        });
      } else {
        console.log("Chart Canvas not found");
      }*/
    },/*
    createUsageChart() {
      let vm = this;
      axios.get('/api/v1/logs')
          .then(function(response){

            vm.logdata = response.data.sort((a,b) => (a.ts > b.ts) ? 1 : ((b.ts > a.ts) ? -1 : 0));

            var date = new Date();
            var today = date.toISOString().substring(0, 11);
            vm.logdata2 = vm.logdata.filter(l => l.timestamp.startsWith(today + '07'))
            console.log("logdata 2")
            console.log(vm.logdata2)
            var today00 = vm.logdata.filter(l => l.timestamp.startsWith(today + '00')).length
            var today01 = vm.logdata.filter(l => l.timestamp.startsWith(today + '01')).length
            var today02 = vm.logdata.filter(l => l.timestamp.startsWith(today + '02')).length
            var today03 = vm.logdata.filter(l => l.timestamp.startsWith(today + '03')).length
            var today04 = vm.logdata.filter(l => l.timestamp.startsWith(today + '04')).length
            var today05 = vm.logdata.filter(l => l.timestamp.startsWith(today + '05')).length
            var today06 = vm.logdata.filter(l => l.timestamp.startsWith(today + '06')).length
            var today07 = vm.logdata.filter(l => l.timestamp.startsWith(today + '07')).length
            var today08 = vm.logdata.filter(l => l.timestamp.startsWith(today + '08')).length
            var today09 = vm.logdata.filter(l => l.timestamp.startsWith(today + '09')).length
            var today10 = vm.logdata.filter(l => l.timestamp.startsWith(today + '10')).length
            var today11 = vm.logdata.filter(l => l.timestamp.startsWith(today + '11')).length
            var today12 = vm.logdata.filter(l => l.timestamp.startsWith(today + '12')).length
            var today13 = vm.logdata.filter(l => l.timestamp.startsWith(today + '13')).length
            var today14 = vm.logdata.filter(l => l.timestamp.startsWith(today + '14')).length
            var today15 = vm.logdata.filter(l => l.timestamp.startsWith(today + '15')).length
            var today16 = vm.logdata.filter(l => l.timestamp.startsWith(today + '16')).length
            var today17 = vm.logdata.filter(l => l.timestamp.startsWith(today + '17')).length
            var today18 = vm.logdata.filter(l => l.timestamp.startsWith(today + '18')).length
            var today19 = vm.logdata.filter(l => l.timestamp.startsWith(today + '19')).length
            var today20 = vm.logdata.filter(l => l.timestamp.startsWith(today + '20')).length
            var today21 = vm.logdata.filter(l => l.timestamp.startsWith(today + '21')).length
            var today22 = vm.logdata.filter(l => l.timestamp.startsWith(today + '22')).length
            var today23 = vm.logdata.filter(l => l.timestamp.startsWith(today + '23')).length


            let ctx = document.getElementById('my-test-chart').getContext('2d');

            let myChart = new Chart(ctx, {
              type: 'line',
              data: {
                labels: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
                  '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23',],
                datasets: [{
                  label: 'Hourly Usage',
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  pointRadius: 0,
                  data: [
                    today00, today01, today02, today03, today04, today05, today06, today07, today08,
                    today09, today10, today11, today12, today13, today14, today15, today16, today17,
                    today18, today19, today20, today21, today22, today23,
                  ]
                }]
              },
              options: vm.chartOptions,
            });
            console.log('21 Uhr ' + today21);
            console.log(response.data);

          })
          .catch(function(error){});
    },*/
    getServerinfo() {
      axios.get(this.baseUrl + '/api/v1/serverinfo', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            this.serverinfo = response.data;
            //console.log(response.data)
            console.log("server info fetched");
          })
          .catch((error)=>{
            console.log(error);
          });
    },
  },
  created() {
    this.getServerinfo()
    this.serverinfoInterval = setInterval(() => {
      this.getServerinfo()
    }, 60000);

    axios.get(this.baseUrl + '/api/v1/logs', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
        .then((response)=>{
          this.logdata = response.data.sort((a,b) => (a.ts > b.ts) ? 1 : ((b.ts > a.ts) ? -1 : 0));
          console.log("server info fetched");
          console.log(response.data);
        })
        .catch((error)=>{
          console.log("error in server info");
          console.log(error);
        });

    /*setTimeout(()=>{
      this.createChart()
    }, 2000);*/
  },
  beforeUnmount() {
    clearInterval(this.serverinfoInterval)
  },
}
</script>