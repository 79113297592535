import axios from "@/main";
import Vue from "vue";

const axios2 = require('axios');

const getRuntimeConfig = async () => {
    const runtimeConfig = await fetch('/config.json');
    return await runtimeConfig.json()
}

let host = 'http://uweg.codefive.five:50001/';
//host = getRuntimeConfig.host;

function createInstance(baseUrl) {
    if (baseUrl !== '') {
        return axios2.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    return axios2.create({
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export default {
    install () {
        Vue.prototype.$http = createInstance(host)
    }
};