<template>
  <v-container>
    <v-alert
        prominent
        type="primary"
        v-if="!keycloak.tokenParsed.realm_access.roles.includes('super-admin')"
    >Keine Berechtigung, {{ keycloak.tokenParsed.name }}!</v-alert>
    <v-card v-if="keycloak.tokenParsed.realm_access.roles.includes('super-admin')">
      <v-card-title>Benutzerverwaltung <v-icon class="ml-5" @click="refresh">mdi-sync</v-icon></v-card-title>
      <v-row
          class="pa-4"
          justify="space-between"
      >
        <v-col cols="4">
          <v-treeview
              :active.sync="active"
              :items="items"
              :load-children="fetchUsers"
              :open.sync="open"
              activatable
              open-on-click
              transition
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="!item.children">
                mdi-account
              </v-icon>
            </template>
          </v-treeview>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col
            class="d-flex text-center"
        >
          <v-scroll-y-transition mode="out-in">
            <div
                v-if="!selected"
                class="text-h6 grey--text text--lighten-1 font-weight-light"
                style="align-self: center;"
            >
              Benutzer auswählen
            </div>
            <v-card
                v-else
                :key="selected.id"
                class="mx-auto"
                flat
            ><!-- v-card max-width="400" -->
              <v-card-text>
                <v-avatar
                    v-if="selected.avatar"
                    size="88"
                >
                  <v-img
                      :src="`${selected.avatar}`"
                  ></v-img>
                </v-avatar>
                <h3 class="text-h5 mb-2 mt-5">
                  {{ selected.firstname }} {{selected.lastname}}
                </h3>
                <div class="blue--text mb-2">
                  {{ selected.email }}
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <v-form>
                  <v-text-field
                      label="Vorname"
                      v-model="selected.firstname"
                  ></v-text-field>
                  <v-text-field
                      label="Nachname"
                      v-model="selected.lastname"
                  ></v-text-field>
                  <v-text-field
                      label="Email"
                      v-model="selected.email"
                  ></v-text-field>
                  <v-row>
                    <v-col>
                      <v-checkbox
                          label="gesperrt"
                          v-model="selected.deactivated"
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
                          label="verifiziert"
                          v-model="selected.verified"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-text-field
                      label="Avatar"
                      v-model="selected.avatar"
                      v-if="user.email==='rene@codefive.de'"
                  ></v-text-field>
                  <v-btn @click="editUser(selected)">Speichern</v-btn>
                  <v-tooltip bottom v-if="selected.deleted == null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          @click="deleteUser(selected)"
                          v-bind="attrs"
                          v-on="on" color="red"
                          class="mx-2"
                      >
                        <v-icon>mdi-account-remove</v-icon>
                        Benutzer löschen
                      </v-btn>
                    </template>
                    <span>Benutzer löschen</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="selected.deleted !== null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          @click="activateUser(selected)"
                          v-bind="attrs"
                          v-on="on" color="green"
                          class="mx-2"
                      >
                        <v-icon>mdi-account-check</v-icon>
                        Benutzer wiederherstellen
                      </v-btn>
                    </template>
                    <span>Benutzer wiederherstellen</span>
                  </v-tooltip>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <h3>Profilbild hochladen</h3>
                <v-file-input
                    accept="image/*"
                    label="Profilbild"
                    v-model="file"
                ></v-file-input>
                <v-btn color="#49fcd4" dark @click="addAvatar(selected)"><v-icon>mdi-upload</v-icon></v-btn>
              </v-card-text>
              <v-divider class="mb-2"></v-divider>
              <v-card-text>
                <h3>Passwort zurücksetzen</h3>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="resetPwd(selected)"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-2"
                    >
                      <v-icon>mdi-account-lock</v-icon>
                      Passwort ändern
                    </v-btn>
                  </template>
                  <span>Passwort ändern</span>
                </v-tooltip>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <h3>Rollen zuweisen</h3>
                <v-combobox
                    label="Rollen"
                ></v-combobox>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <h3>Metadaten</h3>
              </v-card-text>
              <v-row
                  class="text-left"
                  tag="v-card-text"
              >
                <v-col
                    class="text-right mr-4"
                    tag="strong"
                    cols="5"
                >Erstellt:</v-col>
                <v-col>{{selected.created | dateFormatSimple}}</v-col>
              </v-row>
              <v-row
                  class="text-left"
                  tag="v-card-text"
              >
                <v-col
                    class="text-right mr-4"
                    tag="strong"
                    cols="5"
                >Bearbeitet:</v-col>
                <v-col>{{selected.updated | dateFormatSimple}}</v-col>
              </v-row>
              <v-row
                  class="text-left"
                  tag="v-card-text"
                  v-if="user.email === 'rene@codefive.de'"
              >
                <v-col
                    class="text-right mr-4"
                    tag="strong"
                    cols="5"
                >Gelöscht:</v-col>
                <v-col>{{selected.deleted | dateFormatSimple}}</v-col>
              </v-row>
              <v-row
                  tag="v-card-actions"
                  v-if="user.email === 'rene@codefive.de'"
              >
                <v-col>
                  <v-btn v-if="selected.deleted !== null">Endgültig Löschen</v-btn>
                  <v-btn v-if="selected.deleted == null">Löschen</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-if="resetpwd !== null" v-model="dialogUser" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">

      </template>
      <v-card>
        <v-card-title>Passwort bearbeiten</v-card-title>
        <v-card-subtitle>{{dialogUser.email}}</v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-text-field label="Neues Passwort" required v-model="dialogUser.password"></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="resetPassword(dialogUser)" color="#49fcd4">Ändern</v-btn>
          <v-btn @click="resetpwd = null" color="#f71784">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="editing !== null" v-model="dialogUser" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">

      </template>
      <v-card>
        <v-card-title>Benutzer bearbeiten</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="Vorname" required v-model="dialogUser.firstname"></v-text-field>
            <v-text-field label="Nachname" v-model="dialogUser.lastname"></v-text-field>
            <v-text-field label="Email" v-model="dialogUser.email"></v-text-field>
            <v-text-field label="Avatar" v-model="dialogUser.avatar"></v-text-field>
            <v-file-input
                accept="image/*"
                label="Avatar"
                v-model="file"
            ></v-file-input><v-btn color="#49fcd4" dark @click="addAvatar(dialogUser)"><v-icon>mdi-upload</v-icon></v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="editUser(dialogUser)" color="#49fcd4">Speichern</v-btn>
          <v-btn @click="editing = null" color="#f71784">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-row>
      <v-col>
        <v-card>
          <v-card-title>Benutzer</v-card-title>
          <v-card-subtitle>{{info}}</v-card-subtitle>
          <v-container>
            <v-list-item v-for="user in users" :key="user.id">
              <v-list-item-avatar>
                <img :src="user.avatar">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{user.firstname}} {{user.lastname}}, {{user.email}} <span v-if="user.deleted !== null" style="color: red;">, deleted</span></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="editMode(user)" v-bind="attrs"
                                v-on="on">mdi-account-edit</v-icon>
                      </template>
                      <span>Benutzer bearbeiten</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="resetPwd(user)" v-bind="attrs"
                                v-on="on">mdi-account-lock</v-icon>
                      </template>
                      <span>Passwort ändern</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="deleteUser(user)" v-bind="attrs"
                                v-on="on" color="red">mdi-account-remove</v-icon>
                      </template>
                      <span>Benutzer löschen</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list-item-action>

            </v-list-item>


          </v-container>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row v-if="keycloak.tokenParsed.realm_access.roles.includes('super-admin')">
      <v-col>
        <v-card class="mt-4">
          <v-card-title>Benutzer hinzufügen</v-card-title>
          <v-container>
            <v-text-field
                label="Vorname"
                v-model="user.firstname"
                @focus="clearStatus"
                @keypress="clearStatus"
            ></v-text-field>
            <v-text-field
                label="Nachname"
                v-model="user.lastname"
                @focus="clearStatus"
            ></v-text-field>
            <v-text-field
                label="Email"
                v-model="user.email" />
            <v-text-field
                label="Passwort"
                v-model="user.password" />
            <p v-if="error && submitting" class="error-message">
              ❗Bitte alle Felder ausfüllen
            </p>
            <p v-if="success" class="success-message">✅ Benutzer erfolgreich hinzugefügt</p>
            <v-btn @click="handleSubmit" >Benutzer hinzufügen</v-btn>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-5" v-if="keycloak.tokenParsed.realm_access.roles.includes('super-admin')">
      <v-card-title>Registrierte Benutzer &nbsp;&nbsp;<v-btn @click="refresh" icon><v-icon>mdi-sync</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-data-table
            :items="users3"
            :headers="headers2"
        >
          <template v-slot:item.deactivated="{item}">
            <v-icon
                v-if="item.deactivated"
                small
                class="mr-2"
                @click="activateUser0(item)"
                color="primary"
            >
              mdi-checkbox-blank-circle-outline
            </v-icon>
            <v-icon
                v-else
                small
                class="mr-2"
                @click="deactivateUser0(item)"
                color="secondary"
            >
              mdi-check-circle-outline
            </v-icon>
          </template>
          <template v-slot:item.verified="{item}">
            <v-icon
                v-if="item.verified"
                small
                class="mr-2"
                @click="unverifyUser(item)"
                color="secondary"
            >
              mdi-check-circle-outline
            </v-icon>
            <v-icon
                v-else
                small
                class="mr-2"
                @click="verifyUser(item)"
                color="primary"
            >
              mdi-checkbox-blank-circle-outline
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="justPurgeUser(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-if="keycloak.tokenParsed.realm_access.roles.includes('super-admin')">
      <v-card-title>Registrierte Benutzer &nbsp;&nbsp;<v-btn @click="test0" icon><v-icon>mdi-sync</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-data-table
            :items="keycloakUsers"
            :headers="headersKC"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="test0"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-if="keycloak.tokenParsed.realm_access.roles.includes('super-admin')">
      <v-card-title>Benutzer</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="user0 in users" :key="user0.id">
            <v-list-item-avatar>
              <img :src="baseUrl + user0.avatar">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <v-list-item-title>{{ user0.firstname }} {{ user0.lastname }}</v-list-item-title>
                </v-col>
                <v-col>
                  <v-select label="Rolle" v-model="user0.role" :items="systemRoles" outlined dense clearable :disabled="user0.email === 'rene@codefive.de'"></v-select>
                </v-col>
                <v-col>
                  <v-btn outlined @click="updateUser2(user0)" :disabled="user0.email === 'rene@codefive.de'">Update</v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <pre>{{keycloak.tokenParsed}}</pre>
        <pre>{{keycloak.tokenParsed.realm_access.roles}}</pre>
        <pre>{{keycloak.tokenParsed.realm_access.roles.includes("super-admin")}}</pre>
      </v-card-text>
    </v-card>
    <v-snackbar
        v-model="snackbar"
        timeout="-1"
    >
      {{infoMessage}}
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import WorkspaceDataApi from "@/apis/WorkspaceDataApi";
export default {
  name: "UserManager",
  data: () => ({
    workspaceApi: null,
    editing: null,
    resetpwd: null,
    users: Array,
    info: '',
    submitting: false,
    error: false,
    success: false,
    user: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      avatar: '',
    },
    dialogUser: null,
    file: null,

    active: [],
    avatar: null,
    open: [],
    users2: [],
    users3: [],
    keycloakUsers: [],
    headersKC: [
      {text: "Mail", value: "email"},
      {text: "Vorname", value: "firstName"},
      {text: "Nachname", value: "lastName"},
      {text: "ID", value: "id"},
      {text: "Aktionen", value: "actions", sortable: false},
    ],
    headers2: [
      {text: "Mail", value: "email"},
      {text: "Vorname", value: "firstname"},
      {text: "Nachname", value: "lastname"},
      {text: "ID", value: "id"},
      {text: "KC", value: "keycloak"},
      {text: "Aktiv", value: "deactivated"},
      {text: "Verifiziert", value: "verified"},
      {text: "Aktionen", value: "actions", sortable: false},
    ],

    systemRoles: [],

    infoMessage: "",
    snackbar: false,
  }),

  methods: {
    test0() {
      console.log(""+this.keycloak.token);
      axios.get(this.baseUrl + "/api/v1/users0", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then((res)=>{
        console.log(res);
        this.keycloakUsers = res.data;
      })
      .catch((err)=>{
        console.log(err);
      });
    },
    updateUser2(user) {
      this.workspaceApi.updateUser(user)
          .then(() => {
            this.getUsers()
            this.reloadUser()
          })
          .catch((err)=>{
            console.log(err);
          });
    },
    getUsers() {
      this.workspaceApi.getUsers()
          .then((response) => {
            this.users = response.data;
            this.users = this.users.filter(
                u => u.deleted == null
            );
            this.users.sort((a,b) => (a.lastname > b.lastname) ? 1 : ((b.lastname > a.lastname) ? -1 : 0))
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getSystemRoles() {
      this.workspaceApi.getSystemRoles()
          .then((res) => {
            this.systemRoles = res.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async fetchUsers (item) {
      // Remove in 6 months and say
      // you've made optimizations! :)
      //await pause(1500)
      console.log('fetch users');
      let vm = this;

      return fetch(this.baseUrl + '/api/v1/users', {headers: new Headers({"Authorization" : `Bearer ${this.keycloak.token}`})})
          .then(res => res.json())
          //.then(json => (item.children.push(...json)))
          .then(function(json){
            let filtered;
            if (vm.keycloak.tokenParsed.email === 'rene@codefive.de') {
              filtered = json;
            } else {
              filtered = json.filter(u => u.deleted == null);
            }
            filtered.forEach(u => u.name = u.firstname + ' ' + u.lastname);
            filtered.sort((a,b) => (a.lastname > b.lastname) ? 1 : ((b.lastname > a.lastname) ? -1 : 0));
            //item.children.push(...json);
            item.children.push(...filtered);
            //item.children.forEach(u => u.name = u.firstname + ' ' + u.lastname);
            console.log('fetched users');
            console.log(item.children);
          })
          .catch(err => console.warn(err))
    },
    refresh() {
      console.log("refreshing");
      axios.get(this.baseUrl + "/api/v1/users", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            let filtered;
            if (this.keycloak.tokenParsed.email === 'rene@codefive.de') {
              console.log("unfiltered");
              filtered = response.data;
            } else {
              console.log("filtered");
              filtered = response.data.filter(u => u.deleted == null);
            }
            filtered.forEach(u => u.name = u.firstname + ' ' + u.lastname);
            filtered.sort((a,b) => (a.lastname > b.lastname) ? 1 : ((b.lastname > a.lastname) ? -1 : 0));
            console.log(filtered);
            this.users3 = filtered;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    handleSubmit() {
      let vm = this;
      this.submitting = true;
      this.clearStatus();
      if (this.invalidFirstname || this.invalidLastname || this.invalidEmail || this.invalidPassword) {
        this.error = true
        return
      }
      axios.post(this.baseUrl + '/api/v1/users', vm.user, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(function(response) {
            console.log(response);
            vm.users.push(response.data);
            vm.users.sort((a,b) => (a.lastname > b.lastname) ? 1 : ((b.lastname > a.lastname) ? -1 : 0))
            vm.user = {
              firstname: '',
              lastname: '',
              email: '',
              password: '',
            }
            vm.error = false
            vm.success = true
            vm.submitting = false
          })
          .catch(function(error) {
            console.log(error);
          });
    },
    editMode(user) {
      this.editing = user.id;
      this.dialogUser = user;
    },
    resetPwd(user) {
      this.resetpwd = user.id;
      this.dialogUser = user
    },
    editUser(user) {
      this.info = 'Bearbeite Benutzer ' + user.email;
      let vm = this;
      if (user.email === '') return; //validation
      let updatedUser = JSON.parse(JSON.stringify(user));
      delete updatedUser['password'];
      if(updatedUser['name']) {
        delete updatedUser['name'];
      }
      axios.put(this.baseUrl + '/api/v1/users/' + user.id, updatedUser, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(function(response){
            console.log('edit ' + user.email);
            console.log(response.data);
            vm.users = vm.users.map(c =>
                c.id === user.id ? user : c
            );
            vm.refresh();
          })
          .catch(function(error){
            vm.info = 'Es ist ein Fehler aufgetreten';
            console.log(error);
          });
      this.editing = null;
      this.dialogUser = null;
    },
    resetPassword(user) {
      var vm = this;
      if (user.password === '') return; //validation
      axios.put(this.baseUrl + '/api/v1/users/reset-password/' + user.id, {password: user.password}, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(function(response){
            console.log('reset password ' + user.email);
            console.log(response.data);
            vm.users = vm.users.map(c =>
                c.id === user.id ? user : c
            );
          })
          .catch(function(error){
            vm.info = 'Es ist ein Fehler aufgetreten';
            console.log(error);
          });
      vm.resetpwd = null;
    },
    deleteUser(user) {
      this.info = 'Lösche Benutzer ' + user.email;
      axios.delete(this.baseUrl + '/api/v1/users/' + user.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log('deleted: ')
            console.log(response.data);
            this.infoMessage =  'Benutzer wurde erfolgreich gelöscht.';
            this.snackbar = true;
            this.users3 = this.users3.filter(
                u => u.id !== user.id
            );
            this.refresh();
          })
          .catch((error) => {
            console.log('error ' + error);
            this.infoMessage = 'Benutzer konnte nicht gelöscht werden.';
            this.snackbar = true;
          });
    },
    justPurgeUser(user) {
      axios.delete(this.baseUrl + '/api/v1/users/justpurge/' + user.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log('deleted: ')
            this.infoMessage =  'Benutzer wurde erfolgreich gelöscht.';
            this.snackbar = true;
            this.refresh();
          })
          .catch((error) => {
            console.log('error ' + error);
            this.infoMessage = 'Benutzer konnte nicht gelöscht werden.';
            this.snackbar = true;
          });
    },
    activateUser(user) {
      user.deleted = null;
      user.deactivated = false;
      this.editUser(user);
    },
    updateUser(user) {
      axios.put(this.baseUrl + '/api/v1/users/' + user.id, user, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            console.log('update ' + user.email);
            console.log(res.data);
            this.refresh();
          })
          .catch((error)=>{
            this.infoMessage = 'Es ist ein Fehler aufgetreten';
            this.snackbar = true;
            console.log(error);
          });
    },
    activateUser0(user) {
      user.deleted = null;
      user.deactivated = false;
      this.updateUser(user);
    },
    deactivateUser0(user) {
      user.deactivated = true;
      this.updateUser(user);
    },
    unverifyUser(user) {
      user.verified = false;
      this.updateUser(user);
    },
    verifyUser(user) {
      user.verified = true;
      this.updateUser(user);
    },
    clearStatus() {
      this.success = false
      this.error = false
    },
    addAvatar(user) {
      var vm = this;
      var formData = new FormData();
      formData.append("user-id", user.id);
      formData.append("files", this.file, this.file.name);
      axios.post(this.baseUrl + '/api/v1/users/avatars/' + user.id, formData, {
        headers: {
          "Authorization" : 'Bearer ' + this.token,
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(function(response){
            user = response.data;
            vm.users = vm.users.map(c =>
                c.id === user.id ? response.data : c
            );
            vm.refresh();
          })
          .catch(function (error){
            console.log(error);
          });
    },
    updateSelected() {

    },
    ...mapActions(piniaStore, ['reloadUser']),
  },
  watch: {
    selected: 'updateSelected',
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    invalidFirstname() {
      return this.user.firstname === ''
    },
    invalidLastname() {
      return this.user.lastname === ''
    },
    invalidEmail() {
      return this.user.email === ''
    },
    invalidPassword() {
      return this.user.password === ''
    },
    items () {
      return [
        {
          name: 'Benutzer',
          children: this.users,
        },
      ]
    },
    selected () {
      if (!this.active.length) return undefined

      const id = this.active[0]

      return this.users.find(user => user.id === id)
    },
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['keycloak']),
  },
  mounted: function () {
    console.log("mounted user-table");
    this.getUsers();
  },
  created() {
    this.workspaceApi = new WorkspaceDataApi();
    this.getUsers();
    this.getSystemRoles();
  }
};
</script>