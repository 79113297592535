<template>
<v-container>
  <v-card>
    <v-card-title>Projektplanung</v-card-title>
    <v-card-text>
      <v-text-field label="Titel" v-model="projectPlanItem.title" outlined></v-text-field>
      <v-text-field label="Beschreibung" v-model="projectPlanItem.description" outlined></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon outlined @click="getProjectPlans"><v-icon>mdi-refresh</v-icon></v-btn>
      <v-btn outlined @click="addProjectPlan">Hinzufügen</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-list>
        <v-list-item v-for="ppi in projectPlans" :key="ppi.id">
          <v-list-item-content>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Titel" outlined v-model="ppi.title"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea label="Beschreibung" outlined v-model="ppi.description"></v-textarea>
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn outlined small @click="updateProjectPlan(ppi)">Speichern</v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon outlined small @click="deleteProjectPlan(ppi.id)"><v-icon small>mdi-delete</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import ProjectPlanApi from "@/apis/ProjectPlanApi";

export default {
  name: "ProjectPlan",
  data: () => ({
    test: "",
    projectPlanApi: null,
    projectPlans: [],
    defaultProjectPlanItem: {
      title: "",
      description: "",
      parent: null,
    },
    projectPlanItem: {},
  }),
  methods: {
    getProjectPlans() {
      this.projectPlanApi.getProjectPlans().then((res) => {
        this.projectPlans = res.data;
        console.log(this.projectPlans);
      })
    },
    addProjectPlan() {
      this.projectPlanApi.addProjectPlan(this.projectPlanItem).then(()=>{
        this.getProjectPlans();
        this.projectPlanItem = Object.assign({}, this.defaultProjectPlanItem);
      })
    },
    updateProjectPlan(projectPlan) {
      this.projectPlanApi.updateProjectPlan(projectPlan).then(()=>{
        this.getProjectPlans();
      })
    },
    deleteProjectPlan(id) {
      this.projectPlanApi.deleteProjectPlanByID(id).then(()=>{
        this.getProjectPlans();
      })
    }
  },
  created() {
    this.projectPlanApi = new ProjectPlanApi();
    this.getProjectPlans();
    this.projectPlanItem = Object.assign({}, this.defaultProjectPlanItem);
  }
}
</script>

<style scoped>

</style>