import {piniaStore} from "@/store/pinia";
import axios from "axios";
import Config from "@/apis/Config";

export default class WorkspaceDataApi {
    http;
    store;

    constructor() {
        this.store = piniaStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            }
        });
        this.http.interceptors.request.use((config) => {
            config.baseURL = Config.baseUrl();
            config.headers = {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            };
            return config;
        });
    }

    getBonuses() {
        return this.http.get('/api/v1/bonuses');
    }
    getCostCenters() {
        return this.http.get('/api/v1/costcenters');
    }
    getCostCentersByProjectId(projectId) {
        return this.http.get('/api/v1/costcenters/project/' + projectId);
    }
    getProjects() {
        return this.http.get('/api/v1/projects');
    }
    getRoles() {
        return this.http.get('/api/v1/roles');
    }
    getSystemRoles() {
        return this.http.get('/api/v1/roles/systemroles');
    }
    createRole(role) {
        return this.http.post("/api/v1/roles", role);
    }
    updateRole(role) {
        return this.http.put("/api/v1/roles", role);
    }
    deleteRole(id) {
        return this.http.delete("/api/v1/roles/" + id);
    }
    getTeams() {
        return this.http.get('/api/v1/teams');
    }
    createTeam(team) {
        return this.http.post("/api/v1/teams", team);
    }
    updateTeam(team) {
        return this.http.put("/api/v1/teams/" + team.id, team);
    }
    deleteTeam(id) {
        return this.http.delete("/api/v1/teams/" + id);
    }
    addTeamMembers(id, data) {
        return this.http.put("/api/v1/teams/" + id + "/members", data);
    }
    removeTeamMembers(id, data) {
        return this.http.patch("/api/v1/teams/" + id + "/members", data);
    }
    getTeamMembers() {
        return this.http.get("/api/v1/teams/membership");
    }

    getMyTeams() {
        return this.http.get('/api/v1/myteams');
    }
    getUsers() {
        return this.http.get('/api/v1/users');
    }

    updateUser(user) {
        return this.http.put('/api/v1/users/' + user.id, user);
    }

}