<template>
<v-container>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Meldungen - Kategorien</v-toolbar-title>
      <v-spacer></v-spacer>
      <IncidentCategoryDialog activator="create" :incident-category="incidentCategory" @closeEvent="setDefaultIncidentCategory"/>
    </v-toolbar>
    <v-card-text>
      <v-list>
        <v-list-item v-for="cat in incidentCategories" :key="cat.id">
          <v-list-item-content>
            <v-list-item-title>{{cat.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <IncidentCategoryDialog activator="update" :incident-category="cat" @closeEvent="setDefaultIncidentCategory"/>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon color="red" @click="deleteIncidentCategory(cat.id)"><v-icon>mdi-delete</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import {mapActions} from "pinia";
import {piniaStore} from "@/store/pinia";
import axios from "@/main";
import IncidentCategoryDialog from "@/components/IncidentCategoryDialog.vue";

export default {
  name: "IncidentCategoryManager",
  components: {IncidentCategoryDialog},
  data: () => ({
    defaultIncidentCategory: {
      name: "",
    },
    incidentCategory: null,
    incidentCategories: [],
  }),
  methods: {
    deleteIncidentCategory(id) {
      axios.delete("/api/v1/incident-categories/" + id).then(() => {
        this.getIncidentCategories();
      }).catch(()=>{
        this.setErrorMessage("Fehler beim Abruf der Kategorien")
      })
    },
    getIncidentCategories() {
      axios.get("/api/v1/incident-categories").then((res) => {
        this.incidentCategories = res.data;
      }).catch(()=>{
        this.setErrorMessage("Fehler beim Abruf der Kategorien")
      })
    },
    setDefaultIncidentCategory() {
      this.incidentCategory = Object.assign({}, this.defaultIncidentCategory);
      this.getIncidentCategories();
    },
    ...mapActions(piniaStore, ["setErrorMessage"]),
  },
  created() {
    this.incidentCategory = Object.assign({}, this.defaultIncidentCategory);
    this.getIncidentCategories();
  }
}
</script>

<style scoped>

</style>