<template>
  <v-container>
    <v-card>
      <v-data-table
          :items="costCenters"
          :headers="headers"
          :search="search"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title @click.meta.shift="setAdmin">Kostenstellen</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
                clearable
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="costCenterDialog"
                fullscreen
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  Neue Kostenstelle
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{costCenterDialogTitle}}</v-card-title>
                <v-card-text>
                  <v-form>
                    <v-text-field
                        label="Label"
                        v-model="editedCostcenter.label"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        label="Name"
                        v-model="editedCostcenter.name"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        label="Beschreibung"
                        v-model="editedCostcenter.description"
                        outlined
                    ></v-text-field>
                    <v-select
                        label="Project"
                        v-model="editedCostcenter.projectId"
                        :items="projects"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-folder"
                        outlined
                    ></v-select>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="closeCostcenterDialog" text>Abbrechen</v-btn>
                  <v-btn @click="saveCostcenter" color="primary">Speichern</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-icon class="ml-4" @click="getCostCenters">mdi-sync</v-icon>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              @click="editCostcenter(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteCostcenter(item)"
              v-if="user.email === 'rene@codefive.de'"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import ProjectApi from "@/apis/ProjectApi";

export default {
  name: "CostCenterManager",
  data: () => ({
    projectApi: null,
    projects: [],
    headers: [
      {text: "Label", value: "label"},
      {text: "Name", value: "name"},
      {text: "Aktionen", value: "actions", sortable: false},
    ],
    costCenterDialog: false,
    costCenterDialogTitle: "Neue Kostenstelle anlegen",
    editedCostcenter: {},
    defaultCostcenter: {
      label: "",
      name: "",
      projectId: "",
      description: "",
    },
    search: "",
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  methods: {
    closeCostcenterDialog() {
      this.costCenterDialog = false;
      this.costCenterDialogTitle = "Neue Kostenstelle anlegen";
      this.editedCostcenter = Object.assign({}, this.defaultCostcenter);
    },
    deleteCostcenter(cc) {
      axios.delete(this.baseUrl + '/api/v1/costcenters/' + cc.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log('delete ' + cc.name);
            console.log(response.data);
            //vm.costcenters = vm.costcenters.filter(c => c.id !== cc.id);
            this.getCostCenters();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    editCostcenter(costCenter) {
      this.editedCostcenter = Object.assign({}, costCenter);
      this.costCenterDialogTitle = "Kostenstelle bearbeiten";
      this.costCenterDialog = true;
    },
    saveCostcenter() {
      if(this.editedCostcenter.id) {
        // update
        axios.put(this.baseUrl + '/api/v1/costcenters/' + this.editedCostcenter.id, this.editedCostcenter, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((response) => {
              console.log(response.data);
              this.getCostCenters();
              this.closeCostcenterDialog();
            })
            .catch((error) => {
              console.log(error);
            });
      } else {
        // create
        axios.post(this.baseUrl + '/api/v1/costcenters', this.editedCostcenter, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((response) => {
              console.log(response.data);
              this.getCostCenters();
              this.closeCostcenterDialog();
            })
            .catch((error) => {
              console.log(error);
            });
      }
      this.closeCostcenterDialog();
    },
    getProjects() {
      this.projectApi.getProjects().then((res) => {
        this.projects = res.data;
        console.log(this.projects);
      });
    },
    ...mapActions(piniaStore, ["getCostCenters"]),
  },
  created() {
    this.editedCostcenter = Object.assign({}, this.defaultCostcenter);
    this.projectApi = new ProjectApi();
    this.getProjects();
    this.getCostCenters();
    /*var vm = this;
    axios.get('/api/v1/costcenters')
        .then(function (response){
          console.log("root costCenters:")
          console.log(vm.costCenters)
          console.log("END root costCenters")
          vm.costcenters = response.data.filter(c => c.deleted == null);
          vm.costcenters.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
          vm.costCenters = vm.costcenters;
          console.log(vm.costcenters)
        })
        .catch(function (error) {
          console.log(error);
        }); */
  },
}
</script>