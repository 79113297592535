<template>
<v-container>
  <v-card>
    <v-card-title>Zeitraum Report</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field label="Von" v-model="startDate" outlined></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Bis" v-model="endDate" outlined></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Empf. KSt." v-model="receivingCostCenter" outlined @change="filterByCostCenter"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="getTimetrackEvents">Abfragen</v-btn>
    </v-card-actions>
    <v-card-text>
      <table>
        <tr v-for="entry in timetrackData" :key="entry.id">
          <td>{{getStartDate(entry.start)}}</td>
          <td>{{getDuration(entry.start, entry.end)}}</td>
          <td>0</td>
          <td>{{entry.title}}</td>
          <td>{{getCostCenterFullName(entry.receiving_costcenterid)}}</td>
          <td>{{entry.receivingCostCenterFullName}}</td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import {LocalDateTime, LocalDate, Duration} from "@js-joda/core";
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import NFCReader from "@/components/NFCReader.vue";
import {marked} from "marked";
export default {
  name: "ReportRx",
  data: () => ({
    startDate: "",
    endDate: "",
    receivingCostCenter: "",
    dt: "",
    timetrackData: [],
    originalTimetrackData: [],
  }),
  created() {
    this.dt = LocalDateTime.now();
    this.startDate = LocalDate.from(this.dt);
    this.endDate = LocalDate.from(this.dt);
  },
  methods: {
    getTimetrackEvents() {
      let filterData = {
        startDate: this.startDate.toString(),
        endDate: this.endDate.toString(),
      }
      console.log(filterData)
      axios.post("/api/v1/timetrack/filter", filterData).then((res) => {
        console.log(res.data)
        this.timetrackData = res.data
        this.originalTimetrackData = JSON.parse(JSON.stringify(res.data))
        this.addCostCenterFullName(this.timetrackData)
        this.addCostCenterFullName(this.originalTimetrackData)
        this.timetrackData = this.filterByCostCenter()
      })
    },
    getDuration(start, end) {
      return Duration.between(LocalDateTime.parse(start), LocalDateTime.parse(end)).toMinutes()
    },
    getStartDate(dateTime) {
      return LocalDate.from(LocalDateTime.parse(dateTime)).toString();
    },
    getCostCenterFullName(id) {
      if(id == null || id == '') {
        return ""
      }
      let cc = this.costCenterMap.get(id);
      if(cc !== null) {
        console.log(cc)
        return cc.label + " " + cc.name
      }
      return ""
    },
    addCostCenterFullName(timetrackEvents) {
      timetrackEvents.forEach(tte => {
        tte.receivingCostCenterFullName = this.getCostCenterFullName(tte.receiving_costcenterid)
      })
    },
    filterByCostCenter() {
      let searchString = this.receivingCostCenter
      let unfilteredData = JSON.parse(JSON.stringify(this.originalTimetrackData))
      if(searchString) {
        this.timetrackData = unfilteredData.filter(item => item.receivingCostCenterFullName && item.receivingCostCenterFullName.includes(searchString));
        return unfilteredData.filter(item => item.receivingCostCenterFullName && item.receivingCostCenterFullName.includes(searchString));
      }
      this.timetrackData = unfilteredData
      return unfilteredData
    },
  },
  computed: {
    ...mapState(piniaStore, ["costCenterMap"])
  },
}
</script>

<style scoped>

</style>