export default {
  en: {
    message: {
      hello: 'reload'
    },
    labels: {
      reload: "reload",
      firstname: "First Name",
      lastname: "Last Name",
      tasks: "Tasks",
      template: "Template",
      newTask: "New Task",
      newTemplate: "New Template",
      created: "Created",
      modified: "Modified",
      deleted: "Deleted",
      dailyReport: "Daily Report",
      date: "Date",
      actions: "Actions",
    }
  },
  de: {
    message: {
      hello: 'Neu laden'
    },
    labels: {
      reload: "Neu laden",
      firstname: "Vorname",
      lastname: "Nachname",
      tasks: "Aufgaben",
      template: "Vorlage",
      newTask: "Neue Aufgabe",
      newTemplate: "Neue Vorlage",
      created: "Erstellt",
      modified: "Aktualisiert",
      deleted: "Gelöscht",
      dailyReport: "Tagesbericht",
      date: "Datum",
      actions: "Aktionen",
    }
  }
}