<template>
  <v-container>
    <v-card>
      <v-card-title>Hilfethemen</v-card-title>
      <v-card-text>
        <h2>Willkommen bei Cairos - dein smarter Task- und Prozessmanager.</h2>
        <p>
          Du arbeitest gerade auf einem Entwicklungsstand und es sind noch nicht alle Funktionen fertiggestellt.
          Bei Fragen und Problemen wende dich bitte an <a href="mailto:office@codefive.gmbh">office@codefive.gmbh</a>.
        </p>
        <p>
          Viel Spaß beim ausprobieren.
        </p>
      </v-card-text>
      <v-card-text>
        <h3>FAQ</h3>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>Was sind Aufgaben?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Aufgaben sind eine Beschreibung von Tätigkeiten und deren Bearbeitung kann in Cairos erfasst werden.
              Eine Aufgabe kann gestartet, gestoppt und am Ende auch komplett abgeschlossen werden. Ist eine Aufgabe
              erledigt und damit abgeschlossen, verschwindet sie aus der Aufgabenübersicht.<br>
              Es gibt auch Aufgaben, die einmal angelegt, immer wieder auftreten und erledigt werden können. Diese
              können dann immer wieder zur Zeiterfassung herangezogen werden und müssen nicht abgeschlossen werden.<br>
              Eine Übersicht der erfassten Aufgaben findet sich dann im Tagesbericht.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Was ist ein Tagesbericht?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Der Tagesbericht wird nach Erledigung aller Aufgaben am Ende eines Tages aus den erfassten Zeiten durch
              bearbeite Aufgaben erstellt. In einer Übersicht lassen sich die erfassten Aufgaben und Zeiten ändern,
              löschen oder fehlende Zeiterfassungen manuell hinzufügen.<br>
              Die Änderungen werden im System protokolliert.<br>
              Ein Tagesbericht wird eingereicht und dann durch einen Verantwortlichen geprüft und akzeptiert oder
              abgelehnt. Wenn ein Tagesbericht abgelehnt wird, ist dieser in der Übersicht erkennbar und kann
              korrigiert werden. Dieser muss dann wieder eingereicht werden.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Was sind Meldungen?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Meldungen sind sogenannte Tickets. Hier werden Probleme und deren aktueller Bearbeitungsstand erfasst und
              dokumentiert. Eine Meldung kann einem Bearbeiter, einem Team oder einer Rolle zugewiesen werden.<br>
              <br>
              Eine Meldung hat folgende Zustände:
              <ul>
                <li>Offen</li>
                <li>Angenommen</li>
                <li>In Bearbeitung</li>
                <li>Gelöst</li>
                <li>Geschlossen</li>
                <li>Abgelehnt</li>
              </ul>
              <p>
                Eine Meldung kann von vom Zustand "Offen" in die Zustände "Angenommen" und "In Bearbeitung" gesetzt
                werden.
              </p>
              <p>
                Wenn eine Meldung bearbeitet wurde, wird eine Lösung eingetragen und der Zustand wird auf "Gelöst"
                gesetzt. Der Ersteller der Meldung wird dem Ticket zugewiesen. Dieser hat dann die Möglichkeit die
                Lösung zu akzeptieren oder kann diese ablehnen. Die Zustände sind dann entweder "Geschlossen" oder
                "Abgelehnt".
              </p>
              <p>
                Der Verlauf der Bearbeitung ist in der Historie ersichtlich.<br>Es können auch Kommentare hinterlegt
                werden.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Help",
  data: () => ({
    isEditing: null,
  }),
  computed: {
  },
  methods: {
  },
  mounted: function () {
  },
}
</script>