<template>
<v-container>
  <v-card class="mt-5 d-none d-sm-block">
    <v-toolbar flat>
      <v-toolbar-title>Zuletzt online</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="getServerinfo"><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="serverinfo.lastOnline"
      ></v-data-table>
    </v-card-text>
  </v-card>
  <v-card class="mt-5 d-block d-sm-none">
    <v-card-title>Zuletzt online</v-card-title>
    <v-card-text>
      <v-row dense v-for="entry in serverinfo.lastOnline" :key="entry.user">
        <v-col cols="6" class="font-weight-light">{{entry.user}}</v-col>
        <v-col cols="6" class="font-weight-light">{{entry.lastOnline | dateFormat}}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
    <v-card-title>Tests</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="listUploads">List Uploads</v-btn>
    </v-card-actions>
    <v-card-text>
      <div v-for="upl in uploads" :key="upl">
        <v-img :src="baseUrl + '/uploads/' + upl" max-height="50px"></v-img>
        <a :href="baseUrl + '/uploads/' + upl" ><span>/uploads/{{upl}}</span></a>
      </div>
    </v-card-text>
    <v-card-text>
      <pre>
        {{keycloak.tokenParsed}}
      </pre>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import home from "@/views/Home.vue";

export default {
  name: "AdminView2",
  data: () => ({
    serverinfo: [],
    serverinfoInterval: null,

    headers: [
      {
        text: 'User',
        value: 'user',
      },
      {
        text: 'Zuletzt online',
        value: 'lastOnline',
      },
    ],

    returnData: "",

    uploads: [],
  }),
  computed: {
    home() {
      return home
    },
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['keycloak']),
  },
  methods: {
    getServerinfo() {
      axios.get(this.baseUrl + '/api/v1/serverinfo')
          .then((response) => {
            this.serverinfo = response.data;
            this.setSuccessMessage("Daten aktualisiert")
          })
          .catch((err)=>{
            console.log(err)
            this.setErrorMessage("Fehler beim Laden der Daten")
          })
    },
    testSnackbar() {
      this.setErrorMessage("Das ist ein etwas längerer Text um das Layout zu testen. Und noch etwas längher. Schauen wir mal...");
    },
    listUploads() {
      axios.get("/api/v1/upload").then((res) => {
        console.log(res.data)
        this.uploads = res.data;
      })
    },
    ...mapActions(piniaStore, ["setErrorMessage"]),
    ...mapActions(piniaStore, ["setSuccessMessage"]),
  },
  created() {
    this.getServerinfo();
    this.serverinfoInterval = setInterval(() => {
      this.getServerinfo();
    }, 60000);
  },
}
</script>

<style scoped>

</style>