<template>
<v-container>
  <v-card>
    <v-card-title>Rocket Hero</v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="leads"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Leads ({{leads.length}})</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field label="Suche"></v-text-field>
            <v-select label="Filter"></v-select>
            <v-dialog
              v-model="leadDialog"
              fullscreen
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  Neuer Lead (manuell)
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{leadDialogTitle}}</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="editedLead.name"
                    label="Name"
                  ></v-text-field>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="PLZ"
                        v-model="editedLead.zip"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          label="Ort"
                          v-model="editedLead.city"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-select :items="states" v-model="editedLead.state"></v-select>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="closeLeadDialog">Abbrechen</v-btn>
                  <v-btn @click="save">Speichern</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.state="{ item }">
          <v-chip v-if="item.state === 'Qualifiziert'" color="green" text-color="white">{{item.state}}</v-chip>
          <v-chip v-if="item.state === 'in Bearbeitung'" color="yellow">{{item.state}}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click="editLead(item)">mdi-pencil</v-icon>
          <v-icon>mdi-delete</v-icon>
        </template>
        <template v-slot:item.created="{ item }">
          {{item.created | dateFormatSimple}}
        </template>
        <template v-slot:item.updated="{ item }">
          {{item.updated | dateFormatSimple}}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
const JSJoda = require("@js-joda/core");
export default {
  name: "RocketHero",
  data: () => ({
    headers: [
      {text: 'Source', value: 'source'},
      {text: 'Status', value: 'state'},
      {text: 'Datum', value: 'created'},
      {text: 'Name', value: 'name'},
      {text: 'PLZ', value: 'zip'},
      {text: 'Ort', value: 'city'},
      {text: 'Preis', value: 'price'},
      {text: 'Provision', value: 'commission'},
      {text: 'Typ', value: 'commissionType'},
      {text: 'Angebot', value: 'offers'},
      {text: 'Käufe', value: 'bought'},
      {text: 'Aktionen', value: 'actions', sortable: false},
    ],
    leads: [
      {
        id: 1,
        source: 'Instagram',
        state: 'Qualifiziert',
        created: "2022-04-02T09:11:00",
        name: "Max Mustermann",
        zip: "90408",
        city: "Nürnberg",
        offers: 2,
        bought: 1,
      },
      {
        id: 2,
        source: 'Landing Page',
        state: 'in Bearbeitung',
        created: "2022-04-02T10:27:00",
        name: "Claudia Schulz",
        zip: "1010",
        city: "Wien",
        offers: 2,
        bought: 0,
      }
    ],
    editedLead: {},
    defaultLead: {
      source: 'Manuell',
      state: '',
      created: JSJoda.LocalDateTime.now(),
    },
    leadDialog: false,
    leadDialogTitle: 'Neuer Lead',
    states: [
        'in Bearbeitung',
        'Qualifiziert',
    ],
  }),
  computed: {},
  methods: {
    closeLeadDialog() {
      this.leadDialog = false;
      this.editedLead = Object.assign({}, this.defaultLead);
      this.editedLead.id = this.leads.length + 1;
    },
    editLead(lead) {
      this.leadDialog = true;
      this.leadDialogTitle = 'Lead bearbeiten';
      this.editedLead = Object.assign({}, lead);
    },
    save() {
      this.leads.push(this.editedLead);
      this.closeLeadDialog();
    },
  },
  mounted: function () {
    this.editedLead = Object.assign({}, this.defaultLead);
    this.leadDialogTitle = 'Neuer Lead';
  },
}
</script>

<style scoped>

</style>