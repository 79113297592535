<template>
  <v-container>
    <v-card>
      <v-card-title>Rechteverwaltung</v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="permissions"
            item-key="id"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="openPermission(item)"
            >
              mdi-dots-horizontal
            </v-icon>
            <v-icon
                small
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deletePermission(item.id)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
    <v-card class="mt-3">
      <v-card-title>Berechtigung hinzufügen</v-card-title>
      <v-card-text>
        <v-text-field
            label="Name"
            v-model="permission.label"
            @keydown.enter.prevent="addPermission"
        ></v-text-field>
        <v-text-field
            label="Beschreibung"
            v-model="permission.description"
            @keydown.enter.prevent="addPermission"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="addPermission">Speichern</v-btn>
        <v-btn @click="resetPermission">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from "@/main";

export default {
  name: "PermissionManager",
  data: () => ({
    isEditing: null,
    headers: [
      {
        text: 'Name',
        value: 'label'
      },
      {
        text: 'Beschreibung',
        value: 'description'
      },
      {
        text: 'Erstellt',
        value: 'created'
      },
      {
        text: 'Aktualisiert',
        value: 'updated'
      },
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
    permission: {
      label: '',
      description: '',
    },
    permissions: [],
  }),
  methods: {
    getPermissions() {
      let vm = this;
      axios.get('/api/v1/permissions')
      .then(function (res){
        vm.permissions = res.data;
      })
      .catch(function (){
        console.log("There was an error fetching permissions");
      });
    },
    addPermission() {
      let vm = this;
      axios.post('/api/v1/permissions', this.permission)
          .then(function (res){
            vm.permissions.push(res.data);
            vm.resetPermission();
          })
          .catch(function (){
            console.log("There was an error fetching permissions");
          });
    },
    openPermission(item) {

    },
    deletePermission(id) {
      let vm = this;
      axios.delete('/api/v1/permissions/' + id)
          .then(function (){
            vm.permissions = vm.permissions.filter(e => e.id !== id);
          })
          .catch(function (){
            console.log("There was an error deleting the permission");
          });
    },
    resetPermission() {
      this.permission = {
        label: '',
        description: '',
      };
    },
  },
  created: function () {
    this.getPermissions();
  },
}
</script>

<style scoped>

</style>