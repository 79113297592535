<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="roles"
            item-key="id"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title @click.meta.shift="setAdmin">Rollen</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="roleDialog"
                fullscreen
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                  >
                    Neue Rolle
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{roleDialogTitle}}</v-card-title>
                  <v-card-text>
                    <v-form
                        ref="form"
                        v-model="roleFormValid"
                    >
                      <v-text-field
                          label="Name"
                          v-model="editedRole.name"
                      ></v-text-field>
                      <v-text-field
                          label="Beschreibung"
                          v-model="editedRole.description"
                      ></v-text-field>
                      <v-switch label="Systemrolle" v-model="editedRole.systemRole"></v-switch>
                      <v-select
                          v-model="editedRole.users"
                          :items="users"
                          item-text="lastname"
                          item-value="id"
                          label="Benutzer"
                          multiple
                          chips
                      ></v-select>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeRoleDialog" outlined>Abbrechen</v-btn>
                    <v-btn @click="save" outlined>Speichern</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  v-model="roleDeleteDialog"
                  max-width="500px"
              >
                <v-card>
                  <v-card-title><v-icon color="red" class="mr-3">mdi-delete-empty</v-icon>Rolle wirklich löschen?</v-card-title>
                  <v-card-text>
                    Das löschen kann nicht mehr rückgängig gemacht werden.
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteRoleDialog" outlined>Abbrechen</v-btn>
                    <v-btn @click="deleteRoleConfirm" outlined color="red">Löschen</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn icon @click="getRoles"><v-icon>mdi-sync</v-icon></v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.created="{ item }">
            {{item.created | dateFormatSimple}}
          </template>
          <template v-slot:item.updated="{ item }">
            {{item.updated | dateFormatSimple}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                @click="editRole(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                class="ml-3"
                color="red"
                small
                @click="openDeleteRoleDialog(item)"
                v-if="item.id !== '00000000-0000-0000-0000-000000000001' && item.name !== 'admin'"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Systemrollen</v-card-title>
      <v-card-text>
        <v-list v-for="sysRole in systemRoles" :key="sysRole">
          <v-list-item>
            <v-list-item-title>{{ sysRole }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-snackbar
        v-model="snackbar"
        :timeout="3000"
    >
      {{message}}
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import WorkspaceDataApi from "@/apis/WorkspaceDataApi";
export default {
  name: "RoleManager",
  data: () => ({
    // APIs
    workspaceDataApi: null,

    isAdmin: false,
    isEditing: null,
    //roles: [],
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Beschreibung',
        value: 'description'
      },
      {
        text: 'Erstellt',
        value: 'created'
      },
      {
        text: 'Aktualisiert',
        value: 'updated'
      },
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
    role: {
      name: '',
      description: '',
      systemRole: false,
    },
    systemRoles: [],
    roles: [],
    editedRole: {},
    defaultRole: {},
    roleDialog: false,
    roleDialogTitle: 'Rolle erstellen',
    roleDeleteDialog: false,
    roleFormValid: true,
    snackbar: false,
    message: '',
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token', 'users']),
  },
  methods: {
    addRole() {
      if (this.editedRole.name === '') {
        return;
      }
      this.workspaceDataApi.createRole(this.editedRole).then((response) => {
        this.getRoles();
        this.closeRoleDialog();
        this.message = 'Rolle erfolgreich hinzugefügt';
        this.snackbar = true;
      })
      .catch((error)=>{
      });
    },
    closeDeleteRoleDialog() {
      this.roleDeleteDialog = false;
      this.editedRole = Object.assign({}, this.defaultRole);
    },
    closeRoleDialog() {
      this.roleDialog = false;
      this.editedRole = Object.assign({}, this.defaultRole);
      this.roleDialogTitle = "Rolle erstellen";
    },
    deleteRoleConfirm() {
      if (this.editedRole.id === '00000000-0000-0000-0000-000000000001') {
        return; //super admin
      }
      if (this.editedRole.name === 'admin') {
        return;
      }
      if (this.editedRole.systemRole === true) {
        if (this.payload.user.email !== "rene@codefive.de") {
          this.message = 'Rolle kann nicht gelöscht werden. Fehlende Berechtigung.';
          this.snackbar = true;
          return;
        }
      }
      this.workspaceDataApi.deleteRole(this.editedRole.id).then((response) => {
        console.log(response.data)
        console.log("Deleted role successfully")
        this.roles = this.roles.filter(b => b.id !== this.editedRole.id)
        this.getRoles();
        this.closeDeleteRoleDialog();
      })
      .catch((error) => {
        console.log("There was a problem deleting this role");
        console.log(error);
        this.message = 'Rolle konnte nicht gelöscht werden.';
        this.snackbar = true;
      });
    },
    editRole(role) {
      this.editedRole = Object.assign({}, role);
      this.roleDialogTitle = "Rolle bearbeiten";
      this.roleDialog = true;
    },
    getRoles() {
      this.workspaceDataApi.getRoles().then((response) => {
        console.log(response.data)
        this.roles = response.data;
        this.message = 'Rollen aktualisiert';
        this.snackbar = true;
      })
      .catch((error) => {
        console.log("There was an error fetching roles");
        console.log(error);
        this.message = 'Fehler beim Synchronisieren';
        this.snackbar = true;
      });
    },
    getSystemRoles() {
      this.workspaceDataApi.getSystemRoles()
          .then((response) => {
            console.log(response.data)
            this.systemRoles = response.data;
            this.message = 'Rollen aktualisiert';
            this.snackbar = true;
          })
          .catch((error) => {
            console.log("There was an error fetching roles");
            console.log(error);
            this.message = 'Fehler beim Synchronisieren';
            this.snackbar = true;
          });
    },
    openDeleteRoleDialog(role) {
      this.editedRole = Object.assign({}, role);
      this.roleDeleteDialog = true;
    },
    save() {
      console.dir(this.editedRole);
      if(this.roleDialogTitle === "Rolle bearbeiten") {
        this.updateRole();
      } else {
        this.addRole();
      }
    },
    updateRole() {
      if (this.editedRole.name === '') {
        return;
      }
      this.workspaceDataApi.updateRole(this.editedRole).then((response) => {
        this.getRoles();
        this.closeRoleDialog();
        this.message = 'Rolle gespeichert';
        this.snackbar = true;
      })
      .catch((error)=>{});
    },
  },
  mounted: function () {
    this.workspaceDataApi = new WorkspaceDataApi();
    this.getRoles();
    this.getSystemRoles();
  },
};
</script>