<template>
  <v-card class="mx-1">
    <v-card-title>Benutzerprofil</v-card-title>
    <v-card-subtitle>{{id}}</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="Vorname"
              v-model="currentUser.firstname"
              readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="Nachname"
              v-model="currentUser.lastname"
              readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
          label="Email"
          v-model="currentUser.email"
          readonly
      ></v-text-field>
      <v-row>
        <v-col cols="4">
          <v-img :src="currentUser.avatar" />
        </v-col>
        <v-col cols="8">
          <v-file-input
              label="Profilbild"
              accept="image/png, image/jpeg"
              show-size
              prepend-icon="mdi-camera"
          ></v-file-input>
          <v-btn>Profilbild hochladen</v-btn>
        </v-col>
      </v-row>
      <input type="file" accept="image/*">
    </v-card-text>
    <v-card-actions>
      <v-btn>Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "UserProfile",
  props: ['id'],
  data: () => ({
    isMyProfile: false,
  }),
  computed: {
    ...mapState(['currentUser']),
  },
  created: function () {
    if (this.currentUser.id === this.id) {
      this.isMyProfile = true;
    }
  },
};
</script>