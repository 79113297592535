<template>
  <v-container>
    <v-row :key="task.id">
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-icon>mdi-format-list-bulleted</v-icon>
            <v-toolbar-title class="font-weight-light" style="margin-left: 5px;">
              Task bearbeiten
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-toolbar>
          <v-card-text>
            In dieser Ansicht können Manager Tasks für Mitarbeiter bearbeiten.
          </v-card-text>
          <v-card-text>
            <v-text-field
                label="Titel"
                v-model="task.title"
            ></v-text-field>
            <v-textarea
                label="Beschreibung"
                v-model="task.description"
            ></v-textarea>
            <v-autocomplete
                label="Team"
                filled
                chips
                color="blue-grey lighten-2"
                v-model="task.assignedTeams"
                :items="teams"
                item-text="name"
                item-value="id"
                multiple
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeTeam(task, data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-autocomplete
                label="Rolle"
                filled
                chips
                color="blue-grey lighten-2"
                v-model="task.assignedRoles"
                :items="roles"
                item-text="name"
                item-value="id"
                multiple
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeRole(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.avatar"></v-img>
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <img :src="data.item.avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-autocomplete
                label="Benutzer"
                filled
                chips
                color="blue-grey lighten-2"
                v-model="task.assignedUsers"
                :items="users"
                item-text="lastname"
                item-value="id"
                multiple
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeUser(task, data.item)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.avatar"></v-img>
                  </v-avatar>
                  {{ data.item.lastname }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <img :src="data.item.avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.lastname"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.firstname"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-select
                v-model="task.assignedUser"
                :items="users"
                item-value="id"
                single-line
                single
            >
              <template v-slot:item="data">
                <v-list-item-content>{{data.item.lastname}}, {{data.item.firstname}}</v-list-item-content>
              </template>
              <template v-slot:selection="data">
                {{ data.item.lastname }}, {{ data.item.firstname }}
              </template>
            </v-select>
            <v-select
                v-model="task.receiving_costcenterid"
                :items="costCenters"
                item-value="id"
                label="Empfangende Kostenstelle"
                single-line
                single
            >
              <template v-slot:item="data">
                <v-list-item-content>{{data.item.name}}, {{data.item.label}}</v-list-item-content>
              </template>
              <template v-slot:selection="data">
                {{ data.item.name }}, {{ data.item.label }}
              </template>
            </v-select>
            <v-select
                v-model="task.supplying_costcenterid"
                :items="costCenters"
                item-value="id"
                label="Leistende Kostenstelle"
                single-line
                single
            >
              <template v-slot:item="data">
                <v-list-item-content>{{data.item.name}}, {{data.item.label}}</v-list-item-content>
              </template>
              <template v-slot:selection="data">
                {{ data.item.name }}, {{ data.item.label }}
              </template>
            </v-select>
            <v-select
                v-model="task.status"
                :items="status"
                item-text="label"
                item-value="state"
                label="Status"
                single-line
            ></v-select>
            <v-text-field
                label="Erstellt von"
                v-model="task.createdBy"
                disabled
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
                class="mr-3"
                @click="saveTask(task)"
            >Speichern</v-btn>
            <v-btn v-if="payload.user.email === 'rene@codefive.de'"
                @click="deleteTask(task)"
            >Löschen</v-btn>
            <v-btn v-if="payload.user.email === 'rene@codefive.de'"
                   @click="stopTaskAdmin(task.id)"
            >Stoppen</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                @click="back()"
            >Zurück</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
export default {
  name: "TaskManagerSingle",
  props: ['action'],
  data: () => ({
    task_dialog: false,
    task: {},
  }),
  methods: {
    back() {
      this.$router.push('/taskmanager')
    },
    deleteTask(task) {
      axios.delete(this.baseUrl + '/api/v1/tasks/' + task.id, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((response) => {
            console.log(response.data)
            this.$router.push('/taskmanager')
          })
          .catch((error) => {
            console.log(error);
          });
    },
    saveTask(task) {
      console.log(this.task)
      axios.put(this.baseUrl + '/api/v1/tasks/' + task.id, task, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((response) => {
            console.log('Task gespeichert')
            console.log(response.data)
            this.task = response.data
            this.$router.push('/taskmanager')
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getTask(id) {
      axios.get(this.baseUrl + "/api/v1/tasks/" + id, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((response) => {
            console.log('Task ' + id)
            console.log(response.data)
            this.task = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    ...mapActions(piniaStore, ["getCostCenters"]),
    ...mapActions(piniaStore, ["getRoles"]),
    ...mapActions(piniaStore, ["getTeams"]),
    ...mapActions(piniaStore, ["getUsers"]),
    ...mapActions(piniaStore, ["stopTaskAdmin"]),
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    status: function() {
      return this.$root.$children[0].$data.taskStatus
    },
    ...mapState(piniaStore, ['payload']),
    ...mapState(piniaStore, ['token']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['roles']),
    ...mapState(piniaStore, ['teams']),
    ...mapState(piniaStore, ['users']),
  },
  mounted() {
    console.log('task ID is ' + this.$route.params.id);
    this.getCostCenters();
    this.getUsers();
    this.getTeams();
    this.getRoles();
    this.getTask(this.$route.params.id);
  },
};
</script>