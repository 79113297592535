<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list v-if="authorized === false">
        <v-list-item-group color="primary">
          <v-list-item @click="refreshCairos">
            <v-list-item-content>
              <v-list-item-title>Neu laden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content @click="logoutKeycloak">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list v-else>
        <v-list-item v-if="user">
          <v-list-item-avatar>
            <v-img :src="getUserAvatar()"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ user.firstname }} {{ user.lastname }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group color="primary">
          <v-list-item to="/home" v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/reservations" v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
            <v-list-item-icon><v-icon>mdi-format-list-checks</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Reservierungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/listings" v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
            <v-list-item-icon><v-icon>mdi-format-list-checks</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Listings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/tasks">
            <v-list-item-icon><v-icon>mdi-format-list-checks</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meine Tasks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item to="/tasks2" v-if="payload.user.email === 'rene@codefive.de'"> -->
          <v-list-item to="/tasks2" v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
            <v-list-item-icon><v-icon>mdi-numeric-2-circle-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meine Tasks 2</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/tasks3" v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
            <v-list-item-icon><v-icon>mdi-numeric-3-circle-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meine Tasks 3</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/rtasks" v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
            <v-list-item-icon><v-icon>mdi-numeric-4-circle-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meine Tasks 4</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              to="/report-single"
          >
            <v-list-item-icon><v-icon>mdi-file-document-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("labels.dailyReport") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/report-list"
          >
            <v-list-item-icon><v-icon>mdi-file-document-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tagesberichte (Liste)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/reports"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meine Tagesberichte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.realm_access.roles.includes('super-admin') || keycloak.tokenParsed.realm_access.roles.includes('uweg-admin') || user.role === 'super-admin' || user.role === 'uweg-admin' || user.role === 'manager' || keycloak.tokenParsed.realm_access.roles.includes('manager')"
              to="/reports-manager"
          >
            <v-list-item-icon><v-icon>mdi-file-document-multiple-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tagesberichte (Manager)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              to="/jobs"
              v-if="
              hostname.startsWith('uweg') ||
              hostname.startsWith('localhost')
              "
          >
            <v-list-item-icon><v-icon>mdi-view-list</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Aufträge</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              prepend-icon="mdi-test-tube"
          >
            <template v-slot:activator>
              <v-list-item-title>Prototyp</v-list-item-title>
            </template>
            <v-list-item
                to="/finance"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Finanzen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/report-rx"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Zeitraum</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/invoices"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Rechnungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/notes"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Notizen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/profile2"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Profil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/projects"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Projekte</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/project-plans"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Projektplanung</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/project-tasks"
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            >
              <v-list-item-content>
                <v-list-item-title>Projektaufgaben</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/bulk"
                v-if="
              hostname.startsWith('codefive') ||
              hostname.startsWith('localhost')
            "
            >
              <v-list-item-content>
                <v-list-item-title>C5 Tasks</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/simple"
                v-if="
              hostname.startsWith('codefive') ||
              hostname.startsWith('wastics') ||
              hostname.startsWith('localhost')
            "
            >
              <v-list-item-content>
                <v-list-item-title>Simple Tasks</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="
              hostname.startsWith('wastics') ||
              hostname.startsWith('localhost')
              "
                to="/leads"
            >
              <v-list-item-content>
                <v-list-item-title>Leads</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
                to="/tools"
            >
              <v-list-item-content>
                <v-list-item-title>Tools</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
                to="/rockethero"
            >
              <v-list-item-content>
                <v-list-item-title>Rocket Hero</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
                to="/checkin-manager"
            >
              <v-list-item-content>
                <v-list-item-title>Checkin Verwaltung</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/taskmanager"
          >
            <v-list-item-icon><v-icon>mdi-playlist-play</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Task Manager</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.realm_access.roles.includes('super-admin')"
              to="/taskmanager2"
          >
            <v-list-item-icon><v-icon>mdi-playlist-play</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Task Manager 2</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/spaces"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Spaces</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/incidents">
            <v-list-item-icon><v-icon>mdi-toolbox</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meldungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/settings"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              prepend-icon="mdi-cog"
          >
            <template v-slot:activator>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </template>
            <v-list-item
                to="/profile"
            >
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profil bearbeiten</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/usermanager"
                v-if="keycloak.tokenParsed.email == 'rene@codefive.de'"
            >
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Benutzerverwaltung</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/teams"
                v-if="keycloak.tokenParsed.email == 'rene@codefive.de'"
            >
              <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Teams</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/roles"
                v-if="keycloak.tokenParsed.email == 'rene@codefive.de'"
            >
              <v-list-item-icon><v-icon>mdi-shield-account</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Rollen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/permissions"
                v-if="keycloak.tokenParsed.email == 'rene@codefive.de'"
            >
              <v-list-item-icon><v-icon>mdi-shield-lock</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Berechtigungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/costcenters"
                v-if="keycloak.tokenParsed.email == 'rene@codefive.de' || keycloak.tokenParsed.email == 'markus@codefive.de'"
            >
              <v-list-item-icon><v-icon>mdi-domain</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Kostenstellen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/bonuses"
                v-if="keycloak.tokenParsed.email == 'rene@codefive.de' || keycloak.tokenParsed.email == 'markus@codefive.de'"
            >
              <v-list-item-icon><v-icon>mdi-one-up</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Zuschläge</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/incident-categories"
                v-if="keycloak.tokenParsed.email == 'rene@codefive.de' || keycloak.tokenParsed.email == 'markus@codefive.de'"
            >
              <v-list-item-icon><v-icon>mdi-label-multiple</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Kategorien</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                to="/change-password"
            >
              <v-list-item-icon><v-icon>mdi-lock-reset</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Passwort ändern</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de' || keycloak.tokenParsed.email === 'mhfp@uweg.at'"
              to="/admin-last-online"
          >
            <v-list-item-icon><v-icon>mdi-history</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Zuletzt online</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            to="/users-deleted"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Benutzer Papierkorb</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/realms"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Realms</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/report"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("labels.dailyReport") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/connectivity"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Connectivity Test</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/help">
            <v-list-item-icon><v-icon>mdi-help-circle</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hilfe</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-content @click="logoutKeycloak">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            to="/admin-info"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Version 0.7.4</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              to="/media"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Media</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
            to="/focus"
          >
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Focus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="refreshCairos" v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
            <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Neu laden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="transparent" elevate-on-scroll app style="backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px);">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-img src="/cairos-logo-alpha-xs.png" max-height="40" contain v-on:click.shift.meta="settingsDialog"></v-img>
      <v-menu offset-y v-if="keycloak.tokenParsed.email === 'rene@codefive.de'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          ><v-icon>mdi-message-outline</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(msg, index) in messages"
              :key="index"
          >
            <v-list-item-icon v-if="msg.type === 'message'"><v-icon>mdi-message-text</v-icon></v-list-item-icon>
            <v-list-item-icon v-if="msg.type === 'incident'"><v-icon>mdi-toolbox</v-icon></v-list-item-icon>
            <v-list-item-icon v-if="msg.type === 'task'"><v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ msg.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ msg.title }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon><v-icon>mdi-open-in-new</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->
    <!-- <v-main v-if="authorized === false">
      <a href="/login">Bitte anmelden</a>
    </v-main> -->
    <!-- <v-main v-if="authorized == false">
      <v-card>
        <v-card-title>Versuche login...</v-card-title>
        <v-card-text>Prüfe Sitzungsdaten. Falls Sie nicht angemeldet werden können, werden Sie zur Login-Seite weitergeleitet.</v-card-text>
        <v-card-actions>
          <v-btn to="/login" :disabled="loginBtnDisabled">zum Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-main>
    <v-main v-else> -->
    <v-main v-if="authorized">
      <v-alert
          v-if="user.deactivated"
          prominent
          type="primary"
      >Nicht freigeschaltet</v-alert>
      <v-slide-x-transition mode="out-in">
        <router-view/>
      </v-slide-x-transition>
      <v-sheet style="height: 5rem;"></v-sheet>
      <v-dialog
        v-model="baseUrlDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="baseUrlDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Settings</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-title>Base Url</v-card-title>
          <v-card-text>
            <v-text-field
                label="Baseurl (calculated)"
                v-model="baseUrl"
                disabled
            ></v-text-field>
            <v-text-field
                label="Baseurl (manuell)"
                v-model="baseUrlManual"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="getStatus"><v-icon>mdi-sync</v-icon> Status sync</v-btn>
            <v-btn @click="stopAllTasks"><v-icon>mdi-alert</v-icon> Stop All Tasks</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Checkin Feature disabled for rollout - Checkin Dialog deleted -->
      <GlobalSnackbar />
      <v-snackbar
          v-model="offline"
          timeout="-1"
      >
        {{offlineMessage}}
      </v-snackbar>
    </v-main>
    <v-main v-else>
      <v-container>
        <v-alert
            prominent
            type="primary"
        >Nicht angemeldet</v-alert>
        <v-progress-circular indeterminate size="50"><v-icon>mdi-weather-cloudy-clock</v-icon></v-progress-circular>
      </v-container>
    </v-main>
    <v-bottom-navigation
        fixed
        color="primary"
        grow
        app
        v-if="authorized"
    >
      <!-- <CheckinDialogPrototype></CheckinDialogPrototype> -->
      <!-- Checkin Feature deactivated for first rollout -->
      <!--
      <v-btn
          v-if="checkstatus.type === 'checkout'"
          @click.meta="checkin()"
          @click="openCheckinDialog"
          color="#ff55aa"
          :disabled="!statusLoaded"
      >
        <span style="color: #000000;">Checkin</span>
        <v-icon color="black">mdi-login</v-icon>
      </v-btn>
      <v-btn
          v-else
          @click.meta="checkout()"
          @click="openCheckinDialog"
          color="#49fcd4"
          :disabled="!statusLoaded"
      >
        <span style="color: #000000;">Checkout</span>
        <v-icon color="black">mdi-logout</v-icon>
      </v-btn> -->
      <v-btn
          to="/tasks"
      >
        <span>Tasks</span>
        <v-badge
            color="pink"
            overlap
            icon="mdi-pause"
            v-if="runningTaskBadge"
        >
          <v-icon>mdi-format-list-checks</v-icon>
        </v-badge>
        <v-icon v-else >mdi-format-list-checks</v-icon>
      </v-btn>
      <v-btn
          to="/incidents"
      >
        <span>Meldungen</span>
        <v-icon>mdi-toolbox</v-icon>
      </v-btn>
      <v-btn
          to="/report-single"
      >
        <span>{{ $t("labels.dailyReport") }}</span>
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>
      <!--
      <v-btn
          to="/notifications"
      >
        <span>Mitteilungen</span>
        <v-badge
            color="pink"
            overlap
            :content="messageNotification"
            v-if="messageNotification > 0"
        >
          <v-icon>mdi-message-text</v-icon>
        </v-badge>
        <v-icon v-else>mdi-message-text</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import axios from "@/main";
import { mapState, mapActions } from 'pinia';
import {piniaStore} from "@/store/pinia";
import Config from "@/apis/Config";
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import GlobalSnackbar from "@/components/GlobalSnackbar.vue";
/*const axiosInstance = axios.create({
  baseURL: 'http://codefive.codefive.five:50001'
});*/
export default {
  name: "App",
  components: {GlobalSnackbar},
  data: () => ({
    isAuthorized: false,
    feature: false,
    loginBtnDisabled: true,
    // APIs
    timeTrackingApi: null,
    //global app data (teams, users, etc)
    users: [],
    bonuses: [],
    //incidents: [],
    //roles: [],
    permissions: [],
    drawer: false,
    closedDrawer: false,
    baseUrlDialog: false,
    baseUrlManual: "",
    hostname: "",
    tasks: [],
    selected: "mytasks",
    task_dialog: false,
    messages: [
      {
        title: "Nachricht 1",
        type: "message",
      },
      {
        title: "Neue Meldung",
        type: "incident",
      },
      {
        title: "Neuer Task",
        type: "task",
      },
    ],
    running: null,
    runningTask: {},
    runningTaskInterval: null,
    runningTaskData: {
      laufzeit: "0 min",
      hour: 0,
      currentRuntime: "",
    },
    wakeLock: null,
    taskStatus: [
      { label: "Erstellt", state: "created" },
      { label: "In Bearbeitung", state: "running" },
      { label: "Gestoppt", state: "stopped" },
      { label: "Beendet", state: "finished" },
      /*{label: 'Pausiert', state: 'paused'},
        {label: 'Warten', state: 'waiting'},
        {label: 'Abgebrochen', state: 'aborted'},*/
    ],
    jobs: [],
    jobDetails: [],
    //messageNotification: 0,
    errorMessage: '',
    errorLocation: '',
    coords: null,
    updateInterval: null,
    offlineMessage: "Keine Verbindung zum Server. Verbindung wird automatisch wiederhergestellt. Bitte warten.",
    checkinDialog: false,
    checkinMessage: "",
  }),
  computed: {
    baseUrl() {
      if(process.env.NODE_ENV === 'development') {
        console.log("called");
      }
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    messageNotification: function () {
      //return this.notifications.filter(m => m.read == false).length;
      return 0;
    },
    ...mapState(piniaStore, ['authorized']),
    ...mapState(piniaStore, ['checkstatus']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['notifications']),
    ...mapState(piniaStore, ['offline']),
    ...mapState(piniaStore, ['runningTaskId']),
    ...mapState(piniaStore, ['runningTaskBadge']),
    ...mapState(piniaStore, ['statusLoaded']),
    ...mapState(piniaStore, ['tasksCurrent']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  methods: {
    closeSnackbar() {
      this.setGlobalSnackbar(false);
    },
    getUserAvatar() {
      if(this.user.avatar) {
        if(this.user.avatar.startsWith("/")) {
          return Config.baseUrl() + this.user.avatar;
        } else {
          return this.user.avatar;
        }
      }
    },
    openCheckinDialog() {
      this.checkinMessage = "";
      this.getStatus();
      this.checkinDialog = true;
    },
    closeCheckinDialog() {
      this.checkinMessage = ""
      this.checkinDialog = false;
    },
    settingsDialog() {
      console.log("Settings dialog open");
      this.baseUrlDialog = true;
    },
    goHome() {
      this.$router.push('/');
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.setPosition, this.setLocationError);
      } else {
        this.errorMessage = "Geolocation is not supported by this browser.";
      }
    },
    setLocationError(error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          this.errorLocation = "User denied the request for Geolocation."
          break;
        case error.POSITION_UNAVAILABLE:
          this.errorLocation = "Location information is unavailable."
          break;
        case error.TIMEOUT:
          this.errorLocation = "The request to get user location timed out."
          break;
        case error.UNKNOWN_ERROR:
          this.errorLocation = "An unknown error occurred."
          break;
      }
    },
    setPosition(position) {
      this.coords = {
        lat: position.coords.latitude + "",
        lng: position.coords.longitude + "",
        accuracy: position.coords.accuracy + "",
        altitude: position.coords.altitude + "",
        altitudeAccuracy: position.coords.altitudeAccuracy + "",
        heading: position.coords.heading + "",
        speed: position.coords.speed + "",
        timestamp: position.timestamp + "",
      }
    },
    getData(callback) {
      this.getTeams();
      this.getUsers(); //pinia
      callback();
    },
    checkin() {
      this.getLocation();
      axios.post(this.baseUrl + '/api/v1/checkin', {
        geolocation: this.coords,
        error: this.errorLocation
      }, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            this.getStatus();
            this.closeCheckinDialog();
          })
          .catch((error) => {
            console.log(error);
            this.checkinMessage = "Fehler bei Checkin. Bitte nochmal versuchen."
          });
    },
    checkout() {
      this.getLocation();
      axios.post(this.baseUrl + '/api/v1/checkout', {
        geolocation: this.coords,
        error: this.errorLocation
      }, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            this.getStatus();
            this.closeCheckinDialog();
          })
          .catch((error) => {
            console.log(error);
            this.checkinMessage = "Fehler bei Checkin. Bitte nochmal versuchen."
          });
    },
    logoutKeycloak() {
      if(this.keycloak) {
        this.keycloak.logout();
      }
    },
    pushFeatureDetect() {
      if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        return;
      }

      if (!('PushManager' in window)) {
        // Push isn't supported on this browser, disable or hide UI.
        return;
      }
    },
    registerServiceWorker() {
      return navigator.serviceWorker.register('/js/push/service-worker.js')
          .then(function(registration) {
            console.log('Service worker successfully registered.');
            return registration;
          })
          .catch(function(err) {
            console.error('Unable to register service worker.', err);
          });
    },
    /* getNotifications2(id, callback) {
      this.getNotifications();
      this.messages = this.notifications;
      /*var vm = this;
      axios.get('/api/v1/notifications/' + id)
          .then(function (response){
            vm.messages = response.data;
            vm.messages.sort((a,b) => (a.created > b.created) ? -1 : ((b.created > a.created) ? 1 : 0))
            callback()
          })
          .catch(function (error) {
            console.log(error);
          });
    },*/
    getJobs(callback) {
      axios.get(this.baseUrl + '/api/v1/uweg/headers', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            if(process.env.NODE_ENV === 'development') {
              console.log(response.data);
            }
            this.jobs = response.data;
            this.jobs.sort((a,b) => (a.Order_Date > b.Order_Date) ? 1 : ((b.Order_Date > a.Order_Date) ? -1 : 0)).reverse();
            callback();
          })
          .catch((error)=>{});
    },
    getJobDetails(callback) {
      axios.get(this.baseUrl + '/api/v1/uweg/lines', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            if(process.env.NODE_ENV === 'development') {
              console.log(response.data);
            }
            this.jobDetails = response.data;
            this.jobDetails.sort((a,b) => (a.Task_Date > b.Task_Date) ? 1 : ((b.Task_Date > a.Task_Date) ? -1 : 0)).reverse();
            callback();
          })
          .catch((error)=>{});
    },
    getTimetrackEvents() {
      axios.get(this.baseUrl + "/api/v1/tasks/timetrackevents", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then((res)=>{
        console.log("TimetrackEvents");
        if(process.env.NODE_ENV === 'development') {
          console.log(res);
        }
      })
      .catch((err)=>{
        console.log(err);
      });
    },
    loadApp() {

    },
    stopAllTasks() {
      axios.post(this.baseUrl + "/api/v1/tasks/stopThemAll", {}, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            console.log(res);
          }).catch((err) => {console.log(err)});
    },
    handleVisibility() {
      if(document.visibilityState === "hidden") {
        console.log("App is tuned down");
      } else {
        console.log("App is tuned up");
        // this.renewToken();
        // this.getStatus(); now within renewToken
      }
    },
    refreshCairos() {
      window.location.reload();
    },
    ...mapActions(piniaStore, ["getBonuses"]),
    ...mapActions(piniaStore, ["getCostCenters"]),
    //...mapActions("pinia", ["getNotifications"]),
    ...mapActions(piniaStore, ["logout"]),
    ...mapActions(piniaStore, ["setAuthorized"]),
    ...mapActions(piniaStore, ["getUsers"]),
    ...mapActions(piniaStore, ["getUserState"]),
    ...mapActions(piniaStore, ["getRoles"]),
    ...mapActions(piniaStore, ["getStatus"]),
    ...mapActions(piniaStore, ["getTeams"]),
    ...mapActions(piniaStore, ["checkToken"]),
  },
  mounted: function () {
    console.log("[CAIROS] mounted")

    //notifications polling
    /*setInterval(function(){
      vm.getNotifications2(vm.currentUser.id, function(){})
    }, 30000);*/
    console.log("Check Authorized");
    let auth = localStorage.getItem("cairos_authorized");
    console.log("Auth");
    console.log(auth);
  },
  created() {
    console.log("[CAIROS] created");
    document.title = "Cairos";
    this.hostname = window.location.hostname;
    document.addEventListener("visibilitychange", this.handleVisibility);
    //this.timeTrackingApi = new TimeTrackingApi();
    //this.$vuetify.lang.defaultLocale = 'de';
    //this.$vuetify.lang.current = 'de';
    this.checkToken(); //pinia keycloak
    this.getLocation();
    this.getRoles(); //pinia
    this.getStatus(); //pinia
    this.getTeams(); //pinia
    this.getUsers(); //pinia
    this.getCostCenters(); //pinia
    this.getBonuses(); //pinia
    //this.getIncidents(function(){});
    // this.getRoles(function(){});
    this.getTimetrackEvents();

    if (this.updateInterval == null) {
      this.updateInterval = setInterval(() => {
        console.log("updating data");
        if(this.authorized) {
          this.getCostCenters();
          this.getUsers();
          this.getTeams();
          this.getStatus();
          this.getUserState();
        }
      }, 10000);
    }
  },
  unmounted() {
    clearInterval(this.updateInterval);
  },
};
</script>
