<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Mitteilungen</v-toolbar-title>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="notifications"
          item-key="id"
      >
        <template v-slot:item.subject="{ item }">
          <span v-if="item.read" @click="openNotification(item)">{{item.subject}}</span>
          <span v-else style="font-weight: bold;" @click="openNotification(item)">{{item.subject}}</span>
        </template>
        <template v-slot:item.text="{ item }">
          <span v-if="item.read" @click="openNotification(item)">{{item.text}}</span>
          <span v-else style="font-weight: bold;" @click="openNotification(item)">{{item.text}}</span>
        </template>
        <template v-slot:item.read="{ item }">
          <v-simple-checkbox
              v-model="item.read"
              disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="openNotification(item)"
          >
            mdi-dots-horizontal
          </v-icon>
          <v-icon
              small
              @click="deleteNotification(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn
            @click="markRead()"
        >Alle als gelesen markieren</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "vuex";
export default {
  name: "NotificationCenter",
  data: () => ({
    tabs: [
      {
        id: '0',
        name: 'Alle',
        tasks: Array,
      },
      {
        id: '0',
        name: 'Ungelesen',
        tasks: Array,
      },
    ],
    headers: [
      {
        text: 'Betreff',
        value: 'subject',
      },
      {
        text: 'Text',
        value: 'text',
      },
      {
        text: 'Gelesen',
        value: 'read',
      },
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
    //notifications: [],
  }),
  computed: {
    ...mapState(["currentUser"]),
    ...mapState(["notifications"]),
  },
  methods: {
    deleteNotification(notification) {
      let vm = this;
      axios.delete('/api/v1/notifications/' + notification.id)
          .then(function(response){
            /*vm.notifications = vm.notifications.filter(i => i.id !== notification.id);
            vm.$root.$children[0].$data.messages = vm.notifications;*/
            vm.getNotifications();
          })
          .catch(function(error){
            console.log(error)
          })
    },
    openNotification(notification) {
      console.log(notification.id);
      this.markAsRead(notification);
      if(notification.objectType !== null) {
        if(notification.objectType === "Incident") {
          this.$router.push('/incidents/' + notification.objectID);
        }
      }
    },
    markRead() {
      //this.$root.$children[0].$data.messages.forEach(m => m.read = true);
      this.markNotificationsAsRead();
    },
    markAsRead(item) {
      this.markNotificationAsRead(item.id);
      this.getNotifications();
    },
    markAsUnread(item) {
      var index = this.notifications.findIndex(i => i.id === item.id);
      this.notifications[index].read = false;
      this.$root.$children[0].$data.messages = this.notifications;
    },
    ...mapActions(["getNotifications"]),
    ...mapActions(["markNotificationAsRead"]),
    ...mapActions(["markNotificationsAsRead"]),
  },
  mounted: function () {
    this.getNotifications();
    /*var vm = this;
    vm.$root.$children[0].getNotifications(vm.currentUser.id, function(){
      vm.notifications = vm.$root.$children[0].$data.messages;
    });*/
  },
};
</script>