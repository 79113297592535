<template>
  <v-container>
    <v-card>
      <v-card-title>12.11.2021 | René Heinrich</v-card-title>
      <v-card-text>
        <v-select
            v-model="state"
            :items="states"
            item-text="name"
            item-value="value"
            label="Status"
        ></v-select>
        <v-timeline dense>
          <v-timeline-item small fill-dot>Eingereicht am 12.11.2021, 18:37 von René Heinrich</v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"><v-icon small>mdi-cancel</v-icon> Ablehnen</v-btn>
        <v-btn color="secondary"><v-icon small>mdi-check</v-icon> Genehmigen</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ReportSingleManager",
  data: () => ({
    reports: [],
    state: null,
    states: [
      {
        name: "Eingereicht",
        value: "in",
      }, {
        name: "Akzeptiert",
        value: "accepted",
      }, {
        name: "Abgelehnt",
        value: "rejected",
      }
    ]
  }),
  methods: {},
  created: {},
}
</script>

<style scoped>

</style>