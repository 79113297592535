<template>
<v-dialog v-model="dialog" @click:outside="closeDialog">
  <template v-slot:activator="{ on, attrs }">
    <v-btn
        v-if="activator === 'create'"
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        outlined
    >Kategorie erstellen</v-btn>
    <v-btn
        v-else
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        icon
    ><v-icon>mdi-pencil</v-icon></v-btn>
  </template>
  <v-card>
    <v-card-title></v-card-title>
    <v-card-text>
      <v-text-field v-model="incidentCategory.name" label="Name" outlined></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="closeDialog">Abbrechen</v-btn>
      <v-btn outlined color="primary" @click="saveIncidentCategory">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import axios from "@/main";
import {mapActions} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "IncidentCategoryDialog",
  props: {
    activator: {
      type: String,
      required: true,
      default: "icon",
    },
    incidentCategory: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("closeEvent");
    },
    saveIncidentCategory() {
      if(this.activator === "create") {
        this.createIncidentCategory();
      } else {
        this.updateIncidentCategory();
      }
    },
    createIncidentCategory() {
      axios.post("/api/v1/incident-categories", this.incidentCategory).then((res) => {
        this.$emit("createdEvent", res.data);
        this.closeDialog();
      }).catch(()=>{
        this.setErrorMessage("Kategorie konnte nicht gespeichert werden")
      })
    },
    updateIncidentCategory() {
      axios.put("/api/v1/incident-categories", this.incidentCategory).then((res) => {
        this.$emit("updatedEvent", res.data);
        this.closeDialog();
      }).catch(()=>{
        this.setErrorMessage("Kategorie konnte nicht gespeichert werden")
      })
    },
    ...mapActions(piniaStore, ["setErrorMessage"]),
  },
}
</script>

<style scoped>

</style>