<template>
<v-chip
    :color="statusColor"
    :dark="dark"
>
  <v-icon>mdi-label-outline</v-icon>&nbsp;{{statusText}}
</v-chip>
</template>

<script>
export default {
  name: "IncidentStatusChip",
  props: {
    status: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    statusColor: "grey lighten-2",
    statusText: "Offen",
    dark: false,
  }),
  watch: {
    status() {
      this.checkStatusProperties();
    }
  },
  methods: {
    checkStatusProperties() {
      if(this.status == "open") {
        this.statusColor = "grey lighten-2";
        this.statusText = "Offen";
        this.dark = false;
      }
      if(this.status == "in_progress") {
        this.statusColor = "primary";
        this.statusText = "In Bearbeitung";
        this.dark = false;
      }
      if(this.status == "solved") {
        this.statusColor = "green lighten-4";
        this.statusText = "Gelöst";
        this.dark = false;
      }
      if(this.status == "rejected") {
        this.statusColor = "red darken-4";
        this.statusText = "Lösung abgelehnt";
        this.dark = true;
      }
      if(this.status == "accepted") {
        this.statusColor = "green darken-3";
        this.statusText = "Lösung akzeptiert";
        this.dark = true;
      }
      if(this.status == "closed") {
        this.statusColor = "blue-grey darken-4";
        this.statusText = "Geschlossen";
        this.dark = true;
      }
    },
  },
  created() {
    this.checkStatusProperties();
  }
}
</script>

<style scoped>

</style>