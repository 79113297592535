<template>
<v-dialog v-model="isOpen">
  <template v-slot:activator="{ on, attrs }">
    <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        outlined
    ><v-icon>mdi-pencil</v-icon></v-btn>
  </template>
  <v-card>
    <v-card-title>{{rTask.title}}</v-card-title>
    <v-card-text>
      <RTaskFields v-bind:r-task="rTask"></RTaskFields>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="closeDialog" text>Abbrechen</v-btn>
      <v-btn v-if="isEdit" @click="updateTask" color="primary" outlined>Speichern</v-btn>
      <v-btn v-else @click="createTask" color="primary" outlined>Speichern</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import RTaskApi from "@/apis/RTaskApi";
import RTaskFields from "@/components/RTaskFields.vue";

export default {
  name: "RTaskDialog",
  components: {RTaskFields},
  props: ['isEdit', 'rTask'],
  data: () => ({
    rTaskApi: null,
    isOpen: false,
  }),
  methods: {
    closeDialog() {
      this.isOpen = false;
      this.$emit("closeEvent", this.rTask);
    },
    createTask() {
      this.$emit("createEvent", this.rTask);
      this.closeDialog();
    },
    updateTask() {
      this.$emit("updateEvent", this.rTask);
      this.closeDialog();
    },
  },
  created() {
    this.rTaskApi = new RTaskApi();
  }
}
</script>

<style scoped>

</style>