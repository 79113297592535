<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-data-table
            :items="jobs"
            :headers="headers"
            :search="search"
            :loading="jobsLoading"
            loading-text="Wird geladen... Bitte warten"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Aufträge</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Suche"
                  single-line
                  hide-details
                  clearable
                  class="mr-4"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-icon @click="getData">mdi-sync</v-icon>
            </v-toolbar>
          </template>
          <template v-slot:item.No="{ item }">
            <span
                @click="showDetails(item)"
                style="cursor: pointer;"
            >
              {{item.No}}
            </span>
          </template>
          <template v-slot:item.Order_Date="{ item }">
            {{item.Order_Date | timeToDate}}
          </template>
          <template v-slot:item.Task_Date="{ item }">
            {{item.Task_Date | timeToDate}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                class="mr-2"
                @click="showDetails(item)"
            >
              mdi-information-outline
            </v-icon>
            <v-icon
                class="mr-2"
                @click="showJob2Task(item)"
            >
              mdi-clipboard-plus-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="detailDialog" fullscreen>
      <v-card>
        <v-card-title>
          {{selectedJob.No}} | {{selectedJob.Business_with_Name}}
          <span v-if="selectedJob.Business_with_Name !== selectedJob.Task_at_Name"> | {{selectedJob.Task_at_Name}}</span>
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>No</th>
              <th>Anlieferungsdatum</th>
              <th>Beschreibung</th>
              <th>Leistungsart</th>
              <th>Abfallcode</th>
              <th>Task Date</th>
              <th>Aktionen</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="detail in jobDetails" :key="detail.No">
              <td>{{detail.No}}</td>
              <td>{{detail.Datum_der_Anlieferung | timeToDate}}</td>
              <td>{{detail.Description}}</td>
              <td>{{detail.Service_Type}}</td>
              <td>{{detail.Int_Material_Catalog}}</td>
              <td>{{detail.Task_Date | timeToDate}}</td>
              <td>
                <v-icon small>mdi-information</v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDetailDialog" outlined>Zurück</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="job2TaskDialog" fullscreen>
      <v-card>
        <v-card-title>Task aus Auftrag erstellen</v-card-title>
        <v-card-text>
          <v-form
              ref="taskForm"
              v-model="validTaskForm"
          >
            <v-text-field
                label="Titel *"
                v-model="job2task.title"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
            <v-textarea
                label="Beschreibung"
                v-model="job2task.description"
                outlined
            ></v-textarea>
            <v-autocomplete
                v-model="job2task.supplying_costcenterid"
                :items="costCenters"
                item-text="label"
                item-value="id"
                label="Leistende Kostenstelle"
                :filter="customCostcenterFilter"
                clearable
                outlined
            >
              <template v-slot:selection="data">
                {{data.item.label}}, {{data.item.name}}
              </template>
              <template slot="item" slot-scope="data">
                {{data.item.label}}, {{data.item.name}}
              </template>
            </v-autocomplete>
            <v-autocomplete
                v-model="job2task.receiving_costcenterid"
                :items="costCenters"
                item-text="label"
                item-value="id"
                label="Empfangende Kostenstelle *"
                required
                :hint="hints.receiving_costcenter"
                :filter="customCostcenterFilter"
                persistent-hint
                clearable
                outlined
            >
              <template v-slot:selection="data">
                {{data.item.label}}, {{data.item.name}}
              </template>
              <template slot="item" slot-scope="data">
                {{data.item.label}}, {{data.item.name}}
              </template>
            </v-autocomplete>
            <v-combobox
                v-model="job2task.bonuses"
                :items="bonuses"
                item-text="label"
                item-value="id"
                label="Zuschläge"
                multiple
                chips
                outlined
            >
              <template slot="item" slot-scope="data">
                {{data.item.label}} - {{data.item.name}}
              </template>
            </v-combobox>
            <v-autocomplete
                label="Zuständiger Benutzer"
                v-model="job2task.assignedUser"
                :items="users"
                item-text="lastname"
                item-value="id"
                outlined
            >
              <template v-slot:selection="data">
                {{data.item.firstname}} {{data.item.lastname}}
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <img :src="baseUrl + data.item.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.firstname}} {{data.item.lastname}}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeJob2TaskDialog" outlined>Abbrechen</v-btn>
          <v-btn color="secondary" @click="saveTask" :disabled="!validTaskForm" outlined>Speichern</v-btn>
          <!--
          <v-btn color="secondary" :disabled="job2task.assignedUser !== payload.user.id">Speichern & Starten</v-btn>
          -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
export default {
  name: "Jobs",
  data: () => ({
    isEditing: null,
    detailDialog: false,
    headers: [
      {text: "Auftrag", value: "No"},
      {text: "Name", value: "Task_at_Name"},
      //{text: "Firma", value: "Business_with_Name"},
      //{text: "Nummer", value: "Business_with_No"},
      //{text: "Kontakt", value: "Task_Contact"},
      {text: "Datum", value: "Order_Date"},
      //{text: "Datum (Task)", value: "Task_Date"},
      {text: "Aktionen", value: "actions", sortable: false},
    ],
    jobs: [],
    jobsLoading: true,
    jobDetails: [],
    selectedJob: {},
    search: "",

    job2TaskDialog: false,
    job2task: {},
    defaultTask: {
      title: '',
      description: '',
      jobid: '',
      createdBy: '',
      assignedUser: '',
      assignedRoles: [],
      assignedTeams: [],
      assignedUsers: [],
    },
    validTaskForm: false,
    rules: {
      required: value => !!value || 'Erforderlich',
    },

    hints: {
      title: '',
      supplying_costcenter: '',
      receiving_costcenter: '',
    },
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['users']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  methods: {
    closeDetailDialog() {
      this.detailDialog = false;
      this.selectedJob = Object.assign({}, {});
      this.jobDetails = [];
    },
    closeJob2TaskDialog() {
      this.job2TaskDialog = false;
      this.job2task = Object.assign({}, this.defaultTask);
    },
    customCostcenterFilter (item, queryText, itemText) {
      const name = item.name.toLowerCase()
      const label = item.label.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 ||
          label.indexOf(searchText) > -1
    },
    getData() {
      this.jobsLoading = true;
      axios.get(this.baseUrl + "/api/v1/uweg/data", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then((res) => {
        console.log("UWEG DATA");
        console.log(res);
        this.jobsLoading = false;
        this.jobs = res.data;
        this.jobs.sort((a,b) => (a.Order_Date < b.Order_Date) ? 1 : ((b.Order_Date < a.Order_Date) ? -1 : 0));
        //this.jobs.reverse();
        if(this.$route.params.jobno !== null) {
          this.getJobDetail(this.$route.params.jobno);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getJobDetail(jobNo) {
      console.log("Details");
      console.log(jobNo);
      if(jobNo) {
        console.log("Details entered");
        let job = this.jobs.filter(e => e.No === jobNo);
        console.log(job);
        if(job[0]) {
          this.jobDetails = job[0].details;
        }
      }
      this.jobDetails.sort((a,b) => (a.Task_Date > b.Task_Date) ? 1 : ((b.Task_Date > a.Task_Date) ? -1 : 0));
      this.jobDetails.reverse();
      console.log(this.jobDetails);
      return this.jobDetails;
    },
    deleteJob(job) {
      let vm = this;
      axios.delete('/api/v1/jobs/' + job.id)
          .then(function(response){
            console.log(response.data)
            vm.jobs = vm.jobs.filter(b => b.id !== job.id)
          })
          .catch(function(error){})
    },
    pushTo(url) {
      this.$router.push(url);
      this.getData();
    },
    saveTask() {
      axios.post(this.baseUrl + "/api/v1/tasks", this.job2task, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then(()=>{
        this.closeJob2TaskDialog();
      })
      .catch((err)=>{
        console.log(err);
      });
    },
    saveAndStartTask() {
      axios.post(this.baseUrl + "/api/v1/tasks", this.job2task, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(()=>{
            this.closeJob2TaskDialog();
            // todo startTask logic with runningTask sync
          })
          .catch((err)=>{
            console.log(err);
          });
    },
    showJob2Task(item) {
      let details = this.getJobDetail(item.No);
      this.job2task = Object.assign({}, this.defaultTask);
      this.job2task.createdBy = this.user.id;
      this.job2task.assignedUser = this.user.id;
      this.job2task.jobid = item.No;
      this.job2task.title = item.No;
      //this.job2task.description = item.No + ', ' + item.Business_with_Name + ' ';
      this.job2task.description = item.No + ', ' + item.Task_at_Name;
      console.log("show2Task Details");
      console.log(details);
      if(details) {
        details.forEach(jobDetail => {
          this.job2task.description = this.job2task.description + ', ' + jobDetail.Description;
        });
      }
      this.job2TaskDialog = true;
    },
    showDetails(job) {
      this.getJobDetail(job.No);
      this.selectedJob = Object.assign({}, job);
      this.detailDialog = true;
    },
  },
  created() {
    this.getData();
  },
}
</script>