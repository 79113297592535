<template>
<v-container>
  <v-card>
    <v-card-title>{{realm.name}}</v-card-title>
    <v-card-text>
      {{realm.subdomain}}<br>
      {{realm.domain}}<br>
      <v-row v-for="user in users" :key="user.id">
        <v-col>{{user.firstname}} {{user.lastname}} ({{user.email}})</v-col>
        <v-col>
          <v-icon @click="link(user)">mdi-plus</v-icon>
          <v-icon @click="unlink(user)">mdi-minus</v-icon>
        </v-col>
      </v-row>
      <v-expansion-panels>
        <v-expansion-panel
            v-for="user in realm.users"
            :key="user"
        >
          <v-expansion-panel-header>{{getUsername(user)}}</v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="back">
        <v-icon>mdi-arrow-left</v-icon>
        <span>zurück</span>
      </v-btn>
      <v-btn @click="getRealm">
        <v-icon>mdi-refresh</v-icon>
        <span>Aktualisieren</span>
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</v-container>
</template>

<script>
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
export default {
  name: "RealmsSingle",
  data: () => ({
    realm: {
      name: '',
      users: [],
    },
    users: [],
    message: '',
    snackbar: false,
    timeout: 2000,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token', 'userMap']),
  },
  methods: {
    back() {
      this.$router.push('/realms');
    },
    getRealm() {
      axios.get(this.baseUrl + "/api/v1/realms/" + this.$route.params.id, {headers: {"Authorization" : `Bearer ${this.token}`}})
        .then((res) => {
          console.log(res.data);
          this.realm = res.data;
        })
        .catch(() => {
          this.message = 'Realm konnte nicht geladen werden';
          this.snackbar = true;
        });
    },
    getUsername(id) {
      console.log(id);
      if (id) {
        let u = this.userMap.get(id);
        if(u) {
          return u.firstname + ' ' + u.lastname;
        } else {
          return "Not found " + id;
        }
      }
      return "";
    },
    getUsers() {
      axios.get(this.baseUrl + "/api/v1/users", {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((res) => {
            console.log(res.data);
            this.users = res.data;
          })
          .catch(() => {
            this.message = 'Benutzer konnten nicht geladen werden';
            this.snackbar = true;
          });
    },
    link(user) {
      axios.put(this.baseUrl + "/api/v1/realms/" + this.$route.params.id + "/linkUser/" + user.id, {headers: {"Authorization" : `Bearer ${this.token}`}})
        .then(() => {
          this.realm.users.push(user.id);
        })
          .catch(() => {
            this.message = 'Benutzer konnten nicht dem Realm hinzugefügt werden';
            this.snackbar = true;
          });
    },
    unlink(user) {
      axios.put(this.baseUrl + "/api/v1/realms/" + this.$route.params.id + "/unlinkUser/" + user.id, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then(() => {
            this.realm.users = this.realm.users.filter( e => e != user.id);
          })
          .catch(() => {
            this.message = 'Benutzer konnte nicht aus Realm entfernt werden';
            this.snackbar = true;
          });
    },
  },
  mounted: function () {
    this.getRealm();
    this.getUsers();
  },
}
</script>

<style scoped>

</style>