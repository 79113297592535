<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Tagesbericht</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-date-picker
                v-model="date"
                :min="minOneMonth"
                :max="maxToday"
                color="#f71784"
                :first-day-of-week="1"
                locale="de-at"
                @change="dateChange"
                :events="reportsMap"
                :event-color="matchColor"
            ></v-date-picker>
            <br>
            <v-dialog v-model="legendDialog" max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    large
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Erklärung Tagesbericht</v-card-title>
                <v-card-text>
                  Der Kalender zeigt mit einer Farbmarkierung (Punkt unter dem Kalendertag), welche Daten für einen Tagesbericht zur Verfügung stehen.<br>
                  <v-chip color="blue-grey lighten-4">Buchungsdaten</v-chip> - Buchungsdaten liegen vor<br><br>
                  <v-chip color="blue-grey darken-4" dark class="text--white">Angelegt</v-chip> - Tagesbericht wurde erstellt, aber noch nicht eingereicht<br><br>
                  <v-chip color="purple" dark class="text--white">Eingereicht</v-chip> - Tagesbericht wurde für diesen Tag eingereicht<br><br>
                  <v-chip color="green">Akzeptiert</v-chip> - Tagesbericht wurde für diesen Tag vom BV akzeptiert<br><br>
                  <v-chip color="yellow accent-4">Abgelehnt</v-chip> - Tagesbericht wurde für diesen Tag vom BV nicht akzeptiert und erfordert eine Überarbeitung oder Rückmeldung<br><br>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="legendDialog = false">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
          <v-card-text v-if="reportExists && (currentReport.state == 'submitted' || currentReport.state == 'accepted')">Ich habe meinen Tagesbericht für <span style="font-weight: 600;">{{date | dateFormatReport}}</span> bereits eingereicht. Status: {{currentReport.state | reportStatus}}.</v-card-text>
          <v-card-text v-else>
            Ich habe meinen Tagesbericht für <span style="font-weight: 600;">{{date | dateFormatReport}}</span> überprüft und möchte
            ihn mit den unten aufgeführten Daten einreichen.<br>
            Der Bericht wurde angelegt am {{currentReport.created | dateFormat}} <span v-if="currentReport.updated">und zuletzt aktualisiert am {{currentReport.updated | dateFormat}}</span>.<br>
            Falls in der Zwischenzeit weitere Zeitaufzeichnungen erfolgt sind, bitte den Tagesbericht zurücksetzen.
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="6" md="3">
                <v-btn
                    v-if="reportExists"
                    @click="updateReport"
                >Speichern</v-btn>
                <v-btn
                    v-else
                    @click="saveReport"
                >Speichern</v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn
                    :disabled="!reportExists || currentReport.state === 'submitted' || currentReport.state === 'accepted'"
                    @click="submitReport"
                >Einreichen</v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn
                    :disabled="!reportExists || currentReport.state === 'submitted' || currentReport.state === 'accepted'"
                    @click="resetReport"
                >
                  Zurücksetzen
                </v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn
                    :disabled="!reportExists"
                    @click="exportReport"
                >
                  Export
                </v-btn>
              </v-col>
              <v-col cols="6" md="3" v-if="user.email === 'rene@codefive.de'">
                <v-btn
                    @click="logIt"
                >
                  Console Log
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
              :headers="headersCheckins"
              :items="checkins"
              :items-per-page="10"
          >
            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Checkins</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                    v-model="checkinDialog"
                    fullscreen
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                      Neuer Checkin
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>{{checkinDialogTitle}}</v-card-title>
                    <v-card-text>
                      <v-select
                          label="Typ"
                          v-model="editedCheckin.type"
                          :items="selectTypes"
                          item-text="name"
                          item-value="type"
                          prepend-icon="mdi-swap-horizontal"
                      ></v-select>
                      <v-menu
                          ref="menu"
                          v-model="timeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="time"
                              label="Zeit ändern"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="timeMenu"
                            v-model="time"
                            full-width
                            format="24hr"
                            @click:minute="$refs.menu.save(time)"
                        ></v-time-picker>
                      </v-menu>
                      <v-text-field
                          v-model="editedCheckin.comment"
                          label="Kommentar"
                          prepend-icon="mdi-comment-text"
                          persistent-hint
                          hint="Pflichtfeld"
                          required
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="closeCheckinDialog">Abbrechen</v-btn>
                      <v-btn @click="saveReportCheckin" color="primary">Speichern</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.timestamp="{ item }">
              {{ item.timestamp | dateFormatSimple }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                  @click="editReportCheckin(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  @click="deleteReportCheckin(item)"
              >
                mdi-delete
              </v-icon>
              <v-icon
                  v-if="user.email === 'rene@codefive.de'"
                  @click="realDelete(item)"
                  color="pink"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
              :headers="headersTasks"
              :items="tasks"
          >
            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title @click.meta.shift="setAdmin">Tätigkeiten</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                    v-model="timetrackDialog"
                    fullscreen
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                      Neue Tätigkeit
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>{{timetrackDialogTitle}}</v-card-title>
                    <v-card-text>
                      <v-form>
                        <v-text-field
                          label="Titel"
                          v-model="editedTimetrackEvent.title"
                          outlined
                        ></v-text-field>
                        <v-textarea
                            label="Beschreibung"
                            v-model="editedTimetrackEvent.description"
                            outlined
                        ></v-textarea>
                        <v-text-field
                            label="Start"
                            v-model="editedTimetrackEvent.start"
                            outlined
                        ></v-text-field>
                        <v-text-field
                            label="Ende"
                            v-model="editedTimetrackEvent.end"
                            outlined
                        ></v-text-field>
                        <v-autocomplete
                            v-model="editedTimetrackEvent.supplying_costcenterid"
                            :items="costCenters"
                            item-text="label"
                            item-value="id"
                            label="Leistende Kostenstelle"
                            :filter="customCostcenterFilter"
                            clearable
                            outlined
                        >
                          <template v-slot:selection="data">
                            {{data.item.label}}, {{data.item.name}}
                          </template>
                          <template slot="item" slot-scope="data">
                            {{data.item.label}}, {{data.item.name}}
                          </template>
                        </v-autocomplete>
                        <v-autocomplete
                            v-model="editedTimetrackEvent.receiving_costcenterid"
                            :items="costCenters"
                            item-text="label"
                            item-value="id"
                            label="Empfangende Kostenstelle"
                            required
                            :hint="hints.receiving_costcenter"
                            :filter="customCostcenterFilter"
                            persistent-hint
                            clearable
                            outlined
                        >
                          <template v-slot:selection="data">
                            {{data.item.label}}, {{data.item.name}}
                          </template>
                          <template slot="item" slot-scope="data">
                            {{data.item.label}}, {{data.item.name}}
                          </template>
                        </v-autocomplete>
                        <v-combobox
                            v-model="editedTimetrackEvent.bonuses"
                            :items="bonuses"
                            item-text="label"
                            item-value="id"
                            label="Zuschläge"
                            multiple
                            chips
                            outlined
                        >
                          <template slot="item" slot-scope="data">
                            {{data.item.label}} - {{data.item.name}}
                          </template>
                        </v-combobox>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="closeTimetrackDialog">Abbrechen</v-btn>
                      <v-btn @click="saveTimetrackEvent" color="primary">Speichern</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.receiving_costcenterid="{ item }">
              {{getCostCenterLabel(item.receiving_costcenterid)}}
            </template>
            <template v-slot:item.start="{ item }">
              {{ item.start | dateFormatSimple }}
            </template>
            <template v-slot:item.end="{ item }">
              {{ item.end | dateFormatSimple }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2"
                  @click="editTimetrackEvent(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  small
                  @click="deleteTask(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isAdmin">
      <v-col>
        <v-card>
          <v-card-title>Admin</v-card-title>
          <v-card-text>
            <pre>{{ currentReport }}</pre>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "@/main";
//import JSJoda from "@js-joda/core";
const JSJoda = require("@js-joda/core");
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import {mapActions} from "pinia";
export default {
  name: "ReportSingle",
  data: () => ({
    isAdmin: false,
    date: JSJoda.LocalDate.now().toString(),
    legendDialog: false,
    editCheckinEvent: false,
    editCheckinItem: {},
    headersCheckins: [
      {
        text: 'Vorgang',
        value: 'name',
      },
      {
        text: 'Label',
        value: 'label',
      },
      {
        text: 'Kommentar',
        value: 'comment',
      },
      {
        text: 'Zeit',
        value: 'timestamp',
      },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
      },
    ],
    headersTasks: [
      {
        text: 'Titel',
        value: 'title',
      },
      {
        text: 'Empf. KSt.',
        value: 'receiving_costcenterid',
      },
      {
        text: 'Start',
        value: 'start',
      },
      {
        text: 'Ende',
        value: 'end',
      },
      {
        text: 'M',
        value: 'manual',
      },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
      },
    ],
    hints: {
      title: '',
      supplying_costcenter: '',
      receiving_costcenter: '',
    },
    currentReport: {},
    checkins: [],
    tasks: [],
    reports: [],
    reportsMap: [],
    reportExists: false,
    selectedDate: "2022-04-17",
    timetrackEvents: [],
    timetrackDialog: false,
    timetrackDialogTitle: "Tätigkeit anlegen",
    editedTimetrackEvent: {},
    defaultTimetrackEvent: {
      title: "",
      description: "",
      bonuses: [],
      receiving_costcenterid: null,
      supplying_costcenterid: null,
      start: "00:00",
      end: "00:00",
      manual: true,
    },

    checkinDialog: false,
    checkinDialogTitle: 'Neuer Checkin',
    editedCheckin: {},
    defaultCheckin: {
      name: "Checkin",
      type: "checkin",
      label: "",
      comment: "",
      manual: true,
    },
    selectTypes: [
      {
        type: 'checkin',
        name: 'Checkin',
      },
      {
        type: 'checkout',
        name: 'Checkout',
      },
    ],
    time: null,
    timeMenu: false,
  }),
  watch: {
    currentReport: {
      handler(val) {
        console.log("currentReport changed");
        console.log(val.id);
      },
      deep: true,
    },
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    currentUser() {
      return this.user;
    },
    maxToday: function () {
      return JSJoda.LocalDate.now().toString();
    },
    minOneMonth: function () {
      return JSJoda.LocalDate.now().minusWeeks(18).toString(); //change back to 6
    },
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  methods: {
    /* temporary admin stuff */
    matchColor(date) {
      let ret = this.reports.filter(e => e.date === date);
      console.log("MATCH COLOR");
      console.log(ret);
      if (ret[0]) {
        if(ret[0].state === "accepted") {
          return "secondary";
        }
        if(ret[0].state === "submitted") {
          return "purple";
        }
        if(ret[0].state === "rejected") {
          return "yellow accent-4";
        }
        if(ret[0].state === "created") {
          return "blue-grey darken-4";
        }
        return ret[0].colors;
      }
      console.log("MATCH NO MATCH")
      return ["primary"];
    },
    getReport() {
      axios
          .get(this.baseUrl + '/api/v1/report/date/' + this.selectedDate, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.currentReport = JSON.stringify(res.data);
            this.tasks = res.data.tasks;
          })
          .catch((err) => console.log(err));
    },
    /* temporary admin stuff end */
    closeCheckinDialog() {
      this.checkinDialog = false;
      this.checkinDialogTitle = "Neuer Checkin";
      this.editedCheckin = Object.assign({}, this.defaultCheckin);
    },
    closeTimetrackDialog() {
      this.timetrackDialog = false;
      this.timetrackDialogTitle = "Tätigkeit anlegen";
      this.editedTimetrackEvent = Object.assign({}, this.defaultTimetrackEvent);
      this.editedTimetrackEvent.start = JSJoda.LocalDateTime.now().toString();
      this.editedTimetrackEvent.end = JSJoda.LocalDateTime.now().toString();
      this.tasks.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
    },
    customCostcenterFilter (item, queryText, itemText) {
      const name = item.name.toLowerCase()
      const label = item.label.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 ||
          label.indexOf(searchText) > -1
    },
    getCostCenterLabel(id) {
      if (id == null) {
        return 'n/a';
      }
      if (this.costCenterMap.has(id)) {
        let cc = this.costCenterMap.get(id);
        return cc.label;
      }
      return 'n/a';
    },
    deleteTask(task) {
      this.tasks = this.tasks.filter(t => t.id !== task.id);
    },
    editReportCheckin(checkin) {
      this.editedCheckin = Object.assign({}, checkin);
      this.checkinDialogTitle = "Checkin bearbeiten";
      this.checkinDialog = true;
    },
    editTimetrackEvent(timetrackEvent) {
      this.editedTimetrackEvent = Object.assign({}, timetrackEvent);
      if (timetrackEvent.id && timetrackEvent.id !== null) {
        this.editedTimetrackEvent.task = timetrackEvent.id;
      }
      this.timetrackDialogTitle = "Tätigkeit bearbeiten";
      this.timetrackDialog = true;
    },
    resetReport() {
      axios.delete(this.baseUrl + "/api/v1/report/reset/" + this.currentReport.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then(()=>{
        this.getEvents(this.currentReport.date);
      })
      .catch((err)=>console.log(err));
    },
    saveReportCheckin() {
      this.editedCheckin.timestamp = this.date + "T" + this.time;
      if(this.editedCheckin.id) {
        console.log("update report checkin");
        axios
            .put(this.baseUrl + '/api/v1/report/' + this.currentReport.id + '/reportCheckin/' + this.editedCheckin.id, this.editedCheckin, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res) => {
              console.log(res.data);
              let objIndex = this.checkins.findIndex((obj => obj.id === res.data.reportCheckin.id));
              this.tasks[objIndex] = res.data.reportCheckin;
              //this.currentReport.tasks = JSON.parse(JSON.stringify(this.tasks));
              this.currentReport.checkins = this.checkins.map(t =>{
                return t.id;
              });
              this.checkins.sort((a,b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0));
              this.closeCheckinDialog();
            })
            .catch((err) => console.log(err));
      } else {
        console.log("save report checkin");
        axios
            .post(this.baseUrl + '/api/v1/report/' + this.currentReport.id + '/reportCheckin', this.editedCheckin, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res) => {
              console.log(res.data);
              this.checkins.push(res.data.reportCheckin);
              this.currentReport.checkins = this.checkins.map(t =>{
                return t.id;
              });
              this.checkins.sort((a,b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0));
              this.closeCheckinDialog();
            })
            .catch((err) => console.log(err));
      }
    },
    saveTimetrackEvent() {
      if(this.editedTimetrackEvent.id) {
        axios
            .put(this.baseUrl + '/api/v1/report/' + this.currentReport.id + '/reportTask/' + this.editedTimetrackEvent.id, this.editedTimetrackEvent, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res) => {
              console.log(res.data);
              let objIndex = this.tasks.findIndex((obj => obj.id === res.data.reportTask.id));
              this.tasks[objIndex] = res.data.reportTask;
              //this.currentReport.tasks = JSON.parse(JSON.stringify(this.tasks));
              this.currentReport.tasks = this.tasks.map(t =>{
                return t.id;
              });
              this.tasks.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
              this.closeTimetrackDialog();
            })
            .catch((err) => console.log(err));
      } else {
        axios
            .post(this.baseUrl + '/api/v1/report/' + this.currentReport.id + '/reportTask', this.editedTimetrackEvent, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res) => {
              console.log(res.data);
              this.tasks.push(res.data.reportTask);
              this.currentReport.tasks = this.tasks.map(t =>{
                return t.id;
              });
              this.tasks.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
              this.closeTimetrackDialog();
            })
            .catch((err) => console.log(err));
      }
    },
    logIt() {
      //console.log(this.reports);
      console.log(this.currentReport);
    },
    exportReport() {
      let report = this.reports.filter(r => r.date == this.date);
      let url = this.baseUrl + '/api/v1/reports/id/' + report[0].id + '/export/xlsx';
      const viewFile = async (url) => {
        fetch(url, {headers: new Headers({"Authorization" : `Bearer ${this.keycloak.token}`})} ) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
              let _url = window.URL.createObjectURL(blob);
              window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
          console.log(err);
        });
      };
      viewFile(url).then(()=>{console.log("done")});
    },
    closeEditCheckinEvent() {
      this.editCheckinEvent = false;
      this.editCheckinItem = {};
    },
    dateChange() {
      this.getEvents(this.date);
    },
    editCheckin(checkin) {
      this.editCheckinEvent = true;
      this.editCheckinItem = checkin;
    },
    getEvents(date) {
      this.viewDate = date;
      this.selectedDate = date;
      console.log("DATE DATE DATE");
      console.log(date);
      let vm = this;
      axios.get(this.baseUrl + '/api/v1/report/date/' + date, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log("GET EVENTS");
            console.log(response.data)
            //vm.events = response.data
            this.checkins = []
            this.tasks = []
            if (response.data.report) {
              console.log('Report exists')
              this.reportExists = true;
              this.currentReport = response.data.report;
              //this.currentReport.id = response.data.report.id;
            } else {
              this.reportExists = false;
            }
            response.data.checkins.forEach(event => {
              //event.color = "#32a852";
              //event.color = "#834fdc";
              event.color = "#ff3baa";
              event.edited = false
              event.manual = false
              event.start = JSJoda.LocalDateTime.parse(event.timestamp)
                  .format(JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm':00'"));
              if(event.start.startsWith(date)) {
                this.checkins.push(event);
              }
            });
            vm.checkins.sort((a,b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0));
            response.data.tasks.forEach(event => {
              if (event.start) {
                console.log(event.title + " " + event.start + " " + event.end);
                vm.tasks.push(event);
                return;
              }
              if (event.startTimes) {
                console.log('if')
                let i;
                for (i = 0; i < event.startTimes.length; i++) {
                  console.log(i)
                  let subEvent = {
                    task: event.id,
                    name: event.title,
                    title: event.title,
                    description: event.description,
                    start: JSJoda.LocalDateTime.parse(event.startTimes[i])
                        .format(JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm':00'")),
                    end: JSJoda.LocalDateTime.parse(event.stopTimes[i])
                        .format(JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm':00'")),
                    //color: '#4030aa',4fdbd0
                    //color: '#40edd3',
                    color: '#3edebe',
                    edited: false,
                    manual: false,
                  };
                  console.log(subEvent)
                  if(subEvent.start.startsWith(date)) {
                    vm.tasks.push(subEvent)
                  }
                }
              } else {
                console.log('else')
                // sollte nicht mehr getriggert werden, da tasks umgestellt wurden.
                event.color = "#4032a8";
                event.edited = false;
                event.manual = false;
                event.start = JSJoda.LocalDateTime.parse(event.start)
                    .format(JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm':00'"));
                event.stop = JSJoda.LocalDateTime.parse(event.stop)
                    .format(JSJoda.DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm':00'"));
                if(event.start.startsWith(date)) {
                  vm.tasks.push(event);
                }
              }
            });
            vm.tasks.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
          })
          .catch((error) => {
            console.log(error)
          });
      console.log(this.checkins);
      console.log(this.tasks);
    },
    getColor(event) {
      return event.color
    },
    deleteReportCheckin(checkin) {
      //this.checkins.
    },
    realDelete(checkin) {
      axios.delete(this.baseUrl + '/api/v1/checkin/' + checkin.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            this.checkins = this.checkins.filter(c => c.id !== checkin.id);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getReports() {
      axios.get(this.baseUrl + '/api/v1/report', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
        .then((response) => {
          this.reports = response.data;
          this.reportsMap = response.data.map(r =>{
            return r.date;
          });
          console.log("getReports DATA");
        }).catch((err) => {console.log(err);});
    },
    saveReport() {
      //let timetrackEvents = JSON.parse(JSON.stringify(this.timetrackEvents));

      let data = {
        date: this.date,
        saved: JSJoda.LocalDateTime.now().toString(),
        user: this.user.id,
        tasks: this.tasks,
        checkins: this.checkins,
      }
      axios.post(this.baseUrl + '/api/v1/report', data, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            console.log(res);
            this.currentReport = res.data;
            this.currentReport.state = "created";
            this.reportExists = true;
            this.getEvents(this.date);
            this.getReports();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    updateReport() {
      let data = {
        date: this.date,
        saved: JSJoda.LocalDateTime.now().toString(),
        user: this.user.id,
        report: this.currentReport,
        tasks: this.tasks,
        checkins: this.checkins,
      }
      axios.put(this.baseUrl + '/api/v1/report/' + this.currentReport.id, data, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log(response);
            this.getEvents(this.date);
            this.getReports();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    submitReport() {
      let data = {
        date: this.date,
        saved: JSJoda.LocalDateTime.now().toString(),
        user: this.user.id,
        report: this.currentReport,
        tasks: this.tasks,
        checkins: this.checkins,
      };
      axios
          .put(this.baseUrl + '/api/v1/report/' + this.currentReport.id + '/submit', data, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log(response)
            this.currentReport = response.data;
            this.currentReport.state = response.data.state;
            this.getReports();
          })
          .catch((error) => {
            console.log(error)
          });
    },
    ...mapActions(piniaStore, ["getCostCenters"]),
  },
  mounted: function () {
    this.getCostCenters();
    this.getEvents(JSJoda.LocalDate.now().toString());
    this.getReports();
    this.defaultCheckin.userid = this.user.id;
    this.editedCheckin = Object.assign({}, this.defaultCheckin);
    this.checkinDialogTitle = "Neuer Checkin";
    this.defaultTimetrackEvent.user = this.user.id;
    this.editedTimetrackEvent = Object.assign({}, this.defaultTimetrackEvent);
    this.editedTimetrackEvent.start = JSJoda.LocalDateTime.now().toString();
    this.editedTimetrackEvent.end = JSJoda.LocalDateTime.now().toString();
  },
  beforeDestroy: function () {

  },
};
</script>