<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          outlined
          v-bind="attrs"
          v-on="on"
      ><v-icon>mdi-account-plus</v-icon>&nbsp;hinzufügen</v-btn>
    </template>
    <v-card>
      <v-card-title>Mitglieder hinzufügen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="selectedMembers"
                :items="transformedUsers"
                prepend-icon="mdi-account-plus"
                outlined
                chips
                label="Auswählen"
                item-text="name"
                item-value="id"
                multiple
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="baseUrl + data.item.avatar"></v-img>
                  </v-avatar>
                  {{ data.item.firstname }} {{ data.item.lastname }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <ListAvatar v-bind:user="data.item"></ListAvatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.firstname }} {{ data.item.lastname }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <pre>{{ selectedMembers }}</pre>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" text>Abbrechen</v-btn>
        <v-btn @click="addMembers" outlined color="primary">Hinzufügen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WorkspaceDataApi from "@/apis/WorkspaceDataApi";
import ListAvatar from "@/components/ListAvatar.vue";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "TeamMemberDialog",
  components: {ListAvatar},
  props: {
    teamId: {
      type: String,
    },
  },
  data: ()=>({
    workspaceDataApi: null,
    dialog: false,
    selectedMembers: [],
    transformedUsers: [],
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['users', 'userMap',]),
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.selectedMembers = [];
      console.log(this.teamId);
      this.$emit("dialogClosedEvent");
    },
    addMembers() {
      this.workspaceDataApi.addTeamMembers(this.teamId, this.selectedMembers).then(() => {
        this.closeDialog();
      })
    },
    getTransformedUsers() {
      let transformedUserList = [];
      this.workspaceDataApi.getUsers().then((res) => {
        res.data.forEach(user => {
          user.name = user.firstname + ' ' + user.lastname;
          transformedUserList.push(user);
        })
        this.transformedUsers = transformedUserList.filter(user => user.deleted === null);
      });
    },
    remove(item) {
      const index = this.selectedMembers.indexOf(item.id)
      if (index >= 0) this.selectedMembers.splice(index, 1)
    },
  },
  created() {
    this.workspaceDataApi = new WorkspaceDataApi();
    this.getTransformedUsers();
  }
}
</script>

<style scoped>

</style>