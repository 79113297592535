<template>
  <v-container>
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
    ></v-progress-linear>
    <v-alert v-if="loading" color="secondary" dark class="mt-5" type="info">Aufgabe wird geladen</v-alert>
    <v-card v-if="task !== null">
      <v-card-title>
        <v-icon color="primary" v-if="runningTaskId === task.id">mdi-clock-fast</v-icon>
        <v-icon color="grey" v-else>mdi-clock-fast</v-icon>
        &nbsp;{{task.title}}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">{{task.description}}</v-col>
          <v-col cols="6">
            <Avatar :user="userByID(task.assignedUser)" size="22" />
            <span>&nbsp;{{userByID(task.assignedUser).firstname}} {{userByID(task.assignedUser).lastname}}</span>
          </v-col>
          <v-col>AssignedRoles</v-col>
          <v-col>AssignedTeams</v-col>
          <v-col>
            AssignedUsers <v-btn icon @click="getUsers"><v-icon>mdi-refresh</v-icon></v-btn>
            <div v-for="assignedUser in task.assignedUsers" :key="assignedUser">
              <span>{{assignedUser}}</span>
            </div>
          </v-col>
          <v-col cols="6">
            KSt. (leist.) {{costCenterLabel(task.supplying_costcenterid)}} {{costCenterName(task.supplying_costcenterid)}}
          </v-col>
          <v-col cols="6">
            KSt. (empf.) {{costCenterLabel(task.receiving_costcenterid)}} {{costCenterName(task.receiving_costcenterid)}}
          </v-col>
          <v-col>Zuschläge</v-col>
          <v-col>Gebuchte Zeiten</v-col>
          <v-col>Verknüpfter Auftrag</v-col>
          <v-col>kumulierte Zeit</v-col>
          <v-col>Erstellt: {{ task.created | dateFormatSimple }}</v-col>
          <v-col>Aktualisiert: {{ task.updated | dateFormatSimple }}</v-col>
          <v-col>{{ task.deleted }}</v-col>
          <v-col>{{ task.createdBy }}</v-col>
          <v-col>{{ task.status }}</v-col>
          <v-col cols="12" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
            <v-expansion-panels v-for="event in task.timetrackEvents" :key="event" flat>
              <v-expansion-panel>
                <v-expansion-panel-header>{{event}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  Kommt noch
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-none d-sm-flex">
        <v-spacer></v-spacer>
        <v-btn text to="/tasks">Zurück</v-btn>
        <v-btn color="primary" outlined>Starten</v-btn>
      </v-card-actions>
      <v-card-actions class="d-flex d-sm-none">
        <v-row dense>
          <v-col cols="12"><v-btn color="primary" outlined block>Starten</v-btn></v-col>
          <v-col cols="12"><v-btn text block to="/tasks">Zurück</v-btn></v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/main";
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import {piniaStore} from "@/store/pinia";
import {mapActions, mapState} from "pinia";
import Avatar from "@/components/Avatar.vue";
const timeTrackingApi = new TimeTrackingApi();
export default {
  name: "Tasks",
  components: {Avatar},
  data: () => ({
    task: null,
    loading: true,
  }),
  computed: {
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['bonusMap']),
    ...mapState(piniaStore, ['userMap']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['runningTask']),
    ...mapState(piniaStore, ['runningTaskId']),
  },
  methods: {
    costCenterName(id) {
      if(!id) return 'keine Kostenstelle'
      if (this.costCenterMap.has(id)) {
        let cc = this.costCenterMap.get(id);
        return cc.name;
      }
      /* var costcenterData = this.$root.$children[0].$data.costCenters.filter(cc => cc.id === id)
      if (costcenterData.length > 0) {
        return costcenterData[0].name
      } */
      return 'keine Kostenstelle'
    },
    costCenterLabel(id) {
      if(!id) return ""
      if (this.costCenterMap.has(id)) {
        let cc = this.costCenterMap.get(id);
        return cc.label;
      }
      return ''
    },
    userByID(id) {
      console.log(this.userMap)
      if(!id) {
        return null;
      }
      if(this.userMap.has(id)) {
        return this.userMap.get(id);
      }
      return null;
    },
    ...mapActions(piniaStore, ["updateCostCenters"]),
    ...mapActions(piniaStore, ["getUsers"]),
  },
  /* async created() {
    const response = await timeTrackingApi.getTaskByID(
        this.$route.params.id
    );
    this.task = response.data;
    this.loading = false;
    await this.updateCostCenters();
    this.getUsers();
  }, */
  created() {
    timeTrackingApi.getTaskByID(
        this.$route.params.id
    ).then((res) => {
      this.task = res.data;
      this.loading = false;
    });
    this.updateCostCenters();
    this.getUsers();
  },
};
</script>