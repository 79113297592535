import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
//import {de} from "vuetify/es5/locale/de";
//import {en} from "vuetify/es5/locale/en";
import de from "vuetify/lib/locale/de";
import en from "vuetify/lib/locale/en";
import VueI18n from "vue-i18n";

Vue.use(Vuetify);
Vue.use(VueI18n);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#AD1457", original
        //primary: "#9B489B",
        primary: "#a835df",
        //secondary: "#1DE9B6", original
        secondary: "#5435df",
        //success: '#1fccac',
        //success: '#49fcd4',
        accent: '#35DFDF',
        success: "#03c99e",
        //error: '#cc1f73',
        //info: '',
        //warning: '',
        error: "#f71784",
      },
    },
    options: { customProperties: true },
  },
  lang: {
    locales: [de, en],
    current: 'de'
  }
});
