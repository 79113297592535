<template>
  <v-container>
    <v-card>
      <v-card-title>
        Media Manager
      </v-card-title>
      <v-card-text>
        <UploadDialog :dialog.sync="uploadDialog" :multiple="true" @filesUploaded="processUpload($event)" />
      </v-card-text>
      <v-card-text>
        <v-file-input accept="image/*" v-model="files" multiple></v-file-input>
        <v-btn @click="upload">Upload</v-btn>
        <v-btn icon @click="getUploads"><v-icon>mdi-refresh</v-icon></v-btn>
      </v-card-text>
      <v-card-text>
        <v-row v-for="upl in uploads" :key="upl.id">
          <v-col cols="3">
            <v-img :src="baseUrl + '/' + upl.thumbnail" max-height="50" max-width="50"></v-img>
          </v-col>
          <v-col cols="6">
            <a :href="baseUrl + '/' + upl.thumbnail">{{upl.id}}</a>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn icon @click="deleteUpload(upl.id)"><v-icon>mdi-delete</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UploadDialog from "@/components/UploadDialog";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import axios from "@/main";
export default {
  name: "MediaManager",
  components: {UploadDialog},

  data: () => ({
    uploadDialog: true,
    uploadedFiles: [],
    filesUploaded: [],

    uploads: [],
    files: null,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['keycloak']),
  },
  methods: {
    processUpload(event) {

    },
    getUploads() {
      axios.get("/api/v1/uploads").then((res) => {
        console.log(res.data)
        this.uploads = res.data;
      }).catch(() => {
        this.setErrorMessage("Fehler beim Laden der Uploads")
      })
    },
    upload() {
      let formData = new FormData();
      if (this.files !== null) {
        for (let file of this.files) {
          formData.append("files", file, file.name);
        }
        axios.post("/api/v1/uploads", formData).then((res) => {
          console.log(res.data)
          this.files = null
        }).catch((err) => console.log(err))
      }
    },
    deleteUpload(id) {
      axios.delete("/api/v1/uploads/" + id).then(() => {
        console.log("delete successful")
        this.getUploads()
      }).catch(() => {
        this.setErrorMessage("Löschen fehlgeschlagen")
      })
    },
    ...mapActions(piniaStore, ["setErrorMessage"]),
  },
}
</script>

<style scoped>

</style>