<template>
<v-container>
  <v-card>
    <v-card-title>Aufgaben verwalten</v-card-title>
    <v-card-text>
      <v-tabs v-model="selection">
        <v-tab>Eigene</v-tab>
        <v-tab>Team</v-tab>
        <v-tab>Alle</v-tab>
      </v-tabs>
    </v-card-text>
    <v-card-text>
      {{selection}}
      <TaskDialog></TaskDialog>
      <TaskDialog v-bind:task="testTask"></TaskDialog>
    </v-card-text>
    <v-card-text>
      <v-list>
        <v-list-item v-for="task in tasks" :key="task.id">
          <v-list-item-content>
            <v-list-item-title>{{task.title}} ({{task.status}})</v-list-item-title>
            <v-list-item-subtitle>{{task.description}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-if="task.status == 'stopped'" color="red">mdi-circle-small</v-icon>
            <v-icon v-if="task.status == 'finished'" color="red">mdi-circle-small</v-icon>
            <v-icon v-if="task.status == 'created'" color="green">mdi-circle-small</v-icon>
          </v-list-item-action>
          <v-list-item-action>
            <TaskDialog v-bind:task="task" v-bind:icon="true"></TaskDialog>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text>
      <pre>{{testTask}}</pre>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import TaskDialog from "@/components/TaskDialog.vue";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "TaskManager2",
  components: {TaskDialog},
  data: () => ({
    selection: 0,
    testTask: {
      title: 'Hello',
      description: 'Na sowas auch',
      status: 'created',
      bonuses: [],
      receiving_costcenterid: null,
      supplying_costcenterid: null,
      assignedUser: null,
    },
  }),
  computed: {
    ...mapState(piniaStore, ['tasks']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['users']),
  },
  methods: {
    ...mapActions(piniaStore, ["getTasks"]),
  },
  created() {
    this.getTasks();
  }
}
</script>

<style scoped>

</style>