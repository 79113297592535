<template>
<v-container>
  <v-card>
    <v-card-title>Chat</v-card-title>
    <v-card-actions>
      <v-chip v-if="socketState == 0">Connecting</v-chip>
      <v-chip v-if="socketState == 1" color="green">online</v-chip>
      <v-chip v-if="socketState == 2">Closing</v-chip>
      <v-chip v-if="socketState == 3">Closed</v-chip>
      <v-spacer></v-spacer>
      <v-btn v-if="socketState == 1" @click="closeWebsocket" outlined>Disconnect</v-btn>
      <v-btn v-else @click="initWebsocket" outlined>Connect</v-btn>
      <v-btn icon @click="clearHistory"><v-icon>mdi-delete-clock</v-icon></v-btn>
    </v-card-actions>
    <v-card-text>
      <v-row dense>
        <v-col cols="8">
          <v-text-field label="Nachricht" v-model="myMessage" outlined dense @keydown.enter="sendMessage"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn @click="sendMessage" :disabled="socketState !== 1" icon><v-icon>mdi-send</v-icon></v-btn>
        </v-col>
      </v-row>
      <div v-for="(msg, i) in messages" :key="i">
        {{ msg }}
      </div>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import home from "@/views/Home.vue";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "Chat",
  data: () => ({
    socket: null,
    messages: [],
    myMessage: "",
    socketState: null,
  }),
  computed: {
    home() {
      return home
    },
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['keycloak']),
  },
  methods: {
    clearHistory() {
      this.messages = [];
    },
    initWebsocket() {
      console.log("Initialise socket")
      this.socket = new WebSocket("ws://localhost:50001/sockets/0?access_token=" + this.keycloak.token)
      this.socket.onmessage = (event) => {
        this.messages.push(event.data)
        if(event.data === "close") {
          this.socket.close();
        }
      }
      this.socket.onopen = () => {
        this.socketState = this.socket.readyState
      }
      this.socket.onclose = () => {
        this.socketState = this.socket.readyState
      }
    },
    closeWebsocket() {
      this.socket.close();
    },
    sendMessage() {
      if(this.socket.readyState !== WebSocket.OPEN) {
        this.initWebsocket()
      }
      this.socket.send(this.myMessage);
      this.myMessage = "";
    },
    ...mapActions(piniaStore, ["setErrorMessage"]),
    ...mapActions(piniaStore, ["setSuccessMessage"]),
  },
  created() {
    this.initWebsocket();
  },
}
</script>

<style scoped>

</style>