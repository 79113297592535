import axios from "axios";
import { piniaStore } from "@/store/pinia";

axios.interceptors.request.use(
    (config) => {
        const store = piniaStore();
        if (store.keycloak.token) {
            config.headers.Authorization = `Bearer ${store.keycloak.token}`;
        }
        config.baseURL = process.env.VUE_APP_API_BASE_URL;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const store = piniaStore();
        store.setNetworkError(error);
    }
);
