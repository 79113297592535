<template>
<v-container>
  <v-card>
    <v-card-title>Tagesbericht <v-spacer></v-spacer>{{date | dateFormatReport}}</v-card-title>
    <v-card-text>
      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="date"
              label="Datum wählen"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            @input="menu = false"
            no-title
            scrollable
        ></v-date-picker>
      </v-menu>
    </v-card-text>
    <v-card-actions>
      <v-btn icon outlined @click="dayBefore"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <v-btn icon outlined @click="dayAfter" :disabled="futureNav()"><v-icon>mdi-chevron-right</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined @click="today" :disabled="futureNav()"><v-icon>mdi-calendar-today-outline</v-icon>&nbsp; Heute</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-row v-for="fr in filteredReports" :key="fr.id">
        <v-col>{{ fr.date }}</v-col>
        <v-col><v-chip :color="getStateColor(fr.state)">{{ fr.state | reportStatus }}</v-chip></v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <h3>{{date | dateFormatReport}}</h3>
      <v-row>
        <v-col cols="3" md="1" order="2" order-md="1"><strong>Start</strong></v-col>
        <v-col cols="3" md="1" order="3" order-md="2"><strong>Ende</strong></v-col>
        <v-col cols="12" md="4" order="1" order-md="3" v-if="!isMobileResize"><strong>Beschreibung</strong></v-col>
        <v-col cols="6" md="2" order="5" order-md="4" v-if="!isMobileResize"><strong>Leist. KSt.</strong></v-col>
        <v-col cols="6" md="2" order="6" order-md="5" v-if="!isMobileResize"><strong>Empf. KSt.</strong></v-col>
        <v-col cols="6" md="2" order="4" order-md="6" class="text-right"><strong>Aktionen</strong></v-col>
      </v-row>
      <v-row v-if="tteLoading">
        <v-col cols="12">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-for="event in timetrackEvents" :key="event.id" v-else>
        <v-col cols="3" md="1" order="4" order-md="1">{{event.start | dateTimeToTime}}</v-col>
        <v-col cols="3" md="1" order="5" order-md="2">{{event.end | dateTimeToTime}}</v-col>
        <v-col cols="12" md="4" order="1" order-md="3">{{event.title}}</v-col>
        <v-col cols="6" md="2" order="2" order-md="4"><span v-if="event.supplying_costcenterid">{{getCCLabel(event.supplying_costcenterid)}}, {{getCCName(event.supplying_costcenterid)}}</span></v-col>
        <v-col cols="6" md="2" order="3" order-md="5"><span v-if="event.receiving_costcenterid">{{getCCLabel(event.receiving_costcenterid)}}, {{getCCName(event.receiving_costcenterid)}}</span></v-col>
        <v-col cols="6" md="2" order="6" order-md="6" class="text-right">
          <v-btn icon @click="editTimetrackEvent(event)" :disabled="selectedReport.state === 'approved' || selectedReport.state === 'submitted'"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn icon class="ml-2" :disabled="selectedReport.state === 'approved' || selectedReport.state === 'submitted'" @click="deleteTimetrackEventPopup(event)"><v-icon>mdi-close</v-icon></v-btn>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="timetrackEventDialog = true" :disabled="selectedReport.state === 'approved' || selectedReport.state === 'submitted'"><v-icon class="mr-2">mdi-plus-circle-outline</v-icon>Zeit hinzufügen</v-btn>
    </v-card-actions>
    <v-divider class="mt-10"></v-divider>
    <v-card-actions class="d-none d-sm-flex">
      <!-- <v-btn outlined @click="reportDialog = false">Zurück</v-btn> -->
      <v-spacer></v-spacer>
      <v-btn v-if="selectedReport.state === 'submitted' || selectedReport.state === 'approved'" color="primary" outlined @click="reportDialog = false">Schliessen</v-btn>
      <!-- <v-btn color="primary" outlined @click="saveReport"><v-icon class="mr-2">mdi-file-document-edit-outline</v-icon>Speichern</v-btn> -->
      <v-btn v-if="selectedReport.state === 'created'" color="primary" outlined @click="submitReport"><v-icon class="mr-2">mdi-file-document-arrow-right-outline</v-icon>Einreichen</v-btn>
    </v-card-actions>
    <v-card-actions class="d-block d-sm-none">
      <v-row dense>
        <v-col cols="12" v-if="selectedReport.state === 'submitted' || selectedReport.state === 'approved'"><v-btn block color="primary" outlined @click="reportDialog = false">Schliessen</v-btn></v-col>
        <v-col cols="12" v-if="selectedReport.state === 'created'"><v-btn block color="primary" outlined @click="submitReport"><v-icon class="mr-2">mdi-file-document-arrow-right-outline</v-icon>Einreichen</v-btn></v-col>
        <!-- <v-col cols="12"><v-btn block text @click="reportDialog = false">Zurück</v-btn></v-col> -->
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card class="mt-5">
    <v-card-title>Meine Tagesberichte</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4"><strong>{{ $t("labels.date") }}</strong></v-col>
        <v-col cols="4"><strong>Status</strong></v-col>
        <v-col cols="4"><strong>{{ $t("labels.actions") }}</strong></v-col>
      </v-row>
      <v-row v-for="report in reports" :key="report.id">
        <v-col cols="4">{{report.date}}</v-col>
        <v-col cols="4"><v-chip :color="getStateColor(report.state)">{{report.state | reportStatus}}</v-chip></v-col>
        <v-col cols="4">
          <v-btn v-if="report.state === 'approved'" icon @click="openReport(report)"><v-icon>mdi-file-document-check-outline</v-icon></v-btn>
          <v-btn v-else-if="report.state === 'submitted'" icon @click="openReport(report)"><v-icon>mdi-file-document-outline</v-icon></v-btn>
          <v-btn v-else-if="report.state === 'rejected'" icon @click="openReport(report)"><v-icon>mdi-file-document-edit-outline</v-icon></v-btn>
          <v-btn v-else icon @click="openReport(report)"><v-icon>mdi-file-document-arrow-right-outline</v-icon></v-btn>
          <v-btn icon @click="openReportDeleteConfirmDialog(report)" class="ml-3"><v-icon color="red">mdi-file-document-remove</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-dialog
      v-model="reportDialog"
      fullscreen
      persistent
  >
    <v-card>
      <v-card-title>Tagesbericht für den {{date}}</v-card-title>
      <v-card-text>
        <h3>Meine erfassten Zeiten</h3>
        <v-row>
          <v-col cols="3" md="1" order="2" order-md="1"><strong>Start</strong></v-col>
          <v-col cols="3" md="1" order="3" order-md="2"><strong>Ende</strong></v-col>
          <v-col cols="12" md="4" order="1" order-md="3" v-if="!isMobileResize"><strong>Beschreibung</strong></v-col>
          <v-col cols="6" md="2" order="5" order-md="4" v-if="!isMobileResize"><strong>Leist. KSt.</strong></v-col>
          <v-col cols="6" md="2" order="6" order-md="5" v-if="!isMobileResize"><strong>Empf. KSt.</strong></v-col>
          <v-col cols="6" md="2" order="4" order-md="6" class="text-right"><strong>Aktionen</strong></v-col>
        </v-row>
        <v-row v-for="event in timetrackEvents" :key="event.id">
          <v-col cols="3" md="1" order="4" order-md="1">{{event.start | dateTimeToTime}}</v-col>
          <v-col cols="3" md="1" order="5" order-md="2">{{event.end | dateTimeToTime}}</v-col>
          <v-col cols="12" md="4" order="1" order-md="3">{{event.title}}</v-col>
          <v-col cols="6" md="2" order="2" order-md="4"><span v-if="event.supplying_costcenterid">{{getCCLabel(event.supplying_costcenterid)}}, {{getCCName(event.supplying_costcenterid)}}</span></v-col>
          <v-col cols="6" md="2" order="3" order-md="5"><span v-if="event.receiving_costcenterid">{{getCCLabel(event.receiving_costcenterid)}}, {{getCCName(event.receiving_costcenterid)}}</span></v-col>
          <v-col cols="6" md="2" order="6" order-md="6" class="text-right">
            <v-btn icon @click="editTimetrackEvent(event)" :disabled="selectedReportState === 'approved' || selectedReportState === 'submitted'"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon class="ml-2" :disabled="selectedReportState === 'approved' || selectedReportState === 'submitted'" @click="deleteTimetrackEventPopup(event)"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="timetrackEventDialog = true" :disabled="selectedReportState === 'approved' || selectedReportState === 'submitted'"><v-icon class="mr-2">mdi-plus-circle-outline</v-icon>Zeit hinzufügen</v-btn>
      </v-card-actions>
      <v-divider class="mt-10"></v-divider>
      <v-card-actions class="d-none d-sm-flex">
        <v-btn outlined @click="closeReportDialog">Zurück</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="selectedReportState === 'submitted' || selectedReportState === 'approved'" color="primary" outlined @click="reportDialog = false">Schliessen</v-btn>
        <!-- <v-btn color="primary" outlined @click="saveReport"><v-icon class="mr-2">mdi-file-document-edit-outline</v-icon>Speichern</v-btn> -->
        <v-btn v-if="selectedReportState === 'created'" color="primary" outlined @click="submitReport"><v-icon class="mr-2">mdi-file-document-arrow-right-outline</v-icon>Einreichen</v-btn>
      </v-card-actions>
      <v-card-actions class="d-block d-sm-none">
        <v-row dense>
          <v-col cols="12" v-if="selectedReportState === 'submitted' || selectedReportState === 'approved'"><v-btn block color="primary" outlined @click="reportDialog = false">Schliessen</v-btn></v-col>
          <v-col cols="12" v-if="selectedReportState === 'created'"><v-btn block color="primary" outlined @click="submitReport"><v-icon class="mr-2">mdi-file-document-arrow-right-outline</v-icon>Einreichen</v-btn></v-col>
          <v-col cols="12"><v-btn block text @click="closeReportDialog">Zurück</v-btn></v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="confirmReportDeleteDialog">
    <v-card>
      <v-card-title><v-icon color="primary">mdi-delete</v-icon>Wirklich löschen</v-card-title>
      <v-card-text>
        Das Löschen des Tagesberichts kann nicht rückgängig gemacht werden. Bitte bestätigen:
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeReportDeleteConfirmDialog">Abbrechen</v-btn>
        <v-btn outlined color="primary" @click="deleteReport(reportSelectedToDelete)">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="timetrackEventDialog"
      fullscreen
      persistent
  >
    <v-card>
      <v-card-title>Aufgabe bearbeiten</v-card-title>
      <v-card-text>
        <v-form
            v-on:submit.prevent="true"
            ref="formTimetrackEvent"
            v-model="validTimetrackEvent"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                  label="Titel *"
                  v-model="selectedTimetrackEvent.title"
                  outlined
                  :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Beschreibung" v-model="selectedTimetrackEvent.description" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  label="Start *"
                  v-model="selectedTimetrackEvent.startTime"
                  outlined
                  :rules="[rules.required, rules.timePattern]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  label="Ende *"
                  v-model="selectedTimetrackEvent.endTime"
                  outlined
                  :rules="[rules.required, rules.timePattern]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                  label="Leistende Kostenstelle"
                  v-model="selectedTimetrackEvent.supplying_costcenterid"
                  :items="costCenters"
                  item-value="id"
                  item-text="name"
                  :filter="customCostcenterFilter"
                  outlined
                  clearable
              >
                <template v-slot:selection="data">
                  {{data.item.label}}, {{data.item.name}}
                </template>
                <template v-slot:item="data">
                  {{data.item.label}}, {{data.item.name}}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                  label="Empfangende Kostenstelle *"
                  v-model="selectedTimetrackEvent.receiving_costcenterid"
                  :items="costCenters"
                  item-value="id"
                  item-text="label"
                  :filter="customCostcenterFilter"
                  outlined
                  :rules="[rules.required]"
                  clearable
              >
                <template v-slot:selection="data">
                  {{data.item.label}}, {{data.item.name}}
                </template>
                <template v-slot:item="data">
                  {{data.item.label}}, {{data.item.name}}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select label="Zulagen" v-model="selectedTimetrackEvent.bonuses" :items="bonuses" item-value="id" item-text="name" outlined multiple></v-select>
            </v-col>
            <v-col cols="6">
              <v-select label="Auftrag" outlined></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="closeTimetrackEventDialog">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="saveTimetrackEvent" :disabled="!validTimetrackEvent">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showDeleteTimetrackEventDialog">
    <v-card>
      <v-card-title><v-icon color="primary">mdi-delete</v-icon>Zeit wirklich löschen</v-card-title>
      <v-card-text>
        Löschen von Zeiten kann nicht rückgängig gemacht werden.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeDeleteTimetrackEventPopup">Abbrechen</v-btn>
        <v-btn outlined color="primary" @click="confirmedDeleteTimetrackEventByID(selectedTimetrackEvent.id)">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-card v-if="user.email === 'rene@codefive.de'">
    <v-card-text>
      <UploadFile></UploadFile>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import ReportApi from "@/apis/ReportApi";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import moment from "moment";
import UploadFile from "@/components/UploadFile.vue";

export default {
  name: "ReportSingle2",
  components: {UploadFile},
  data: () => ({
    // APIs
    timeTrackingApi: null,
    reportApi: null,
    // other
    menu: false,
    date: "2023-01-03",
    reportDialog: false,
    confirmReportDeleteDialog: false,
    timetrackEventDialog: false,
    showDeleteTimetrackEventDialog: false,
    isMobileResize: false,
    validTimetrackEvent: false,
    tteLoading: false,

    // events
    timetrackEvents: [],

    timetrackEventsCollection: [],

    defaultTimetrackEvent: {
      title: "",
      description: "",
      bonuses: [],
      receiving_costcenterid: null,
      supplying_costcenterid: null,
      start: "",
      end: "",
      startTime: "00:00",
      endTime: "00:00",
      manual: true,
    },
    selectedTimetrackEvent: null,
    selectedReportState: null,

    // reports
    reports: [],
    defaultReport: {
      state: "created",
    },
    selectedReport: null,

    filteredReports: [],

    reportSelectedToDelete: null,

    rules: {
      required: value => !!value || 'Erforderlich',
      timePattern: value => {
        const pattern = /^\d\d:\d\d$/
        return pattern.test(value) || 'HH:MM Format erforderlich';
      },
    },

  }),
  beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  watch: {
    date: {
      handler: function (newVal) {
        //this.filteredReports = this.reports.filter((r) => r.date === newVal)
        this.filteredReports = this.filterReportsByDate(newVal);
        this.getEventsByDate()
      },
    },
    timetrackEventsCollection: {
      handler: function (val) {
        console.log("Watch timetrackEventsCollection")
        console.log(val);
      },
      deep: true,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['user']),
  },
  methods: {
    today() {
      this.date = moment().format("YYYY-MM-DD");
    },
    dateOfToday() {
      return moment().format("YYYY-MM-DD");
    },
    dayBefore() {
      this.date = moment(this.date, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD");
    },
    dayAfter() {
      this.date = moment(this.date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    },
    futureNav() {
      if(this.date === moment().format("YYYY-MM-DD")) {
        return true;
      }
      return false;
    },
    onResize () {
      if(window.innerWidth < 600) {
        this.isMobileResize = true
      } else {
        this.isMobileResize = false
        this.isMobileResize = false
      }
      //this.isMobileResize = window.innerWidth < 600
    },
    customCostcenterFilter (item, queryText, itemText) {
      const name = item.name.toLowerCase()
      const label = item.label.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 ||
          label.indexOf(searchText) > -1
    },
    filterReportsByDate(date) {
      return this.reports.filter((r) => r.date === date);
    },
    editTimetrackEvent(event) {
      this.selectedTimetrackEvent = Object.assign({}, event);
      if(this.selectedTimetrackEvent.start) {
        this.selectedTimetrackEvent.startTime = this.selectedTimetrackEvent.start.substr(11, 5);
      }
      if(this.selectedTimetrackEvent.end) {
        this.selectedTimetrackEvent.endTime = this.selectedTimetrackEvent.end.substr(11, 5);
      }
      console.log(this.selectedTimetrackEvent)
      this.timetrackEventDialog = true;
    },
    closeTimetrackEventDialog() {
      this.timetrackEventDialog = false;
      this.selectedTimetrackEvent = Object.assign({}, this.defaultTimetrackEvent);
      this.$refs.formTimetrackEvent.reset();
    },
    deleteTimetrackEventPopup(event) {
      this.selectedTimetrackEvent = Object.assign({}, event);
      console.log(this.selectedTimetrackEvent)
      this.showDeleteTimetrackEventDialog = true;
    },
    closeDeleteTimetrackEventPopup() {
      this.selectedTimetrackEvent = Object.assign({}, this.defaultTimetrackEvent);
      this.showDeleteTimetrackEventDialog = false;
    },
    saveTimetrackEvent() {
      if(this.selectedTimetrackEvent.startTime) {
        this.selectedTimetrackEvent.start = this.date + "T" + this.selectedTimetrackEvent.startTime;
      }
      if(this.selectedTimetrackEvent.endTime) {
        this.selectedTimetrackEvent.end = this.date + "T" + this.selectedTimetrackEvent.endTime;
      }
      if(this.selectedTimetrackEvent.id) {
        console.log("UPDATE")
        this.updateTimetrackEvent();
      } else {
        console.log("CREATE NEW")
        this.addTimetrackEvent();
      }
    },
    addTimetrackEvent() {
      this.timeTrackingApi.createTimetrackEvent(this.selectedTimetrackEvent).then((res0) => {
        console.log(res0.data)
        this.reportApi.getUsersReportEventsByDate(this.date).then((res) => {
          console.log(res.data)
          this.timetrackEvents = res.data.timetrackEvents;
          this.selectedReport = res.data.report;
          this.closeTimetrackEventDialog();
        })
      });
    },
    updateTimetrackEvent() {
      this.timeTrackingApi.updateTimetrackEvent(this.selectedTimetrackEvent).then((res0) => {
        console.log(res0.data)
        this.reportApi.getUsersReportEventsByDate(this.date).then((res) => {
          console.log(res.data)
          this.timetrackEvents = res.data.timetrackEvents;
          this.selectedReport = res.data.report;
          this.closeTimetrackEventDialog();
        })
      });
    },
    confirmedDeleteTimetrackEventByID(id) {
      this.timeTrackingApi.deleteTimetrackEventByID(id).then(() => {
        this.reportApi.getUsersReportEventsByDate(this.date).then((res) => {
          //console.log(res.data)
          this.timetrackEvents = res.data.timetrackEvents;
          this.selectedReport = res.data.report;
          this.closeDeleteTimetrackEventPopup();
        }).catch((err) => {
          console.log("Inner")
          console.log(err);
        })
      }).catch((err) => {
        console.log("Outer")
        console.log(err);
      })
    },
    saveReport() {
      if(this.selectedReport) {
        this.selectedReport.timetrackEvents = this.timetrackEvents;
        this.timeTrackingApi.createReport(this.selectedReport).then((res) => {
          console.log(res.data);
          this.timetrackEvents = [];
          this.selectedReport = Object.assign({}, this.defaultReport);
          this.getReports();
          this.closeReportDialog();
        })
      }
    },
    openReport(report) {
      console.log("Open Report")
      console.log(report)
      this.selectedReport = report;
      this.selectedReportState = this.selectedReport.state;
      console.log(this.selectedReportState)
      this.date = report.date;
      this.timetrackEvents = report.timetrackEvents;
      this.reportDialog = true;
    },
    closeReportDialog() {
      this.selectedReport = Object.assign({}, this.defaultReport);
      this.selectedReportState = this.selectedReport.state;
      this.today();
      this.getEventsByDate();
      this.reportDialog = false;
    },
    openReportDeleteConfirmDialog(report) {
      this.reportSelectedToDelete = report;
      this.confirmReportDeleteDialog = true;
    },
    closeReportDeleteConfirmDialog() {
      this.reportSelectedToDelete = null;
      this.confirmReportDeleteDialog = false;
    },
    deleteReport(report) {
      this.timeTrackingApi.deleteReportByID(report.id).then(()=>{
        this.getReports();
        this.closeReportDeleteConfirmDialog();
      })
    },
    submitReport() {
      if(!this.selectedReport.saved) {
        this.selectedReport.timetrackEvents = this.timetrackEvents;
        console.log("Submit Report (not saved):")
        console.log(this.selectedReport)
        this.timeTrackingApi.createReport(this.selectedReport).then((res) => {
          console.log(res.data);
          this.selectedReport = res.data;
          this.timeTrackingApi.submitReport(this.selectedReport).then(() => {
            this.getReports();
            this.filteredReports = this.filterReportsByDate(this.selectedReport.date);
            this.timetrackEvents = [];
            this.selectedReport = Object.assign({}, this.defaultReport);
            this.closeReportDialog();
            this.setSuccessMessage("Tagesbericht erfolgreich eingereicht");
          })
        })
      } else {
        console.log("Submit Report (saved):")
        console.log(this.selectedReport)
        this.timeTrackingApi.submitReport(this.selectedReport).then(() => {
          this.getReports();
          this.filteredReports = this.filterReportsByDate(this.selectedReport.date);
          this.timetrackEvents = [];
          this.selectedReport = Object.assign({}, this.defaultReport);
          this.closeReportDialog();
          this.setSuccessMessage("Tagesbericht erfolgreich eingereicht");
        })
      }
    },

    getEventsByDate() {
      this.tteLoading = true;
      this.reportApi.getUsersReportEventsByDate(this.date).then((res) => {
        console.log(res.data);
        this.timetrackEvents = res.data.timetrackEvents;
        this.selectedReport = res.data.report;
        //this.selectedReportState = res.data.report.state;
        // this.reportDialog = true;
        this.tteLoading = false;
      })
    },
    getInitialReports() {
      this.reportApi.getUsersReports().then((res) => {
        this.reports = res.data;
        this.filteredReports = this.filterReportsByDate(this.date);
      })
    },
    getReports() {
      this.reportApi.getUsersReports().then((res) => {
        this.reports = res.data;
      })
    },
    getStateColor(state) {
      if(state === 'created') {
        return "grey";
      }
      if(state === 'submitted') {
        return "yellow";
      }
      if(state === 'approved') {
        return "green";
      }
      if(state === 'rejected') {
        return "red";
      }
    },
    getCCLabel(id) {
      if(id == null || id == "") {
        return "n.a.";
      }
      if(id) {
        let cc = this.costCenterMap.get(id);
        return cc.label;
      }
      return '';
    },
    getCCName(id) {
      if(id) {
        let cc = this.costCenterMap.get(id);
        return cc.name;
      }
      return '';
    },
    ...mapActions(piniaStore, ['getCostCenters']),
    ...mapActions(piniaStore, ['getBonuses']),
    ...mapActions(piniaStore, ['setSuccessMessage']),
  },
  created() {
    //this.date = moment().format("YYYY-MM-DD");
    this.today();
    this.timeTrackingApi = new TimeTrackingApi();
    this.reportApi = new ReportApi();
    this.selectedTimetrackEvent = Object.assign({}, this.defaultTimetrackEvent);
    this.getInitialReports();
    this.selectedReport = Object.assign({}, this.defaultReport);
    this.selectedReportState = this.selectedReport.state;
    this.getBonuses();
    this.getCostCenters();
    this.getEventsByDate();
  },
  mounted () {
    console.log(this.$vuetify.breakpoint.mobile)
    console.log(this.$vuetify.breakpoint.mobileBreakpoint)
    console.log(this.$vuetify.breakpoint.mdAndUp)
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  }
}
</script>

<style scoped>

</style>