<template>
<v-container>
  <v-card>
    <v-card-title>Realm hinzufügen</v-card-title>
    <v-card-text>
      <v-text-field
        label="Name"
        v-model="realm.name"
      ></v-text-field>
      <v-text-field
          label="Beschreibung"
          v-model="realm.description"
      ></v-text-field>
      <v-text-field
          label="Subdomain"
          v-model="realm.subdomain"
      ></v-text-field>
      <v-text-field
          label="Domain"
          v-model="realm.domain"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="save"
      >Speichern</v-btn>

      <v-btn
          @click="cancel"
      >Abbrechen</v-btn>
    </v-card-actions>
  </v-card>
  <v-card>
    <v-card-title>Realms</v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="realms"
          item-key="id"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="loadRealm(item)"
          >
            mdi-dots-horizontal
          </v-icon>
          <v-icon
              small
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteRealm(item)"
              v-if="item.id !== '00000000-0000-0000-0000-000000000001' && item.name !== 'admin'"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
export default {
  name: "Realms",
  data: () => ({
    realm: {
      name: '',
      description: '',
      subdomain: '',
      domain: '',
    },
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Beschreibung',
        value: 'description'
      },
      {
        text: 'Subdomain',
        value: 'subdomain'
      },
      {
        text: 'Domain',
        value: 'domain'
      },
      {
        text: 'Erstellt',
        value: 'created'
      },
      {
        text: 'Aktualisiert',
        value: 'updated'
      },
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
    realms: [],
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token']),
  },
  methods: {
    deleteRealm(realm) {
      console.log("You cannot delete the realm " + realm.name);
    },
    cancel() {
      this.realm = {
        name: '',
        description: '',
        subdomain: '',
        domain: '',
      };
    },
    save() {
      axios.post(this.baseUrl + '/api/v1/realms', this.realm, {headers: {"Authorization" : `Bearer ${this.token}`}})
        .then((response) => {
          console.log(response.status);
          this.cancel();
          this.getRealms();
        })
        .catch(() => {
          console.log("Something went wrong while saving the realm");
        });
    },
    getRealms() {
      axios
          .get(this.baseUrl + '/api/v1/realms', {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((response) => {
            this.realms = response.data;
          })
          .catch(() => {
            console.log("Something went wrong while fetching all realms");
          });
    },
    loadRealm(realm) {
      this.$router.push('/realms/' + realm.id);
    },
  },
  created: function() {
    this.getRealms();
  },
}
</script>

<style scoped>

</style>