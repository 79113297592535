<template>
  <v-container fluid>
    <v-tabs
        v-model="currentTab"
        align-with-title
        color="#0f1a20"
    >
      <v-tabs-slider color="#49fcd4"></v-tabs-slider>
      <v-tab key="Eigene">Eigene</v-tab>
      <v-tab
          v-for="tab in tabs"
          :key="tab.name"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item key="Eigene">
        <v-row>
          <v-col>
            <v-sheet class="pa-3">
              <TaskDialog></TaskDialog>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-for="task in tasksCurrent" :key="task.id" class="my-2">
          <v-col>
            <TaskCard v-bind:task="task" v-if="task.id !== runningTaskId" class="mx-1"></TaskCard>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
          v-for="tab in tabs"
          :key="tab.name"
      >
        <v-row>
          <v-col>
            <TaskDialog></TaskDialog>
          </v-col>
        </v-row>
        <v-row v-if="tab.tasks.length === 0"></v-row>
        <v-row v-else
            v-for="task in tab.tasks.filter(t => t.status !== 'running')"
               :key="task.id"
        >
          <v-col>
            <TaskCard v-bind:task="task"></TaskCard>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-container v-if="runningTask !== null">
      <div style="height: 50px;"></div>
    </v-container>
    <TaskActive ref="activeTask" v-bind:runningTask="runningTask" v-if="runningTask !== null"></TaskActive>
    <!-- EBFFFA fffcfc-->
  </v-container>
</template>
<script>
import axios from "@/main";
import TaskActive from "@/components/TaskActive";
import TaskDialog from "@/components/TaskDialog";
import { mapState, mapActions } from 'pinia';
import TaskCard from "@/components/TaskCard";
import {piniaStore} from "@/store/pinia";
export default {
  name: "TaskList",
  components: {TaskCard, TaskDialog, TaskActive},
  data: () => ({
    tabs: [
      {
        id: '1',
        name: 'Abgeschlossen',
        tasks: [],
      },
      // {
      //     id: '2',
      //     name: 'Erstellt von mir',
      //     tasks: [],
      // },
    ],
    myteams: Array,
    currentTab: null,
    tasks: Array,
    teamTasks: Array,
    roleTasks: Array,
    finishedTasks: Array,
    running: null,
    info: '',
    activateFullscreen: false,
    showdetails: null,
    incident: {
      title: '',
      description: '',
    },
    pullTasksHandle: null,
  }),
  watch: {
    /*runnies: {
        handler: function (newVal) {
            console.log(newVal)
        },
        deep: true,
    },*/
    running: function(newVal, oldVal) {
      console.log('running watcher')
      console.log(newVal)
      console.log(oldVal)
    },
    tasks: {
      handler: function(newVal) {
        console.log('tasks changed')
        console.log(newVal.length)
      },
      deep: true,
    },
    tabs: {
      handler: function(newVal) {
        console.log('tasksCurrent changed')
        console.log(newVal.length)
      },
      deep: true,
    }
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    /*running: function () {
        return this.$root.$children[0].$data.running;
    }*/
    /*
    Never used and has side effect warning / error in Eslint
    ownTasks: function () {
      console.log('own tasks computed')
      let tasks = this.$root.$children[0].$data.tasks.filter(t => t.status !== 'finished')
      tasks = tasks.filter(t => t.assignedUser === this.currentUser.id)
      tasks.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0))
      this.tabs[0].tasks = tasks
      return tasks
    },*/
    finishedTasks2() {
      console.log('finished tasks computed')
      let tasks = this.$root.$children[0].$data.tasks.filter(t => t.status === 'finished')
      tasks = tasks.filter(t => t.assignedUser === this.payload.user.id)
      tasks.sort((a,b) => (a.end > b.end) ? 1 : ((b.end > a.end) ? -1 : 0))
      return tasks;
    },
    runningTasks () {
      console.log('running tasks computed')
      let tasks = this.$root.$children[0].$data.tasks.filter(t => t.status === 'running')
      return tasks
    },
    /*currentUser: function () {
      return this.$root.$children[0].$data.currentUser;
    },*/
    users: function() {
      return this.$root.$children[0].$data.users;
    },
    teams: function() {
      return this.$root.$children[0].$data.teams;
    },
    roles: function() {
      return this.$root.$children[0].$data.roles;
    },
    status: function() {
      return this.$root.$children[0].$data.taskStatus;
    },
    ...mapState(piniaStore, ['costCenters']), //wird evtl. nicht genutzt
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['payload']),
    ...mapState(piniaStore, ["tasksCurrent"]),
    ...mapState(piniaStore, ["runningTask"]),
    ...mapState(piniaStore, ["runningTaskId"]),
  },
  methods: {
    setRunningTask(task) {
      if (task === null) {
        this.running = null
        this.runningTask = null;
        this.$root.$children[0].$data.running = null;
        this.$root.$children[0].$data.runningTask = null;
        window.localStorage.removeItem("runningTask");
        window.localStorage.removeItem("runningTaskID");
      } else {
        this.running = task.id
        this.runningTask = task;
        this.$root.$children[0].$data.running = task.id;
        this.$root.$children[0].$data.runningTask = task;
        window.localStorage.setItem("runningTask", JSON.stringify(task));
        window.localStorage.setItem("runningTaskID", task.id);
      }
    },
    /*
    startTask(task) {
      console.log('X start task ' + task.id)
      var vm = this;
      if (this.running !== null) {
        this.stopTask(this.running);
      }

      axios.post('/tasks/start/' + task.id)
          .then(function (response) {
            console.log(response.data);
            vm.info = "Starting task " + task.id;
            vm.tasks = vm.tasks.map(c =>
                c.id === task.id ? response.data : c
            );
            vm.running = task.id;

            // Noch mit rich data auffüllen, weil vom backend nix kommt
            if (response.data.receiving_costcenterid !== null) {
              let rcc = vm.costcenters.findIndex(cc => cc.id === response.data.receiving_costcenterid)
              response.data.receiving_costcenter = {
                id: response.data.receiving_costcenterid,
                name: vm.costcenters[rcc].name,
                label: vm.costcenters[rcc].label,
              }
            } else {
              response.data.receiving_costcenter = {
                id: '',
                name: '',
                label: '',
              }
            }
            if (response.data.supplying_costcenterid !== null) {
              let scc = vm.costcenters.findIndex(cc => cc.id === response.data.supplying_costcenterid)
              response.data.supplying_costcenter = {
                id: response.data.supplying_costcenterid,
                name: vm.costcenters[scc].name,
                label: vm.costcenters[scc].label,
              }
            } else {
              response.data.supplying_costcenter = {
                id: '',
                name: '',
                label: '',
              }
            }
            // Ende auffüllen
            vm.setRunningTask(response.data)
            vm.tabs.forEach((t, i)=>{
              var index = t.tasks.findIndex(ta => ta.id === task.id)
              vm.tabs[i].tasks[index] = response.data;
            });
            //taskTimer(id);
          })
          .catch(function (error) {
            console.log('error starting task')
            console.log(error);
          })
    }, */
    /* stopTask(id) {
      console.log('X stop task ' + id)
      var vm = this;
      if (vm.running === id) {
        vm.setRunningTask(null)
      }
      axios.post('/tasks/stop/' + id)
          .then(function (response) {
            console.log(response.data);
            vm.info = "Stopping task " + id;
            vm.tasks = vm.tasks.map(c =>
                c.id === id ? response.data : c
            );
            vm.tabs.forEach((t, i)=>{
              var index = t.tasks.findIndex(ta => ta.id === id)
              vm.tabs[i].tasks[index] = response.data;
            });
            //$('#task-timer-display-' + id).html( displayTime(Math.floor( parsePeriod(response.data.duration) ) ));
            //clearInterval(currentTimer);
          })
          .catch(function (error) {
            console.log('error stopping task')
            console.log(error);
          })
    }, */
    // state manager overtake
    /* finishTask(id) {
      console.log('X finish task ' + id)
      var vm = this;
      if (vm.running === id) {
        vm.setRunningTask(null)
      }
      axios.post('/tasks/finish/' + id)
          .then(function (response) {
            console.log(response.data);
            vm.info = "Finished task " + id;
            vm.tasks = vm.tasks.map(c =>
                c.id === id ? response.data : c
            );
            vm.tasks = vm.tasks.filter(
                t => t.id !== id
            );
            vm.tabs.forEach((t, i)=>{
              //var index = t.tasks.findIndex(ta => ta.id === id)
              //vm.tabs[i].tasks[index] = response.data;
              vm.tabs[i].tasks = vm.tabs[i].tasks.filter(ta => ta.id !== id)
            });
            var finishedTabIndex = vm.tabs.findIndex(t => t.id === '1')
            vm.tabs[finishedTabIndex].push(response.data)
          })
          .catch(function (error) {
            console.log('error finishing task')
            console.log(error);
          })
    }, */
    updateTask() {
      let vm = this;
      if (vm.running !== null) {
        axios.get(this.baseUrl + '/api/v1/tasks/' + vm.running)
            .then(function (response) {
              console.log(response.data);
              console.log("Get task " + vm.running);
              vm.tasks = vm.tasks.map(t =>
                  t.id === vm.running ? response.data : t
              );
              vm.info = '';
            })
            .catch(error => {
              if (!error.response) {
                // network error
                vm.info = 'Netzwerk Fehler';
              } else {
                vm.info = error.response.data.message;
              }
            })
      }
    },
    showTask(task) {
      console.log(task)
      if (this.showdetails === task.id) {
        this.showdetails = null;
      } else {
        this.showdetails = task.id;
      }
    },
    ...mapActions(piniaStore, ["getTasksCurrent"]),
    ...mapActions(piniaStore, ["startTask"]),
    ...mapActions(piniaStore, ["stopTask"]),
    ...mapActions(piniaStore, ["finishTask"]),
    ...mapActions(piniaStore, ["getCostCenters"]),
  },
  mounted: function() {
    console.log("mounted TaskList");
    this.running = this.$root.$children[0].$data.running;
    this.getCostCenters();

    let vm = this;

    vm.tabs[0].tasks = this.tasksCurrent;
    let runningTask = vm.tasksCurrent.find(element => element.status === 'running');
    if (runningTask != null) {
      vm.setRunningTask(runningTask)
    }

    // finished tasks
    axios.get(this.baseUrl + '/api/v1/tasks/all')
        .then(function (response){
          response.data.sort((a,b) => (a.end > b.end) ? 1 : ((b.end > a.end) ? -1 : 0))
          vm.finishedTasks = response.data.filter(t => t.status === 'finished');
          vm.finishedTasks = vm.finishedTasks.filter(t => t.assignedUser === vm.payload.user.id);
          vm.finishedTasks.sort((a,b) => (a.end > b.end) ? 1 : ((b.end > a.end) ? -1 : 0));
          let tab = vm.tabs.find(t => t.id === '1')
          tab.tasks = response.data.filter(t => t.status === 'finished');
          tab.tasks = tab.tasks.filter(t => t.assignedUser === vm.payload.user.id);
          tab.tasks.sort((a,b) => (a.end > b.end) ? 1 : ((b.end > a.end) ? -1 : 0));
          console.log("Finished tasks");
          console.log(tab.tasks);
          console.log(vm.finishedTasks);
        })
        .catch(function (error) {
          console.log(error);
        });

    let pth = parseInt( window.localStorage.getItem('pullTaskHandle') );
    if (!isNaN(pth)) {
      window.localStorage.removeItem('pullTaskHandle');
      clearInterval(pth);
    }

    /* erst mal nicht
    if (isNaN(pth)) {
        window.localStorage.setItem('pullTaskHandle', setInterval(getTasksAndStoreLocal, 10000).toString() );
        console.log('handle ' + this.pullTasksHandle)
    }*/

    axios.get(this.baseUrl + '/api/teams/membership')
        .then(function (response){
          vm.myteams = response.data;
          vm.myteams.sort(
              (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
          );
          vm.myteams.forEach(function(item,index) {
            axios.get(this.baseUrl() + '/api/v1/tasks/team/' + item.id)
                .then(function (response){
                  vm.tabs.push({
                    id: item.id,
                    name: item.name,
                    tasks: response.data.filter(ta => ta.status !== 'finished'),
                  })
                })
                .catch(function (error) {
                  console.log(error);
                });
          })
          console.log('tabs membership')
          console.log(vm.tabs)
        })
        .catch(function (error) {
          console.log(error);
        });

    console.log(vm.tabs)

    this.$on("UpdateCurrentTasks", update => {
      console.log("Update-Event fired");
      vm.getTasksCurrent();
    });

    //console.log('Childs')
    //console.log(this.$root.$children)//.forEach(c => console.log(c.$options.name))
    //this.$root.$children[0].$children[0].$children.find(child => child.$options.name === 'v-main').$children.find(child => child.$options.name === 'task-list').karamba = 'HAHA'  //.forEach(child => console.log(child.$options.name))
  },
};
</script>