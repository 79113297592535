<template>
  <v-container>
    <v-card>
      <v-card-title>Meine Tagesberichte</v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Datum
            </th>
            <th class="text-left">
              Status
            </th>
            <th class="text-left">
              Aktionen
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in reports"
              :key="item.id"
          >
            <td>{{ item.date | dateFormatReport}}</td>
            <td><v-chip :color="getColorFromState(item.state)">{{ item.state | reportStatus }}</v-chip></td>
            <td>
              <!--<v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="view(item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Ansehen</span>
              </v-tooltip>-->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="ml-3"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="view(item.id)"
                  >
                    mdi-open-in-new
                  </v-icon>
                </template>
                <span>Ansehen</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="ml-3"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-send
                  </v-icon>
                </template>
                <span>Einreichen</span>
              </v-tooltip>
              <v-tooltip top v-if="payload.user.email === 'rene@codefive.de'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="ml-3"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteReport(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-actions>
        <v-btn icon @click="getReports"><v-icon>mdi-sync</v-icon></v-btn>
        <v-btn icon @click="getMyReports"><v-icon>mdi-sync</v-icon></v-btn>
        <span>{{message}}</span>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "ReportOverview",
  data: () => ({
    reports: [],
    message: '',
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['token']),
    ...mapState(piniaStore, ['payload']),
  },
  methods: {
    deleteReport(id) {
      axios.delete(this.baseUrl + "/api/v1/report/" + id, {headers: {"Authorization" : `Bearer ${this.token}`}})
      .then(()=>{
        this.getReports();
      })
      .catch((err)=>console.log(err));
    },
    getColorFromState(state) {
      if(state === 'submitted') {
        return 'green';
      }
      if(state === 'rejected') {
        return 'red';
      }
      if(state === 'accepted') {
        return 'green';
      }
      return 'yellow';
    },
    getMyReports() {
      this.message = 'Lade Tagesberichte ...';
      axios.get(this.baseUrl + '/api/v1/report', {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((res) => {
            this.reports = res.data;
            this.reports.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
            console.log(res.data);
            this.message = 'Tagesberichte geladen';
            if (this.reports.length === 0) {
              this.message = 'Tagesberichte geladen. Keine Daten vorhanden.';
            }
          })
          .catch((error) => {
            console.log("Error loading reports.");
            console.dir(error);
            this.message = "Beim Laden der Tagesberichte ist ein Fehler aufgetreten."
          });
    },
    getReports() {
      this.message = 'Lade Tagesberichte ...';
      axios.get(this.baseUrl + '/api/v1/report/reports/all', {headers: {"Authorization" : `Bearer ${this.token}`}})
      .then((res) => {
        this.reports = res.data;
        this.reports.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
        console.log(res.data);
        this.message = 'Tagesberichte geladen';
        if (this.reports.length === 0) {
          this.message = 'Tagesberichte geladen. Keine Daten vorhanden.';
        }
      })
      .catch((error) => {
        console.log("Error loading reports.");
        console.dir(error);
        this.message = "Beim Laden der Tagesberichte ist ein Fehler aufgetreten."
      });
    },
    view(id) {
      this.$router.push({ path: '/report-manager/' + id, });
    },
  },
  mounted: function() {
    this.reports.reverse();
    this.getReports();
  },
};
</script>