<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Admin Tokens</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>User</th>
                <th>Identifier</th>
                <th>Token</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Aktionen</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="token in tokens" :key="token.identifier">
                <td>{{token.userid}}</td>
                <td>{{token.identifier}}</td>
                <td>{{token.securitytoken}}</td>
                <td>{{token.created}}</td>
                <td>{{token.updated}}</td>
                <td>
                  <!-- <bonus-dialog v-bind:bonus="bonus"></bonus-dialog> -->
                  <v-icon small @click="deleteToken(token)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "@/main";
export default {
  name: "AdminTokens",
  data: () => ({
    isEditing: null,
    tokens: [],
  }),
  methods: {
    deleteToken(token) {
      let vm = this;
      axios.delete('/api/v1/tokens/' + token.identifier)
          .then(function(response){
            console.log(response.data)
            vm.tokens = vm.tokens.filter(b => b.identifier !== token.identifier)
          })
          .catch(function(error){})
    },
    getTokens() {
      let vm = this;
      axios.get('/api/v1/tokens')
          .then(function(response){
            console.log(response.data)
            vm.tokens = response.data
          })
          .catch(function(error){})
    },
  },
  mounted: function () {
    this.getTokens()
  },
}
</script>