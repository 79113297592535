<template>
<v-container>
  <v-card-title>Notizen</v-card-title>
  <v-card>
    <v-card-text>
      <v-textarea label="Notiz (Markdown)" v-model="markdown" outlined></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="getClient">Client</v-btn>
      <v-btn outlined @click="saveNote" color="primary">Speichern</v-btn>
    </v-card-actions>
    <v-card-text>
      <h2>Preview</h2>
      <div v-html="compiledMarkdown"></div>
    </v-card-text>
  </v-card>
  <v-card class="mt-5">
    <v-card-text>
      <div v-for="note in notes" :key="note.id">
        <div v-html="compileMD(note.markdown)"></div>
        <v-btn icon @click="loadNote(note)"><v-icon>mdi-file-upload-outline</v-icon></v-btn>
        <v-btn icon @click="deleteNote(note.id)"><v-icon>mdi-delete</v-icon></v-btn>
      </div>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";
import {marked} from "marked";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "Notes",
  data: () => ({
    notes: [],
    markdown: "",
    id: null,
    note: null,
  }),
  computed: {
    compiledMarkdown: function() {
      return marked(this.markdown, { sanitize: true });
    },
    ...mapState(piniaStore, ["costCenterMap"])
  },
  created() {
    this.getNotes();
  },
  methods: {
    compileMD(md) {
      return marked(md, { sanitize: true });
    },
    deleteNote(id) {
      axios.delete("/api/v1/notes/" + id).then(() => {
        this.getNotes();
      })
    },
    getNotes() {
      axios.get("/api/v1/notes").then((res) => {
        this.notes = res.data;
      })
    },
    loadNote(note) {
      this.note = Object.assign({}, note);
      this.id = note.id;
      this.markdown = note.markdown;
      console.log(this.note)
      console.log(this.id)
    },
    saveNote() {
      if(this.id !== null) {
        this.note.markdown = this.markdown;
        this.updateNote();
      } else {
        this.addNote();
      }
    },
    addNote() {
      axios.post("/api/v1/notes", {
        markdown: this.markdown,
      }).then(()=> {
        this.getNotes();
        this.markdown = "";
      });
    },
    updateNote() {
      console.log(this.note)
      axios.put("/api/v1/notes", this.note).then(()=> {
        this.getNotes();
        this.markdown = "";
        this.id = null;
      });
    },
    getClient() {
      axios.get("/api/v1/notes/client").then((res) => {
        console.log(res.data)
      })
    },
  },
}
</script>

<style scoped>

</style>