<template>
  <v-card>
    <v-row class="d-flex justify-space-between align-center">
      <v-col cols="8" sm="10" @click="showTask(task)">
        <v-card-title>{{task.title}}</v-card-title>
        <v-card-text>{{task.description}}</v-card-text>
      </v-col>
      <v-col cols="4" sm="2">
        <!-- <v-btn v-if="task.status === 'running'" fab large color="#f71784" dark @click="stopTask(task.id)">
          <v-icon large>mdi-pause</v-icon>
        </v-btn> -->
        <!-- <v-btn v-else fab large color="#49fcd4" dark @click="startTask(task.id)"> -->
        <v-btn
            v-if="task.status !== 'running' && task.status !== 'finished'"
            fab
            large
            color="#49fcd4"
            dark
            @click="doStartTask(task)">
          <v-icon large>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showdetails === task.id">
      <v-col>
        <v-card-text>{{getCostCenterName(task.supplying_costcenterid)}} ({{getCostCenterLabel(task.supplying_costcenterid)}})</v-card-text>
        <v-card-text>{{getCostCenterName(task.receiving_costcenterid)}} ({{getCostCenterLabel(task.receiving_costcenterid)}})</v-card-text>
        <v-card-text>Status: {{task.status}}</v-card-text>
      </v-col>
    </v-row>
    <v-row v-if="showdetails === task.id">
      <v-col>
        <v-card-actions>
          <IncidentDialog v-bind:task="task"></IncidentDialog>
          <v-btn
              class="ml-3"
              color="#f71784"
              dark
              @click="finishTask(task.id)">Task abschließen</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-row>
      <v-card-text style="text-align: center;">{{task.duration | runtimeFormat}}</v-card-text>
    </v-row>
  </v-card>
</template>
<script>
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "TaskCard",
  props: ['task'],
  data: () => ({
    showdetails: null,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['costCenterMap']),
  },
  methods: {
    doStartTask(task) {
      console.log("Starting task -> handover pinia");
      this.startTask(task);
    },
    getCostCenterLabel(id) {
      if (id == null) {
        return 'n/a';
      }
      if (this.costCenterMap.has(id)) {
        let cc = this.costCenterMap.get(id);
        return cc.label;
      }
      return 'n/a';
    },
    getCostCenterName(id) {
      if (id == null) {
        return 'Nicht zugewiesen';
      }
      if (this.costCenterMap.has(id)) {
        let cc = this.costCenterMap.get(id);
        return cc.name;
      }
      return 'Nicht zugewiesen';
    },
    showTask(task) {
      console.log(task)
      if (this.showdetails === task.id) {
        this.showdetails = null;
      } else {
        this.showdetails = task.id;
      }
    },
    ...mapActions(piniaStore, ["startTask"]),
    ...mapActions(piniaStore, ["stopTask"]),
    ...mapActions(piniaStore, ["finishTask"]),
  },
};
</script>