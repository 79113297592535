<template>
<v-container>
  <v-card>
    <v-card-title>Projektverwaltung</v-card-title>
    <v-card-text>
      <v-text-field label="Name" v-model="project.name" outlined></v-text-field>
      <v-text-field label="Beschreibung" v-model="project.description" outlined></v-text-field>
      <v-select
          label="Kunde"
          v-model="project.clientId"
          :items="clients"
          item-text="name"
          item-value="id"
          outlined
      ></v-select>
      <v-row>
        <v-col cols="8"><v-text-field label="Budget" v-model="project.budget" outlined type="number"></v-text-field></v-col>
        <v-col cols="4"><v-text-field label="Währung" v-model="project.currency" outlined></v-text-field></v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="addProject" color="secondary" outlined>Hinzufügen</v-btn>
    </v-card-actions>
    <v-card-text class="mt-5">
      <div v-for="prj in projects" :key="prj.id" class="mb-5">
        <v-row>
          <v-col cols="10">
            <v-text-field label="Projekt" v-model="prj.name" dense></v-text-field>
            <v-text-field label="Label" v-model="prj.label" dense></v-text-field>
            <v-text-field label="Beschreibung" v-model="prj.description" dense></v-text-field>
            <v-select label="Kunde" :items="clients" item-value="id" item-text="name" v-model="prj.clientId" dense></v-select>
            <v-text-field label="Budget" v-model="prj.budget" dense></v-text-field>
            <v-text-field label="Währung" v-model="prj.currency" dense></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn icon @click="save(prj)"><v-icon>mdi-content-save</v-icon></v-btn>
            <v-btn icon @click="deleteProject(prj.id)"><v-icon>mdi-delete</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </v-card-text>
  </v-card>
  <v-card>
    <v-card-title>Kunden</v-card-title>
    <v-card-text>
      <v-form>
        <v-row dense>
          <v-col cols="12">
            <v-text-field outlined label="Name" v-model="client.name"></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field outlined label="Straße" v-model="client.street"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field outlined label="Nummer" v-model="client.streetNo"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined label="Adresszusatz" v-model="client.additionalAddress"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field outlined label="PLZ" v-model="client.zip"></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field outlined label="Ort" v-model="client.city"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined label="Land" v-model="client.country"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined label="Rechnungs-Email" v-model="client.invoiceEmail"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="addClient" color="primary">Speichern</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-list>
        <v-list-item v-for="cl in clients" :key="cl.id">
          <v-list-item-content>
            <v-list-item-title>{{cl.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="loadClient(cl)">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
  <v-card>
    <v-card-text>
      <table>
        <tr v-for="cl in clients" :key="cl.id">
          <td>07.08.2023</td>
          <td>02:00</td>
          <td>75</td>
          <td>{{cl.name}}</td>
          <td>303 Lead Generation</td>
          <td>A7 Matchmaking-Tool V2 Prototype - Front- & Backend</td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import ProjectApi from "@/apis/ProjectApi";
import {mapActions} from "pinia";
import {piniaStore} from "@/store/pinia";
import {Duration, LocalDateTime} from "@js-joda/core";

export default {
  name: "Projects",
  data: () => ({
    projects: [],
    projectApi: null,

    name: "",
    description: "",
    clientId: "",
    budget: null,

    defaultProject: {
      name: "",
      label: null,
      description: "",
      clientId: null,
      budget: null,
      currency: "EUR",
      type: null,
    },
    project: null,

    defaultClient: {
      name: "",
      street: "",
      streetNo: "",
      additionalAddress: "",
      city: "",
      country: "",
      zip: "",
      lat: "",
      lon: "",
      invoiceEmail: "",
    },
    client: null,
    clients: [],
  }),
  created() {
    this.projectApi = new ProjectApi();
    this.getProjects();
    this.getClients();
    this.client = Object.assign({}, this.defaultClient);
    this.project = Object.assign({}, this.defaultProject);

    let dt = LocalDateTime.parse("2023-08-07T10:00:00");
    let dt2 = LocalDateTime.parse("2023-08-07T12:30:00");
    let minutes = Duration.between(dt, dt2).toMinutes();
    let rate = 75;
    let result = rate * minutes / 60;
    console.log("Payday: " + result);
  },
  methods: {
    addProject() {
      this.projectApi.addProject({name: this.name, description: this.description}).then(()=>{
        this.getProjects();
        this.name = "";
        this.description = "";
      })
    },
    getProjects() {
      this.projectApi.getProjects().then((res)=>{
        this.projects = res.data;
      })
    },
    save(project) {
      this.projectApi.updateProject(project).then(()=>{
        this.getProjects()
      })
    },
    deleteProject(id) {
      this.projectApi.deleteProject(id).then(()=>{
        this.getProjects()
      })
    },
    addClient() {
      if(this.client.id) {
        this.projectApi.updateClient(this.client).then((res) => {
          this.client = Object.assign({}, this.defaultClient);
          this.setSuccessMessage("Kunde gespeichert")
          this.getClients();
        })
      } else {
        this.projectApi.addClient(this.client).then((res) => {
          this.client = Object.assign({}, this.defaultClient);
          this.setSuccessMessage("Kunde gespeichert")
          this.getClients();
        })
      }
    },
    getClientNameByID(id) {
      if(id == null || id === "") {
        return ""
      }
      this.projectApi.getClient(id).then((res) => {
        return res.data.name;
      }).catch(()=>{
        return "n/a";
      });
    },
    getClients() {
      this.projectApi.getClients().then((res) => {
        this.clients = res.data;
      });
    },
    loadClient(client) {
      this.client = Object.assign({}, client);
    },
    ...mapActions(piniaStore, ["setSuccessMessage"]),
  },
}
</script>

<style scoped>

</style>