<template>
<v-container>
  <v-card>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="leads"
          item-key="id"
          :expanded.sync="expanded"
          single-expand
          show-expand
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title @click.meta.shift="setAdmin">Leads</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="leadDialog"
                fullscreen
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  Neuer Lead
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{leadDialogTitle}}</v-card-title>
                <v-card-text>
                  <v-form
                      ref="form"
                      v-model="leadFormValid"
                  >
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            label="Vorname"
                            v-model="editedLead.firstname"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Nachname"
                            v-model="editedLead.lastname"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Titel"
                            v-model="editedLead.title"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="ID"
                            v-model="editedLead.manualId"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="editedLead.lastContacted"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editedLead.lastContacted"
                                label="Letzter Kontakt"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="editedLead.lastContacted"
                              no-title
                              scrollable
                              locale="de-de"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                              Abbrechen
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(editedLead.lastContacted)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Firma"
                            v-model="editedLead.business"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Position"
                            v-model="editedLead.position"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="E-Mail"
                            v-model="editedLead.email"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Telefon"
                            v-model="editedLead.phone"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="LinkedIn"
                            v-model="editedLead.linkedin"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-select
                            label="Kategorie"
                            v-model="editedLead.leadCategory"
                            :items="leadCategories"
                            item-text="name"
                            item-value="id"
                        ></v-select>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Branche"
                            v-model="editedLead.industry"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Firmengröße"
                            v-model="editedLead.companySize"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Firmeninformation"
                            v-model="editedLead.info"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4" >
                        <v-text-field
                            label="Deal Size"
                            v-model="editedLead.dealSize"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                            label="Notizen"
                            v-model="editedLead.notes"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="closeLeadDialog">Abbrechen</v-btn>
                  <v-btn @click="save">Speichern</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
                v-model="leadDialogDelete"
                max-width="500px"
            >
              <v-card>
                <v-card-title>Lead wirklich löschen?</v-card-title>
                <v-card-actions>
                  <v-btn @click="closeDeleteLead">Abbrechen</v-btn>
                  <v-btn @click="deleteLeadConfirm">Löschen</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
                v-model="leadDialogCategory"
                fullscreen
            >
              <v-card>
                <v-card-title>Neue Lead-Kategorie</v-card-title>
                <v-card-text>
                  <v-form
                      ref="categoryForm"
                      v-model="leadCategoryValid"
                  >
                    <v-text-field
                        label="Kategorie"
                        v-model="editedLeadCategory.name"
                    ></v-text-field>
                    <v-text-field
                        label="Beschreibung"
                        v-model="editedLeadCategory.description"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="leadDialogCategory=false">Abbrechen</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn icon class="mb-2 ml-2" @click="updateData"><v-icon>mdi-sync</v-icon></v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.leadCategory="{ item }">
          {{ getLeadCategoryName(item.leadCategory) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editLead(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteLead(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="exp-row">
            <h4>Details zu {{item.firstname}} {{ item.lastname }}</h4>
            <div>
              🌐 <a :href="item.url">{{item.url}}</a><br>
              🔗 <a :href="item.linkedin">LinkedIn</a><br>
              📱 <a :href="`tel:${item.phone}`">{{item.phone}}</a><br>
            </div>
            <div v-if="item.notes">
              <hr>
              <h4>Notizen</h4>
              <pre>{{item.notes}}</pre>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <v-card class="mt-2" v-if="showAdmin">
    <v-card-title>Admin Options</v-card-title>
    <v-card-text>Please use carefully. All actions are instant.</v-card-text>
    <v-card-actions>
      <v-btn @click="showAdmin=false">Cancel</v-btn>
      <v-btn @click="dropLeads" color="primary">Drop</v-btn>
    </v-card-actions>
  </v-card>
  <v-btn to="/leadcategories" class="mt-3">Kategorien verwalten</v-btn>
</v-container>
</template>

<script>
import axios from "@/main";
import {mapState} from "pinia/dist/pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "LeadManager",
  data: () => ({
    headers: [
      {text: "Nachname", value: "lastname"},
      {text: "Vorname", value: "firstname"},
      {text: "Firma", value: "business"},
      {text: "Kontakt", value: "lastContacted"},
      {text: "Mail", value: "email"},
      {text: "Kategorie", value: "leadCategory"},
      {text: 'Actions', value: 'actions', sortable: false},
      {text: '', value: 'data-table-expand'},
    ],
    expanded: [],
    leads: [],
    leadCategories: [],
    leadCategoryMap: null,
    leadDialog: false,
    leadDialogTitle: 'Lead erstellen',
    leadFormValid: false,
    leadDialogDelete: false,
    leadDialogCategory: false,
    leadCategoryValid: false,
    editedLead: {},
    defaultLead: {
      manualId: '',
      title: '',
      firstname: '',
      lastname: '',
      business: '',
      location: '',
      companySize: '',
      info: '',
      industry: '',
      position: '',
      email: '',
      url: '',
      linkedin: '',
      phone: '',
      lastContacted: '',
      dealSize: '',
      notes: '',
      leadCategory: null,
    },
    editedLeadCategory: {},
    defaultLeadCategory: {
      name: '',
      description: '',
    },
    menu: false,
    showAdmin: false,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token']),
  },
  methods: {
    getLeads() {
      axios.get(this.baseUrl + "/api/v1/leads/get", {headers: {"Authorization" : `Bearer ${this.token}`}})
      .then((res)=>{
        this.leads = res.data;
      });
    },
    getLeadCategories() {
      axios.get(this.baseUrl + "/api/v1/leadcategories/get", {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((res)=>{
            this.leadCategories = res.data;
            let lcMap = new Map();
            for (let index = 0; index < this.leadCategories.length; ++index) {
              const element = this.leadCategories[index];
              lcMap.set(element.id, element);
            }
            this.leadCategoryMap = lcMap;
          });
    },
    getLeadCategoryName(id) {
      if(id) {
        let lc = this.leadCategoryMap.get(id);
        return lc.name;
      }
      return '';
    },
    save() {
      if(this.editedLead.id) {
        this.updateLead(this.editedLead);
      } else {
        this.saveLead(this.editedLead);
      }
    },
    saveLead(lead) {
      lead.createdBy = this.payload.user.id;
      lead.assignedUser = this.payload.user.id;
      axios.post(this.baseUrl + "/api/v1/leads/add", lead, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((res)=>{
            this.getLeads();
            this.closeLeadDialog();
          });
    },
    updateLead(lead) {
      axios
          .put(this.baseUrl + "/api/v1/leads/update", lead, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then(()=>{
            this.getLeads();
            this.closeLeadDialog();
          });
    },
    closeLeadDialog() {
      this.editedLead = Object.assign({}, this.defaultLead);
      this.leadDialog = false;
      this.leadDialogTitle = "Lead erstellen";
    },
    editLead(lead) {
      this.leadDialogTitle = "Lead bearbeiten";
      this.editedLead = Object.assign({}, lead);
      this.leadDialog = true;
    },
    deleteLead(lead) {
      this.editedLead = Object.assign({}, lead);
      this.leadDialogDelete = true;
    },
    deleteLeadConfirm() {
      axios
          .delete(this.baseUrl + "/api/v1/leads/delete/" + this.editedLead.id, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then(()=>{
            this.getLeads();
            this.closeDeleteLead();
          })
    },
    closeDeleteLead() {
      this.leadDialogDelete = false
      this.$nextTick(() => {
        this.editedLead = Object.assign({}, this.defaultLead)
      });
    },
    dropLeads() {
      axios
      .delete(this.baseUrl + "/api/v1/leads/drop", {headers: {"Authorization" : `Bearer ${this.token}`}})
      .then(() => {
        this.getLeads();
      });
    },
    setAdmin() {
      if(this.payload.user.email === 'rene@codefive.de') {
        this.showAdmin = true;
      }
    },
    updateData() {
      this.getLeadCategories();
      this.getLeads();
    },
  },
  created() {
    this.editedLead = Object.assign({}, this.defaultLead);
    this.getLeadCategories();
    this.getLeads();
  },
}
</script>

<style scoped>
.exp-row {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
tr {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.exp-row h4 {
  padding-top: 10px;
}
.exp-row div {
  padding-bottom: 5px;
}
.exp-row pre {
  padding: 10px 5px;
}
</style>