<template>
  <v-snackbar
      v-model="showSnackbar"
      :timeout="2000"
      :color="snackbarMessage.type === 'ERROR' ? 'red darken-4' : 'grey darken-4'"
  >
    {{ snackbarMessage.message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {piniaStore} from "@/store/pinia";
import {mapState} from "pinia";

export default {
  name: "GlobalSnackbar",
  data: () => ({
    showSnackbar: false,
  }),
  watch: {
    snackbarMessage() {
      if (this.snackbarMessage) {
        this.showSnackbar = true;
      }
    },
  },
  computed: {
    ...mapState(piniaStore, ["snackbarMessage"]),
  },
}
</script>

<style scoped>

</style>