<template>
<v-list-item-avatar>
  <v-img v-if="user.avatar !== null || user.avatar !== ''" :src="avatarUrl" :alt="initials"></v-img>
  <span v-else>{{initials}}</span>
</v-list-item-avatar>
</template>

<script>
export default {
  name: "ListAvatar",
  props: ["user"],
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    initials() {
      return this.user.firstname.substring(0,1) + this.user.lastname.substring(0,1);
    },
    avatarUrl() {
      return this.baseUrl + this.user.avatar;
    },
  },
}
</script>

<style scoped>

</style>