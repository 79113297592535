<template>
<v-container>
  <v-card>
    <v-card-title>Tagesberichte (Manager-Ansicht)</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="filterDate"
                  label="Datum wählen"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  clearable
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="filterDate"
                @input="menu = false"
                no-title
                scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-select label="Status" outlined :items="stateList" item-text="label" item-value="state" v-model="filterState" @change="filterByState"></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
              label="Benutzer"
              prepend-inner-icon="mdi-account"
              outlined
              :items="filteredUsers"
              item-text="name"
              item-value="id"
              v-model="filterUser"
              @change="filterByUser"
              clearable
          >
            <template v-slot:selection="data">
              <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
              >
                <v-avatar left>
                  <v-img :src="baseUrl + data.item.avatar"></v-img>
                </v-avatar>
                {{ data.item.firstname }} {{ data.item.lastname }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <ListAvatar v-bind:user="data.item"></ListAvatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.firstname }} {{ data.item.lastname }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      {{filterState}}
    </v-card-text>
    <v-card-text>
      Eingereichte Tagesberichte (bereit zur Freigabe)
      <v-row>
        <v-col cols="2"><strong>Datum</strong></v-col>
        <v-col cols="4"><strong>Benutzer</strong></v-col>
        <v-col cols="4"><strong>Status</strong></v-col>
        <v-col cols="2"><strong>Aktionen</strong></v-col>
      </v-row>
      <v-row v-for="report in reports" :key="report.id">
        <v-col cols="2">{{ report.date }}</v-col>
        <v-col cols="4">{{ getUserNameByID(report.user) }}</v-col>
        <v-col cols="4"><v-chip :color="getStateColor(report.state)" small>{{ report.state | reportStatus }}</v-chip></v-col>
        <v-col cols="2" class="text-right">
          <!--
          <v-btn v-if="report.state === 'created'" @click="submitReport(report)" outlined small><v-icon small class="mr-1">mdi-file-document-arrow-right-outline</v-icon> Einreichen</v-btn>
          <v-btn v-if="report.state === 'submitted'" @click="approveReport(report)" outlined small class="mr-1">Freigeben</v-btn>
          <v-btn v-if="report.state === 'submitted'" @click="rejectReport(report)" outlined small>Ablehnen</v-btn>
          <v-btn v-if="report.state === 'approved' || report.state === 'rejected'" @click="reset(report)" outlined small>Reset</v-btn>
          -->
          <v-btn icon class="ml-3" @click="exportReport(report)" v-if="report.state === 'approved'"><v-icon>mdi-download</v-icon></v-btn>
          <v-btn icon class="ml-3" @click="openReport(report)"><v-icon>mdi-file-document</v-icon></v-btn>
          <v-btn icon class="ml-3" @click="openReportDeleteConfirmDialog(report)"><v-icon color="red">mdi-file-document-remove</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-dialog v-model="confirmReportDeleteDialog">
    <v-card>
      <v-card-title><v-icon color="primary">mdi-delete</v-icon>Wirklich löschen</v-card-title>
      <v-card-text>
        Das Löschen des Tagesberichts kann nicht rückgängig gemacht werden. Bitte bestätigen:
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeReportDeleteConfirmDialog">Abbrechen</v-btn>
        <v-btn outlined color="primary" @click="deleteReport(reportSelectedToDelete)">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="reportDialog" fullscreen persistent>
    <v-card>
      <v-card-title>Report von {{ getUserNameByID(selectedReport.user) }}, {{selectedReport.date}} <v-chip :color="getStateColor(selectedReport.state)" small class="ml-2">{{ selectedReport.state | reportStatus }}</v-chip></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3" md="1" order="2" order-md="1"><strong>Start</strong></v-col>
          <v-col cols="3" md="1" order="3" order-md="2"><strong>Ende</strong></v-col>
          <v-col cols="12" md="8" order="1" order-md="3"><strong>Beschreibung</strong></v-col><!-- mobile resize removed -->
          <v-col cols="3" md="2" order="4" order-md="4"><strong>Zulagen</strong></v-col>
        </v-row>
        <v-row v-for="event in selectedReport.timetrackEvents" :key="event.id">
          <v-col cols="3" md="1" order="2" order-md="1">{{event.start | dateTimeToTime}}</v-col>
          <v-col cols="3" md="1" order="3" order-md="2">{{event.end | dateTimeToTime}}</v-col>
          <v-col cols="12" md="8" order="1" order-md="3">
            {{event.title}}<br>
            {{event.description}}
          </v-col>
          <v-col cols="12" md="12" order="4" order-md="4">
            <span>Leistende KSt. {{getCCLabel(event.supplying_costcenterid)}}, {{getCCName(event.supplying_costcenterid)}}</span>
            <span>Empf. KSt. {{getCCLabel(event.receiving_costcenterid)}}, {{getCCName(event.receiving_costcenterid)}}</span>
            <span v-for="bon in event.bonuses" :key="bon">{{bon}}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-none d-sm-flex">
        <v-btn @click="closeReportDialog" outlined>Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="selectedReport.state === 'rejected' || selectedReport.state === 'approved'" @click="reset(selectedReport)" outlined color="primary">Zurücksetzen</v-btn>
        <v-btn v-if="selectedReport.state === 'submitted'" @click="rejectReport(selectedReport)" outlined color="primary"><v-icon>mdi-file-document-remove-outline</v-icon> Ablehnen</v-btn>
        <v-btn v-if="selectedReport.state === 'submitted'" @click="approveReport(selectedReport)" outlined color="secondary"><v-icon>mdi-file-document-arrow-right-outline</v-icon> Freigeben</v-btn>
      </v-card-actions>
      <v-card-actions class="d-block d-sm-none">
        <v-row dense>
          <v-col cols="12" v-if="selectedReport.state === 'rejected' || selectedReport.state === 'approved'"><v-btn @click="reset(selectedReport)" outlined color="primary" block>Zurücksetzen</v-btn></v-col>
          <v-col cols="12" v-if="selectedReport.state === 'submitted'"><v-btn @click="approveReport(selectedReport)" outlined color="secondary" block><v-icon>mdi-file-document-arrow-right-outline</v-icon> Freigeben</v-btn></v-col>
          <v-col cols="12" v-if="selectedReport.state === 'submitted'"><v-btn @click="rejectReport(selectedReport)" outlined color="primary" block><v-icon>mdi-file-document-remove-outline</v-icon> Ablehnen</v-btn></v-col>
          <v-col cols="12"><v-btn @click="closeReportDialog" text block>Abbrechen</v-btn></v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import {mapState, mapActions} from "pinia";
import {piniaStore} from "@/store/pinia";
import ListAvatar from "@/components/ListAvatar.vue";

export default {
  name: "ReportManager",
  components: {ListAvatar},
  data: () => ({
    timeTrackingApi: null,

    baseUrl: process.env.VUE_APP_API_BASE_URL,

    //Dialogs
    menu: false,
    reportDialog: false,
    confirmReportDeleteDialog: false,

    stateList: [
      {
        state: "",
        label: "Alle"
      },
      {
        state: "created",
        label: "Erstellt"
      },
      {
        state: "submitted",
        label: "Eingereicht"
      },
      {
        state: "approved",
        label: "Akzeptiert"
      },
      {
        state: "rejected",
        label: "Abgelehnt"
      },
    ],

    filterState: "",
    filterDate: "",

    filterUser: null,

    filteredUsers: [],

    reports: [],
    testReport: null,
    savedReports: [],

    defaultReport: {
      user: null,
      date: "",
      state: "created"
    },
    selectedReport: null,

    reportSelectedToDelete: null,
  }),
  watch: {
    reports: {
      deep: true,
      handler() {
        console.log('The list of colours has changed!');
      },
    },
    filterDate: function (newVal, oldVal) {
      console.log("changed from " + oldVal + " to " + newVal)
      this.filterByDate();
    },
  },
  computed: {
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ["users"]),
    ...mapState(piniaStore, ["userMap"]),
  },
  methods: {
    getReports() {
      this.timeTrackingApi.getReports().then((res) => {
        console.log(res.data);
        this.reports = res.data;
        this.savedReports = Object.assign([], this.reports);
      }).catch((err)=>{
        console.log(err);
      });
    },
    openReport(report) {
      this.selectedReport = Object.assign({}, report);
      this.reportDialog = true;
    },
    closeReportDialog() {
      this.selectedReport = Object.assign({}, this.defaultReport);
      this.reportDialog = false;
    },
    openReportDeleteConfirmDialog(report) {
      this.reportSelectedToDelete = report;
      this.confirmReportDeleteDialog = true;
    },

    closeReportDeleteConfirmDialog() {
      this.reportSelectedToDelete = null;
      this.confirmReportDeleteDialog = false;
    },

    deleteReport(report) {
      this.timeTrackingApi.deleteReportByID(report.id).then(()=>{
        this.getReports();
        this.closeReportDeleteConfirmDialog();
      })
    },
    createReport() {
      this.timeTrackingApi.createReport(this.testReport).then((res) => {
        this.testReport = res.data;
      })
    },
    submitReport(report) {
      this.timeTrackingApi.submitReport(report).then((res) => {
        this.getReports();
        this.closeReportDialog();
      })
    },
    approveReport(report) {
      this.timeTrackingApi.approveReport(report).then((res) => {
        this.getReports();
        this.closeReportDialog();
      })
    },
    rejectReport(report) {
      this.timeTrackingApi.rejectReport(report).then((res) => {
        this.getReports();
        this.closeReportDialog();
      })
    },
    reset(report) {
      report.state = "submitted";
      report.reportState = "submitted";
      this.timeTrackingApi.updateReport(report).then((res) => {
        this.getReports();
        this.closeReportDialog();
      });
    },
    exportReport(report) {
      this.timeTrackingApi.exportReportByID(report.id)
          .then(res => {
            console.log(res)
            console.log(decodeURI(res.headers["x-suggested-filename"]))
            console.log(res.headers["content-disposition"])
            let objectUrl = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = objectUrl;
            a.download = decodeURI(res.headers["x-suggested-filename"]);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch(err => console.log(err));
    },
    getStateColor(state) {
      if(state === 'created') {
        return "grey";
      }
      if(state === 'submitted') {
        return "yellow";
      }
      if(state === 'approved') {
        return "green";
      }
      if(state === 'rejected') {
        return "red";
      }
    },
    getUserNameByID(id) {
      if(!id) {
        return "Kein User";
      }
      let user = this.userMap.get(id);
      console.log(user);
      if(user !== null) {
        return user.firstname + " " + user.lastname;
      } else {
        return "";
      }
    },
    getCCLabel(id) {
      if(id == null || id == "") {
        return "n.a.";
      }
      if(id) {
        let cc = this.costCenterMap.get(id);
        return cc.label;
      }
      return '';
    },
    getCCName(id) {
      if(id) {
        let cc = this.costCenterMap.get(id);
        return cc.name;
      }
      return '';
    },
    filterByState() {
      if(this.filterState !== "") {
        this.reports = this.savedReports.filter(r => r.state === this.filterState);
      } else {
        this.reports = Object.assign([], this.savedReports);
      }
    },
    filterByDate() {
      console.log("Filter By Date")
      console.log(this.filterDate)
      if(this.filterDate && this.filterDate !== "") {
        this.reports = this.savedReports.filter(r => r.date === this.filterDate);
      } else {
        this.reports = Object.assign([], this.savedReports);
      }
    },
    filterByUser() {
      if(this.filterUser && this.filterUser !== "") {
        this.reports = this.savedReports.filter(r => r.user === this.filterUser);
      } else {
        this.reports = Object.assign([], this.savedReports);
      }
    },
    preFilterUsers() {
      let mailFilter = "uweg.at"
      if (process.env.NODE_ENV === 'development') {
        mailFilter = "codefive.de"
      }
      this.filteredUsers = this.users
          .filter((user) => user.email.endsWith(mailFilter))
          .map(({id, firstname, lastname, avatar}) => ({
            id,
            name: `${firstname} ${lastname}`,
            firstname,
            lastname,
            avatar,
          }))
    },
    ...mapActions(piniaStore, ['getBonuses']),
    ...mapActions(piniaStore, ['getCostCenters']),
    ...mapActions(piniaStore, ["getUsers"]),
  },
  created() {
    this.getUsers();
    this.timeTrackingApi = new TimeTrackingApi();
    this.getReports();
    this.selectedReport = Object.assign({}, this.defaultReport);
    this.preFilterUsers();
  }
}
</script>

<style scoped>

</style>