import { render, staticRenderFns } from "./NFCReader.vue?vue&type=template&id=538cfb98&scoped=true&"
import script from "./NFCReader.vue?vue&type=script&lang=js&"
export * from "./NFCReader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538cfb98",
  null
  
)

export default component.exports