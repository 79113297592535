<template>
  <v-card>
    <v-card-text>
      <div>
        {{error}} <br>
        {{errorDetails}}
      </div>
      <div>
        Result {{version}}
        <pre>
          {{serialNumber}}
        </pre>
      </div>
      <div>
        Message
        <pre>
          {{message}}
        </pre>
      </div>
      <div v-for="(record, i) in records" :key="i">
        Record Type: {{record.recordType}} <br>
        Mime Type: {{record.mimeType}} <br>
        Tag Data: {{record.tagData}} <br>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="readTag">Tag lesen</v-btn>
      <v-btn outlined color="primary" @click="callRead">Read</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "NFCReader",
  data: () => ({
    records: [],
    error: "",
    errorDetails: "",
    serialNumber: "",
    message: null,
    ndef: null,
    version: "3",
  }),
  methods: {
    /* eslint-disable */
    async readTag() {
      if ("NDEFReader" in window) {
        this.ndef = new NDEFReader();
        this.ndef.addEventListener("reading", this.handleReading, {once: true});

        try {
          await this.ndef.scan();
          /* ndef.onreading = event => {
            this.records = [];
            const decoder = new TextDecoder();
            this.message = event.message;
            this.serialNumber = event.serialNumber;
            for (const record of event.message.records) {
              this.records.push({
                recordType: record.recordType,
                mimeType: record.mediaType,
                tagData: decoder.decode(record.data),
              });
            }
          } */
        } catch(error) {
          console.log(error);
          this.error = "Error reading NFC"
          this.errorDetails = error
        }
      } else {
        console.log("Web NFC is not supported.");
        this.error = "Web NFC is not supported."
      }
    },
    handleReading(event) {
      this.records = [];
      const decoder = new TextDecoder();
      this.message = event.message;
      this.serialNumber = event.serialNumber;
      for (const record of event.message.records) {
        this.records.push({
          recordType: record.recordType,
          mimeType: record.mediaType,
          tagData: decoder.decode(record.data),
        });
      }
      this.ndef.removeEventListener("reading", this.handleReading);
    },
    read() {
      if("NDEFReader" in window) {
        const ndef = new NDEFReader();
        return new Promise((resolve, reject) => {
          const ctlr = new AbortController();
          ctlr.signal.onabort = reject;
          ndef.addEventListener("reading", event => {
            ctlr.abort();
            resolve(event);
          }, {once: true});
          ndef.scan({signal: ctlr.signal}).catch(err => reject(err));
        });
      } else {
        console.log("Web NFC is not supported.");
        this.error = "Web NFC is not supported."
      }
    },
    callRead() {
      this.read().then(({serialNumber}) => {
        this.serialNumber = serialNumber
      }).catch((error) => {
        this.error = error
        this.errorDetails = error.message
      })
      /* this.read().then((result) => {
        const decoder = new TextDecoder();
        this.serialNumber = result.serialNumber;
        for (const record of result.message.records) {
          this.records.push({
            recordType: record.recordType,
            mimeType: record.mediaType,
            tagData: decoder.decode(record.data),
          });
        }
      }); */
    },
    /* eslint-enable */
  },
}
</script>

<style scoped>

</style>