<template>
  <v-dialog v-model="isOpen">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          outlined
      ><v-icon>mdi-play</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title>{{rTask.title}}</v-card-title>
      <v-card-text>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" text>Abbrechen</v-btn>
        <v-btn @click="updateTask" color="primary" outlined>Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RTimeTrackingDialog",
  props: ["rTask"],
  data: () => ({
    isOpen: false,
    rTimeTrackingEvents: [],
  }),
  methods: {
    closeDialog() {
      this.isOpen = false;
    },
  },
}
</script>

<style scoped>

</style>