<template>
  <v-container>
    <v-list>
      <v-subheader>Einstellungen</v-subheader>
      <v-list-item-group>
        <v-list-item to="/usermanager" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
          <v-list-item-content>
            <v-list-item-title>Benutzerverwaltung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="'/profile/' + keycloak.tokenParsed.firstName" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
          <v-list-item-content>
            <v-list-item-title>Profil bearbeiten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/teams" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
          <v-list-item-content>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/roles" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
          <v-list-item-content>
            <v-list-item-title>Rollen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/permissions" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
          <v-list-item-content>
            <v-list-item-title>Berechtigungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/costcenters" v-if="keycloak.tokenParsed.email == 'rene@codefive.de' || keycloak.tokenParsed.email == 'markus@codefive.de'">
          <v-list-item-content>
            <v-list-item-title>Kostenstellen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/bonuses" v-if="keycloak.tokenParsed.email == 'rene@codefive.de' || keycloak.tokenParsed.email == 'markus@codefive.de'">
          <v-list-item-content>
            <v-list-item-title>Zuschläge</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/change-password">
          <v-list-item-content>
            <v-list-item-title>Passwort ändern</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-card class="mt-5">
      <v-card-title>Info</v-card-title>
      <v-card-text>
        <v-text-field :label="$t('labels.firstname')" v-model="user.firstname" disabled></v-text-field>
        <v-text-field :label="$t('labels.lastname')" v-model="user.lastname" disabled></v-text-field>
        <v-text-field label="Rolle" v-model="user.role" disabled></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="reloadUser">{{ $t("labels.reload") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-title>Sprache | Language</v-card-title>
      <v-card-text>
        Aktuelle Sprache: <strong>{{$vuetify.lang.current}}</strong>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="switchToDe">Deutsch</v-btn>
        <v-btn outlined @click="switchToEn">English</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "Settings",
  computed: {
    ...mapState(piniaStore, ['payload']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  methods: {
    switchToDe() {
      this.$vuetify.lang.current = "de";
      this.$i18n.locale = "de";
    },
    switchToEn() {
      this.$vuetify.lang.current = "en";
      this.$i18n.locale = "en";
    },
    ...mapActions(piniaStore, ['reloadUser']),
  },
}
</script>