<template>
<v-container>
  <v-card>
    <v-card-title>Passwort ändern</v-card-title>
    <v-card-text>
      Sie werden beim Klicken auf Passwort ändern auf eine sichere Seite weitergeleitet.<br>
      Bitte melden Sie sich gegebenen falls dort erneut an um ein neues Passwort zu setzen.
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined x-large @click="redirectUpdatePassword">Passwort ändern</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
  <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
  >
    {{ snackbarMessage }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</v-container>
</template>

<script>
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
export default {
  name: "ChangePassword",
  data: () => ({
    password: '',
    confirmPassword: '',
    passwordRules: [
      (value) => !!value || 'Erforderlich',
      (value) => value.length > 7 || 'Mindestens 8 Zeichen',
    ],
    confirmPasswordRules: [
      (value) => !!value || 'Erforderlich',
      (value) => value.length > 7 || 'Mindestens 8 Zeichen',
      (value) => value === this.checkPassword() || 'Passwörter stimmen nicht überein',
    ],
    rules: {
      required: value => !!value || 'Erforderlich',
      counter: value => value.length <= 20 || 'Maximal 20 Zeichen',
      minLength: value => value.length > 7 || 'Mindestens 8 Zeichen',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
    valid: false,
    snackbarMessage: '',
    snackbar: false,
    snackbarTimeout: 2000,
    confirmChange: false,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token', 'users']),
  },
  methods: {
    redirectUpdatePassword() {
      console.log(process.env.VUE_APP_AUTH_URL)
      const successUrl = window.location.origin + "/change-password";
      const url = `${
          process.env.VUE_APP_AUTH_URL
      }/realms/Cairos/protocol/openid-connect/auth?client_id=cairos-frontend&redirect_uri=${encodeURIComponent(
          successUrl
      )}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;

      window.location.href = url;
    },
    changePassword() {
      let vm = this;
      if (this.password.trim() === '') {
        this.snackbarMessage = 'Passwort nicht gesetzt';
        this.snackbar = true;
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.snackbarMessage = 'Passwörter stimmen nicht überein';
        this.snackbar = true;
        return;
      } else {
        axios
            .put(this.baseUrl + '/api/v1/change-password', {password: this.password}, {headers: {"Authorization" : `Bearer ${this.token}`}})
            .then(() => {
              this.confirmChange = true;
            })
            .catch(() => {
              this.snackbarMessage = 'Passwörter konnte nicht geändert werden';
              this.snackbar = true;
            });
      }
    },
    /*testHttp() {
      let vm = this;
      this.$http.get('/api/v1/version').then(function(res) {
        vm.version = res.data;
      })
    },*/
  },
  mounted: function () {
    //this.testHttp();
  },
}
</script>

<style scoped>

</style>