import Vue from "vue";
import Vuex from "vuex";
import axios from "@/main";

Vue.use(Vuex);

const JSJoda = require("@js-joda/core");

function catchOffline(context, error) {
    console.log(error);
    if (error.response) {
        console.log("Request made and server responsed with error")
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        context.commit("setOffline", false);
    } else if (error.request) {
        // The request was made but no response was received
        console.log("Request made, but no response");
        console.log(error.request);
        context.commit("setOffline", true);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log("General error")
        console.log('Error', error.message);
        context.commit("setOffline", true);
    }
}

function parseJwt (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export default new Vuex.Store({
    state: {
        authorized: false,
        bonuses: [],
        costCenters: [],
        costCenterMap: new Map(),
        currentUser: {},
        incidents: [],
        notifications: [],
        offline: false,
        payload: null,
        roles: [],
        runningTask: null,
        runningTaskData: {
            laufzeit: "0 min",
            hour: 0,
            currentRuntime: "",
        },
        runningTaskId: null,
        runningTaskInterval: null,
        tasks: [],
        tasksCurrent: [
            {
                id: "0",
                title: "No Tasks fetched",
                description: "No tasks from backend retrieved yet",
            },
        ],
        teams: [],
        token: null,
        tokenInterval: null,
        users: [],
        userStatus: {},
    },
    getters: {
        currentRunningTask: (state) => {
            let i = state.tasks.current.findIndex(t => t.status === "running");
            return state.tasks[i];
        },
        currentRunningTaskId: (state) => {
            return state.runningTaskId;
        },
    },
    mutations: {
        runningTask(state, task) {
            if (state.runningTaskInterval !== null) {
                //if(state.runningTask.id !== task.id) {
                    clearInterval(state.runningTaskInterval);
                //}
            }
            state.runningTask = task;

            if (task !== null) {
                state.runningTaskId = task.id;

                state.runningTaskInterval = setInterval(function(){
                    let now = JSJoda.LocalDateTime.now();
                    let startDate;
                    if (state.runningTask.start === null) {
                        startDate = now;
                    } else {
                        startDate = JSJoda.LocalDateTime.parse(state.runningTask.start);
                    }
                    let duration;
                    if(state.runningTask.duration === null) {
                        duration = JSJoda.Duration.ZERO
                    } else {
                        duration = JSJoda.Duration.parse(state.runningTask.duration)
                    }
                    //console.log(JSJoda.Duration.between(startDate, now).plus(duration).seconds() + ' ' + JSJoda.Duration.between(startDate, now).plus(duration).seconds() % 60 + ' ' + JSJoda.Duration.between(startDate, now).plus(duration).seconds() / 60 + ' ' + JSJoda.Duration.between(startDate, now).plus(duration).seconds() / 1200)
                    let data = {
                        laufzeit: Math.floor(JSJoda.Duration.between(startDate, now).plus(duration).seconds() / 60) + ' min',
                        hour: ((JSJoda.Duration.between(startDate, now).plus(duration).seconds()%3600) / 3600 ) * 100,
                        currentRuntime: JSJoda.Duration.between(startDate, now).plus(duration).toString(),
                    };
                    state.runningTaskData = data;
                }, 1000);


            } else {
                state.runningTaskId = null;
                state.runningTaskData = {
                    laufzeit: "0 min",
                    hour: 0,
                    currentRuntime: "",
                };
            }

        },
        runningTaskData(state, data) {
            state.runningTaskData = data;
        },
        runningTaskInterval(state, handler) {
            state.runningTaskInterval = handler;
        },
        setCostCenters(state, costcenters) {
            state.costCenters = costcenters;
        },
        setCostCenterMap(state, costcenterMap) {
            state.costCenterMap = costcenterMap;
        },
        setCurrentUser(state, user) {
            state.currentUser = user;
        },
        setTasksCurrent(state, tasks) {
            /* let runningIndex = tasks.findIndex(t => t.status === "running");
            console.log("[Cairos] store state running task");

            if (runningIndex !== -1) {
                let running = tasks[runningIndex];
                state.runningTask = running;
                state.runningTaskId = running.id;
                console.log(runningIndex);
                console.log(running);
            } else {
                state.runningTask = null;
                state.runningTaskId = null;
            } */
            state.tasksCurrent = tasks;
            state.tasksCurrent = [...state.tasksCurrent];
        },
        addTaskCurrent(state, task) {
            const existsAtIndex = state.tasksCurrent.findIndex(t => t.id === task.id);

            if (existsAtIndex !== -1) {
                state.tasksCurrent[existsAtIndex] = task;
            } else {
                state.tasksCurrent.push(task);
            }

            state.tasksCurrent = [...state.tasksCurrent];
        },
        setIncidents(state, incidents) {
            state.incidents = incidents;
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        setUsers(state, users) {
            state.users = users;
        },
        markNotificationsAsRead(state) {
            state.notifications.forEach(n => n.read = true);
        },
        markNotificationAsRead(state, notification) {
            let index = state.notifications.findIndex(i => i.id === notification.id);
            state.notifications[index] = notification;
            //state.notifications[index].read = true;
        },
        setToken(state, token) {
            state.token = token;
        },
        setTokenInterval(state, tokenInterval) {
            state.tokenInterval = tokenInterval;
        },
        setPayload(state, payload) {
            state.payload = payload;
        },
        setAuthorized(state, authorized) {
            state.authorized = authorized;
        },
        setOffline(state, offline) {
            state.offline = offline;
        },
    },
    actions: {
        async apiLogin(context, creds){
            console.log(creds);
            let retVal = false;
            try {
                const res = await axios.post("http://localhost:50001/api/login", creds, {withCredentials: true })
                    .then((res) => {
                        context.commit("setToken", res.data);
                        context.commit("setPayload", parseJwt(res.data));
                        context.commit("setAuthorized", true);
                        context.commit("setOffline", false);
                        context.dispatch("getCurrentUser");
                        retVal = true;
                        return true;
                    })
                    .catch((err) => {
                        catchOffline(context, err);
                        retVal = false;
                        return false;
                    });
                return res;
            } catch (err) {
                console.log(err);
            }
        },
        apiLogout(context){
            axios.get("http://localhost:50001/api/logout", {withCredentials: true })
                .then(()=>{console.log("logged out axios"); context.commit("setOffline", false);})
                .catch((err) => {
                    catchOffline(context, err);
                });
            context.commit("setToken", null);
            context.commit("setPayload", null);
            context.commit("setAuthorized", false);
            console.log("logged out");
        },
        renewToken(context) {
            //console.log("Token: " + state.token);
            //axios.get("http://localhost:50001/api/refresh-token", { headers: {"Authorization" : `Bearer ${state.token}`}, withCredentials: true })
            axios.get("http://localhost:50001/api/refresh-token", { withCredentials: true })
                .then((res)=>{
                    console.log(res.data);
                    context.commit("setToken", res.data);
                    context.commit("setPayload", parseJwt(res.data));
                    context.commit("setAuthorized", true);
                    context.commit("setOffline", false);
                    console.log("Token renewed");
                }).catch((error) => {
                    console.log("Error in token renew");
                    console.log(error);
                    if (error.response) {
                        console.log("Request made and server responsed with error")
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        context.commit("setOffline", false);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("Request made, but no response");
                        console.log(error.request);
                        context.commit("setOffline", true);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("General error")
                        console.log('Error', error.message);
                        context.commit("setOffline", true);
                    }
                    if (error.response) {
                        context.dispatch("apiLogout");
                    }
                });
        },
        loadToken(context) {
            context.dispatch("renewToken");
            context.dispatch("checkToken");
        },
        checkToken({context, state}) {
            console.log("Check tokens")
            if (state.tokenInterval == null) {
                let ti = setInterval(() => {
                    if (state.payload !== null) {
                        if (state.payload.exp < new Date()/1000) {
                            console.log("EXPIRED TOKEN");
                            context.dispatch("apiLogout");
                        } else {
                            console.log("VALID TOKEN");
                            context.dispatch("renewToken");
                        }
                    }
                }, 30000);
                context.commit("setTokenInterval", ti);
            }
        },
        clearToken({context, state}) {
            clearInterval(state.tokenInterval);
        },
        catchOffline(context, error) {
            console.log(error);
            if (error.response) {
                console.log("Request made and server responsed with error")
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                context.commit("setOffline", false);
            } else if (error.request) {
                // The request was made but no response was received
                console.log("Request made, but no response");
                console.log(error.request);
                context.commit("setOffline", true);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("General error")
                console.log('Error', error.message);
                context.commit("setOffline", true);
            }
        },
        setAuthorized(context, authorized) {
            context.commit("setAuthorized", authorized);
        },
        getCurrentUser(context) {
            axios
                .get("http://localhost:50001/api/v1/currentuser")
                .then(function (response) {
                    context.commit("setCurrentUser", response.data);
                    window.localStorage.setItem("currentUser", JSON.stringify(response.data))
                    console.log('fetched current user');
                })
                .catch(function (error) {
                    console.log(error);
                    context.commit("setCurrentUser", {
                        firstname: "Max",
                        lastname: "Mustermann",
                        email: "max@codefive.de"
                    })
                });
        },
        getTasksCurrent(context) {
            axios
                .get('/api/v1/tasks/current')
                .then(function (response) {
                    response.data.sort((a, b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));
                    /*
                    if there is a running task in the response from server, but currently no running task registered
                    register the returned running task in the frontend as runningTask
                     */
                    let running = response.data.findIndex(t => t.status === "running");
                    if (running > -1) {
                        if(context.state.runningTask === null) {
                            context.commit("runningTask", response.data[running]);
                        }
                    }
                    context.commit("setTasksCurrent", response.data);
                    window.localStorage.setItem('cairos.tasksCurrent', JSON.stringify(response.data));
                    console.log('cairos.tasks updated');
                })
                .catch(function (error) {
                    console.log(error);
                    console.log('cairos.tasksCurrent not updated, maybe offline');
                });
        },
        addTasksCurrent(context, task) {
            context.commit("addTaskCurrent", task);
        },
        getIncidents(context) {
            axios.get("/api/v1/incidents")
                .then(function (response){
                    console.log("Store getIncidents")
                    console.log(response.data);
                    let incidents = response.data.filter(
                        i => i.deleted == null
                    );
                    incidents.sort((a, b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0)).reverse();
                    context.commit("setIncidents", incidents);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getNotifications(context) {
            axios.get('/api/v1/notifications/' + context.state.currentUser.id)
                .then(function (response){
                    let messages = response.data;
                    messages.sort((a,b) => (a.created > b.created) ? -1 : ((b.created > a.created) ? 1 : 0));
                    context.commit("setNotifications", messages);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        markNotificationsAsRead(context) {
            var vm = this;
            context.state.notifications.forEach(n => {
                if(!n.read) {
                    context.dispatch("markNotificationAsRead", n.id);
                }
            });
            context.dispatch("getNotifications");
        },
        markNotificationAsRead(context, id) {
            axios.patch('/api/v1/notifications/' + id)
                .then(function(response){
                    context.commit("markNotificationAsRead", response.data);
                })
                .catch(function(error){
                    console.log(error)
                })
        },
        getUsers(context) {
            axios.get('/api/v1/users')
                .then(function (response) {
                    console.log('[Cairos] Fetched users');
                    console.log(response.data);
                    let users = response.data.filter(e => e.deleted == null);
                    users.sort((a, b) => (a.lastname > b.lastname) ? 1 : ((b.lastname > a.lastname) ? -1 : 0));
                    context.commit("setUsers", users);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        startTask(context, task) {
            console.log('[Cairos] start task ' + task.id);

            if (context.state.runningTask !== null) {
                context.dispatch("stopTask", context.state.runningTask.id);
            }

            axios
                .post('/api/v1/tasks/start/' + task.id)
                .then(function (response) {
                    console.log("[Cairos] started Task " + task.id);
                    console.log(response.data);
                    //vm.info = "Starting task " + task.id;
                    /*vm.tasks = vm.tasks.map(c =>
                        c.id === task.id ? response.data : c
                    );*/
                    context.dispatch("getTasksCurrent");

                    let runningTask = response.data;

                    // fill costcenter property with rich data
                    if (runningTask.receiving_costcenterid !== null) {
                        let rcc = context.state.costCenters.findIndex(cc => cc.id === runningTask.receiving_costcenterid)
                        runningTask.receiving_costcenter = {
                            id: runningTask.receiving_costcenterid,
                            name: context.state.costCenters[rcc].name,
                            label: context.state.costCenters[rcc].label,
                        }
                    } else {
                        runningTask.receiving_costcenter = {
                            id: '',
                            name: '',
                            label: '',
                        }
                    }
                    if (runningTask.supplying_costcenterid !== null) {
                        let scc = context.state.costCenters.findIndex(cc => cc.id === runningTask.supplying_costcenterid)
                        runningTask.supplying_costcenter = {
                            id: runningTask.supplying_costcenterid,
                            name: context.state.costCenters[scc].name,
                            label: context.state.costCenters[scc].label,
                        }
                    } else {
                        runningTask.supplying_costcenter = {
                            id: '',
                            name: '',
                            label: '',
                        }
                    }
                    // end fill data

                    context.commit("runningTask", runningTask);
                    // update tabs tasks arrays
                    /*vm.tabs.forEach((t, i)=>{
                        var index = t.tasks.findIndex(ta => ta.id === task.id)
                        vm.tabs[i].tasks[index] = response.data;
                    });*/
                    //taskTimer(id);
                })
                .catch(function (error) {
                    console.log('[Cairos] error starting task ' + task.id);
                    console.log(error);
                })
        },
        stopTask(context, id) {
            console.log('[Cairos] stop task ' + id);

            axios.post('/api/v1/tasks/stop/' + id)
                .then(function (response) {
                    console.log("[Cairos] stopped task " + id);
                    console.log(response.data);
                    if(context.state.runningTask !== null) {
                        if (context.state.runningTask.id === id) {
                            context.commit("runningTask", null);
                            context.commit("runningTaskId", null);
                        }
                    }
                    context.dispatch("getTasksCurrent");
                })
                .catch(function (error) {
                    console.log('[Cairos] error stopping task ' + id);
                    console.log(error);
                })
        },
        finishTask(context, id) {
            console.log('[Cairos] finish task ' + id);


            axios.post('/api/v1/tasks/finish/' + id)
                .then(function (response) {
                    console.log("[Cairos] finished task " + id);
                    console.log(response.data);
                    context.dispatch("getTasksCurrent");
                    if(context.state.runningTask !== null) {
                        if (context.state.runningTask.id === id) {
                            context.commit("runningTask", null);
                            context.commit("runningTaskId", null);
                        }
                    }
                })
                .catch(function (error) {
                    console.log('[Cairos] error finishing task ' + id);
                    console.log(error);
                })
        },
        setRunningTaskData(context, data) {
            context.commit("runningTaskData", data);
        },
        setRunningTaskInterval(context, handler) {
            context.commit("runningTaskInterval", handler);
        },
        updateRunningTask(context, task) {
            context.commit("runningTask", task);
        },
        updateCostCenters(context) {
            axios.get('/api/v1/costcenters')
                .then(function (response){
                    console.log('costcenters fetched');
                    let costCenterMap = new Map();
                    let costCenters = response.data.filter(c => c.deleted == null);
                    costCenters.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
                    for (let index = 0; index < costCenters.length; ++index) {
                        const element = costCenters[index];
                        costCenterMap.set(element.id, element);
                        //console.log('X ' + element.name);
                    }
                    context.commit("setCostCenterMap", costCenterMap);
                    context.commit("setCostCenters", costCenters);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    modules: {},
});
