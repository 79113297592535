<template>
  <v-dialog v-model="incident_dialog" fullscreen persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-if="activator === 'icon'"
          icon
          x-large
          class="mx-4"
          v-bind="attrs"
          v-on="on"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
            >mdi-flash-alert-outline</v-icon>
          </template>
          <span>Meldung zum Task erstellen</span>
        </v-tooltip>
      </v-btn>
      <v-list-item
          v-else-if="activator === 'menuItem'"
          v-bind="attrs"
          v-on="on"
      >
        <v-list-item-title>Meldung erstellen</v-list-item-title>
      </v-list-item>
      <v-btn
          v-else
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          outlined
      >Meldung erstellen</v-btn>
    </template>
    <v-card>
      <v-card-title v-if="task !== null">Meldung zu {{task.title}}</v-card-title>
      <v-card-title v-else>Neue Meldung erstellen</v-card-title>
      <v-card-text>
        <v-form
            ref="incidentForm"
            v-model="validIncidentForm"
        >
          <v-text-field
              label="Titel"
              v-model="incident.title"
              :rules="[rules.required]"
              outlined
          ></v-text-field>
          <v-textarea
              label="Beschreibung"
              v-model="incident.description"
              :rules="[rules.required]"
              outlined
          ></v-textarea>
          <v-select
              label="Kategorie"
              v-model="incident.category"
              :items="incidentCategories"
              item-text="name"
              item-value="id"
              outlined
          ></v-select>
          <v-file-input
              accept="image/*"
              label="Datei hochladen"
              capture="camera"
              multiple
              v-model="files"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              outlined
          ></v-file-input><!-- @change="selectFile" -->
        </v-form>
      </v-card-text>
      <v-card-actions class="d-none d-sm-flex">
        <v-spacer></v-spacer>
        <v-btn @click="closeIncidentDialog()" text>Abbrechen</v-btn>
        <v-btn color="primary" outlined @click="saveIncident()" :disabled="!validIncidentForm">Speichern</v-btn>
      </v-card-actions>
      <v-card-actions class="d-block d-sm-none">
        <v-row dense>
          <v-col cols="12"><v-btn color="primary" outlined block @click="saveIncident()" :disabled="!validIncidentForm">Speichern</v-btn></v-col>
          <v-col cols="12"><v-btn @click="closeIncidentDialog()" text block>Abbrechen</v-btn></v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import IncidentApi from "@/apis/IncidentApi";
export default {
  name: "IncidentDialog",
  props: ['task', 'activator'],
  data: () => ({
    incidentApi: null,
    incident_dialog: false,
    validIncidentForm: false,
    incident: {
      title: '',
      description: '',
      category: null,
    },
    incidentCategories: [],
    currentFile: undefined,
    progress: 0,
    message: '',
    fileInfos: [],
    files: null,
    rules: {
      required: value => !!value || 'Erforderlich',
    },
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['token']),
    ...mapState(piniaStore, ['payload']),
  },
  methods: {
    closeIncidentDialog() {
      this.incident_dialog = false;
      this.resetIncidentDialog();
    },
    getIncidentCategories() {
      axios.get("/api/v1/incident-categories").then((res) => {
        this.incidentCategories = res.data;
      }).catch(()=>{
        this.setErrorMessage("Fehler beim Abruf der Kategorien")
      })
    },
    resetIncidentDialog() {
      this.incident.title = '';
      this.incident.description = '';
      this.$refs.incidentForm.resetValidation();
    },
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },
    upload() {
      if (!this.currentFile) {
        this.message = "Bitte Datei auswählen";
        return;
      }

      this.message = "";
      // original code???
      /*
      UploadService.upload(this.currentFile, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
          .then((response) => {
            this.message = response.data.message;
            return UploadService.getFiles();
          })
          .then((files) => {
            this.fileInfos = files.data;
          })
          .catch(() => {
            this.progress = 0;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });*/
    },
    saveIncident() {
      let taskID = null
      if (this.task !== null) {
        console.log('save incident to task with id ' + this.task.id + ', ' + this.task.title)
        taskID = this.task.id
      } else {
        console.log('save incident')
      }

      let vm = this;
      let formData = new FormData();
      //formData.append("task-id", taskID);
      //formData.append('filename', vm.currentFile); //check
      console.log(this.files);

      this.incidentApi.addIncident({
        taskId: taskID,
        title: this.incident.title,
        description: this.incident.description,
      })
          .then((response) => {
            console.log(response.data);
            let createdIncident = response.data;
            /* let incidentComp = vm.$root.$children[0].$children[0].$children.find(child => child.$options.name === 'v-main').$children.find(child => child.$options.name === 'incidents');
            incidentComp.getIncidents() */
            if (this.files !== null) {
              for (let file of vm.files) {
                formData.append("files", file, file.name);
              }
              axios.post("/api/v1/uploads", formData).then((res) => {
                console.log(res.data)
                this.files = null
                let uploadInformationIDs = []
                res.data.forEach(fileObject => {
                  uploadInformationIDs.push(fileObject.id)
                })
                this.incidentApi.addIncidentFile(createdIncident.id, uploadInformationIDs)
                    .then((response) => {
                      console.log(response);
                      console.log('File upload successful');
                    }).catch((error) => {
                      console.log(error);
                      this.currentFile = null;
                    });
              }).catch((err) => console.log(err))

            }
            //vm.$root.$children[0].getIncidents(function(){});
            this.getIncidents();
            this.closeIncidentDialog();
          })
          .catch((error) => {
            console.log(error);
            this.setErrorMessage("Fehler beim Anlegen der Meldung")
          });
    },
    ...mapActions(piniaStore, ["getIncidents"]),
    ...mapActions(piniaStore, ["setErrorMessage"]),
  },
  created() {
    this.getIncidentCategories();
    this.incidentApi = new IncidentApi();
    if(this.task !== null) {
      this.incident.title = this.task.title;
      this.incident.description = "Meldung zu " + this.task.title + ": ";
    }
  }
}
</script>