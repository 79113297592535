<template>
<v-list>
  <v-list-item v-if="running">
    <v-list-item-content>
      <v-list-item-title>{{runningTask.title}}</v-list-item-title>
      <v-list-item-subtitle>{{runningTask.start}}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon><v-icon>mdi-stop</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>
  <v-list-item v-else>
    <v-list-item-content>
      <v-list-item-title><v-text-field label="Neue Aufgabe" v-model="task.title"></v-text-field></v-list-item-title>
      <v-list-item-subtitle>
        <v-autocomplete
            label="Empf. Kostenstelle"
        ></v-autocomplete>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon><v-icon>mdi-play</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>
</v-list>
</template>

<script>
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "TaskBar",
  data: () => ({
    checkInterval: null,
    running: false,
    task: {
      id: "1234556445",
      title: "Just a task",
    },
  }),
  watch: {
    runningTaskId: function (newRunning, oldRunning) {
      if (newRunning !== null) {
        console.log("running task id is now " + newRunning)
      } else {
        console.log("running task id is now null")
      }
    },
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['userMap']),
    ...mapState(piniaStore, ['offline']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['runningTask']),
    ...mapState(piniaStore, ['runningTaskId']),
  },
  created() {
    this.checkInterval = setInterval(()=>{
      console.log("Running: " + this.running)
      if(this.runningTaskId !== null) {
        this.running = true;
        console.log(this.runningTask.title)
      } else {
        this.running = false;
      }
    }, 1000);
  }
}
</script>

<style scoped>

</style>