<template>
<v-container>
  <v-card>
    <v-card-title>Pinia</v-card-title>
    <v-card-text>{{authorized}}</v-card-text>
    <v-card-actions>
      <v-btn @click="testPinia">Test</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "Pinia",
  data: () => ({
    currentUser: {
      avatar: "/assets/cairos_s.png",
      firstname: "René",
      lastname: "Sanchez",
    },
    drawer: false,
    closedDrawer: false,
  }),
  computed: {
    ...mapState(piniaStore, ["authorized"]),
  },
  methods: {
    testPinia() {
      this.testP();
    },
    ...mapActions(piniaStore, ["testP"]),
  },
}
</script>

<style scoped>

</style>