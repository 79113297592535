<template>
  <v-container>
    <v-tabs
        v-model="currentTab"
        align-with-title
        color="secondary"
    >
      <v-tabs-slider color="secondary"></v-tabs-slider>

      <v-tab
          v-for="tab in tabs"
          :key="tab.name"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item
          v-for="tab in tabs"
          :key="tab.name"
      >
        <v-row v-if="tab.name === 'Alle'">
          <v-col>
            <v-card>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Meldungen</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                  :headers="headers"
                  :items="incidents"
                  item-key="id"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="openIncident(item)"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                  <v-icon
                      small
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteIncident(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="tab.name === 'Offene'">
          <v-col>
            <v-card>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Offene Meldungen</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                  :headers="headers"
                  :items="incidents.filter(i => i.status === 'open')"
                  item-key="id"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="openIncident(item)"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                  <v-icon
                      small
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteIncident(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="tab.name === 'Eigene'">
          <v-col>
            <v-card>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Eigene Meldungen</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                  :headers="headers"
                  :items="incidents.filter(i => i.assignedUser !== null && i.assignedUser.id === user.id)"
                  item-key="id"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="openIncident(item)"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                  <v-icon
                      small
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteIncident(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="tab.name === 'Erstellte'">
          <v-col>
            <v-card>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Erstellte Meldungen</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                  :headers="headers"
                  :items="incidents.filter(i => i.user === user.id)"
                  item-key="id"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="openIncident(item)"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                  <v-icon
                      small
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteIncident(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="tab.name === 'Nicht zugewiesene'">
          <v-col>
            <v-card>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Nicht zugewiesene Meldungen</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                  :headers="headers"
                  :items="incidents.filter(i => i.assignedTeam === null && i.assignedUser === null)"
                  item-key="id"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="openIncident(item)"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                  <v-icon
                      small
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteIncident(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="tab.name === 'Erledigte'">
          <v-col>
            <v-card>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Erledigte Meldungen</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                  :headers="headers"
                  :items="incidents.filter(i => i.status === 'closed')"
                  item-key="id"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="openIncident(item)"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                  <v-icon
                      small
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteIncident(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <IncidentDialog v-bind:task="null"></IncidentDialog>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import axios from "@/main";
import IncidentDialog from "../components/IncidentDialog";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import IncidentApi from "@/apis/IncidentApi";

export default {
  name: "Incidents",
  components: {
    IncidentDialog,
  },
  data: () => ({
    incidentApi: null,
    //incidents: [],
    tabs: [
      {
        name: 'Alle',
      },
      {
        name: 'Offene',
      },
      {
        name: 'Eigene',
      },
      {
        name: 'Erstellte',
      },
      {
        name: 'Nicht zugewiesene',
      },
      {
        name: 'Erledigte',
      },
    ],
    currentTab: {
      name: 'Alle',
    },
    headers: [
      {
        text: 'Titel',
        value: 'title',
      },
      {
        text: 'Beschreibung',
        value: 'description',
      },
      {
        text: 'Team',
        value: 'assignedTeam.name',
      },
      {
        text: 'Benutzer',
        value: 'assignedUser.lastname',
      },
      {
        text: 'Ersteller',
        value: 'user.lastname',
      },
      /*{
          text: 'Erstellt',
          value: 'created',
      },*/
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['incidents']),
    ...mapState(piniaStore, ['token']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['keycloak']),
  },
  methods: {
    openIncident(incident) {
      this.$router.push('/incidents/' + incident.id)
    },
    deleteIncident(incident) {
      this.incidentApi.deleteIncident(incident.id).then((response) => {
            console.log(response.data);
            this.getIncidents();
          })
          .catch((error) => {
            console.log(error);
            this.setErrorMessage("Fehler beim Löschen der Meldung")
          });
    },
    ...mapActions(piniaStore, ["getIncidents"]),
    ...mapActions(piniaStore, ["setErrorMessage"]),
    ...mapActions(piniaStore, ["setSuccessMessage"]),
  },
  created() {
    this.getIncidents();
    this.incidentApi = new IncidentApi();
  }
};
</script>