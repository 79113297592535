<template>
  <v-row>
    <v-col cols="12">
      <v-text-field label="Title" v-model="rTask.title"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field label="Details" v-model="rTask.details"></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field label="Category" v-model="rTask.category"></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-autocomplete label="State" v-model="rTask.state" :items="states" clearable></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-text-field label="Client" v-model="rTask.client"></v-text-field>
    </v-col>
    <v-col>
      <v-text-field label="Project" v-model="rTask.project"></v-text-field>
    </v-col>
    <v-col>
      <v-autocomplete label="Cost Center" v-model="rTask.costCenter" :items="costCenters" clearable></v-autocomplete>
    </v-col>
    <v-col>
      <v-autocomplete label="Working Package" v-model="rTask.workPackage" :items="workPackages" clearable></v-autocomplete>
    </v-col>
    <v-col>
      <v-text-field label="Duration" v-model="rTask.duration"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RTaskFields",
  props: ["rTask"],
  data: () => ({
    costCenters: [
      "300 IT Allgemein",
      "301 IT Landingpage",
      "302 IT Website",
      "303 Lead Generation",
      "304 Digitales AWK",
      "305 Dokumentenmanagement",
      "306 Entsorger-Matchmaking",
      "307 Abfallanalyse-Service",
      "308 Wastemanagement",
      "310 IT Organisation",
      "311 IT Meeting",
      "311 IT Product Owner",
      "311 IT Scrum Master",
      "400 Zentrale Verwaltung",
      "401 Geschäftsführung",
      "402 HR",
      "403 Legal",
      "408 Produktmanagement",
      "409 Business Development",
      "410 Organisationsentwicklung",
    ],
    states: [
        "open",
        "in progress",
        "done",
    ],
    workPackages: [
      "A1 Projektmanagement",
      "A2 Productmanagement & Business Development",
      "A3 Marketing & Vertrieb",
      "A4 Plattform Basis - Front- & Backend anpassen",
      "A5 Digitales AWK Prototype - Front- & Backend",
      "A6 Digitales AWK - Testing & further Development",
      "A7 Matchmaking-Tool V2 Prototype - Front- & Backend",
      "A8 Matchmaking-Tool V2 - Testing & further Development",
      "A9 Waste & Resource Management V1 Prototype - Front- & Backend",
      "A10 Waste & Resource Management V1 - Testing & further Development",
      "A11 Waste & Resource Management V2 - Front- & Backend",
      "A12 Waste & Resource Management V2 - Testing & further Development",
    ],
  }),
}
</script>

<style scoped>

</style>