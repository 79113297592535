<template>
  <v-container>
    <v-card>
      <v-card-title>Feature Test</v-card-title>
      <v-card-text>
        <v-switch
          label="Nur eigene Aufgaben"
          v-model="assignedToMe"
          @change="switchChange"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="addElement(planned)">Hinzufügen</v-btn>
      </v-card-actions>
    </v-card>
    <v-row class="mt-5">
      <v-col cols="4">
        <v-card>
          <v-card-title>Geplant</v-card-title>
          <v-card-text>
            <draggable v-model="planned" group="kanban" class="drag-zone" @change="setPlanned">
              <v-card v-for="item in planned" :key="item.id" elevation="1" class="my-1 mb-2 drag-item" color="#f5f5f5">
                <v-card-text><span style="font-size: 11pt; color: #000000;">{{item.title}}</span></v-card-text>
                <v-card-actions>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="30" color="indigo" v-bind="attrs" v-on="on">
                        <v-img :src="getAvatar(item.user)"></v-img>
                      </v-avatar>
                    </template>
                    <span>
                      {{userMap.get(item.user).firstname.substr(0,1)}}{{userMap.get(item.user).lastname.substr(0,1)}}
                    </span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="openTaskDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </draggable>
            <v-container class="d-inline-flex flex-row justify-space-between">
              <v-text-field v-model="element.title" label="Neuer Eintrag" @keydown.enter.prevent="addSimpleTask('created')"></v-text-field>
              <v-icon @click="addSimpleTask('created')">mdi-plus</v-icon>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>In Bearbeitung</v-card-title>
          <v-card-text>
            <draggable v-model="inProgress" group="kanban" class="drag-zone" @change="setInProgress" >
              <v-card v-for="item in inProgress" :key="item.id" elevation="1" class="my-1 mb-2 drag-item" color="#f5f5f5">
                <v-card-text><span style="font-size: 11pt; color: #000000;">{{item.title}}</span></v-card-text>
                <v-card-actions>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="30" color="indigo" v-bind="attrs" v-on="on">
                        <v-img :src="getAvatar(item.user)"></v-img>
                      </v-avatar>
                    </template>
                    <span>
                      {{userMap.get(item.user).firstname.substr(0,1)}}{{userMap.get(item.user).lastname.substr(0,1)}}
                    </span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="openTaskDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </draggable>
            <v-container class="d-inline-flex flex-row justify-space-between">
              <v-text-field v-model="element.title" label="Neuer Eintrag" @keydown.enter.prevent="addSimpleTask('in progress')"></v-text-field>
              <v-icon @click="addSimpleTask('in progress')">mdi-plus</v-icon>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>Abgeschlossen</v-card-title>
          <v-card-text>
            <draggable v-model="done" group="kanban" class="drag-zone" @change="setFinished">
              <v-card v-for="item in done" :key="item.id" elevation="1" class="my-1 mb-2 drag-item" color="#f5f5f5">
                <v-card-text><span style="font-size: 11pt; color: #000000;">{{item.title}}</span></v-card-text>
                <v-card-actions>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="30" color="indigo" v-bind="attrs" v-on="on">
                        <v-img :src="getAvatar(item.user)"></v-img>
                      </v-avatar>
                    </template>
                    <span>
                      {{userMap.get(item.user).firstname.substr(0,1)}}{{userMap.get(item.user).lastname.substr(0,1)}}
                    </span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="openTaskDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </draggable>
            <v-container class="d-inline-flex flex-row justify-space-between">
              <v-text-field v-model="element.title" label="Neuer Eintrag" @keydown.enter.prevent="addSimpleTask('done')"></v-text-field>
              <v-icon @click="addSimpleTask('done')">mdi-plus</v-icon>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="simpleTaskDialog"
        fullscreen
    >
      <v-card>
        <v-card-title>Aufgabe bearbeiten</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedTask.title"
                    label="Titel"
                    :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedTask.body"
                    label="Beschreibung"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedTask.date"
                    label="Datum"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-combobox
                    v-model="editedTask.state"
                    solo
                    :items="states"
                    label="Status"
                ></v-combobox>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedTask.start"
                    :rules="[rules.timeFormat]"
                    label="Start"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedTask.end"
                    :rules="[rules.timeFormat]"
                    label="Ende"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-combobox
                    v-model="editedTask.project"
                    solo
                    :items="projects"
                    label="Projekt"
                ></v-combobox>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-select
                    v-model="editedTask.user"
                    label="Benutzer"
                    :items="users"
                    item-text="lastname"
                    item-value="id"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeTaskDialog">Abbrechen</v-btn>
          <v-btn @click="saveTask">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
.drag-zone {
  min-height: 5rem;
}
.drag-item {
  border-radius: 5px;
}
</style>
<script>
import draggable from 'vuedraggable'
//import ToolDialog from "@/components/ToolDialog";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import axios from "axios";
import * as JSJoda from "@js-joda/core";
export default {
  name: "ToolTest",
  components: {draggable},
  data: () => ({
    assignedToMe: false,
    element: {
      title: '',
      date: JSJoda.LocalDate.now(),
    },
    planned: [],
    inProgress: [],
    done: [],
    deleted: [],
    simpleTasks: [],
    simpleTaskDialog: false,
    editedTask: {},
    defaultTask: {
      title: '',
      body: '',
      start: '00:00',
      end: '00:00',
      state: 'created',
      date: JSJoda.LocalDate.now(),
      project: '',
    },
    projects: ["Wastics", "Code Five", "Cairos", "Kundenportal"],
    states: ["created", "in progress", "done"],
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      counter: value => value.length <= 20 || 'Max 20 Zeichen',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Ungültige E-Mail.';
      },
      timeFormat: value => {
        const pattern = /^([0-9]){2}:([0-9]){2}$/;
        const empty = /^$/;
        return pattern.test(value) || empty.test(value) || 'Ungültiges Zeitformat';
      },
    },
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token', 'users', 'userMap', 'keycloak', 'user']),
  },
  methods: {
    addElement(list) {
      if (this.element.title == '') {
        return;
      }
      this.element.id = this.uid();
      list.push(this.element);
      this.element = {
        id: null,
        title: '',
        description: '',
      };
    },
    addSimpleTask(state) {
      if (this.element.title == '') {
        return;
      }
      this.element.user = this.payload.user.id;
      this.element.state = state;
      axios.post(this.baseUrl + '/api/v1/simpletasks/', this.element,{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.getSimpleTasks();
            this.element = {
              title: '',
              date: JSJoda.LocalDate.now(),
            };
          })
          .catch((err) => {
          });
    },
    removeElement(list, item) {
      let index = list.findIndex(i => i.id === item.id);
      this.$delete(list, index);
      /* console.log(list);
      console.log(item);
      list = list.filter(i => i.id !== item.id);
      console.log(list);
      this.deleted.push(item); */
    },
    uid() {
      return Date.now().toString(36) + '-' + Math.random().toString(36).substr(2);
    },
    setPlanned(event) {
      if(event.added) {
        event.added.element.state = 'created';
        this.updateSimpleTask(event.added.element);
      }
    },
    setInProgress(event) {
      if(event.added) {
        event.added.element.state = 'in progress';
        this.updateSimpleTask(event.added.element);
      }
    },
    setFinished(event) {
      if(event.added) {
        event.added.element.state = 'done';
        this.updateSimpleTask(event.added.element);
      }
    },
    getSimpleTasks() {
      axios.get(this.baseUrl + '/api/v1/simpletasks/',{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            if(this.assignedToMe) {
              this.simpleTasks = res.data.filter(e => e.user === this.payload.user.id);
            } else {
              this.simpleTasks = res.data;
            }

            this.planned = this.simpleTasks.filter(e => e.state === 'created' || e.state === 'planned');
            this.inProgress =this.simpleTasks.filter(e => e.state.toLowerCase() === 'in progress');
            this.done = this.simpleTasks.filter(e => e.state === 'done');
          })
          .catch(() => {
          });
    },
    updateSimpleTask(task) {
      axios.put(this.baseUrl + '/api/v1/simpletasks/', task,{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.getSimpleTasks();
          })
          .catch((err) => {
          });
    },
    switchChange() {
      this.getSimpleTasks();
    },
    getAvatar(id) {
      let user = this.userMap.get(id);
      return this.baseUrl + user.avatar;
    },
    openTaskDialog(task) {
      this.simpleTaskDialog = true;
      if(task) {
        this.editedTask = Object.assign({}, task);
      } else {
        this.editedTask = Object.assign({}, this.defaultTask);
      }
    },
    closeTaskDialog() {
      this.simpleTaskDialog = false;
      this.editedTask = Object.assign({}, this.defaultTask);
    },
    saveTask() {
      this.updateSimpleTask(this.editedTask);
      this.closeTaskDialog();
    },
    ...mapActions(piniaStore, ["getUsers"]),
  },
  created () {
    this.getSimpleTasks();
    this.getUsers();
    console.log(this.users);
  },
};
</script>