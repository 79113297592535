<v-dialog
    fullscreen
    persistent
    v-model="isOpen"
>
<template v-slot:activator="{ on, attrs }">
  <v-icon
      v-if="!emptyBonus"
      small
      class="mr-2"
      v-bind="attrs"
      v-on="on"
  >
    mdi-pencil
  </v-icon>
  <v-btn
      v-else
      fab
      dark
      small
      v-bind="attrs"
      v-on="on"
      class="mt-3 mb-5"
      color="#f71784"
  >
    <v-icon>
      mdi-plus
    </v-icon>
  </v-btn>
</template>
<v-card>
  <v-card-title>Zuschlag</v-card-title>
  <v-card-text>
    <v-text-field
        label="Name"
        v-model="name"
    ></v-text-field>
    <v-text-field
        label="Kurzbezeichnung"
        v-model="label"
    ></v-text-field>
    <v-text-field
        label="Beschreibung"
        v-model="description"
    ></v-text-field>
  </v-card-text>
  <v-card-actions>
    <v-btn @click="saveBonus">Speichern</v-btn>
    <v-btn @click="closeDialog">Abbrechen</v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
<script>
import axios from "@/main";
export default {
  name: "BonusDialog",
  props: {
    bonus: {
      type: Object,
      default: function () {
        return {
          name: '',
          label: '',
          description: '',
        }
      },
    },
    emptyBonus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEditing: null,
    isOpen: false,
    name: '',
    label: '',
    description: '',
  }),
  methods: {
    closeDialog() {
      this.isOpen = false
    },
    saveBonus() {
      let vm = this;
      let data;
      if (this.bonus.name === '') {
        data = {
          name: this.name,
          label: this.label,
          description: this.description,
        }
      } else {
        data = {
          id: this.bonus.id,
          name: this.name,
          label: this.label,
          description: this.description,
        }
      }
      axios.post('/api/v1/bonuses', data)
          .then(function(response){
            console.log(response.data)
            console.log(vm.$parent.$options.name)
            console.log(vm.$parent.$parent.$options.name)
            console.log(vm.$parent.$parent.$parent.$options.name)
            if (vm.bonus.name === '') {
              vm.$parent.bonuses.push(response.data)
            } else {
              vm.$parent.$parent.$parent.bonuses = vm.$parent.$parent.$parent.bonuses.filter(b => b.id !== vm.bonus.id)
              vm.$parent.$parent.$parent.bonuses.push(response.data)
            }
            vm.closeDialog()
          })
          .catch(function(error){})
    },
  },
  mounted: function () {
    if(this.bonus.name !== '') {
      this.name = this.bonus.name
      this.label = this.bonus.label
      this.description = this.bonus.description
    }
  },
}
</script>