<template>
  <v-container>
    <v-sheet v-if="incidentData == null">Daten werden geladen</v-sheet>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <span v-if="!editContent">{{incidentData.title}}</span>
              <v-text-field v-else label="Titel" v-model="incidentData.title"></v-text-field>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon small @click="editContentMode()" :disabled="!(incidentData.status == 'open' || incidentData.status == 'in_progress')"><v-icon>mdi-pencil</v-icon></v-btn>
          </v-toolbar>
          <v-card-subtitle>Meldung {{incidentData.shortId}}</v-card-subtitle>
          <v-card-text>
            <span v-if="!editContent">{{incidentData.description}}</span>
            <v-textarea v-else label="Beschreibung" v-model="incidentData.description"></v-textarea>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col>
                Erstellt von
                <v-chip>
                  <v-avatar left>
                    <v-img :src="baseUrl + incidentData.user.avatar"></v-img>
                  </v-avatar>
                  {{ incidentData.user.lastname }}, {{ incidentData.user.firstname }}
                </v-chip>
              </v-col>
              <v-col>
                <IncidentStatusChip :status="incidentData.status" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="incidentData.taskId !== null">Task: {{incidentData.taskId}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-actions>
            <v-btn outlined><v-icon>mdi-account</v-icon>&nbsp;Zuweisen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Anhänge</v-card-title>
          <v-card-text>
            <v-row v-for="attachement in incidentAttachments" :key="attachement.id">
              <v-col cols="6" md="3">
                <a :href="baseUrl + '/' + attachement.path">
                  <v-img :src="baseUrl + '/' + attachement.thumbnail" max-height="150px" max-width="150px"></v-img>
                </a>
              </v-col>
              <v-col cols="6" md="9">
                <span>{{ attachement.name }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Zuständigkeit</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon small @click="editMode()"><v-icon>mdi-account-switch</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-select
                :disabled="!editAssignments"
                label="Zuständiges Team"
                v-model="incidentData.assignedTeam"
                :items="teams"
                item-value="id"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeAssignedTeam(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="baseUrl + data.item.avatar"></v-img>
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <img :src="baseUrl + data.item.avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
            <v-select
                :disabled="!editAssignments"
                label="Zuständiger Bearbeiter"
                v-model="incidentData.assignedUser"
                :items="users"
                item-value="id"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeAssignedUser(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="baseUrl + data.item.avatar"></v-img>
                  </v-avatar>
                  {{ data.item.lastname }}, {{ data.item.firstname }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <img :src="baseUrl + data.item.avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.lastname"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.firstname"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <v-card-actions v-if="editAssignments">
              <v-btn @click="assign">Zuweisen</v-btn> <v-btn @click="abort">Abbrechen</v-btn>
            </v-card-actions>
            <v-select
                label="Status"
                :items="status"
                item-value="value"
                v-model="incidentData.status"
                @change="statusChange"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-icon class="mr-3">mdi-ticket</v-icon>
            <v-toolbar-title>Aktionen</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="incidentData.status === 'open'">Bearbeitung starten</v-card-text>
          <v-card-text v-if="incidentData.status === 'in_progress'">Lösung eintragen</v-card-text>
          <v-card-text v-if="incidentData.status === 'solved'">Lösung akzeptieren oder ablehnen</v-card-text>
          <v-card-text v-if="incidentData.status === 'rejected'">Bearbeitung erneut starten</v-card-text>
          <v-card-text v-if="incidentData.status === 'accepted'">Ticket schließen</v-card-text>
          <v-card-text v-if="incidentData.status === 'closed'">Ticket ist geschlossen und kann nicht weiter bearbeitet werden.</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-icon class="mr-3">mdi-timeline-text</v-icon>
            <v-toolbar-title>Verlauf</v-toolbar-title>
          </v-toolbar>
          <v-timeline dense>
            <v-timeline-item
                v-for="protocol in incidentProtocol"
                :key="protocol.id"
                small
                fill-dot
                color="#49fcd4">
              <span v-if="protocol.action === 'assignment'">{{protocol.user.firstname}} {{protocol.user.lastname}} hat die Meldung an Team {{protocol.assignedTeam.name}} und Benutzer {{protocol.assignedUser.firstname}} {{protocol.assignedUser.lastname}} zugewiesen ({{protocol.created | dateFormatSimple}})</span>
              <span v-else-if="protocol.action === 'statusChange'">{{protocol.user.firstname}} {{protocol.user.lastname}} hat den Status von {{protocol.previousStatus | incidentStatus}} auf {{protocol.status | incidentStatus}} gesetzt ({{protocol.created | dateFormatSimple}})</span>
              <span v-else>{{protocol.user.firstname}} {{protocol.user.lastname}} hat Aktion {{protocol.action}} durchgeführt ({{protocol.created | dateFormatSimple}})</span>
            </v-timeline-item>
            <v-timeline-item small fill-dot color="#49fcd4">Erstellt von {{incidentData.user.firstname}} {{incidentData.user.lastname}}, {{incidentData.created | dateFormat}}</v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <div class="d-flex align-center">
              <v-avatar left  size="30">
                <v-img :src="user.avatar"></v-img>
              </v-avatar>
              <v-text-field v-model="commentText" class="ml-3" label="Kommentieren" append-icon="mdi-send" @click:append="addComment(null)" @keyup.enter="addComment(null)"></v-text-field>
            </div>
            <v-sheet v-for="(comment, index) in incidentData.comments" :key="comment.id" class="mb-3">
              <div>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar left  size="28" v-bind="attrs"
                              v-on="on">
                      <v-img :src="comment.user.avatar"></v-img>
                    </v-avatar>
                  </template>
                  <span>{{ comment.user.firstname }} {{ comment.user.lastname }}</span>
                </v-tooltip>
                <span v-if="comment.deleted == null">{{comment.text}} <v-icon small class="ml-3" v-if="comment.user.id == user.id" @click="loggi(comment)">mdi-delete</v-icon></span>
                <span v-else class="font-italic font-weight-light">Kommentar gelöscht</span>
              </div>
              <v-sheet v-for="(reply, idx) in comment.replies" :key="reply.id" class="pl-10 mt-3 mb-3">
                <div>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar left  size="28" v-bind="attrs"
                                v-on="on">
                        <v-img :src="reply.user.avatar"></v-img>
                      </v-avatar>
                    </template>
                    <span>{{ reply.user.firstname }} {{ reply.user.lastname }}</span>
                  </v-tooltip>
                  <span v-if="reply.deleted == null">{{reply.text}} <v-icon v-if="reply.user.id == user.id" class="ml-3" small @click="loggi(reply)">mdi-delete</v-icon></span>
                  <span v-else class="font-italic font-weight-light">Kommentar gelöscht</span>
                </div>
              </v-sheet>
              <v-sheet class="pl-10" >
                <div class="d-flex align-center">
                  <v-avatar left  size="28">
                    <v-img :src="user.avatar"></v-img>
                  </v-avatar>
                  <v-text-field class="ml-3" v-model="commentText" label="Kommentieren" append-icon="mdi-send" @click:append="addComment(comment.id)" @keyup.enter="addComment(comment.id)"></v-text-field>
                </div>
              </v-sheet>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import IncidentApi from "@/apis/IncidentApi";
import IncidentStatusChip from "@/components/IncidentStatusChip.vue";
export default {
  name: "IncidentSingle",
  components: {IncidentStatusChip},
  props: ['incident'],
  data: () => ({
    incidentApi: null,
    editContent: false,
    editAssignments: false,
    commentText: '',
    incidentProtocol: [
      {
        id: '1',
        created: '2020-12-08T12:23:33',
        incident: 'id von incident',
        user: 'userID',
        log: '',
        action: 'zugewiesen an',
        previousAssignedTeam: null,
        previousAssignedUser: null,
        assignedTeam: 'Code Five',
        assignedUser: 'René Heinrich',
      }
    ],
    status: [
      /*{
          text: 'Erstellt',
          value: 'created',
      },
      {
          text: 'Entwurf',
          value: 'draft',
      },*/
      {
        text: 'Offen',
        value: 'open',
      },
      {
        text: 'In Bearbeitung',
        value: 'in_progress',
      },
      {
        text: 'Gelöst',
        value: 'solved',
      },
      {
        text: 'Lösung abgelehnt',
        value: 'rejected',
      },
      {
        text: 'Lösung akzeptiert',
        value: 'accepted',
      },
      {
        text: 'Geschlossen',
        value: 'closed',
      },
    ],
    nextStatus: [],
    incidentAttachments: [],
    currentIncident: {},
    incidentData: null,
    incidentData2: {
      id: '1234',
      shortID: 'CF423',
      status: 'open',
      title: 'Task verschwindet bei Klick auf Stop',
      description: 'Der aktive Task verschwindet beim klicken auf den Stop Button. Lässt sich reproduzieren.',
      user: '0513',
      assignedUser: '0513',
      assignedTeam:  '1',
      assignedRole: null,
      comments: [
        {
          text: 'Kann das einer von euch näher untersuchen?',
          replies: [
            {
              text: 'Ja klar, mache ich sofort',
              replies: [],
              created: '2020-11-25T11:25:07',
              createdBy: '0514',
            },
            {
              text: 'Ich glaube ich weiß schon woran das liegt ;-)',
              replies: [
                {
                  text: 'Who should do this?',
                  replies: [],
                  created: '2020-11-25T11:17:12',
                  createdBy: {
                    avatar: '/images/avatars/default.png',
                  },
                },
              ],
              created: '2020-11-25T11:15:22',
              createdBy: '0513',
            }
          ],
          created: '2020-11-25T11:09:30',
          createdBy: '0514',
        }
      ],
    }
  }),
  methods: {
    addComment(id) {
      let now = new Date();
      console.log('comment clicked')
      let data = {
        incident: this.incidentData.id,
        text: this.commentText,
        replies: [],
        replyTo: id,
        created: now.toISOString(),
      };
      this.incidentApi.addIncidentComment(this.incidentData.id, data).then((response) => {
            let retData = response.data;
            retData.user = this.getUser(retData.user);
            if(id == null) {
              this.incidentData.comments.unshift(retData);
            } else {
              let index = this.incidentData.comments.findIndex(c => c.id === id);
              this.incidentData.comments[index].replies.push(retData);
            }
            this.commentText = '';
          })
          .catch((error) => {
            console.log(error);
          });

      console.log(this.incidentData);
      //this.$refs.incident.focus()
    },
    editContentMode() {
      if(this.editContent) {
        console.log('saving data ...')
        let data = this.incidentData;
        if (this.incidentData.assignedUser !== null) {
          data.assignedUser = this.incidentData.assignedUser.id
        }
        if (this.incidentData.assignedTeam !== null) {
          data.assignedTeam = this.incidentData.assignedTeam.id
        }

        this.incidentApi.updateIncident(data).then((response) => {
              console.log('done');
            })
            .catch((error) => {
              console.log(error);
            });
      }
      this.editContent = !this.editContent
    },
    editMode() {
      this.editAssignments = !this.editAssignments
    },
    abort() {
      this.editAssignments = false
    },
    removeAssignedTeam(team) {
      this.incidentData.assignedTeam = null
    },
    removeAssignedUser(user) {
      this.incidentData.assignedUser = null
    },
    assign() {
      console.log(this.incidentData);
      let data = this.incidentData;
      if (this.incidentData.assignedTeam !== null) {
        if(! (this.incidentData.assignedTeam.id === undefined) ){
          data.assignedTeam = this.incidentData.assignedTeam.id
        }
      }
      if (this.incidentData.assignedUser !== null) {
        if(! (this.incidentData.assignedUser.id === undefined) ){
          data.assignedUser = this.incidentData.assignedUser.id
        }
      }
      axios.put(this.baseUrl + '/api/v1/incidents/assign/' + this.incidentData.id, data, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log('Incident reassigned')
            this.editAssignments = false;
          })
          .catch((error) => {
            console.log('Error assigning incident');
            console.log(error);
            // TODO Fehlermeldung anzeigen
          })
    },
    statusChange() {
      this.incidentApi.updateIncidentStatus(this.incidentData.id, this.incidentData.status).then((response) => {
            console.log('Status changed to ' + this.incidentData.status)
          })
          .catch((error) => {
            console.log(error);
            // TODO Fehlermeldung anzeigen
          });
    },
    getUser(id) {
      let index = this.users.findIndex(u => u.id === id)
      console.log('user ' + id + " " + index)
      if (index === -1) {
        //return {}
        return null;
      }
      return this.users[index];
    },
    getTeam(id) {
      let index = this.teams.findIndex(t => t.id === id)
      console.log('team ' + index)
      if (index === -1) {
        //return {avatar: 'https://static.thenounproject.com/png/61749-200.png', name: 'keins'}
        return null;
      }
      return this.teams[index];
    },
    extendUserdata() {
      let u = this.getUser( this.incidentData.user )
      this.incidentData.user = u;

      let assignedUser = this.getUser( this.incidentData.assignedUser )
      this.incidentData.assignedUser = assignedUser

      if(this.incidentData.comments) {
        this.incidentData.comments.forEach(comment =>{
          let author = this.getUser( comment.user )
          comment.user = author
          if(comment.replies) {
            comment.replies.forEach(reply => {
              let replyAuthor = this.getUser( reply.user )
              reply.user = replyAuthor
            })
          }
        })
      }

      let assignedTeam = this.getTeam( this.incidentData.assignedTeam );
      this.incidentData.assignedTeam = assignedTeam;
    },
    getIncident(id, callback) {
      this.incidentApi.getIncident(id).then((response) => {
            console.log(response.data)
            this.incidentData = response.data;
            this.incidentData.comments.sort((a,b) => (a.created > b.created) ? -1 : ((b.created > a.created) ? 1 : 0));
            this.incidentData.comments.forEach(com => {
              com.replies.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0))
            });
            this.setSuccessMessage("Meldung erfolgreich geladen");
            callback();
          })
          .catch((error) => {
            console.log(error);
            this.setErrorMessage("Meldung konnte nicht geladen werden");
          });
    },
    getIncidentProtocol(incidentID, callback) {
      this.incidentApi.getIncidentProtocol(incidentID).then((response) => {
            console.log(response.data)
            this.incidentProtocol = response.data;
            this.incidentProtocol.sort((a,b) => (a.created > b.created) ? -1 : ((b.created > a.created) ? 1 : 0));
            callback();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    extendProtocolData() {
      this.incidentProtocol.forEach(ip => {
        if(ip.previousAssignedTeam) {
          ip.previousAssignedTeam = this.getTeam(ip.previousAssignedTeam)
        } else {
          ip.previousAssignedTeam = {id: '0', name: 'nicht zugewiesen'}
        }
        if(ip.previousAssignedUser) {
          ip.previousAssignedUser = this.getUser(ip.previousAssignedUser)
        } else {
          ip.previousAssignedUser = {id: '0', firstname: 'nicht', lastname: 'zugewiesen'}
        }
        if(ip.assignedTeam) {
          ip.assignedTeam = this.getTeam(ip.assignedTeam)
        } else {
          ip.assignedTeam = {id: '0', name: 'leer'}
        }
        if(ip.assignedUser) {
          ip.assignedUser = this.getUser(ip.assignedUser)
        } else {
          ip.assignedUser = {id: '0', firstname: 'kein', lastname: 'Benutzer'}
        }
        if(ip.user) {
          ip.user = this.getUser(ip.user)
        }
      })
    },
    getIncidentAttachments(id) {
      this.incidentApi.getIncidentAttachments(id).then((res) => {
        this.incidentAttachments = res.data;
      }).catch(err => console.log(err))
    },
    loggi(comment) {
      console.log('clickiduuu');
      this.incidentApi.deleteComment(comment.id).then((response) => {
            comment.deleted = response.data.deleted
          })
          .catch((error) => {
            console.log(error);
          });
    },
    ...mapActions(piniaStore, ["setErrorMessage"]),
    ...mapActions(piniaStore, ["setSuccessMessage"]),
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['token']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['teams']),
    ...mapState(piniaStore, ['users']),
  },
  created() {
    console.log('incident ID is ' + this.$route.params.id)
    this.incidentApi = new IncidentApi();
    let incidentID = this.$route.params.id;
    this.getIncidentAttachments(incidentID);
    let vm = this;
    if (this.$route.params.id !== 'test-abc') {
      if(this.$root.$children[0].$data.users === undefined ||  this.$root.$children[0].$data.users.length == 0) {
        console.log('users empty')
        this.$root.$children[0].getUsers(function(){
          vm.users = vm.$root.$children[0].$data.users
        })
      } else {
        this.users = this.$root.$children[0].$data.users
      }
      if(this.$root.$children[0].$data.teams === undefined || this.$root.$children[0].$data.teams.length == 0) {
        console.log('teams empty')
        this.$root.$children[0].getTeams(function(){
          vm.teams = vm.$root.$children[0].$data.teams
        })
      } else {
        this.teams = this.$root.$children[0].$data.teams
      }

      this.getIncident(this.$route.params.id, () => {
        this.$root.$children[0].getData(
            () => {
              console.log('data fetched from root');
              this.extendUserdata();
            })
      })
      this.getIncidentProtocol(this.$route.params.id, () => {
        this.extendProtocolData()
      })
    } else {
      this.extendUserdata()
      console.log('incidentData')
      console.log(this.incidentData)
    }
  },
};
</script>