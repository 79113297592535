<template>
  <v-container>
    <v-card>
      <v-card-title>{{report.date | dateFormatReport}}</v-card-title>
      <v-card-text>
        {{id}}<br>
        Status: {{report.state | reportStatus}}<br>
        Gespeichert: {{report.saved | dateFormatSimple}}
      </v-card-text>
      <v-card-actions>
        <v-btn @click="back">Zurück</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "pinia";
import axios from "@/main";
import {piniaStore} from "@/store/pinia";

export default {
  name: "ReportSingleManagerReport",
  props: ['id'],
  data: () => ({
    isMyProfile: false,
    report: {},
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['token']),
    ...mapState(piniaStore, ['payload']),
  },
  methods: {
    back() {
      this.$router.push("/reports");
    },
    getReport() {
      axios.get(this.baseUrl + '/api/v1/report/get/' + this.id, {headers: {"Authorization" : `Bearer ${this.token}`}})
      .then((res) => {
        console.log(res.data);
        this.report = res.data.report;
      })
      .catch((error) => {
        console.log("There was an error fetching the requested report.")
        this.report = {
          id: this.id,
          date: '2021-11-04',
          user: 'René',
        };
      });
    },
  },
  created: function () {
    this.getReport();
  },
};
</script>

<style scoped>

</style>