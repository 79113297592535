<template>
<v-container>
  <v-card>
    <v-card-text>
      <v-text-field v-model="data"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="save">Speichern</v-btn>
      <v-btn @click="logElements">Log</v-btn>
      <v-btn @click="drop">Delete all data</v-btn>
    </v-card-actions>
  </v-card>
  <v-row v-for="el in elements" :key="el.id">
    <v-col>
      <v-card>
        <v-card-text>{{el.data}}</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from "@/main";

export default {
  name: "Spaces",
  data: () => ({
    //baseUrl: 'http://uweg.codefive.five:50001/api/v1/spaces/test1',
    baseUrl: '/api/v1/spaces/test1',
    spaces: [],
    space: 'test1',
    data: '',
    elements: [],
  }),
  methods: {
    push(id) {
      let vm = this;
      axios.get(this.baseUrl + '/' + id)
      .then(function (res){
        console.log(res.data);
        vm.elements.push( res.data );
      })
    },
    getSpace () {
      let vm = this;
      axios.get(this.baseUrl)
      .then(function (res){
        console.log('Space: test1');
        console.log(res.data);
        res.data.forEach(id => {
          vm.push(id);
        });
      });
    },
    save() {
      let vm = this;
      axios.post(this.baseUrl, {
        data: this.data
      })
      .then(function (res){
        vm.elements.push(res.data);
        vm.data = '';
      })
    },
    logElements() {
      console.log('Elements');
      console.log(this.elements);
    },
    drop() {
      axios.delete("/api/v1/spaces/drop/all/data")
      .then(function(res){
        console.log("All data dropped");
      });
    }
  },
  created: function () {
    this.getSpace();
  },
}
</script>

<style scoped>

</style>