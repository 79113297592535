<template>
<v-container>
  <v-card>
    <v-card-title>RTasks</v-card-title>
    <v-card-text>
      <RTaskFields v-bind:r-task="task"></RTaskFields>
      <RTaskDialog v-bind:is-edit="false" v-bind:r-task="task" @createEvent="createEvent"></RTaskDialog>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="clearTask" icon><v-icon>mdi-cancel</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="updateTask" outlined>Update</v-btn>
      <v-btn @click="addTask" outlined>Speichern</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-list>
        <v-list-item v-for="t in tasks" :key="t.id">
          <v-list-item-content>
            <v-list-item-title>{{t.title}}</v-list-item-title>
            <v-list-item-subtitle>{{t.details}}</v-list-item-subtitle>
            <v-list-item-subtitle>
              P: {{t.project}} | CC: {{t.costCenter}} | WP: {{t.workPackage}}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-chip v-if="t.category">{{t.category}}</v-chip>
              <v-chip v-if="t.state" class="ml-2" :color="t.state == 'done' ? 'green' : 'light-grey'">{{t.state}}</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <RTaskDialog v-bind:r-task="t" @closeEvent="closeEvent" @updateEvent="updateEvent"></RTaskDialog>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon @click="deleteTask(t.id)" outlined color="red"><v-icon>mdi-delete</v-icon></v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <RTimeTrackingDialog v-bind:r-task="t"></RTimeTrackingDialog>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import RTaskApi from "@/apis/RTaskApi";
import RTaskDialog from "@/components/RTaskDialog.vue";
import RTimeTrackingDialog from "@/components/RTimeTrackingDialog.vue";
import RTaskFields from "@/components/RTaskFields.vue";

export default {
  name: "RTasks",
  components: {RTaskFields, RTimeTrackingDialog, RTaskDialog},
  data: () => ({
    rTaskApi: null,
    showDetails: false,
    tasks: [],
    defaultTask: {
      title: "",
      details: "",
      category: "",
      state: "",
      project: "",
      client: "",
      costCenter: "",
      workPackage: "",
      duration: "",
    },
    task: {
      title: "",
      details: "",
      category: "",
      state: "",
      project: "",
      client: "",
      costCenter: "",
      workPackage: "",
      duration: "",
    },
  }),
  methods: {
    getTasks() {
      this.rTaskApi.getRTasks().then((res) => {
        this.tasks = res.data;
      })
    },
    getUserTasks() {},
    addTask() {
      delete this.task.id;
      this.rTaskApi.addRTasks(this.task).then((res) => {
        this.task = res.data;
        this.getTasks();
      });
    },
    updateTask() {
      this.rTaskApi.updateRTasks(this.task).then((res) => {
        this.task = res.data;
        this.getTasks();
      });
    },
    deleteTask(id) {
      this.rTaskApi.deleteRTasks(id).then((res) => {
        this.getTasks();
      });
    },
    clearTask() {
      this.task = {
        title: "",
        details: "",
        category: "",
        state: "",
      };
    },
    editTask(task) {
      this.task = task;
    },
    closeEvent(task) {
      console.log(task);
    },
    createEvent(task) {
      this.rTaskApi.addRTasks(task).then((res) => {
        console.log(res.data)
        this.getTasks();
      });
    },
    updateEvent(task) {
      this.rTaskApi.updateRTasks(task).then((res) => {
        console.log(res.data);
        this.getTasks();
      });
    }
  },
  created() {
    this.rTaskApi = new RTaskApi();
    this.getTasks();
  }
}
</script>

<style scoped>

</style>