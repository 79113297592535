import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import http from "./plugins/http";
import {createPinia, PiniaVuePlugin} from "pinia";
import VueI18n from "vue-i18n";
import translations from "@/assets/i18n-lib.js";
//import axios from "axios";
// Add global interceptor to add bearer token to all requests:
import "@/store/axiosInterceptors";

Vue.config.productionTip = false;
const axios = require('axios');
export default axios;

Vue.use(http);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw-0.0.1.js');
  console.log("Service worker 0.0.1 registered");
};

/* Screen wake lock mechanism
 */
// The wake lock sentinel.
let wakeLock = null;

// Function that attempts to request a screen wake lock.
const requestWakeLock = async () => {
  try {
    wakeLock = await navigator.wakeLock.request('screen');
    wakeLock.addEventListener('release', () => {
      console.log('Screen Wake Lock released');
    });
  } catch (err) {
    console.error(`${err.name}, ${err.message}`);
  }
};

// Request a screen wake lock…
requestWakeLock().then(r => {
  console.log("Screen lock requested.")
});

const handleVisibilityChange = async () => {
  if (wakeLock !== null && document.visibilityState === 'visible') {
    await requestWakeLock();
  }
};

document.addEventListener('visibilitychange', handleVisibilityChange);

// End screen wake lock

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('dateFormat', function (value) {
  if (!value) return ''
  value = value.toString()
  let date = new Date(value)
  return new Intl.DateTimeFormat('de-DE', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date)
})

Vue.filter('dateFormatSimple', function (value) {
  if (!value) return ''
  value = value.toString()
  let date = new Date(value)
  return new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date)
})

Vue.filter('dateFormatReport', function (value) {
  if (!value) return ''
  value = value.toString()
  let date = new Date(value)
  return new Intl.DateTimeFormat('de-DE', {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date)
})

Vue.filter('dateTimeToTime', function (value) {
  if (!value) return ''
  return value.substr(11,5)
})

Vue.filter('timeToDate', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.substr(8,2) + '.' + value.substr(5,2) + "." + value.substr(0,4)
})

Vue.filter('runtimeFormat', function(value){
  if (!value) return '00:00'
  let Duration = require('@js-joda/core').Duration;
  let duration = Duration.parse(value)
  //return ("0" + (duration.seconds() / 3600)).slice(-2) + ':' + ("0" + (duration.seconds() / 60)).slice(-2)
  return ("0" + Math.floor(duration.seconds() / 3600)).slice(-2) + ':' + ("0" + Math.floor((duration.seconds() / 60) % 60)).slice(-2)
})

Vue.filter('incidentStatus', function(value){
  if (!value) return 'kein Status';
  if (value === 'open') return 'Offen';
  if (value === 'in_progress') return 'In Bearbeitung';
  if (value === 'closed') return 'Geschlossen';
  if (value === 'solved') return 'Gelöst';
  if (value === 'rejected') return 'Lösung abgelehnt';
  if (value === 'accepted') return 'Lösung akzeptiert';
  return 'kein Status';
})

Vue.filter('reportStatus', function(value){
  if (!value) return 'kein Status';
  if (value === 'open') return 'Offen';
  if (value === 'created') return 'Erstellt';
  if (value === 'in_progress') return 'In Bearbeitung';
  if (value === 'submitted') return 'Eingereicht';
  if (value === 'accepted') return 'Akzeptiert';
  if (value === 'approved') return 'Akzeptiert';
  if (value === 'finished') return 'Beendet';
  if (value === 'rejected') return 'Abgelehnt';
  if (value === 'closed') return 'Geschlossen';
  return 'kein Status';
})

Vue.filter('taskStatus', function(value){
  if (!value) return 'kein Status'
  if (value === 'created') return 'Erstellt'
  if (value === 'running') return 'In Bearbeitung'
  if (value === 'paused') return 'Pausiert'
  if (value === 'stopped') return 'Gestoppt'
  if (value === 'waiting') return 'Wartend'
  if (value === 'aborted') return 'Abgebrochen'
  if (value === 'finished') return 'Abgeschlossen'
  return 'kein Status'
})

const messages = translations;

const i18n = new VueI18n({
  locale: 'de', // set locale
  messages, // set locale messages
})


new Vue({
  router,
  store,
  pinia,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
