<template>
  <v-sheet
      style="position: sticky;
      bottom: 55px; left: 0; right: 0; width: 100%;
      margin-bottom: 50px; background: #ebfffa;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);"
  >
    <v-container v-model="runningTask" min-width="80vw">
      <v-row class="d-flex justify-space-between align-center" >
        <v-col cols="9" @click="showFullscreen()" style="cursor: pointer;"> <!-- showTask(task.id) -->
          <v-card-title>{{runningTask.title}}</v-card-title>
        </v-col>
        <v-col cols="3">
          <v-btn  fab large color="#f71784" dark @click="stopTask(runningTask.id)">
            <v-icon large>mdi-pause</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!activateFullscreen">
        <v-col>
          <div>
            <v-progress-circular
                rotate="270"
                size="100"
                :value="runningTaskData.hour"
                width="10"
                color="light-blue"
                class="ml-3"
            >{{ runningTaskData.laufzeit }}</v-progress-circular>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="activateFullscreen">
        <v-col height="50vh">
          <v-container>
            Dauer: {{runningTaskData.currentRuntime | runtimeFormat}}<br/>
            {{runningTask.description}}<br/>
            Leistende Kostenstelle: {{getCostCenterName(runningTask.supplying_costcenterid)}} ({{getCostCenterLabel(runningTask.supplying_costcenterid)}}) <br/>
            Empfangende Kostenstelle: {{getCostCenterName(runningTask.receiving_costcenterid)}} ({{getCostCenterLabel(runningTask.receiving_costcenterid)}}) <br/>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <IncidentDialog v-bind:task="runningTask"></IncidentDialog>
            <br>
            <v-btn class="my-3" color="#f71784" dark @click="finishTask(runningTask.id)">Task abschließen</v-btn>
            <v-btn fab @click="minimizeFullscreen()" bottom right absolute style="bottom: 20px;"><v-icon>mdi-chevron-down</v-icon></v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import {mapActions, mapState, mapGetters} from "pinia";
import IncidentDialog from "@/components/IncidentDialog";
import {piniaStore} from "@/store/pinia";

const JSJoda = require("@js-joda/core");
export default {
  name: "TaskActive",
  components: {IncidentDialog},
  props: ['runningTask'],
  data: () => ({
    activateFullscreen: false,
  }),
  methods: {
    getCostCenterLabel(id) {
      if (id == null) {
        return 'n/a';
      }
      if (this.costCenterMap.has(id)) {
        let cc = this.costCenterMap.get(id);
        return cc.label;
      }
      return 'n/a';
    },
    getCostCenterName(id) {
      if (id == null) {
        return 'Nicht zugewiesen';
      }
      if (this.costCenterMap.has(id)) {
        let cc = this.costCenterMap.get(id);
        return cc.name;
      }
      return 'Nicht zugewiesen';
    },
    minimizeFullscreen() {
      this.activateFullscreen = false;
    },
    showFullscreen() {
      if (this.activateFullscreen) {
        this.activateFullscreen = false;
      } else {
        this.activateFullscreen = true;
      }
    },
    currentTimer() {
      console.log("[Cairos] currentTimer");
      let vm = this;
      this.setRunningTaskInterval(
          setInterval(function(){
            let now = JSJoda.LocalDateTime.now();
            let startDate;
            if (vm.runningTask.start === null) {
              startDate = now;
            } else {
              startDate = JSJoda.LocalDateTime.parse(vm.runningTask.start);
            }
            let duration;
            if(vm.runningTask.duration === null) {
              duration = JSJoda.Duration.ZERO
            } else {
              duration = JSJoda.Duration.parse(vm.runningTask.duration)
            }
            //console.log(JSJoda.Duration.between(startDate, now).plus(duration).seconds() + ' ' + JSJoda.Duration.between(startDate, now).plus(duration).seconds() % 60 + ' ' + JSJoda.Duration.between(startDate, now).plus(duration).seconds() / 60 + ' ' + JSJoda.Duration.between(startDate, now).plus(duration).seconds() / 1200)
            let data = {
              laufzeit: Math.floor(JSJoda.Duration.between(startDate, now).plus(duration).seconds() / 60) + ' min',
              hour: ((JSJoda.Duration.between(startDate, now).plus(duration).seconds()%3600) / 3600 ) * 100,
              currentRuntime: JSJoda.Duration.between(startDate, now).plus(duration).toString(),
            };
            vm.setRunningTaskData(data);
          }, 1000)
      );
    },
    ...mapActions(piniaStore, ["stopTask"]),
    ...mapActions(piniaStore, ["finishTask"]),
    ...mapActions(piniaStore, ["setRunningTaskData"]),
    ...mapActions(piniaStore, ["setRunningTaskInterval"]),
    ...mapActions(piniaStore, ["getCostCenters"]),
  },
  watch: {
    //'$store.state.runningTaskId': function (newRunning, oldRunning) {
    //runningTaskId: function (newRunning, oldRunning) {
    runningTaskId: function (newRunning, oldRunning) {
      if(newRunning !== null) {
        this.currentTimer()
      } else {
        clearInterval(this.runningTaskInterval);
        /*let taskList = this.$root.$children[0].$children[0].$children.find(child => child.$options.name === 'v-main').$children.find(child => child.$options.name === 'task-list');
        taskList.$data.running = null
        taskList.$data.runningTask = null
        taskList.$data.info = 'Helllo'
        taskList.$data.tasks = this.$root.$children[0].$data.tasks*/
      }
    }
  },
  computed: {
    running: function () {
      return this.$root.$children[0].$data.running;
    },
    runtime: function () {
      var startDate = new Date(this.runningTask.start)
      var now = new Date();
      return (now.getTime() - startDate.getTime()) / 1000
    },
    ...mapState(piniaStore, ["runningTask"]),
    ...mapState(piniaStore, ["runningTaskId"]),
    ...mapState(piniaStore, ["runningTaskData"]),
    ...mapState(piniaStore, ["runningTaskInterval"]),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
  },
  mounted: function () {
    console.log('mounted active task')
    this.getCostCenters();
  },
};
</script>