import {piniaStore} from "@/store/pinia";
import axios from "axios";
import Config from "@/apis/Config";

export default class RTaskApi {
    http;
    store;

    constructor() {
        this.store = piniaStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            }
        });
        this.http.interceptors.request.use((config) => {
            config.baseURL = Config.baseUrl();
            config.headers = {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            };
            return config;
        });
    }

    getRTasks() {
        return this.http.get("/api/v1/rtasks");
    }
    getUsersRTasks(userid) {
        return this.http.get("/api/v1/rtasks/user/" + userid);
    }
    addRTasks(rtask) {
        return this.http.post("/api/v1/rtasks", rtask);
    }
    updateRTasks(rtask) {
        return this.http.put("/api/v1/rtasks", rtask);
    }
    deleteRTasks(id) {
        return this.http.delete("/api/v1/rtasks/" + id);
    }
}