<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Zuschläge verwalten</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="bonuses"
            item-key="id"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title @click.meta.shift="setAdmin">Zuschläge</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="bonusDialog"
                  fullscreen
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Neuer Zuschlag
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{bonusDialogTitle}}</v-card-title>
                  <v-card-text>
                    <v-form
                        ref="form"
                        v-model="bonusFormValid"
                    >
                      <v-text-field
                          label="Name"
                          v-model="editedBonus.name"
                      ></v-text-field>
                      <v-text-field
                          label="Label"
                          v-model="editedBonus.label"
                      ></v-text-field>
                      <v-text-field
                          label="Beschreibung"
                          v-model="editedBonus.description"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="closeBonusDialog">Abbrechen</v-btn>
                    <v-btn @click="save">Speichern</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  v-model="bonusDeleteDialog"
                  max-width="500px"
              >
                <v-card>
                  <v-card-title>Zuschlag wirklich löschen?</v-card-title>
                  <v-card-actions>
                    <v-btn @click="closeDeleteBonusDialog">Abbrechen</v-btn>
                    <v-btn @click="deleteBonusConfirm">Löschen</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn icon @click="getBonuses"><v-icon>mdi-sync</v-icon></v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.created="{ item }">
            {{item.created | dateFormatSimple}}
          </template>
          <template v-slot:item.updated="{ item }">
            {{item.updated | dateFormatSimple}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                @click="editBonus(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="openDeleteBonusDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar
        v-model="snackbar"
        :timeout="3000"
    >
      {{message}}
    </v-snackbar>
    <!-- #49fcd4 -->
  </v-container>
</template>

<script>
import axios from "@/main";
import BonusDialog from "@/components/BonusDialog";
import {mapState} from "pinia/dist/pinia.esm-browser";
import {piniaStore} from "@/store/pinia";

export default {
  name: "BonusManager",
  data: () => ({
    isAdmin: false,
    isEditing: null,
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Label',
        value: 'label'
      },
      {
        text: 'Beschreibung',
        value: 'description'
      },
      {
        text: 'Erstellt',
        value: 'created'
      },
      {
        text: 'Aktualisiert',
        value: 'updated'
      },
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
    bonuses: [],
    bonusDialog: false,
    bonusDialogTitle: 'Neuer Bonus',
    bonusFormValid: true,
    bonusDeleteDialog: false,
    editedBonus: {},
    defaultBonus: {
      name: '',
      label: '',
      description: '',
    },
    snackbar: false,
    message: '',
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['keycloak', 'token', 'users']),
  },
  methods: {
    addBonus() {
      axios.post(this.baseUrl + "/api/v1/bonuses", this.editedBonus, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log(response.data)
            this.getBonuses();
            this.closeBonusDialog();
            this.message = 'Zuschlag gespeichert';
            this.snackbar = true;
          })
          .catch((error) => {});
    },
    closeBonusDialog() {
      this.bonusDialog = false;
      this.bonusDialogTitle = 'Neuer Bonus';
    },
    closeDeleteBonusDialog() {
      this.bonusDeleteDialog = false;
      this.editedBonus = Object.assign({}, this.defaultBonus);
    },
    deleteBonusConfirm() {
      axios.delete(this.baseUrl + '/api/v1/bonuses/' + this.editedBonus.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log(response.data)
            this.bonuses = this.bonuses.filter(b => b.id !== this.editedBonus.id);
            this.closeDeleteBonusDialog();
          })
          .catch((error) => {});
    },
    editBonus(bonus) {
      this.editedBonus = Object.assign({}, bonus);
      this.bonusDialogTitle = "Zuschlag bearbeiten";
      this.bonusDialog = true;
    },
    getBonuses() {
      axios.get(this.baseUrl + '/api/v1/bonuses', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log(response.data)
            this.bonuses = response.data
          })
          .catch((error) => {});
    },
    openDeleteBonusDialog(bonus) {
      this.bonusDeleteDialog = true;
      this.editedBonus = Object.assign({}, bonus);
    },
    save() {
      if (this.bonusDialogTitle === "Zuschlag bearbeiten") {
        this.updateBonus();
      } else {
        this.addBonus();
      }
    },
    setAdmin() {
      this.isAdmin = true;
    },
    updateBonus() {
      axios.put(this.baseUrl + "/api/v1/bonuses/" + this.editedBonus.id, this.editedBonus, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log(response.data)
            this.getBonuses();
            this.closeBonusDialog();
            this.message = 'Zuschlag gespeichert';
            this.snackbar = true;
          })
          .catch((error) => {});
    },
  },
  mounted: function () {
    this.getBonuses();
  },
}
</script>