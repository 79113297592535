import axios from "axios";
import {piniaStore} from "@/store/pinia";
import Config from "@/apis/Config";

export default class ProjectPlanApi {
    http;
    store;

    constructor() {
        this.store = piniaStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            }
        });
        this.http.interceptors.request.use((config) => {
            config.baseURL = Config.baseUrl();
            config.headers = {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            };
            return config;
        });
    }

    getProjectPlans() {
        return this.http.get("/api/v1/projectplans");
    }
    addProjectPlan(projectPlan) {
        return this.http.post("/api/v1/projectplans", projectPlan);
    }
    updateProjectPlan(projectPlan) {
        return this.http.put("/api/v1/projectplans", projectPlan);
    }
    getProjectPlanByID(id) {
        return this.http.get("/api/v1/projectplans/" + id);
    }
    deleteProjectPlanByID(id) {
        return this.http.delete("/api/v1/projectplans/" + id);
    }
}