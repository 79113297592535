import axios from "axios";
import {piniaStore} from "@/store/pinia";
import Config from "@/apis/Config";

export default class ReportApi {
    http;
    store;

    constructor() {
        this.store = piniaStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            }
        });
        this.http.interceptors.request.use((config) => {
            config.baseURL = Config.baseUrl();
            config.headers = {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            };
            return config;
        });
    }

    getUsersReports() {
        return this.http.get("/api/v1/myreports");
    }
    getUsersReportEventsByDate(date) {
        return this.http.get("/api/v1/myreports/events/date/" + date);
    }
}