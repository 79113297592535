import axios from "axios";
import {defineStore} from "pinia";
import Keycloak from "keycloak-js";
import TimeTrackingApi from "@/apis/TimeTrackingApi";

let bu = "";
if (window.location.hostname.startsWith("localhost")) {
    bu = "http://localhost:50001";
}

const baseUrl = bu;

const axiosInstance = axios.create({
    withCredentials: true,
});
// headers: {"Authorization" : `Bearer ${this.token}`},

export const piniaStore = defineStore("pinia", {
    state: () => ({
        // Login and Token
        authorized: false,
        token: null,
        tokenInterval: null,
        payload: null,
        offline: false,
        test: false,

        user: null,

        checkstatus: {
            id: "",
            name: "",
            type: "checkin",
        },
        statusLoaded: false,

        // APIs
        timeTrackingApi: null,

        // General data
        bonuses: [],
        bonusMap: new Map(),
        costCenters: [],
        costCenterMap: new Map(),
        incidents: [],
        keycloak: null,
        roles: [],
        roleMap: new Map(),
        teams: [],
        teamMap: new Map(),
        users: [],
        userMap: new Map(),

        // Task handling
        runningTask: null,
        runningTaskData: {
            laufzeit: "0 min",
            hour: 0,
            currentRuntime: "",
        },
        runningTaskId: null,
        runningTaskInterval: null,
        runningTaskBadge: false,
        runningTimetrackEventId: null,
        tasks: [],
        tasksCurrent: [
            {
                id: "0",
                title: "No Tasks fetched",
                description: "No tasks from backend retrieved yet",
            },
        ],

        simpleTasks: [],

        snackbarMessage: { message: "", type: null },
    }),
    actions: {
        baseUrl() {
            if (window.location.hostname.startsWith("localhost")) {
                return "http://localhost:50001";
            }
            return "";
        },
        testP() {
            this.test = true;
            console.log("[PINIA] test successful: " + this.test);
        },
        checkToken() {
            let initOptions = {
                url: 'https://auth.cairos.app', realm: 'Cairos', clientId: 'cairos-frontend', onLoad:'login-required'
                //url: 'https://auth2.cairos.app', realm: 'Cairos', clientId: 'cairos-frontend', onLoad:'login-required'
                //url: 'https://auth0.cairos.app', realm: 'Cairos', clientId: 'cairos-frontend', onLoad:'login-required'
            }
            if(process.env.NODE_ENV === 'development') {
                console.log("PINIA")
                console.log(initOptions)
                initOptions = {
                    url: 'http://localhost:9002', realm: 'Cairos', clientId: 'cairos-frontend', onLoad:'login-required'
                }
            }
            console.log(initOptions)
            this.keycloak = Keycloak(initOptions);
            this.keycloak.init({ onLoad: initOptions.onLoad }).then((auth) =>{
                if(!auth) {
                    console.log("Authentication failed");
                    this.authorized = false;
                    window.location.reload();
                } else {
                    console.log("Authenticated with keycloak");
                    if(process.env.NODE_ENV === 'development') {
                        console.log(this.keycloak.subject)
                        console.log(this.keycloak.tokenParsed)
                        console.log(this.keycloak.realmAccess)
                    }
                    this.keycloak.realmAccess.roles.forEach(r => {
                        if(process.env.NODE_ENV === 'development') {
                            console.log(r);
                        }
                    })
                    if(process.env.NODE_ENV === 'development') {
                        console.log(this.keycloak.resourceAccess)
                    }
                    this.authorized = true;
                    this.token = this.keycloak.token;
                    this.timeTrackingApi = new TimeTrackingApi();
                    axios
                        .get("/api/v2/user")
                        .then((res) => {
                            if(process.env.NODE_ENV === 'development') {
                                console.log(res.data);
                            }
                            this.user = res.data;
                            this.user.avatar = baseUrl + this.user.avatar;
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setErrorMessage("Fehler bei Anmeldung");
                        });

                    this.getUsers();
                    this.getRoles();
                    this.getTeams();

                    this.getBonuses();
                    this.getCostCenters();

                    //Check for update in user state
                    /* setInterval(()=>{
                        axios.get(baseUrl + "/api/v1/userState", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                        .then((res)=>{
                            console.log(res.data)
                        })
                    },5000); */
                }

                //Token Refresh
                setInterval(() => {
                    this.keycloak.updateToken(70).then((refreshed) => {
                        if (refreshed) {
                            console.log('Token refreshed ' + refreshed);
                        } else {
                            console.log('Token not refreshed, valid for '
                                + Math.round(this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                        }
                    }).catch(() => {
                        console.log('Failed to refresh token');
                    });
                }, 6000);
            }).catch((err) => {
                console.log("Authentication Failed");
                console.log(err);
                this.setErrorMessage("Fehler bei Authentifzierung")
            });
        },
        catchOffline(error) {
            console.log(error);
            if (error.response) {
                console.log("Request made and server responded with error")
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                this.offline = false;
                this.setErrorMessage("Fehler bei der Verarbeitung am Server (" + error.response.status + ")")
            } else if (error.request) {
                // The request was made but no response was received
                console.log("Request made, but no response");
                console.log(error.request);
                this.offline = true;
                this.setErrorMessage("Keine Antwort vom Server")
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("General error")
                console.log('Error', error.message);
                this.offline = true;
                this.setErrorMessage("Es ist ein allgemeiner Fehler aufgetreten")
            }
        },
        setOnline() {
            this.offline = false;
        },
        /*
            Additional Data
         */
        getBonuses() {
            axios
                .get(baseUrl + '/api/v1/bonuses', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((response) => {
                    if(process.env.NODE_ENV === 'development') {
                        console.log('bonuses fetched');
                    }
                    let bonusMap = new Map();
                    let bonusData = response.data.filter(c => c.deleted == null);
                    bonusData.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
                    for (let index = 0; index < bonusData.length; ++index) {
                        const element = bonusData[index];
                        bonusMap.set(element.id, element);
                        //console.log('X ' + element.name);
                    }
                    this.bonuses = bonusData;
                    this.bonusMap = bonusMap;
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        getCostCenters() {
            axios
                .get(baseUrl + '/api/v1/costcenters', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((response) => {
                    console.log('costcenters fetched');
                    let costCenterMap = new Map();
                    let costCenters = response.data.filter(c => c.deleted == null);
                    costCenters.forEach(c => {
                        if (c.label == null) {
                            console.error('Cost center with null or undefined label:', c);
                        }
                    });
                    costCenters.sort((a,b) => {
                        if (a.label == null) return 1;
                        if (b.label == null) return -1;
                        return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)
                    });
                    for (let index = 0; index < costCenters.length; ++index) {
                        const element = costCenters[index];
                        costCenterMap.set(element.id, element);
                        //console.log('X ' + element.name);
                    }
                    this.costCenters = costCenters;
                    this.costCenterMap = costCenterMap;
                    this.offline = false;
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        getIncidents() {
            axios
                .get(baseUrl + "/api/v1/incidents", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((response) => {
                    if(process.env.NODE_ENV === 'development') {
                        console.log("Incidents fetched")
                    }
                    //console.log(response.data);
                    let incidents = response.data.filter(
                        i => i.deleted == null
                    );
                    incidents.sort((a, b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0)).reverse();
                    this.incidents = incidents;
                    this.setSuccessMessage("Meldungen erfolgreich geladen")
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        getRoles() {
            axios.get(baseUrl + '/api/v1/roles', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((response) => {
                    if(process.env.NODE_ENV === 'development') {
                        console.log('[Cairos] Fetched roles')
                        console.log(response.data)
                    }
                    this.roles = response.data.filter(e => e.deleted == null)
                    this.roles.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                })
                .catch(function(error){
                    if(process.env.NODE_ENV === 'development') {
                        console.log(error)
                    }
                    this.setErrorMessage("Fehler beim Laden der Benutzerrollen")
                });
        },
        getStatus() {
            this.statusLoaded = false;
            axios.get(baseUrl + '/api/v1/checkstatus', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((response) => {
                    this.checkstatus = response.data.status;
                    this.offline = false;
                    if(process.env.NODE_ENV === 'development') {
                        console.log("Checkstatus Data");
                        console.log(response.data);
                    }
                    this.statusLoaded = true;
                })
                .catch((error) => {
                    this.catchOffline(error);
                });
        },
        getTeams() {
            axios
                .get('/api/v1/teams')
                .then((response) => {
                    if(process.env.NODE_ENV === 'development') {
                        console.log('teams fetched');
                    }
                    let teamsMap = new Map();
                    let teams = response.data.filter(c => c.deleted == null);
                    teams.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    for (let index = 0; index < teams.length; ++index) {
                        const element = teams[index];
                        teamsMap.set(element.id, element);
                    }
                    this.teams = teams;
                    this.teamMap = teamsMap;
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        getUsers() {
            axios
                .get('/api/v1/users')
                .then((response) => {
                    if(process.env.NODE_ENV === 'development') {
                        console.log('users fetched');
                        console.log(response.data)
                    }
                    let usersMap = new Map();
                    let users = response.data.filter(c => c.deleted == null);
                    console.log(users)
                    users.sort((a,b) => (a.lastname > b.lastname) ? 1 : ((b.lastname > a.lastname) ? -1 : 0));
                    for (let index = 0; index < users.length; ++index) {
                        const element = users[index];
                        usersMap.set(element.id, element);
                    }
                    this.users = users;
                    this.userMap = usersMap;
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        getUserState() {
            axios.get(baseUrl + "/api/v1/user-state", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((res)=>{
                    if(process.env.NODE_ENV === 'development') {
                        console.log(res.data)
                    }
                    this.runningTaskId = res.data.runningTaskId;
                    this.runningTimetrackEventId = res.data.runningTimetrackEventId;
                    if(this.runningTaskId === null) {
                        this.runningTask = null;
                        this.runningTaskBadge = false;
                    } else {
                        this.runningTaskBadge = true;
                        // this.runningTask =
                        axios.get(baseUrl + "/api/v1/tasks/" + this.runningTaskId, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}}).then((res) => {
                            this.runningTask = res.data;
                        }).catch(()=>{
                            this.setErrorMessage("Fehler");
                        })
                    }
                    console.log("running task: " + this.runningTaskId);
                })
                .catch((err) => {
                    console.log(err);
                    this.setErrorMessage("Fehler beim Laden des UserState")
                })
        },
        reloadUser() {
            axios
                .get(baseUrl + "/api/v2/user", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((res) => {
                    if(process.env.NODE_ENV === 'development') {
                        console.log(res.data);
                    }
                    this.user = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        /*
            Task handling
         */

        getTasks(sortAscending) {
            let params = "";
            if(sortAscending) {
                params = "?sortOrder=ascending";
            }
            axios
                .get('/api/v1/tasks/current' + params)
                .then( (res) => {
                    let resultTasks = res.data.filter(e => e.status !== 'finished' );
                    //resultTasks = resultTasks.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));
                    if(this.runningTaskId !== null) {
                        resultTasks = resultTasks.filter(e => e.id !== this.runningTaskId);
                    }
                    this.tasks = resultTasks;
                    this.setSuccessMessage('Tasks aktualisiert');
                })
                .catch((err) => {
                    console.log('cairos.tasksCurrent not updated, maybe offline');
                    this.catchOffline(err);
                });
        },

        getTasksCurrent() {
            axios
                .get('/api/v1/tasks/current')
                .then( (response) => {
                    response.data.sort((a, b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));
                    /*
                    if there is a running task in the response from server, but currently no running task registered
                    register the returned running task in the frontend as runningTask
                     */
                    let running = response.data.findIndex(t => t.status === "running");
                    if (running > -1) {
                        if(this.runningTask === null) {
                            this.runningTask = running;
                        }
                    }
                    this.tasksCurrent = response.data;
                    window.localStorage.setItem('cairos.tasksCurrent', JSON.stringify(response.data));
                    console.log('cairos.tasksCurrent updated');
                })
                .catch((err) => {
                    console.log('cairos.tasksCurrent not updated, maybe offline');
                    this.catchOffline(err);
                });
        },
        getBonusDetailsByID(id) {
            if(id !== null) {
                let u = this.bonusMap.get(id);
                if(u) {
                    return u;
                } else {
                    console.log("Unbekannter Zuschlag");
                    return null;
                }
            }
            return null;
        },
        getCostCenterDetailsByID(id) {
            if(id !== null) {
                let u = this.costCenterMap.get(id);
                if(u) {
                    return u;
                } else {
                    console.log("Unbekannte Kostenstelle");
                    return null;
                }
            }
            return null;
        },
        getRoleDetailsByID(id) {
            if(id !== null) {
                let u = this.roleMap.get(id);
                if(u) {
                    return u;
                } else {
                    console.log("Unbekannte Rolle");
                    return null;
                }
            }
            return null;
        },
        getTeamDetailsByID(id) {
            if(id !== null) {
                let u = this.teamMap.get(id);
                if(u) {
                    return u;
                } else {
                    console.log("Unbekanntes Team");
                    return null;
                }
            }
            return null;
        },
        getUserDetailsByID(id) {
            if(id !== null) {
                let u = this.userMap.get(id);
                if(u) {
                    return u;
                } else {
                    console.log("Unbekannter Benutzer");
                    return null;
                }
            }
            return null;
        },
        /*
        async coolFunction() {
            try{
                let res = await axios.get("/example");
                let data = res.data;
            } catch(err) {
                console.log(err);
            }
        },
         */

        // time tracking handling pinia central style

        startTimetrackTask(taskId) {
            axios.post("/api/v1/timetrack/start", {
                parentModel: "Task",
                parentId: taskId,
            }).then((res) => {
                this.runningTimetrackEventId = res.data.userState.runningTimetrackEventId;
                this.runningTaskId = res.data.userState.runningTaskId;
                this.runningTask = res.data.task;
                if(this.runningTaskId !== null) {
                    this.tasks = this.tasks.filter(e => e.id !== this.runningTaskId);
                }
                this.runningTaskBadge = true;
            }).catch((err) => {
                console.log(err)
            })
        },

        stopTimetrackTask(taskId, timetrackEventId) {
            axios.post("/api/v1/timetrack/stop", {
                parentModel: "Task",
                parentId: taskId,
                timetrackEventId: timetrackEventId,
            }).then((res) => {
                this.runningTimetrackEventId = null;
                this.runningTaskId = null;
                this.runningTask = null;
                this.runningTaskBadge = false;
            }).catch((err) => {
                console.log(err)
            })
        },

        setTasks(tasks) {
            this.tasks = tasks;
        },

        // Task handling

        startTask(task) {
            if(process.env.NODE_ENV === 'development') {
                console.log('[Cairos] start task ' + task.id);
                console.log('xx ' + this.baseUrl());
            }
            if (this.runningTask !== null) {
                this.stopTask(this.runningTask.id);
            }
            this.timeTrackingApi.startTask(task.id)
            //axios
            //    .post(this.baseUrl() + '/api/v1/tasks/start/' + task.id, {}, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((response) => {
                    console.log("[Cairos] started Task " + task.id);
                    if(process.env.NODE_ENV === 'development') {
                        console.log(response.data);
                    }

                    //vm.info = "Starting task " + task.id;
                    /*vm.tasks = vm.tasks.map(c =>
                        c.id === task.id ? response.data : c
                    );*/
                    this.getTasksCurrent();

                    let runningTask = response.data;

                    // fill costcenter property with rich data
                    if (runningTask.receiving_costcenterid !== null) {
                        runningTask.receiving_costcenter = this.costCenterMap.get(runningTask.receiving_costcenterid);
                    } else {
                        //todo null branch sinnvoll initialisieren
                        runningTask.receiving_costcenter = {
                            id: '',
                            name: '',
                            label: '',
                        }
                    }
                    if (runningTask.supplying_costcenterid !== null) {
                        runningTask.supplying_costcenter = this.costCenterMap.get(runningTask.supplying_costcenterid);
                    } else {
                        //todo null branch sinnvoll initialisieren
                        runningTask.supplying_costcenter = {
                            id: '',
                            name: '',
                            label: '',
                        }
                    }
                    // end fill data

                    this.runningTask = runningTask;
                    this.runningTaskId = runningTask.id;
                    this.runningTaskBadge = true;
                    // update tabs tasks arrays
                    /*vm.tabs.forEach((t, i)=>{
                        var index = t.tasks.findIndex(ta => ta.id === task.id)
                        vm.tabs[i].tasks[index] = response.data;
                    });*/
                    //taskTimer(id);
                })
                .catch( (error) => {
                    console.log('[Cairos] error starting task ' + task.id);
                    console.log(error);
                    this.catchOffline(error);
                });
        },
        stopTask(id) {
            console.log('[Cairos] stop task ' + id);
            this.timeTrackingApi.stopTask(id)
            //axios.post(baseUrl + '/api/v1/tasks/stop/' + id,{},{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then( (response) => {
                    console.log("[Cairos] stopped task " + id);
                    if(process.env.NODE_ENV === 'development') {
                        console.log(response.data);
                    }
                    if(this.runningTask !== null) {
                        if (this.runningTaskId === id) {
                            this.runningTask = null;
                            this.runningTaskId = null;
                            this.runningTaskBadge = false;
                        }
                    }
                    this.getTasksCurrent();
                })
                .catch(function (error) {
                    console.log('[Cairos] error stopping task ' + id);
                    console.log(error);
                })
        },
        stopTaskAdmin(id) {
            console.log('[Cairos] Admin stop task ' + id);
            axios.post(baseUrl + '/api/v1/tasks/stop/' + id,{},{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then( (response) => {
                    console.log("[Cairos] Admin stopped task " + id);
                    console.log(response.data);
                })
                .catch(function (error) {
                    console.log('[Cairos] Admin error stopping task ' + id);
                    console.log(error);
                })
        },
        finishTask(id) {
            console.log('[Cairos] finish task ' + id);
            this.timeTrackingApi.finishTask(id)
            //axios.post(baseUrl + '/tasks/finish/' + id)
                .then((response) => {
                    console.log("[Cairos] finished task " + id);
                    if(process.env.NODE_ENV === 'development') {
                        console.log(response.data);
                    }
                    this.getTasksCurrent();
                    if(this.runningTask !== null) {
                        if (this.runningTask.id === id) {
                            this.runningTask = null;
                            this.runningTaskId = null;
                        }
                    }
                })
                .catch(function (error) {
                    console.log('[Cairos] error finishing task ' + id);
                    console.log(error);
                })
        },
        // new in 2023-02 setRunningTask
        setRunningTask(task) {
            this.runningTask = task;
        },
        // existing running task stuff
        setRunningTaskData(data) {
            this.runningTaskData = data;
        },
        setRunningTaskInterval(handler) {
            this.runningTaskInterval = handler;
        }, /* TODO review and adapt
        updateRunningTask(context, task) {
            context.commit("runningTask", task);
        }, */
        setRunningTaskBadge(badge) {
            this.runningTaskBadge = badge;
        },
        setRunningTaskId(id) {
            this.runningTaskId = id;
        },
        getSimpleTasks() {
            axios.get(baseUrl + '/api/v1/simpletasks/',{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((res) => {
                    this.simpleTasks = res.data;
                    console.log("Data");
                    if(process.env.NODE_ENV === 'development') {
                        console.log(res.data);
                    }
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        addSimpleTask(data) {
            axios.post(baseUrl + '/api/v1/simpletasks/', data,{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((res) => {
                    //this.simpleTasks = res.data;
                    console.log("Data");
                    if(process.env.NODE_ENV === 'development') {
                        console.log(res.data);
                    }
                    this.getSimpleTasks();
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        updateSimpleTask(data) {
            axios.put(baseUrl + '/api/v1/simpletasks/', data,{headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((res) => {
                    //this.simpleTasks = res.data;
                    console.log("Data");
                    if(process.env.NODE_ENV === 'development') {
                        console.log(res.data);
                    }
                    this.getSimpleTasks();
                })
                .catch((err) => {
                    this.catchOffline(err);
                });
        },
        deleteSimpleTask(id) {
            axios.delete(baseUrl + '/api/v1/simpletasks/' + id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((res) => {
                    console.log("Delete " + id);
                    this.getSimpleTasks();
                })
                .catch((err) => {
                    this.catchOffline(err);
                })
        },
        setNetworkError(error) {
            this.snackbarMessage = { type: "ERROR", message: error };
        },
        setErrorMessage(error) {
            this.snackbarMessage = { type: "ERROR", message: error };
        },
        setSuccessMessage(message) {
            this.snackbarMessage = { type: "SUCCESS", message };
        },
    },
});