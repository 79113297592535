<template>
  <v-container>
    <v-tabs>
      <v-tab>Alle</v-tab>
      <v-tab>In Bearbeitung</v-tab>
      <v-tab>Vorlagen</v-tab>
      <v-tab-item key="1">
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="tasks"
                    item-key="id"
                    :loading="tasksLoading"
                    loading-text="Wird geladen... Bitte warten"
                    :search="search"
                >
                  <template v-slot:top>
                    <v-toolbar
                        flat
                    >
                      <v-toolbar-title @click.meta.shift="setAdmin">Aufgaben (Alle)</v-toolbar-title>
                      <v-divider
                          class="mx-4"
                          inset
                          vertical
                      ></v-divider>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Suche"
                          single-line
                          hide-details
                          clearable
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-dialog
                          v-model="taskDialog"
                          fullscreen
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                          >
                            Neue Aufgabe
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>{{taskDialogTitle}}</v-card-title>
                          <v-card-text>
                            <v-form
                                ref="form"
                                v-model="taskFormValid"
                            >
                              <v-text-field
                                  label="Titel"
                                  v-model="editedTask.title"
                                  outlined
                              ></v-text-field>
                              <v-text-field
                                  label="Beschreibung"
                                  v-model="editedTask.description"
                                  outlined
                              ></v-text-field>
                              <v-text-field
                                  label="Status"
                                  v-model="editedTask.status"
                                  outlined
                              ></v-text-field>
                              <v-autocomplete
                                  v-model="editedTask.supplying_costcenterid"
                                  :items="costCenters"
                                  item-text="label"
                                  item-value="id"
                                  label="Leistende Kostenstelle"
                                  :filter="customCostcenterFilter"
                                  clearable
                                  outlined
                              >
                                <template v-slot:selection="data">
                                  {{data.item.label}}, {{data.item.name}}
                                </template>
                                <template slot="item" slot-scope="data">
                                  {{data.item.label}}, {{data.item.name}}
                                </template>
                              </v-autocomplete>
                              <v-autocomplete
                                  v-model="editedTask.receiving_costcenterid"
                                  :items="costCenters"
                                  item-text="label"
                                  item-value="id"
                                  label="Empfangende Kostenstelle"
                                  required
                                  :hint="hints.receiving_costcenter"
                                  :filter="customCostcenterFilter"
                                  persistent-hint
                                  clearable
                                  outlined
                              >
                                <template v-slot:selection="data">
                                  {{data.item.label}}, {{data.item.name}}
                                </template>
                                <template slot="item" slot-scope="data">
                                  {{data.item.label}}, {{data.item.name}}
                                </template>
                              </v-autocomplete>
                              <v-text-field
                                  v-if="isAdmin"
                                  label="RCC"
                                  v-model="editedTask.receiving_costcenterid"
                                  disabled
                                  outlined
                              ></v-text-field>
                              <v-combobox
                                  v-model="editedTask.bonuses"
                                  :items="bonuses"
                                  item-text="label"
                                  item-value="id"
                                  label="Zuschläge"
                                  multiple
                                  chips
                                  outlined
                              >
                                <template slot="item" slot-scope="data">
                                  {{data.item.label}} - {{data.item.name}}
                                </template>
                              </v-combobox>
                              <v-autocomplete
                                  label="Zuständiger Benutzer"
                                  v-model="editedTask.assignedUser"
                                  :items="users"
                                  item-text="lastname"
                                  item-value="id"
                                  outlined
                              >
                                <template v-slot:selection="data">
                                  {{data.item.firstname}} {{data.item.lastname}}
                                </template>
                                <template v-slot:item="data">
                                  <template>
                                    <v-list-item-avatar>
                                      <img :src="baseUrl + data.item.avatar">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>{{data.item.firstname}} {{data.item.lastname}}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </template>
                              </v-autocomplete>
                              <v-textarea
                                  v-if="user.email === 'rene@codefive.de'"
                                  outlined
                                  v-model="editedTask.startTimes"
                                  label="Start times"></v-textarea>
                              <v-textarea
                                  v-if="user.email === 'rene@codefive.de'"
                                  outlined
                                  v-model="editedTask.stopTimes"
                                  label="Stop times"></v-textarea>
                            </v-form>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeTaskDialog" outlined>Abbrechen</v-btn>
                            <v-btn @click="saveTaskNew" color="primary" outlined>Speichern</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-btn icon class="mb-2 ml-2" @click="updateData"><v-icon>mdi-sync</v-icon></v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.created="{ item }">
                    {{item.created | dateFormatSimple}}
                  </template>
                  <template v-slot:item.updated="{ item }">
                    {{item.updated | dateFormatSimple}}
                  </template>
                  <template v-slot:item.title="{ item }">
                    <span @click="editTask(item)" style="cursor: pointer;">{{item.title}}</span>
                  </template>
                  <template v-slot:item.status="{ item }">
                    {{item.status | taskStatus}}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        @click="editTask(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteTask(item)"
                        v-if="user.email === 'rene@codefive.de'"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item key="2">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Aufgaben (in Bearbeitung)</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn icon @click="getRunningTasks"><v-icon>mdi-sync</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row v-for="task in runningTasks" :key="task.id">
              <v-col>
                <v-card class="mt-2">
                  <v-card-title>{{task.title}}</v-card-title>
                  <v-card-text>{{getUsername(task.assignedUser)}}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="4">
        <v-card>
          <v-card-title>Vorlagen</v-card-title>
          <v-card-text>Dieses Feature kommt in Kürze</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <!-- <v-row>
      <v-col cols="3" xs="4">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-container
                v-bind="attrs"
                v-on="on"
            >
              <TaskDialog></TaskDialog>
            </v-container>
          </template>
          <span>Neuen Task erstellen</span>
        </v-tooltip>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
export default {
  name: "TaskManager",
  data: () => ({
    isAdmin: false,
    isEditing: null,
    headers: [
      {
        text: 'Titel',
        value: 'title',
      },
      {
        text: 'Beschreibung',
        value: 'description',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Leistende KSt.',
        value: 'supplying_costcenter.name',
      },
      {
        text: 'Empfangende KSt.',
        value: 'receiving_costcenter.name',
      },
      {
        text: 'Erstellt',
        value: 'created'
      },
      {
        text: 'Aktualisiert',
        value: 'updated'
      },
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
    /* new UI restructure */
    nTasks: [],
    tasks: [],
    search: '',
    editedTask: {},
    defaultTask: {
      createdBy: '',
      title: '',
      description: '',
      receiving_costcenterid: null,
      supplying_costcenterid: null,
      jobid: null,
      assignedUser: null,
    },
    startTimes: [],
    stopTimes: [],
    runningTasks: [],
    taskDialog: false,
    taskDialogTitle: "Neuer Task",
    taskFormValid: false,
    hints: {
      title: '',
      supplying_costcenter: '',
      receiving_costcenter: '',
    },
    tasksLoading: true,
  }),
  watch: {
    tasks: {
      handler: function(newVal) {
        console.log(newVal)
      },
      deep: true,
    }
  },
  methods: {
    closeTaskDialog() {
      this.taskDialog = false;
      this.editedTask = Object.assign({}, this.defaultTask);
      this.startTimes = [];
      this.stopTimes = [];
    },
    customCostcenterFilter (item, queryText, itemText) {
      const name = item.name.toLowerCase()
      const label = item.label.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 ||
          label.indexOf(searchText) > -1
    },
    deleteTask(task) {
      axios.delete(this.baseUrl + '/api/v1/tasks/' + task.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            console.log(response.data)
            this.tasks = this.tasks.filter(t =>
                t.id !== task.id
            );
            this.isEditing = null;
            this.getTasks();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    editMode(task) {
      if (this.isEditing === task.id) {
        this.isEditing = null;
      } else {
        this.isEditing = task.id;
      }
    },
    editTask(task) {
      this.editedTask = Object.assign({}, task);
      this.startTimes = Object.assign([], task.startTimes);
      this.stopTimes = Object.assign([], task.stopTimes);
      this.taskDialogTitle = "Aufgabe bearbeiten";
      this.taskDialog = true;
    },
    getRunningTasks() {
      axios.get(this.baseUrl + '/api/v1/tasks/all', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            response.data.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0))
            this.runningTasks = response.data.filter(t => t.status === "running");
          })
          .catch(function (error) {
            console.log(error);
            console.log('cairos.tasks not updated, maybe offline');
          });
    },
    getTasks() {
      this.tasksLoading = true;
      axios.get(this.baseUrl + '/api/v1/tasks/all', {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            response.data.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0))
            this.tasks = response.data;
            window.localStorage.setItem('cairos.tasks', JSON.stringify(response.data));
            console.log('cairos.tasks updated');
            this.tasksLoading = false;
          })
          .catch(function (error) {
            console.log(error);
            console.log('cairos.tasks not updated, maybe offline');
          });
    },
    getUsername(id) {
      if(id !== null) {
        let u = this.userMap.get(id);
        if(u) {
          return u.firstname + " " + u.lastname;
        }
      }
      return "Unbekannter Benutzer";
    },
    saveTaskNew() {
      if(typeof this.editedTask.startTimes === "string") {
        this.editedTask.startTimes = this.editedTask.startTimes.split(",");
      }
      if(typeof this.editedTask.stopTimes === "string") {
        this.editedTask.stopTimes = this.editedTask.stopTimes.split(",");
      }
      console.log(this.editedTask);
      if(this.editedTask.receiving_costcenter) {
        delete this.editedTask.receiving_costcenter;
      }
      if(this.editedTask.supplying_costcenter) {
        delete this.editedTask.supplying_costcenter;
      }
      if(this.editedTask.id) {
        axios.put(this.baseUrl + "/api/v1/tasks/" + this.editedTask.id, this.editedTask, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res)=>{
              console.log(res);
              this.closeTaskDialog();
            })
            .catch((err) => {
              console.log(err);
            });
      } else {
        axios.post(this.baseUrl + "/api/v1/tasks", this.editedTask, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res)=>{
              console.log(res);
              this.closeTaskDialog();
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    removeTeam (task, item) {
      const index = task.assignedTeams.indexOf(item.id)
      if (index >= 0) task.assignedTeams.splice(index, 1)
    },
    removeRole (task, item) {
      const index = task.assignedRoles.indexOf(item.id)
      if (index >= 0) task.assignedRoles.splice(index, 1)
    },
    removeUser (task, item) {
      const index = task.assignedUsers.indexOf(item.id)
      if (index >= 0) task.assignedUsers.splice(index, 1)
    },
    saveTask() {
      let vm = this;
      //validate task
      this.editedTask.title = this.editedTask.title.trim();
      if (this.editedTask.title === '') {
        this.hints.title = 'Bitte Titel angegeben';
        return;
      }
      if (this.editedTask.receiving_costcenterid === null) {
        this.hints.receiving_costcenter = 'Bitte Kostenstelle angegeben';
        return;
      }
      axios.post(this.baseUrl + '/api/v1/tasks', vm.task, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((response) => {
            this.closeTaskDialog();
            console.log('Task from backend');
            console.log(response.data);
            this.getTasksCurrent();
          })
          .catch(function (error) {
            console.log(error);
            this.info = 'Es ist ein Fehler aufgetreten.'
          });
    },
    setAdmin() {
      this.isAdmin = true;
    },
    updateData() {
      this.getTasks();
    },
    ...mapActions(piniaStore, ["getCostCenters"]),
    ...mapActions(piniaStore, ["getRoles"]),
    ...mapActions(piniaStore, ["getTeams"]),
    ...mapActions(piniaStore, ["getUsers"]),
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    /* costcenters: function() {
      return this.$root.$children[0].$data.costCenters
    }, */
    supplying_costcenter_list: function() {
      //return this.$root.$children[0].$data.costCenters;
      return this.costCenters;
    },
    receiving_costcenter_list: function() {
      //return this.$root.$children[0].$data.costCenters;
      return this.costCenters;
    },
    status: function() {
      return this.$root.$children[0].$data.taskStatus
    },
    ...mapState(piniaStore, ['payload']),
    ...mapState(piniaStore, ['token']),
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['roles']),
    ...mapState(piniaStore, ['teams']),
    ...mapState(piniaStore, ['users']),
    ...mapState(piniaStore, ['userMap']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  mounted: function() {
    console.log('mounted task-manager');
    this.getRoles();
    this.getTeams();
    this.getUsers();
    this.getTasks();
    this.getCostCenters();
    this.getRunningTasks();
    this.defaultTask.assignedUser = this.user.id;
    this.editedTask = Object.assign({}, this.defaultTask);
  },
};
</script>

<style scoped>
.plain-link {
  text-decoration: none;
}
</style>