<template>
<v-container>
  <v-card>
    <v-card-title @click="loggi">New Time Tracking Preview<v-spacer></v-spacer><v-btn icon @click="toggleTaskSortOrder"><v-icon>mdi-swap-vertical</v-icon></v-btn></v-card-title>
    <v-card-text>
      <v-icon @click="logInfo">mdi-information</v-icon>
      <v-row>
        <v-col cols="10">
          <v-text-field label="What" v-model="preparedTask.title"></v-text-field>
        </v-col>
        <v-col cols="2" class="text-right">
          <span class="mr-5">0:00</span>
          <v-btn icon outlined color="secondary" @click="createAndStart" v-if="runningTaskId == null"><v-icon>mdi-play</v-icon></v-btn>
          <v-btn icon outlined color="primary" @click="stopAndClear" v-else><v-icon>mdi-stop</v-icon></v-btn>
        </v-col>
        <v-col cols="6">
          <v-combobox
              :items="tags"
              v-model="selectedTags"
              multiple
              dense
              prepend-icon="mdi-tag"
              small-chips
              @keyup.enter="addTag"
          ></v-combobox>
        </v-col>
        <v-col cols="4">
          <v-select
              label="Project"
              v-model="selectedProject"
              :items="projects"
              item-text="name"
              item-value="id"
              prepend-icon="mdi-folder"
              dense
              clearable
              @change="changedProject"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
              :items="rates"
              prepend-icon="mdi-currency-eur"
              dense
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
              label="Kostenstelle"
              v-model="selectedCostCenter"
              :items="costCentersList"
              item-text="name"
              item-value="id"
              prepend-icon="mdi-finance"
              clearable
              dense
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
              label="Arbeitspaket"
              :items="packages"
              prepend-icon="mdi-package-variant-closed"
              clearable
              dense
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-text-field label="Suche" v-model="searchText" outlined @input="filterTasks" clearable></v-text-field>
    </v-card-text>
  </v-card>
  <v-card class="mt-5">
    <v-card-title>
      <v-row>
        <v-col>Today</v-col>
        <v-col class="text-right">3:54</v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="9"><h3>Newsletter Emails</h3></v-col>
        <v-col cols="3" class="text-right">0:48</v-col>
        <v-col cols="9">
          <v-btn icon><v-icon small>mdi-tag</v-icon></v-btn>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn icon><v-icon small>mdi-folder</v-icon></v-btn>
          <v-btn icon><v-icon small>mdi-currency-eur</v-icon></v-btn>
          <v-btn icon outlined><v-icon>mdi-play</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="9"><h3>UI Edits</h3></v-col>
        <v-col cols="3" class="text-right">2:17</v-col>
        <v-col cols="9">
          <v-btn icon><v-icon small>mdi-tag</v-icon></v-btn>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn icon><v-icon small>mdi-folder</v-icon></v-btn>
          <v-btn icon><v-icon small>mdi-currency-eur</v-icon></v-btn>
          <v-btn icon outlined><v-icon>mdi-play</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <div v-for="task in fetchedTasks" :key="task.id">
      <v-card-text @click="openTaskDetailDialog(task)">
        <v-row>
          <v-col cols="9"><h3>{{task.title}}</h3></v-col>
          <v-col cols="3" class="text-right">{{task.duration}}</v-col>
          <v-col cols="9">
            <v-btn icon><v-icon small>mdi-tag</v-icon></v-btn>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn icon><v-icon small>mdi-folder</v-icon></v-btn>
            <v-btn icon><v-icon small>mdi-currency-eur</v-icon></v-btn>
            <v-btn icon outlined @click="startFromList(task)"><v-icon>mdi-play</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
  <v-dialog
      v-model="taskDetailDialog"
      persistent
      fullscreen
  >
    <v-card>
      <v-card-title>{{selectedTask.title}}</v-card-title>
      <v-card-text>
        <v-text-field label="Titel" v-model="selectedTask.title"></v-text-field>
        <v-text-field label="Beschreibung" v-model="selectedTask.description"></v-text-field>
        <v-row>
          <v-col>
          <v-text-field label="Start" v-model="selectedTask.start"></v-text-field>
        </v-col>
          <v-col>
            <v-text-field label="Ende" v-model="selectedTask.end"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-for="tte in selectedTaskTimetrackEvents" :key="tte.id">
        <v-row>
          <v-col cols="5">
            <v-text-field label="Start" v-model="tte.start"></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field label="Ende" v-model="tte.end"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-switch inset label="Manuell" v-model="selectedTask.manual"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="closeTaskDetailDialog">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import ProjectApi from "@/apis/ProjectApi";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import WorkspaceDataApi from "@/apis/WorkspaceDataApi";

export default {
  name: "TaskViewFeaturePreview",
  data: () => ({
    timeTrackingApi: null,
    projectApi: null,
    workspaceDataApi: null,

    toggleTasksAscending: false,

    searchText: "",

    defaultPreparedTask: {
      title: "",
      description: "",
      projectid: null,
    },
    preparedTask: {},

    fetchedTasks: [],
    fetchedTasksCopy: [],
    taskDetailDialog: false,
    defaultTask: {
      id: "",
      title: "",
      description: "",
    },
    selectedTask: {},
    selectedTaskTimetrackEvents: [],

    selectedProject: "",
    selectedCostCenter: null,

    projects: [],
    rates: [
        "0",
        "20",
        "75",
    ],
    tags: [
        "difficult",
    ],
    selectedTags: [],
    packages: [
        "AWS 1 - Verwaltung",
        "AWS 2",
        "AWS 3",
        "AWS 4",
        "AWS 5",
        "AWS 6",
    ],
    costCentersList: [
      "300 IT Allgemein",
      "301 IT Landingpage",
      "302 IT Website",
      "303 Lead Generation",
      "304 Digitales AWK",
      "305 Dokumentenmanagement",
      "306 Entsorger-Matchmaking",
      "307 Abfallanalyse-Service",
      "308 Wastemanagement",
      "310 IT Organisation",
      "311 IT Meeting",
      "311 IT Product Owner",
      "311 IT Scrum Master",
      "400 Zentrale Verwaltung",
      "401 Geschäftsführung",
      "400 HR",
    ],
  }),
  computed: {
    runtime: function () {
      let startDate = new Date(this.runningTask.start)
      let now = new Date();
      return (now.getTime() - startDate.getTime()) / 1000
    },
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['userMap']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['runningTaskId']),
  },
  methods: {
    logInfo() {
      console.log(this.runningTaskId)
    },
    addTag() {
      this.selectedTags.forEach(item => {
        if(this.tags.indexOf(item) === -1) {
          this.tags.push(item);
        }
      })
    },
    createAndStart() {
      let yourDate = new Date();
      if(this.preparedTask.title == "") {
        this.preparedTask.title = "Task from " + yourDate.toISOString().substring(0, 16);
      }
      let task = Object.assign({}, this.preparedTask);
      this.timeTrackingApi.createTask(task).then((res)=>{
        console.log("Task created");
        console.log(res.data);
        this.startTask(res.data);
      }).catch((err)=>{
        console.log(err);
      });
    },
    startFromList(task) {
      this.startTask(task);
      this.preparedTask = Object.assign({}, task);
    },
    stopAndClear() {
      this.stopTask(this.runningTaskId);
      this.getTasks();
      this.preparedTask = Object.assign({}, this.defaultPreparedTask);
    },
    openTaskDetailDialog(task) {
      this.selectedTask = task;
      this.selectedTaskTimetrackEvents = new Array();
      this.selectedTask.timetrackEvents.forEach(te => {
        this.timeTrackingApi.getTimetrackEvent(te).then((res)=>{
          this.selectedTaskTimetrackEvents.push(res.data);
          this.sortTimetrackEventsDescending();
        })
      });
      this.taskDetailDialog = true;
    },
    closeTaskDetailDialog() {
      this.taskDetailDialog = false;
      this.selectedTask = this.defaultTask;
    },
    sortTimetrackEventsAscending() {
      if(this.selectedTask.timetrackEvents.length == this.selectedTaskTimetrackEvents.length) {
        this.selectedTaskTimetrackEvents.sort((a,b) => (a.start > b.start ? 1 : -1));
      }
    },
    sortTimetrackEventsDescending() {
      if(this.selectedTask.timetrackEvents.length == this.selectedTaskTimetrackEvents.length) {
        this.selectedTaskTimetrackEvents.sort((a,b) => (a.start < b.start ? 1 : -1));
      }
    },
    changedProject() {
      if(this.selectedProject !== null && this.selectedProject !== '') {
        this.selectedCostCenter = null;
        this.workspaceDataApi.getCostCentersByProjectId(this.selectedProject).then((res) => {
          this.costCentersList = res.data;
        })
      } else {
        this.workspaceDataApi.getCostCenters().then((res) => {
          this.costCentersList = res.data;
        })
      }
    },
    toggleTaskSortOrder() {
      this.toggleTasksAscending = !this.toggleTasksAscending;
      this.getTasks();
    },
    getTasks() {
      this.timeTrackingApi.getTasks(this.toggleTasksAscending).then((res)=>{
        this.fetchedTasks = res.data;
        this.fetchedTasksCopy = Object.assign([], this.fetchedTasks);
        console.log(res.data);
      }).catch((err)=>{
        console.log(err);
      });
    },
    getProjects() {
      this.projectApi.getProjects().then((res)=>{
        this.projects = res.data;
      })
    },
    loggi() {
      console.log(this.selectedProject);
    },
    filterTasks() {
      if(this.searchText === null || this.searchText === '') {
        this.getTasks();
        return;
      }
      const searchTextLowerCase = this.searchText.toLowerCase();
      this.fetchedTasks = this.fetchedTasksCopy.filter(task => {
        const titleMatch = task.title.toLowerCase().includes(searchTextLowerCase);
        const descriptionMatch = task.description.toLowerCase().includes(searchTextLowerCase);
        return titleMatch || descriptionMatch;
      });
    },
    ...mapActions(piniaStore, ["getBonuses"]),
    ...mapActions(piniaStore, ["getCostCenters"]),
    ...mapActions(piniaStore, ["getUsers"]),
    ...mapActions(piniaStore, ["setRunningTaskBadge"]),
    ...mapActions(piniaStore, ["setRunningTaskId"]),
    ...mapActions(piniaStore, ["startTask"]),
    ...mapActions(piniaStore, ["stopTask"]),
  },
  created() {
    this.preparedTask = Object.assign({}, this.defaultPreparedTask);
    this.timeTrackingApi = new TimeTrackingApi();
    this.projectApi = new ProjectApi();
    this.workspaceDataApi = new WorkspaceDataApi();
    this.getProjects();
    this.getTasks();
  }
}
</script>

<style scoped>

</style>