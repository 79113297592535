<template>
  <v-dialog v-model="task_dialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-if="task == null && !icon"
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
      >
        Neue Aufgabe
      </v-btn>
      <v-btn
          v-if="task == null && icon"
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
          icon
      >
        <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
      </v-btn>
      <v-btn
          v-if="task !== null && icon"
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
          icon
          small
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
          v-if="task !== null && !icon"
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
      >
        Bearbeiten
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{taskDialogTitle}}</v-card-title>
      <v-card-text>
        <v-form
            ref="taskForm"
            v-model="validTaskForm"
        >
          <v-text-field
              label="Titel *"
              v-model="editedTask.title"
              :rules="[rules.required]"
              outlined
          ></v-text-field>
          <v-textarea
              label="Beschreibung"
              v-model="editedTask.description"
              outlined
          ></v-textarea>
          <v-text-field
              label="Dauer"
              v-model="editedTask.duration"
              disabled
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
          ></v-text-field>
          <v-autocomplete
              v-model="editedTask.supplying_costcenterid"
              :items="costCenters"
              item-text="name"
              item-value="id"
              label="Leistende Kostenstelle"
              :filter="customCostcenterFilter"
              chips
              clearable
              outlined
          >
            <template v-slot:selection="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
            <template slot="item" slot-scope="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
          </v-autocomplete>
          <v-autocomplete
              v-model="editedTask.receiving_costcenterid"
              :items="costCenters"
              item-text="label"
              item-value="id"
              label="Empfangende Kostenstelle *"
              :filter="customCostcenterFilter"
              :rules="[rules.required]"
              required
              chips
              persistent-hint
              clearable
              outlined
          >
            <template v-slot:selection="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
            <template slot="item" slot-scope="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
          </v-autocomplete>
          <v-combobox
              v-model="editedTask.bonuses"
              :items="bonuses"
              item-text="label"
              item-value="id"
              label="Zuschläge"
              multiple
              chips
              outlined
          >
            <template slot="item" slot-scope="data">
              {{data.item.label}} - {{data.item.name}}
            </template>
          </v-combobox>
          <v-autocomplete
              v-model="editedTask.assignedUser"
              :items="filteredUsers"
              item-text="name"
              item-value="id"
              label="Benutzer zuweisen"
              chips
              clearable
              outlined
          >
          </v-autocomplete>
          <v-autocomplete
              v-model="editedTask.assignedTeams"
              :items="teams"
              item-text="name"
              item-value="id"
              label="Team zuweisen"
              multiple
              chips
              clearable
              outlined
          >
          </v-autocomplete>
          <v-autocomplete
              v-model="editedTask.assignedUsers"
              :items="filteredUsers"
              item-text="name"
              item-value="id"
              label="Benutzern zuweisen"
              multiple
              chips
              clearable
              outlined
          >
          </v-autocomplete>
          <v-autocomplete
              v-if="keycloak.tokenParsed.email === 'rene@codefive.de'"
              v-model="editedTask.projectId"
              :items="projects"
              item-text="name"
              item-value="id"
              label="Projekt zuweisen"
              clearable
              outlined
          >
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-none d-sm-flex">
        <v-spacer></v-spacer>
        <v-btn @click="closeTaskDialog" text>Abbrechen</v-btn>
        <v-btn @click="saveTask(false)" :disabled="!validTaskForm" outlined>Speichern</v-btn>
        <v-btn v-if="editedTask.id !== runningTaskId" @click="saveTask(true)" :disabled="!validTaskForm" color="secondary" outlined>Speichern & Starten</v-btn>
      </v-card-actions>
      <v-card-actions class="d-flex d-sm-none">
        <v-row dense>
          <v-col cols="12"><v-btn v-if="editedTask.id !== runningTaskId" @click="saveTask(true)" :disabled="!validTaskForm" color="secondary" outlined block>Speichern & Starten</v-btn></v-col>
          <v-col cols="12"><v-btn @click="saveTask(false)" :disabled="!validTaskForm" outlined block>Speichern</v-btn></v-col>
          <v-col cols="12"><v-btn @click="closeTaskDialog" text block>Abbrechen</v-btn></v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/main";
import { mapState, mapActions } from 'pinia';
import {piniaStore} from "@/store/pinia";
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import ProjectApi from "@/apis/ProjectApi";

export default {
  name: "TaskDialog",
  //props: ['task'],
  props: {
    task: {
      type: Object,
      default: null,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    parentTaskId: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      default: null,
    }
  },
  /* watch: {
    task_dialog: val => {
      console.log("Watching task_dialog")
      if(val) {
        console.log("task_dialog: " + val)
        this.editedTask = Object.assign({}, this.task);
      } else {
        console.log("task_dialog (2): " + val)
      }
    },
  }, */
  data: () => ({
    timeTrackingApi: null,
    projectApi: null,
    task_dialog: false,
    taskDialogTitle: 'Neue Aufgabe',
    validTaskForm: false,
    editedTask: {},
    defaultTask: {
      title: '',
      description: '',
      status: 'created',
      bonuses: [],
      receiving_costcenterid: null,
      supplying_costcenterid: null,
      assignedUser: null,
      projectId: null,
      parentTaskId: null,
    },
    projects: [],
    receiving_costcenter: null,
    supplying_costcenter: null,
    rules: {
      required: value => !!value || 'Erforderlich',
    },
    filteredUsers: [],
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['runningTask']),
    ...mapState(piniaStore, ['runningTaskId']),
    ...mapState(piniaStore, ['runningTimetrackEventId']),
    ...mapState(piniaStore, ['teams']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['users']),
  },
  methods: {
    closeTaskDialog() {
      this.task_dialog = false;
      this.resetTaskDialog();
    },
    customCostcenterFilter (item, queryText, itemText) {
      const name = item.name.toLowerCase()
      const label = item.label.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 ||
          label.indexOf(searchText) > -1
    },
    getProjects() {
      this.projectApi.getProjects().then((res) => {
        this.projects = res.data;
      })
    },
    resetTaskDialog() {
      this.$refs.taskForm.resetValidation();
      if(this.task !== null) {
        this.editedTask = Object.assign({}, this.task);
      } else {
        this.editedTask = Object.assign({}, this.defaultTask);
      }
    },
    saveTask(start) {
      console.dir(this.editedTask);
      if(this.taskDialogTitle === 'Neue Aufgabe') {
        this.editedTask.createdBy = this.user.id;
        if(this.editedTask.assignedUser == null || this.editedTask.assignedUser == "") {
          this.editedTask.assignedUser = this.user.id;
        }
        this.timeTrackingApi.createTask(this.editedTask)
            .then((res)=>{
              if(start) {
                this.startTask(res.data);
              }
            })
            .catch((error) => {
              console.log(error);
              this.setErrorMessage("Fehler beim Anlegen der Aufgabe");
            })
            .finally(()=>{
              this.getTasks();
            });
      } else {
        this.timeTrackingApi.updateTask(this.editedTask)
            .then((res)=>{
              if(start) {
                this.startTask(res.data);
              }
            })
            .catch((error) => {
              console.log(error);
              this.setErrorMessage("Fehler beim Aktualisieren der Aufgabe");
            })
            .finally(()=>{
              this.getTasks();
            });
      }
      this.closeTaskDialog();
    },
    startTask(task) {
      this.startTimetrackTask(task.id);
      // scrollToTop(300);
    },
    filterUsers() {
      console.log(this.users)
      let mailFilter = "uweg.at"
      if (process.env.NODE_ENV === 'development') {
        mailFilter = "codefive.de"
      }
      this.filteredUsers = this.users
          .filter((user) => user.email.endsWith(mailFilter))
          .map(({id, firstname, lastname}) => ({
            id,
            name: `${firstname} ${lastname}`,
          }))
      console.log(this.filteredUsers)
    },
    ...mapActions(piniaStore, ["getTasksCurrent"]),
    ...mapActions(piniaStore, ["getBonuses"]),
    ...mapActions(piniaStore, ["getCostCenters"]),
    ...mapActions(piniaStore, ["getTasks"]),
    ...mapActions(piniaStore, ["getTeams"]),
    ...mapActions(piniaStore, ["getUsers"]),
    ...mapActions(piniaStore, ["setErrorMessage"]),
  },
  created() {
    this.timeTrackingApi = new TimeTrackingApi();
    this.projectApi = new ProjectApi();
    if(this.task !== null) {
      this.editedTask = Object.assign({}, this.task);
      this.taskDialogTitle = "Aufgabe bearbeiten";
    } else {
      this.editedTask = Object.assign({}, this.defaultTask);
      this.taskDialogTitle = "Neue Aufgabe";
      if(this.projectId) {
        this.editedTask.projectId = this.projectId;
      }
      if(this.parentTaskId) {
        this.editedTask.parentTaskId = this.parentTaskId;
      }
    }
    this.getBonuses();
    this.getCostCenters();
    this.getProjects();
    this.getTeams();
    this.getUsers();
    this.filterUsers();
  },
};
</script>