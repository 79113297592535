<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/cairos.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Willkommen bei Cairos
        </h1>
        <p class="subheading font-weight-regular">
          Sie nutzen die aktuellste Version
          <br />der Prozess- und Zeitmanagementapp.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
  }),
};
</script>
