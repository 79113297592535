import axios from "axios";
import {piniaStore} from "@/store/pinia";
import Config from "@/apis/Config";

export default class ProjectApi {
    http;
    store;
    constructor() {
        this.store = piniaStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            }
        });
        this.http.interceptors.request.use((config) => {
            config.baseURL = Config.baseUrl();
            config.headers = {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            };
            return config;
        });
    }

    getProjects() {
        return this.http.get("/api/v1/projects/")
    }

    getProject(id) {
        return this.http.get("/api/v1/projects/" + id)
    }

    addProject(project) {
        return this.http.post("/api/v1/projects", project)
    }

    updateProject(project) {
        return this.http.put("/api/v1/projects", project)
    }

    deleteProject(id) {
        return this.http.delete("/api/v1/projects/" + id)
    }

    getClients() {
        return this.http.get("/api/v1/clients")
    }

    getClient(id) {
        return this.http.get("/api/v1/clients/" + id)
    }

    addClient(client) {
        return this.http.post("/api/v1/clients", client)
    }

    updateClient(client) {
        return this.http.put("/api/v1/clients", client)
    }

    deleteClient(id) {
        return this.http.delete("/api/v1/clients/" + id)
    }
}