<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Teams</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="teamDialog" fullscreen persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark color="#f71784" @click="openTeamCreateDialog" outlined class="mr-3"><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-title>Team hinzufügen</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Teamname"
                        prepend-icon="mdi-account-group"
                        v-model="editTeam.name"
                        outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                        label="Teamleiter"
                        prepend-icon="mdi-account-tie"
                        v-model="editTeam.primaryManager"
                        :items="users"
                        outlined
                        item-value="id"
                        clearable
                        chips
                    >
                      <template v-slot:selection="data">
                        <v-chip>
                          <v-avatar left>
                            <v-img :src="baseUrl + data.item.avatar"></v-img>
                          </v-avatar>
                          {{ data.item.firstname }} {{ data.item.lastname }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img :src="baseUrl + data.item.avatar">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.lastname"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.firstname"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                        label="Vertretung"
                        prepend-icon="mdi-account-tie"
                        v-model="editTeam.secondaryManager"
                        :items="users"
                        outlined
                        item-value="id"
                        clearable
                        chips
                    >
                      <template v-slot:selection="data">
                        <v-chip>
                          <v-avatar left>
                            <v-img :src="baseUrl + data.item.avatar"></v-img>
                          </v-avatar>
                          {{ data.item.firstname }} {{ data.item.lastname }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img :src="baseUrl + data.item.avatar">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.lastname"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.firstname"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                        label="Vertretung (temporär)"
                        prepend-icon="mdi-account-clock"
                        v-model="editTeam.temporaryManager"
                        :items="users"
                        outlined
                        item-value="id"
                        clearable
                        chips
                    >
                      <template v-slot:selection="data">
                        <v-chip>
                          <v-avatar left>
                            <v-img :src="baseUrl + data.item.avatar"></v-img>
                          </v-avatar>
                          {{ data.item.firstname }} {{ data.item.lastname }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img :src="baseUrl + data.item.avatar">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.lastname"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.firstname"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closeTeamEditDialog" outlined>Abbrechen</v-btn>
                <v-btn v-if="editMode" @click="updateTeam" color="primary" outlined>Aktualisieren</v-btn>
                <v-btn v-else @click="addTeam" color="primary" outlined>Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn icon outlined @click="getTeams"><v-icon>mdi-sync</v-icon></v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-expansion-panels flat>
          <v-expansion-panel v-for="team in teams" :key="team.id">
            <v-expansion-panel-header>
              <v-row>
                <v-col
                    cols="4"
                    sm="2"
                    md="1"
                >
                  <v-avatar size="24px">
                    <img v-if="team.avatar == null || team.avatar ==''" :src="baseUrl + '/images/avatars/icons8-business_conference_female_speaker.png'">
                    <img v-else :src="baseUrl + team.avatar">
                  </v-avatar>
                </v-col>
                <v-col
                    cols="8"
                    sm="10"
                    md="11"
                >
                  {{team.name}}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h3>Verantwortlich</h3>
              <span v-if="team.primaryManager">
                Teamleiter: {{fetchUserData(team.primaryManager).firstname}} {{fetchUserData(team.primaryManager).lastname}} <br>
              </span>
              <span v-if="team.secondaryManager">
                Vertretung: {{fetchUserData(team.secondaryManager).firstname}} {{fetchUserData(team.secondaryManager).lastname}} <br>
              </span>
              <span v-if="team.temporaryManager">
                Vertretung (temporär): {{fetchUserData(team.temporaryManager).firstname}} {{fetchUserData(team.temporaryManager).lastname}} <br>
              </span>
              <h3 class="mt-3">Mitglieder</h3>
              <v-row>
                <v-col class="text-right">
                  <TeamMemberDialog v-bind:team-id="team.id" @dialogClosedEvent="getTeams"></TeamMemberDialog>
                </v-col>
              </v-row>
              <v-list v-if="team.members.length > 0">
                <v-list-item v-for="m in team.members" :key="m">
                  <ListAvatar v-bind:user="fetchUserData(m)"></ListAvatar>
                  <v-list-item-title>{{fetchUserData(m).firstname}} {{fetchUserData(m).lastname}}</v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon @click="removeMember(team.id, m)"><v-icon>mdi-account-minus</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-alert v-else>Keine Mitglieder</v-alert>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img v-if="team.avatar == null" :src="baseUrl + '/images/avatars/icons8-business_conference_female_speaker.png'"></v-img>
                  <v-img v-else :src="baseUrl + team.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{team.name}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="openTeamEditDialog(team)"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn icon @click="deleteTeam(team.id)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
              <div class="font-weight-light text-left">
                Angelegt am: {{team.created | dateFormatSimple}}<br/>
                <span v-if="team.updated">Zuletzt bearbeitet am: {{team.updated | dateFormatSimple}}</span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import WorkspaceDataApi from "@/apis/WorkspaceDataApi";
import ListAvatar from "@/components/ListAvatar.vue";
import TeamMemberDialog from "@/components/TeamMemberDialog.vue";
export default {
  name: "TeamManager",
  components: {TeamMemberDialog, ListAvatar},
  data: () => ({
    // APIs
    workspaceDataApi: null,
    active: [],
    open: [],
    saving: false,
    deleting: false,
    info: '',
    teamDialog: false,
    teamMemberDialog: false,
    isEditing: null,
    team_edit: null,
    members_edit: {
      old: null,
      id: null,
    },
    teamname: '',
    teams: [],
    //users: [],
    // ...
    isUpdating: true,

    // testing new adding member feature
    addingMember: [],
    newTeamMembers: [],
    teamlead: null,
    teamlead2: null,
    defaultTeam: {
      name: "",
      avatar: "",
      admin: [],
      members: [],
      primaryManager: null,
      secondaryManager: null,
      temporaryManager: null,
    },
    editTeam: {},
    editMode: false,
  }),
  methods: {
    openTeamCreateDialog() {
      this.editTeam = Object.assign({}, this.defaultTeam);
      this.editMode = false;
      this.teamDialog = true;
    },
    openTeamEditDialog(team) {
      this.editTeam = Object.assign({}, team);
      this.editMode = true;
      this.teamDialog = true;
    },
    closeTeamEditDialog() {
      this.editTeam = Object.assign({}, this.defaultTeam);
      this.editMode = false;
      this.teamDialog = false;
    },
    removeMember(teamId, userId) {
      let data = [];
      data.push(userId)
      this.workspaceDataApi.removeTeamMembers(teamId, data).then(()=>{
        this.getTeams();
      });
    },
    addingNewTeamMembers() {
      this.addingMember.forEach(id => {
        this.editTeam.members.push(id)
      })
      this.addingMember = []
    },
    deletingNewTeamMembers(id) {
      this.editTeam.members = this.editTeam.members.filter(el => el !== id)
    },
    fetchAvatarSrc(id) {
      if(id) {
        let u = this.userMap.get(id)
        if(u) {
          return u.lastname + ' ' + u.firstname
        }
      }
      return "nada"
    },
    fetchUserData(id) {
      if(id) {
        let u = this.userMap.get(id)
        if(u) {
          return u
        }
      }
      return {lastname: "N/A", firstname: "N/A"}
    },
    addTeam() {
      this.workspaceDataApi.createTeam(this.editTeam)
          .then((response) => {
            this.teams.push(response.data)
            this.closeTeamEditDialog();
          })
          .catch((error) => {
            console.log(error)
          });
    },
    updateTeam() {
      this.workspaceDataApi.updateTeam(this.editTeam)
          .then((res) => {
            console.log(res.data);
            this.getTeams();
            this.closeTeamEditDialog();
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getTeams() {
      console.log(this.baseUrl + "/api/v1/teams");
      this.workspaceDataApi.getTeams().then((response) => {
        this.teams = response.data;
        this.teams = this.teams.filter(
            t => t.deleted == null
        );
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    },
    deleteTeam(id) {
      this.workspaceDataApi.deleteTeam(id)
          .then(() => {
            this.getTeams();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    ...mapActions(piniaStore, ["getUsers"]),
  },
  watch: {
    teams: {
      deep: true,
      handler () {
        console.log(this.teams)
      }
    },
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token', 'users', 'userMap', 'keycloak']),
  },
  mounted: function () {
    console.log("mounted teams");
    // this.getTeams()
    // this.getUsers()
  },
  created() {
    console.log("created teams");
    this.workspaceDataApi = new WorkspaceDataApi();
    this.editTeam = Object.assign({}, this.defaultTeam);
    this.getTeams();
    this.getUsers();
    this.getTransformedUsers();
  }
};
</script>