<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="leadCategories"
            item-key="id"
            :expanded.sync="expanded"
            single-expand
            show-expand
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title @click.meta.shift="setAdmin">Lead Kategorien</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="leadCategoryDialog"
                  fullscreen
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Neue Kategorie
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{leadCategoryDialogTitle}}</v-card-title>
                  <v-card-text>
                    <v-form
                        ref="form"
                        v-model="leadCategoryFormValid"
                    >
                      <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              label="Kategorie"
                              v-model="editedLeadCategory.name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4" >
                          <v-text-field
                              label="Beschreibung"
                              v-model="editedLeadCategory.description"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="closeLeadCategoryDialog">Abbrechen</v-btn>
                    <v-btn @click="save">Speichern</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  v-model="leadCategoryDialogDelete"
                  max-width="500px"
              >
                <v-card>
                  <v-card-title>Kategorie wirklich löschen?</v-card-title>
                  <v-card-actions>
                    <v-btn @click="closeDeleteLeadCategory">Abbrechen</v-btn>
                    <v-btn @click="deleteLeadCategoryConfirm">Löschen</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn icon class="mb-2 ml-2" @click="getLeadCategories"><v-icon>mdi-sync</v-icon></v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editLeadCategory(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteLeadCategory(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="exp-row">
              <h4>Details zu {{item.firstname}} {{ item.lastname }}</h4>
              <div>
                🌐 <a :href="item.url">{{item.url}}</a><br>
                🔗 <a :href="item.linkedin">LinkedIn</a><br>
                📱 <a :href="`tel:${item.phone}`">{{item.phone}}</a><br>
              </div>
              <div v-if="item.notes">
                <hr>
                <h4>Notizen</h4>
                <pre>{{item.notes}}</pre>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="showAdmin">
      <v-card-title>Admin Options</v-card-title>
      <v-card-text>Please use carefully. All actions are instant.</v-card-text>
      <v-card-actions>
        <v-btn @click="showAdmin=false">Cancel</v-btn>
        <v-btn @click="dropLeads" color="primary">Drop</v-btn>
      </v-card-actions>
    </v-card>
    <v-btn to="/leads" class="mt-3">zurück zu Leads</v-btn>
  </v-container>
</template>

<script>
import {mapState} from "pinia/dist/pinia";
import {piniaStore} from "@/store/pinia";
import axios from "@/main";

export default {
  name: "LeadCategoryManager",
  data: () => ({
    headers: [
      {text: "Kategorie", value: "name"},
      {text: "Beschreibung", value: "description"},
      {text: 'Actions', value: 'actions', sortable: false},
      {text: '', value: 'data-table-expand'},
    ],
    expanded: [],
    leadCategories: [],
    leadCategoryDialog: false,
    leadCategoryDialogTitle: 'Kategorie erstellen',
    leadCategoryFormValid: false,
    leadCategoryDialogDelete: false,
    editedLeadCategory: {},
    defaultLeadCategory: {
      name: '',
      description: '',
    },
    showAdmin: false,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token']),
  },
  methods: {
    closeDeleteLeadCategory() {
      this.leadCategoryDialogDelete = false
      this.$nextTick(() => {
        this.editedLeadCategory = Object.assign({}, this.defaultLeadCategory);
      });
    },
    closeLeadCategoryDialog() {
      this.editedLeadCategory = Object.assign({}, this.defaultLeadCategory);
      this.leadCategoryDialog = false;
      this.leadCategoryDialogTitle = "Kategorie erstellen";
    },
    deleteLeadCategory(leadCategory) {
      this.editedLeadCategory = Object.assign({}, leadCategory);
      this.leadCategoryDialogDelete = true;
    },
    deleteLeadCategoryConfirm() {
      axios
          .delete(this.baseUrl + "/api/v1/leadcategories/delete/" + this.editedLeadCategory.id, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then(()=>{
            this.getLeadCategories();
            this.closeDeleteLeadCategory();
          })
    },
    dropLeads() {
      axios
          .delete(this.baseUrl + "/api/v1/leadcategories/drop", {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then(() => {
            this.getLeads();
          });
    },
    editLeadCategory(leadCategory) {
      this.leadCategoryDialogTitle = "Kategorie bearbeiten";
      this.editedLeadCategory = Object.assign({}, leadCategory);
      this.leadCategoryDialog = true;
    },
    getLeadCategories() {
      axios.get(this.baseUrl + "/api/v1/leadcategories/get", {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((res)=>{
            this.leadCategories = res.data;
          });
    },
    save() {
      if(this.editedLeadCategory.id) {
        this.updateLeadCategory(this.editedLeadCategory);
      } else {
        this.saveLeadCategory(this.editedLeadCategory);
      }
    },
    saveLeadCategory(leadCategory) {
      leadCategory.createdBy = this.payload.user.id;
      axios.post(this.baseUrl + "/api/v1/leadcategories/add", leadCategory, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((res)=>{
            this.getLeadCategories();
            this.closeLeadCategoryDialog();
          });
    },
    updateLeadCategory(leadCategory) {
      axios
          .put(this.baseUrl + "/api/v1/leadcategories/update", leadCategory, {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then(()=>{
            this.getLeadCategories();
            this.closeLeadCategoryDialog();
          });
    },
    setAdmin() {
      if(this.payload.user.email === 'rene@codefive.de') {
        this.showAdmin = true;
      }
    },
  },
  created() {
    this.editedLeadCategory = Object.assign({}, this.defaultLeadCategory);
    this.getLeadCategories();
  },
}
</script>

<style scoped>

</style>