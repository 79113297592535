<template>
<v-container>
  <v-card>
    <v-card-title>Rechnungsverwaltung</v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field label="Rechnungsnummer" outlined v-model="invoice.reference"></v-text-field>
        <v-text-field label="Rechnungsdatum" outlined v-model="invoice.date"></v-text-field>
        <v-text-field label="Leistungsort" outlined v-model="invoice.serviceLocation"></v-text-field>
        <v-text-field label="Kunde" outlined v-model="invoice.customer.name"></v-text-field>
        <v-text-field label="Adresse 1" outlined v-model="invoice.customer.address1"></v-text-field>
        <v-text-field label="Adresse 2" outlined v-model="invoice.customer.address2"></v-text-field>
        <v-text-field label="Adresse 3" outlined v-model="invoice.customer.address3"></v-text-field>
        <v-text-field label="USt. ID" outlined v-model="invoice.customer.vat"></v-text-field>
        <v-text-field label="Summe" outlined v-model="invoice.total"></v-text-field>
        <v-text-field label="Zwischensumme" outlined v-model="invoice.subtotal"></v-text-field>
        <v-text-field label="USt." outlined v-model="invoice.vat"></v-text-field>
        <v-text-field label="Leistungszeitraum" outlined v-model="invoice.serviceInterval"></v-text-field>
        <v-text-field label="Payment in EUR" outlined v-model="invoice.payment.eur"></v-text-field>
        <v-btn outlined @click="updatePayment">Update Payment</v-btn>
        <v-btn outlined @click="addPosition">Position hinzufügen</v-btn>
        <v-row v-for="pos in invoice.positions" :key="pos.id" class="mt-5">
          <v-col cols="12" md="6">
            <v-text-field label="Titel" outlined v-model="pos.title"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Untertitel" outlined v-model="pos.subtitle"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Menge" outlined v-model="pos.quantity"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Preis" outlined v-model="pos.price"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Gesamt" outlined v-model="pos.total"></v-text-field>
          </v-col>
          <v-col cols="8"><v-divider></v-divider></v-col>
          <v-col cols="4"><v-btn outlined color="error" @click="removePosition(pos.id)">Löschen</v-btn></v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="updateInvoice" v-if="invoice.id">Speichern</v-btn>
      <v-btn outlined color="primary" @click="createInvoice" v-else>Erstellen</v-btn>
    </v-card-actions>
    <v-card-actions v-if="invoiceId">
      <a :href="'http://localhost:50001/invoices/' + invoiceId" target="_blank">Zur Rechnung</a>
      <a :href="'/invoices/' + invoiceId" target="_blank">Zur Rechnung</a>
    </v-card-actions>
  </v-card>
  <v-card class="mt-5">
    <v-card-title>Rechnungen</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item v-for="inv in invoices" :key="inv.id">
          <v-list-item-title>{{inv.reference}} - {{inv.customer.name}}</v-list-item-title>
          <v-list-item-action>
            <a :href="'http://localhost:50001/invoices/' + inv.id"><v-icon>mdi-file-upload-outline</v-icon></a>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon outlined @click="loadInvoice(inv.id)"><v-icon>mdi-file-upload-outline</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";
function genId() {
  return (
      Date.now().toString(36) +
      Math.floor(
          Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
      ).toString(36)
  );
}
export default {
  name: "Invoices",
  data: () => ({
    invoice: {
      reference: "RH-2023-08",
      date: "04.10.2023",
      serviceLocation: "Wien",
      serviceInterval: "10.08.2023 - 11.09.2023",
      customer: {
        name: "Wastics GmbH",
        address1: "Vivenotgasse 52/42",
        address2: "AT-1120 Wien",
        address3: "",
        vat: "ATU77896207",
      },
      total: "5.119,42 €",
      subtotal: "5.119,42 €",
      vat: "0,00 €",
      positions: [],
      payment: {
        receiver: "Rene Heinrich",
        iban: "AT362011183957454400",
        bic: "GIBAATWWXXX",
        eur: "5119.42",
        reference: "RH-2023-07",
      },
    },
    defaultPosition: {
      title: "Software Entwicklung",
      subtitle: "",
      quantity: "",
      price: "",
      total: "",
    },
    invoiceId: null,
    invoices: [],
  }),
  methods: {
    addPosition() {
      let position = Object.assign({}, this.defaultPosition);
      position.id = genId();
      position.subtitle = this.invoice.serviceInterval;
      this.invoice.positions.push(position);
    },
    removePosition(id) {
      let index = this.invoice.positions.findIndex(obj => obj.id === id);
      if (index !== -1) {
        this.invoice.positions.splice(index, 1);
      }
    },
    createInvoice() {
      this.updatePayment();
      axios.post("/api/v1/invoices", this.invoice).then((res) => {
        this.invoiceId = res.data.id;
        this.getInvoices();
      })
    },
    updateInvoice() {
      this.updatePayment();
      axios.put("/api/v1/invoices", this.invoice).then((res) => {
        this.invoiceId = res.data.id;
        this.getInvoices();
      })
    },
    getInvoices() {
      axios.get("/api/v1/invoices").then((res) => {
        this.invoices = res.data;
      });
    },
    formatPaymentSum(inputSum) {
      let numericString = inputSum.replace('.', '').replace('€', '').replace(',', '.').trim();
      return numericString;
    },
    updatePayment() {
      this.invoice.payment.eur = this.formatPaymentSum( this.invoice.total );
      this.invoice.payment.reference = this.invoice.reference;
    },
    loadInvoice(id) {
      axios.get("/api/v1/invoices/" + id).then((res) => {
        this.invoice = res.data;
      })
    },
  },
  created() {
    this.getInvoices();
  }
}
</script>

<style scoped>

</style>