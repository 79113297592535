<template>
<v-container>
  <v-card>
    <v-img :src="headerImage" height="30vh" style="background-color: #777777;" @click="$refs.headerFile.click()"></v-img>
    <input type="file" ref="headerFile" style="display: none" @change="onHeaderFileSelected" accept="image/png, image/gif, image/jpeg">
    <v-dialog v-model="dialogHeaderImage" width="500">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-img :src="headerImage" height="30vh" style="background-color: #777777;" @click="$refs.headerFile.click()" v-bind="attrs" v-on="on"></v-img>

      </template> -->
      <v-card>
        <v-card height="70vh" elevation="0">
          <cropper :src="imgHeader"
                   :stencil-props="stencilPropsHeader"
                   ref="cropperHeader"
                   v-if="imgHeader"
          >
          </cropper>
        </v-card>
        <v-card-actions>
          <v-btn @click="dialogHeaderImage = false" outlined>Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="uploadHeaderImage" outlined color="primary">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text style="margin-top: -75px;">
      <input type="file" ref="file" style="display: none" @change="onFileSelected" accept="image/png, image/gif, image/jpeg">
      <v-avatar :color="transparentIfAvatar()" :size="avatarSize"
                @click="$refs.file.click()">
        <img v-if="avatar" :src="avatar" alt="X">
        <span v-else class="avatar-item white--text text-h5">{{user.firstname.substr(0,1)}} {{user.lastname.substr(0,1)}}</span>
      </v-avatar>
      <v-dialog v-model="dialog" width="500">
        <!-- <template v-slot:activator="{ on, attrs }">
          <input type="file" ref="file" style="display: none" @change="onFileSelected" accept="image/png, image/gif, image/jpeg">
          <v-avatar :color="transparentIfAvatar()" :size="avatarSize"
                    @click="$refs.file.click()" v-bind="attrs" v-on="on">
            <img v-if="avatar" :src="avatar" alt="X">
            <span v-else class="avatar-item white--text text-h5">{{user.firstname.substr(0,1)}} {{user.lastname.substr(0,1)}}</span>
          </v-avatar>
        </template> -->
        <v-card>
          <v-card height="70vh" elevation="0">
            <cropper :src="img"
                     @change="change"
                     :stencil-props="stencilProps"
                     ref="cropper"
                     v-if="img"
            >
            </cropper>
          </v-card>
          <v-card-actions>
            <v-btn @click="dialog = false" outlined>Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="uploadImage" outlined color="primary">Upload</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-card-title>{{user.firstname}} {{user.lastname}}</v-card-title>
    <v-card-text>
      Hier sind Details zum Profil {{user.avatar}}
    </v-card-text>
    <v-card-title>Info</v-card-title>
    <v-card-text>
      <v-text-field label="Vorname" v-model="user.firstname" disabled></v-text-field>
      <v-text-field label="Nachname" v-model="user.lastname" disabled></v-text-field>
      <v-text-field label="Rolle" v-model="user.role" disabled></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="reloadUser">Neu laden</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import axios from "axios";
import Config from "@/apis/Config";

export default {
  name: "Profile",
  components: {Cropper},
  data: () => ({
    dialog: false,
    dialogHeaderImage: false,
    headerImage: null,
    avatar: null,
    avatarStatus: true,
    avatarSize: 120,
    coordinates: null,
    //img: "https://images.pexels.com/photos/4323307/pexels-photo-4323307.jpeg",
    img: null,
    imgHeader: null,
    selectedFile: null,
    selectedFileHeader: null,
    stencilProps: { aspectRatio: 1/1},
    stencilPropsHeader: { aspectRatio: 2/1},
    authConfig: null,
  }),
  computed: {
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
  },
  methods: {
    transparentIfAvatar() {
      if(this.avatar){
        return "transparent";
      }
      return "primary";
    },
    change({coordinates, canvas}) {
      this.coordinates = coordinates
    },
    onFileSelected(event){
      //console.log(event)
      try{
        this.selectedFile = event.target.files[0]
        this.img = URL.createObjectURL(this.selectedFile)
        this.dialog = true;
      } catch {
        this.img = null
      }
    },
    onHeaderFileSelected(event){
      //console.log(event)
      try{
        this.selectedFileHeader = event.target.files[0]
        this.imgHeader = URL.createObjectURL(this.selectedFileHeader)
        this.dialogHeaderImage = true;
      } catch {
        this.imgHeader = null
      }
    },
    cropImage() {
      const result = this.$refs.cropper.getResult();
      const newTab = window.open();
      // for testing open the result in a new tab
      newTab.document.body.innerHTML = `<img src="${result.canvas.toDataURL(
          "image/jpeg"
      )}"></img>`;
    },
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const formData = new FormData();
        canvas.toBlob(async blob => {
          formData.append(
              "File",
              blob,
              "myImageName.png"
          );
          // add optional stuff like a path to save the image
          // formData.append("path", "/theKoiIsAwesome");
          // You can use axios, fetch and other libraries instead here
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Bearer ${this.keycloak.token}`
            }
          };
          axios.post(Config.baseUrl() + "/api/v1/upload", formData, config).then((res) => {
            this.avatar = Config.baseUrl() + "/uploads/" + res.data[0].id;
            axios.post(Config.baseUrl() + "/api/v1/users/updateAvatar", {avatar: "/uploads/" + res.data[0].id}, this.authConfig)
                .then((res) => {
                  if(res.data.avatar) {
                    if(res.data.avatar.startsWith("/")) {
                      this.avatar = Config.baseUrl() + res.data.avatar;
                    } else {
                      this.avatar = res.data.avatar;
                    }
                  }
                  this.reloadUser();
                })
                .catch((err) => {
                  console.log(err)
                })
            console.log(this.avatar);
            this.dialog = false;
          });
          // Perhaps you should add the setting appropriate file format here
        }, "image/png");
      }
    },
    uploadHeaderImage() {
      const { canvas } = this.$refs.cropperHeader.getResult();
      if (canvas) {
        const formData = new FormData();
        canvas.toBlob(async blob => {
          formData.append(
              "File",
              blob,
              "myImageName.png"
          );
          // add optional stuff like a path to save the image
          // formData.append("path", "/theKoiIsAwesome");
          // You can use axios, fetch and other libraries instead here
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Bearer ${this.keycloak.token}`
            }
          };
          axios.post(Config.baseUrl() + "/api/v1/upload", formData, config).then((res) => {
            this.headerImage = Config.baseUrl() + "/uploads/" + res.data[0].id;
            axios.post(Config.baseUrl() + "/api/v1/users/updateHeaderImage", {headerImage: "/uploads/" + res.data[0].id}, this.authConfig)
                .then((res) => {
                  if(res.data.headerImage) {
                    if(res.data.headerImage.startsWith("/")) {
                      this.headerImage = Config.baseUrl() + res.data.headerImage;
                    } else {
                      this.headerImage = res.data.headerImage;
                    }
                  }
                  this.reloadUser();
                })
                .catch((err) => {
                  console.log(err)
                })
            this.dialogHeaderImage = false;
          });
          // Perhaps you should add the setting appropriate file format here
        }, "image/png");
      }
    },
    ...mapActions(piniaStore, ["reloadUser"]),
  },
  created() {
    this.authConfig = {
      headers: {
        "Authorization": `Bearer ${this.keycloak.token}`
      }
    };
    if(this.user.avatar) {
      if(this.user.avatar.startsWith("/")) {
        this.avatar = Config.baseUrl() + this.user.avatar;
      } else {
        this.avatar = this.user.avatar;
      }
    }
    if(this.user.headerImage) {
      if(this.user.headerImage.startsWith("/")) {
        this.headerImage = Config.baseUrl() + this.user.headerImage;
      } else {
        this.headerImage = this.user.headerImage;
      }
    }
  }
}
</script>

<style scoped>

</style>