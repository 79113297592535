import {piniaStore} from "@/store/pinia";
import axios from "axios";
import Config from "@/apis/Config";

export default class WorkspaceDataApi {
    http;
    store;

    constructor() {
        this.store = piniaStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            }
        });
        this.http.interceptors.request.use((config) => {
            config.baseURL = Config.baseUrl();
            config.headers = {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            };
            return config;
        });
    }

    getIncidents() {
        return this.http.get("/api/v1/incidents");
    }

    getIncident(id) {
        return this.http.get("/api/v1/incidents/" + id);
    }

    addIncident(incident) {
        return this.http.post("/api/v1/incidents", incident);
    }

    addIncidentFile(id, uploadIDs) {
        return this.http.post("/api/v1/incidents/incident-uploads/" + id, {
            uploadInformationIds: uploadIDs,
        }, {
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`,
            }});
    }

    getIncidentAttachments(incidentID) {
        return this.http.get("/api/v1/incidents/incident-uploads/" + incidentID);
    }

    addIncidentFileDeprecated(id, formData) {
        return this.http.post("/api/v1/incidents/incident-upload/" + id, formData, {
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`,
                'Content-Type': 'multipart/form-data',
            }});
    }

    updateIncident(incident) {
        return this.http.put("/api/v1/incidents/" + incident.id, incident);
    }

    updateIncidentStatus(id, status) {
        return this.http.put("/api/v1/incidents/status/" + status + "/" +  id, {});
    }

    getIncidentProtocol(id) {
        return this.http.get("/api/v1/incidents/protocol/" + id);
    }

    deleteIncident(id) {
        return this.http.delete("/api/v1/incidents/" + id);
    }

    addIncidentComment(incidentId, comment) {
        return this.http.post("/api/v1/incidents/comment/" + incidentId, comment);
    }
    deleteComment(id) {
        return this.http.delete("/api/v1/incidents/comment/" + id);
    }
}