<template>
<v-container>
  <v-card>
    <v-card-title>Projektaufgaben</v-card-title>
    <v-card-text>
      <v-select label="Projekt wählen" :items="projects" item-text="name" item-value="id" v-model="selectedProject" outlined clearable @change="getTasks"></v-select>
      <pre>{{selectedProject}}</pre>
      <h3>Aufgaben</h3>
      <v-row dense>
        <v-col cols="6"><v-text-field label="Titel" v-model="newTaskTitle" outlined :disabled="!selectedProject"></v-text-field></v-col>
        <v-col cols="4"><v-text-field label="Parent" v-model="newParentTask" outlined :disabled="!selectedProject"></v-text-field></v-col>
        <v-col cols="2" class="text-right"><v-btn icon color="primary" outlined :disabled="!selectedProject" @click="createTask"><v-icon>mdi-plus</v-icon></v-btn></v-col>
      </v-row>
      <pre>{{treeViewTasks}}</pre>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <v-treeview :items="treeViewTasks" :open="openNodes" activatable :active.sync="active">
            <template v-slot:label="{ item, open }">
              <span @click="toggleNode(item)">
                {{ item.title }}
              </span>
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="7">
          <v-row v-if="selected" dense>
            <v-col cols="12"><v-text-field label="Titel" outlined v-model="selected.title"></v-text-field></v-col>
            <v-col cols="12"><v-text-field label="Beschreibung" outlined v-model="selected.description"></v-text-field></v-col>
            <v-col cols="12"><v-select label="Projekt" outlined v-model="selected.projectId" :items="projects" item-text="name" item-value="id"></v-select></v-col>
            <v-col cols="12" class="text-right"><task-dialog :project-id="selected.projectId" :parent-task-id="selected.id"></task-dialog></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import ProjectApi from "@/apis/ProjectApi";
import TaskDialog from "@/components/TaskDialog.vue";

export default {
  name: "ProjectTasks",
  components: {TaskDialog},
  data: () => ({
    projectApi: null,
    timeTrackingApi: null,
    projects: [],
    tasks: [],
    treeViewTasks: [],
    openNodes: [],
    active: [],
    taskMap: null,

    selectedProject: null,

    newTaskTitle: "",
    newParentTask: null,
  }),
  created() {
    this.taskMap = new Map();
    this.projectApi = new ProjectApi();
    this.timeTrackingApi = new TimeTrackingApi();
    this.getProjects();
  },
  computed: {
    selected () {
      if (!this.active.length) return undefined
      const id = this.active[0]
      return this.taskMap.get(id)
    },
  },
  methods: {
    createTask() {
      let parentTask = null;
      if(this.newParentTask !== '') {
        parentTask = this.newParentTask;
      }
      this.timeTrackingApi.createTask({
        title: this.newTaskTitle,
        projectId: this.selectedProject,
        parentTaskId: parentTask,
      }).then(() => {
        this.newTaskTitle = "";
        this.getTasks();
      })
    },
    getProjects() {
      this.projectApi.getProjects().then(res => {
        this.projects = res.data;
      })
    },
    getTasks() {
      this.taskMap = new Map();
      if(!this.selectedProject) {
        this.tasks = [];
        this.treeViewTasks = [];
        return;
      }
      this.timeTrackingApi.getTasks().then(res => {
        this.tasks = res.data.filter(task => task.projectId === this.selectedProject);
        this.tasks.forEach(task => {
          this.taskMap.set(task.id, task);
        });
        this.tasks.forEach(task => {
          if(task.parentTaskId) {
            let parentTask = this.taskMap.get(task.parentTaskId);
            if(parentTask) {
              if(!Object.prototype.hasOwnProperty.call(parentTask, "children")) {
                parentTask["children"] = [];
              }
              parentTask.children.push(task);
            }
          }
        });
        let rootTasks = this.tasks.filter(task => !task.parentTaskId);
        this.treeViewTasks = rootTasks.map(task => this.taskMap.get(task.id));
      })
    },
    toggleNode(item) {
      const index = this.openNodes.indexOf(item.id);
      if (index === -1) {
        this.openNodes.push(item.id);
      } else {
        this.openNodes.splice(index, 1);
      }
    },
  },
}
</script>

<style scoped>

</style>