<template>
<v-container>
  <v-row>
    <v-col cols="3">
      <v-navigation-drawer
          permanent
      >
        <v-list
            nav
            dense
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-inbox-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ausgaben</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
            </v-list-item-icon>
            <v-list-item-title>Eingang</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
            </v-list-item-icon>
            <v-list-item-title>Ausstehend</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
            </v-list-item-icon>
            <v-list-item-title>Freigegeben</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-inbox-arrow-down</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Einnahmen</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
            </v-list-item-icon>
            <v-list-item-title>Eingang</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
            </v-list-item-icon>
            <v-list-item-title>Freigegeben</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Shared with me</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Starred</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <v-col cols="9">
      <v-card flat>

        <v-card-title>Ausgaben</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12"><v-text-field label="Bezeichnung" outlined></v-text-field></v-col>
              <v-col cols="8"><v-text-field label="Gesamtbetrag" outlined></v-text-field></v-col>
              <v-col cols="4"><v-text-field label="Währung" outlined></v-text-field></v-col>
              <v-col cols="12"><v-text-field label="Verantwortlich" outlined></v-text-field></v-col>
              <v-col cols="8"><v-text-field label="Lieferant" outlined></v-text-field></v-col>
              <v-col cols="4"><v-text-field label="Rechnungsdatum" outlined></v-text-field></v-col>
              <v-col cols="8"><v-text-field label="Rechnungsnummer" outlined></v-text-field></v-col>
              <v-col cols="4"><v-text-field label="Zahlungsart" outlined></v-text-field></v-col>
            </v-row>
            <h3>Positionen</h3>
            <v-text-field label="Position" outlined></v-text-field>
            <v-text-field label="Kategorie" outlined></v-text-field>
            <v-text-field label="Betrag" outlined></v-text-field>
            <v-switch label="netto"></v-switch>
            <v-select label="USt." outlined></v-select>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>


  <v-dialog
    v-model="showExpenseDialog"
  >
    <v-card>
      <v-card-title>Ausgabe hinzufügen</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field label="Bezeichnung"></v-text-field>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
export default {
  name: "Finance",
  data: () => ({
    expenses: [],
    revenues: [],
  }),
}
</script>

<style scoped>

</style>