var render, staticRenderFns
import script from "./BonusDialog.vue?vue&type=script&lang=js&"
export * from "./BonusDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BonusDialog.vue?vue&type=custom&index=0&blockType=v-dialog&fullscreen=true&persistent=true&v-model=isOpen"
if (typeof block0 === 'function') block0(component)

export default component.exports