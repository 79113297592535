<template>
<v-container>
  <v-card>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="simpleTasks"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title @click.meta.shift="setAdmin">Aufgaben</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="dialog"
                max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  Neue Aufgabe
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                      ref="form"
                      v-model="valid"
                  >
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedTask.title"
                            label="Titel"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedTask.body"
                            label="Beschreibung"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedTask.date"
                            label="Datum"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-combobox
                            v-model="editedTask.state"
                            solo
                            :items="states"
                            label="Status"
                        ></v-combobox>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <!--<v-time-picker
                            format="24hr"
                            scrollable
                            v-model="editedTask.start"
                            :rules="[rules.timeFormat]"
                            label="Start"
                        ></v-time-picker>-->
                        <v-text-field
                            v-model="editedTask.start"
                            :rules="[rules.timeFormat]"
                            label="Start"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedTask.end"
                            :rules="[rules.timeFormat]"
                            label="Ende"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-combobox
                            v-model="editedTask.project"
                            solo
                            :items="projects"
                            label="Projekt"
                        ></v-combobox>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedTask.user"
                            label="Benutzer"
                            disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Aufgabe wirklich löschen?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteTaskConfirm">Löschen</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn icon class="mb-2 ml-2" @click="getSimpleTasksStub"><v-icon>mdi-sync</v-icon></v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editTask(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteTask(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-expansion-panels v-if="adminMode">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Tasks
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <pre>{{simpleTasks}}</pre>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import * as JSJoda from "@js-joda/core";

export default {
  name: "SimpleTask",
  data: () => ({
    adminMode: false,
    headers: [
      {text: 'Datum', value: 'date'},
      {text: 'Titel', value: 'title'},
      {text: 'Beschreibung', value: 'body'},
      {text: 'Projekt', value: 'project'},
      {text: 'Status', value: 'state'},
      {text: 'Start', value: 'start'},
      {text: 'Ende', value: 'end'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    dialog: false,
    dialogDelete: false,
    editedTask: {},
    defaultTask: {
      title: '',
      body: '',
      date: JSJoda.LocalDate.now(),
      state: 'created',
      project: 'Wastics',
      start: '00:00',
      end: '00:00',
    },
    formTitle: 'Neue Aufgabe',
    projects: ["Wastics", "Code Five", "Cairos", "Kundenportal"],
    states: ["created", "in progress", "done"],
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      counter: value => value.length <= 20 || 'Max 20 Zeichen',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Ungültige E-Mail.';
      },
      timeFormat: value => {
        const pattern = /^([0-9]){2}:([0-9]){2}$/;
        const empty = /^$/;
        return pattern.test(value) || empty.test(value) || 'Ungültiges Zeitformat';
      },
    },
    valid: false,
  }),
  computed: {
    ...mapState(piniaStore, ['payload', 'simpleTasks']),
  },
  methods: {
    setAdmin() {
      if(this.adminMode) {
        this.adminMode = false;
      } else {
        this.adminMode = true;
      }
    },
    close() {
      this.dialog = false;
      this.formTitle = 'Neue Aufgabe';
      this.$nextTick(() => {
        this.editedTask = Object.assign({}, this.defaultTask);
      });
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedTask = Object.assign({}, this.defaultTask)
      });
    },
    save() {
      this.editedTask.user = this.payload.user.id;
      if (this.editedTask.id) {
        console.log("Bearbeiten");
        this.updateSimpleTask(this.editedTask);
      } else {
        this.addSimpleTask(this.editedTask);
      }
      this.close();
    },
    deleteTask(task) {
      this.editedTask = Object.assign({}, task);
      this.dialogDelete = true;
    },
    deleteTaskConfirm() {
      this.deleteSimpleTask(this.editedTask.id);
      this.closeDelete();
    },
    editTask(task) {
      console.log("Edit task");
      this.formTitle = "Aufgabe bearbeiten";
      this.editedTask = Object.assign({}, task);
      this.dialog = true;
    },
    getSimpleTasksStub() {
      this.getSimpleTasks();
    },
    testSimpleTask() {
      console.log("test");
      this.addSimpleTask({
        user: this.payload.user.id,
        title: 'Test Task',
      });
    },
    ...mapActions(piniaStore, ["getSimpleTasks", "addSimpleTask", "deleteSimpleTask", "updateSimpleTask"]),
  },
  created() {
    this.editedTask = Object.assign({}, this.defaultTask);
    this.getSimpleTasksStub();
  }
}
</script>

<style scoped>

</style>