<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Connectivity Test</v-card-title>
          <v-card-subtitle>Bitte Standort-Ermittlung zulassen</v-card-subtitle>
          <v-card-text>
            Requests: {{counter}} <br/>
            Interval handle: {{interval}} <br/>
            Start time: {{startTime}} <br/>
            Genauigkeit: {{currentAltitudeAccuracy}} <br/>
            Altitude: {{currentAltitude}} <br/>
            Parent data: {{xyz}} <br/>
            <v-text-field v-model="testLabel" label="Test Bezeichnung"></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-icon v-if="online" class="teal accent-2" color="white" >mdi-wifi</v-icon>
            <v-icon v-else class="pink" color="white" >mdi-wifi</v-icon>
          </v-card-text>
          <v-card-text>
            <div v-if="errorStr">
              Sorry, but the following error
              occurred: {{errorStr}}
            </div>

            <div v-if="gettingLocation">
              <i>Getting your location...</i>
            </div>

            <div v-if="location">
              Your location data is {{ location.coords.latitude }}, {{ location.coords.longitude}}
            </div>

            <div v-if="currentPosition">
              Your watched location data is {{ currentPosition.latitude }}, {{ currentPosition.longitude}}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
                @click="start()"
                v-if="!isStarted"
            >
              Start
            </v-btn>
            <v-btn
                @click="stop()"
                v-else
            >
              Beenden
            </v-btn>

            <v-btn
                @click="sync()"
                :disabled="doSync"
            >Sync</v-btn>
            {{ syncResult }}
          </v-card-actions>
          <v-card-actions>
            <v-btn
                @click="save()"
            >Speichern</v-btn>
            <v-btn
                @click="load()"
            >Laden</v-btn>
            <v-btn
                @click="clear()"
            >Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>

          <div id="geomap" style="position: relative; height: 300px; z-index: 0;"></div>

          <v-card-actions>
            <v-btn
                @click="showRoute()"
            >Route anzeigen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Aufgaben systemweit beenden</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="stopAllRunningTasks">Alle beenden</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "@/main";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
export default {
  name: "Connectivity",
  data: () => ({
    startTime: null,
    isStarted: false,
    testID: '',
    testLabel: '',
    datagrams: [],
    datagramsSuccess: [],
    datagramsError: [],
    interval: null,
    online: false,
    counter: 0,
    connectFunction: null,
    errorStr: '',
    gettingLocation: false,
    location: null,
    positionWatchHandle: null,
    target: {
      latitude: 0,
      longitude: 0,
    },
    currentPosition: {
      latitude: 0,
      longitude: 0,
    },
    currentAltitude: 0,
    currentAltitudeAccuracy: null,
    currentPath: [],
    currentUser: '',
    doSync: false,
    syncResult: '',
    map: null,
    currentPolyline: null,
    xyz: 'hola',
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['userMap']),
    ...mapState(piniaStore, ['offline']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['runningTask']),
    ...mapState(piniaStore, ['runningTaskId']),
  },
  methods: {
    locate() {
      //do we support geolocation
      if(!("geolocation" in navigator)) {
        this.errorStr = 'Geolocation is not available.';
        return;
      }

      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(pos => {
        this.gettingLocation = false;
        this.location = pos;
      }, err => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      })

      var dis = this
      function success(pos) {
        var crd = pos.coords;
        dis.currentPosition = pos.coords;
        dis.currentAltitude = pos.coords.altitude;
        dis.currentAltitudeAccuracy = pos.coords.altitudeAccuracy;
        var x = [pos.coords.latitude, pos.coords.longitude];
        dis.currentPath.push(x);
        console.log('current path')
        console.log(x)
        console.log(dis.currentPath)
        console.log('pos.coords')
        console.log(pos.coords)

        if (dis.target.latitude === crd.latitude && dis.target.longitude === crd.longitude) {
          console.log('Congratulations, you reached the target');
          navigator.geolocation.clearWatch(dis.positionWatchHandle);
        }
      }

      function error(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
      }

      var options = {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 0
      };

      this.positionWatchHandle = navigator.geolocation.watchPosition(success, error, options);

    },
    start() {
      this.isStarted = true;
      this.startTime = new Date();
      console.log(this.startTime.toISOString())


      this.locate()

      this.testID = '_' + Math.random().toString(36).substr(2, 9);

      this.counter = 0
      var vm = this;
      var user = JSON.parse( window.localStorage.getItem('currentUser') )
      vm.currentUser = user.email;
      this.connectFunction = function() {
        vm.counter = vm.counter + 1
        var data = {
          id: vm.testID,
          label: vm.testLabel,
          user: vm.currentUser,
          package: vm.counter,
          created: new Date().toISOString(),
          interval: vm.interval,
          position: {
            latitude: vm.currentPosition.latitude,
            longitude: vm.currentPosition.longitude,
          },
          altitude: vm.currentAltitude,
          altitudeAccuracy: vm.currentAltitudeAccuracy,
          error: null,
        }
        vm.datagrams.push(data)
        axios.post('/api/v1/connectivity', data)
            .then(function(response) {
              vm.online = true
              vm.datagramsSuccess.push(data)
              console.log(vm.counter)
              console.log(vm.online)
              console.log(vm.interval)
            })
            .catch(function(error) {
              console.log(vm.counter)
              console.log(error)
              data.error = error
              vm.online = false
              vm.datagramsError.push(data)
              // write to local storage and then transmit later
            })
      }
      vm.interval = setInterval(this.connectFunction, 1000)
      console.log(vm.interval)
    },
    stop() {
      console.log('stop called')
      console.log(this.interval)
      clearInterval(this.interval)
      navigator.geolocation.clearWatch(this.positionWatchHandle);
      this.isStarted = false
    },
    save() {
      var storage = window.localStorage;
      storage.setItem('datagrams', JSON.stringify(this.datagrams));
      storage.setItem('datagramsSuccess', JSON.stringify(this.datagramsSuccess));
      storage.setItem('datagramsError', JSON.stringify(this.datagramsError));
    },
    load(){
      var storage = window.localStorage;
      this.datagrams = JSON.parse( storage.getItem('datagrams') )
      this.datagramsSuccess = JSON.parse( storage.getItem('datagramsSuccess') )
      this.datagramsError = JSON.parse( storage.getItem('datagramsError') )
      if (this.datagrams === null) {
        this.datagrams = []
      }
      if (this.datagramsSuccess === null) {
        this.datagramsSuccess = []
      }
      if (this.datagramsError === null) {
        this.datagramsError = []
      }
    },
    clear(){
      var storage = window.localStorage;
      storage.removeItem('datagrams')
      storage.removeItem('datagramsSuccess')
      storage.removeItem('datagramsError')
    },
    sync() {
      var vm = this;
      var data = {
        id: '_' + Math.random().toString(36).substr(2, 9),
        label: vm.testLabel,
        created: new Date().toISOString(),
        datagrams: vm.datagrams,
        datagramsSuccess: vm.datagramsSuccess,
        datagramsError: vm.datagramsError,
      };
      vm.doSync = true;
      vm.syncResult = 'sync in progress'
      axios.post('/api/v1/connectivity/datagrams', data)
          .then(function(response) {
            console.log(response.data)
            vm.doSync = false
            vm.syncResult = 'sync successful'
          })
          .catch(function(error) {
            console.log(error)
            vm.syncResult = 'sync error: '
            vm.doSync = false
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              vm.syncResult = vm.syncResult + 'server responend with ' + error.response.status
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              vm.syncResult = vm.syncResult + 'server did not respond'
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              vm.syncResult = vm.syncResult + error.message
            }
          })
    },
    showRoute() {
      if (this.map == null) {
        this.map = L.map('geomap').setView([48.1690152, 16.3515286], 13);
        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: 'pk.eyJ1IjoicmVuZWhlaW5yaWNoIiwiYSI6ImNqeDYxdXJ0dTA0dnc0OW50ZGlwNTQ2cWcifQ.LmQ1_voOaZeqCRH1GX3F4Q'
        }).addTo(this.map);
      }

      if(this.currentPolyline !== null) {
        this.map.removeLayer( this.currentPolyline );
      }
      this.currentPolyline = L.polyline(this.currentPath, {color: 'red'}).addTo(this.map);

      // zoom the map to the polyline
      this.map.fitBounds(this.currentPolyline.getBounds());
    },
    stopAllRunningTasks() {
      axios.post(this.baseUrl + "/stopThemAll", {}, {headers: {"Authorization" : `Bearer Coh5phiequenaiteinajeemughaepa`}})
          .then((res)=>{
            console.log(res.data);
          })
          .catch((err) => console.log(err));
    },
  },
  mounted() {
    console.log('mounting connectivity module')
    console.log(this.xyz)
    console.log(this.$root.$children[0].$data) //this is always app, since only child
    console.log(this.$root.$children.length)
    let user = JSON.parse(window.localStorage.getItem('currentUser'))
    console.log(user.email)
  },
  beforeUnmount: () => {
    console.log('unmounting connectivity module')
    clearInterval( this.interval )
    console.log(this.counter + ' requests been made')
  },
}
</script>