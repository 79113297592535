import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import BulkTasks from "../views/BulkTasks.vue";
import UserManager from "../views/UserManager";
import CostCenterManager from "@/views/CostCenterManager";
import IncidentSingle from "@/views/IncidentSingle";
import Incidents from "@/views/Incidents";
import AdminView from "@/views/AdminView";
import AdminTokens from "@/views/AdminTokens";
import BonusManager from "@/views/BonusManager";
import Help from "@/views/Help";
import Jobs from "@/views/Jobs";
import Connectivity from "@/views/Connectivity";
import RoleManager from "@/views/RoleManager";
import Settings from "@/views/Settings";
import ReportOverview from "@/views/ReportOverview";
import ReportSingle from "@/views/ReportSingle";
import TaskManager from "@/views/TaskManager";
import TaskManagerSingle from "@/views/TaskManagerSingle";
import TeamManager from "@/views/TeamManager";
import TaskList from "@/views/TaskList";
import NotificationCenter from "@/views/NotificationCenter";
import CheckinManager from "@/views/CheckinManager";
import ToolView from "@/views/ToolView";
import UserProfile from "@/views/UserProfile";
import PermissionManager from "@/views/PermissionManager";
import ReportDaily from "@/views/ReportDaily";
import Spaces from "@/views/Spaces";
import ReportSingleManager from "@/views/ReportSingleManager";
import ReportSingleManagerReport from "@/views/ReportSingleManagerReport";
import Realms from "@/views/Realms";
import MediaManager from "@/views/MediaManager";
import RealmsSingle from "@/views/RealmsSingle";
import ChangePassword from "@/views/ChangePassword";
import AdminView2 from "@/views/AdminView2";
import Pinia from "@/views/Pinia";
import SimpleTask from "@/views/SimpleTask";
import LeadManager from "@/views/LeadManager";
import TaskOverview from "@/views/TaskOverview";
import LeadCategoryManager from "@/views/LeadCategoryManager";
import RocketHero from "@/views/RocketHero";
import TaskViewFeaturePreview from "@/views/TaskViewFeaturePreview.vue";
import Projects from "@/views/Projects.vue";
import ReportSingle2 from "@/views/ReportSingle2.vue";
import ReportManager from "@/views/ReportManager.vue";
import ProjectPlan from "@/views/ProjectPlan.vue";
import Profile from "@/views/Profile.vue";
import RTasks from "@/views/RTasks.vue";
import Tasks from "@/views/Tasks.vue";
import IncidentCategoryManager from "@/views/IncidentCategoryManager.vue";
import Chat from "@/views/Chat.vue";
import TaskManager2 from "@/views/TaskManager2.vue";
import Finance from "@/views/Finance.vue";
import ReportRx from "@/views/ReportRx.vue";
import Notes from "@/views/Notes.vue";
import Invoices from "@/views/Invoices.vue";
import ProjectTasks from "@/views/ProjectTasks.vue";
import ReportList from "@/views/ReportList.vue";

Vue.use(VueRouter);
Vue.use(Vuex);

const routes = [
  {
    path: "/",
    name: "Tasks",
    component: TaskOverview,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  { path: '/admin-info', component: AdminView },
  { path: '/admin-last-online', component: AdminView2 },
  { path: '/admin-tokens', component: AdminTokens },
  { path: '/bonuses', component: BonusManager },
  //{ path: "/bulk", component: BulkTasks },
  { path: "/bulk", component: Pinia },
  { path: "/change-password", component: ChangePassword },
  { path: "/chat", component: Chat },
  { path: "/checkin-manager", component: CheckinManager },
  { path: '/connectivity', component: Connectivity },
  { path: '/costcenters', component: CostCenterManager },
  { path: '/finance', component: Finance },
  {
    path: '/focus',
    component: () =>
        import(/* webpackChunkName: "focus" */ "../components/Focus.vue"),
  },
  { path: '/help', component: Help },
  { path: '/incidents', component: Incidents },
  { path: '/incidents/:id', component: IncidentSingle },
  { path: '/incident-categories', component: IncidentCategoryManager },
  { path: '/invoices', component: Invoices },
  { path: '/jobs', component: Jobs },
  { path: '/jobs/:jobno', component: Jobs },
  { path: '/leadcategories', component: LeadCategoryManager },
  { path: '/leads', component: LeadManager },
  { path: '/media', component: MediaManager },
  { path: '/notes', component: Notes },
  { path: '/notifications', component: NotificationCenter },
  { path: '/permissions', component: PermissionManager },
  { path: '/profile/:id', component: UserProfile, props: true },
  { path: '/profile', component: Profile, props: true },
  { path: '/projects', component: Projects },
  { path: '/project-plans', component: ProjectPlan },
  { path: '/project-tasks', component: ProjectTasks },
  { path: '/realms', component: Realms },
  { path: '/realms/:id', component: RealmsSingle, props: true },
  { path: '/reports', component: ReportOverview },
  { path: '/reports-manager', component: ReportManager },
  { path: '/report-single0', component: ReportSingle },
  { path: '/report-single', component: ReportSingle2 },
  { path: '/report-list', component: ReportList },
  { path: '/report-daily', component: ReportDaily },
  { path: '/report-manager', component: ReportSingleManager },
  { path: '/report-manager/:id', component: ReportSingleManagerReport, props: true },
  { path: '/report-rx', component: ReportRx, },
  /*{ path: '/reports', component: reportDashboard },
  { path: '/report', component: reportComponent },
  { path: '/report/:date', component: reportComponent },
  { path: '/report-single', component: reportSingle },*/
  { path: '/rockethero', component: RocketHero },
  { path: '/roles', component: RoleManager },
  { path: '/rtasks', component: RTasks },
  { path: '/settings', component: Settings },
  { path: '/simple', component: SimpleTask },
  { path: '/spaces', component: Spaces },
  { path: '/tasks2', component: TaskList }, //alte Ansicht als Referenz
  { path: '/tasks', component: TaskOverview },
  { path: '/tasks/:id', component: Tasks },
  { path: '/tasks3', component: TaskViewFeaturePreview },
  /*{ path: '/tasks-new', component: taskView },*/
  { path: '/taskmanager2', component: TaskManager2 },
  { path: '/taskmanager', component: TaskManager },
  { path: '/taskmanager/:id', component: TaskManagerSingle },
  /*{ path: '/tasktemplates', component: taskTemplateView },*/
  { path: '/teams', component: TeamManager },
  { path: '/tools', component: ToolView },
  { path: '/usermanager', component: UserManager },
  //{ path: '/users-deleted', component: userTrash },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: () =>
        import(/* webpackChunkName: "reservations" */ "../views/Reservations.vue"),
  },
  {
    path: "/reservations/:id",
    name: "ReservationDetails",
    component: () =>
        import(/* webpackChunkName: "reservations" */ "../views/ReservationDetails.vue"),
  },
  {
    path: "/listings",
    name: "Listings",
    component: () =>
        import(/* webpackChunkName: "listings" */ "../views/Listings.vue"),
  },
  {
    path: "/listings/:id",
    name: "ListingDetails",
    component: () =>
        import(/* webpackChunkName: "listings" */ "../views/ListingDetails.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
