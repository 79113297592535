<template>
  <v-container>
    <v-card>
      <v-card-title> Bulk Tasks </v-card-title>
      <v-card-text> Hello World {{status | incidentStatus}}</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "BulkTasks",
  data: () => ({
    status: 'open',
  }),
};
</script>
