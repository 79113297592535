<template>
  <v-container>
    <v-card v-if="realms.length > 1">
      <v-card-title>Kontext auswählen</v-card-title>
      <v-card-text>
        <v-select
            v-model="selectedRealm"
            :items="realms"
            item-text="name"
            return-object
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="switchRealm">Kontext Wechseln</v-btn>
      </v-card-actions>
    </v-card>
    <hello-world />
  </v-container>
</template>

<script>
import axios from "@/main";
import HelloWorld from "../components/HelloWorld";
import {mapState} from "pinia";
import {piniaStore} from "@/store/pinia";

export default {
  name: "Home",
  data: () => ({
    realms: [],
    selectedRealm: {},
  }),

  components: {
    HelloWorld,
  },

  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['payload', 'token', 'users']),
  },

  methods: {
    getUserRealms() {
      axios.get(this.baseUrl + "/api/v1/users/" + this.payload.user.id + "/realms", {headers: {"Authorization" : `Bearer ${this.token}`}})
          .then((res) => {
            console.log(res.data);
            this.realms = res.data;
          });
    },
    switchRealm() {
      if ( window.location.hostname.endsWith("codefive.five") ) {
        window.location.assign(window.location.protocol + "//" + this.selectedRealm.subdomain + ".codefive.five:50001/home");
      }
      if ( window.location.hostname.endsWith("cairos.app") ) {
        window.location.assign(window.location.protocol + "//" + this.selectedRealm.subdomain + ".cairos.app/home");
      }
    },
  },

  mounted: function() {
    this.getUserRealms();
  }
};
</script>
