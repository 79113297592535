<template>
  <v-container>
    <v-card class="mb-3">
      <v-card-title>Checkin Manager</v-card-title>
      <v-card-actions>
        <v-btn
            @click="getCheckinEvents"
        >Aktualisieren
        </v-btn>
      </v-card-actions>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Checkin Events</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="event in checkinEvents" :key="event.id">
            <v-list-item-title>{{ event.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ event.type }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ event.username }} - {{ event.timestamp }}</v-list-item-subtitle>
            <v-list-item-action>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <v-card>
      <v-card-title>Geo Checkin Manager</v-card-title>
      <v-card-actions>
        <v-btn
            @click="getGeoEvents"
        >Aktualisieren
        </v-btn>
      </v-card-actions>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Checkin Events</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="event in geoEvents" :key="event.id">
            <v-list-item-title>{{ event._eventType }}</v-list-item-title>
            <v-list-item-subtitle>{{ event.username }} - {{ event.timestamp }} - {{ event.created }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ event.lat }} - {{ event.lng }}
              <a :href="'https://maps.google.com/?q=' + event.lat + ',' + event.lng" target="_blank">Open in Google
                Maps</a>
            </v-list-item-subtitle>
            <v-list-item-action>
              <v-icon
                  @click="deleteGeoEvent(event.id)"
              >mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/main";
import {mapState, mapGetter, mapActions} from 'vuex';

export default {
  name: "CheckinManager",
  data: () => ({
    checkinEvents: [],
    geoEvents: [],
  }),
  computed: {
    ...mapState(["users"]),
  },
  methods: {
    deleteGeoEvent(id) {
      let vm = this;
      axios
          .delete("/api/v1/geoevents/" + id)
          .then(function (response) {
            vm.geoEvents = vm.geoEvents.filter(e => e.id !== id);
          })
          .catch(function (error) {
            console.log("Error deleting GeoEvent");
          });
    },
    getCheckinEvents() {
      let vm = this;
      axios
          .get("/api/v1/checkinevents")
          .then(function (response) {
            vm.checkinEvents = response.data;
            vm.checkinEvents.forEach(function (event) {
              event.username = vm.getUsername(event.userid);
            });
            vm.checkinEvents.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0)).reverse();
          })
          .catch(function (error) {
            console.log("Error fetching CheckinEvents");
          });
    },
    getGeoEvents() {
      let vm = this;
      axios
          .get("/api/v1/geoevents")
          .then(function (response) {
            console.log("GeoEvents:");
            console.log(response.data);
            vm.geoEvents = response.data;
            vm.geoEvents.forEach(function (event) {
              console.log(event._userId);
              event.username = vm.getUsername(event._userId);
            });
            vm.geoEvents.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0)).reverse();
          })
          .catch(function (error) {
            console.log("Error fetching GeoEvents");
          });
    },
    getUsername(userId) {
      if (userId !== null) {
        let user = this.users.find(x => x.id === userId);
        return user.firstname + " " + user.lastname;
      }
      return "Unbekannt";
    },
    ...mapActions(["getUsers"]),
  },
  mounted: function () {
    this.getUsers();
    this.getCheckinEvents();
    this.getGeoEvents();
  },
};
</script>