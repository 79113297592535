<template>
  <v-container>
    <h1>Tagesübersicht</h1>
    <v-sheet elevation="1" class="pa-2 mb-3">
      <h2>Buchungen</h2>
      <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="date"
              label="Tagesbericht"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            @input="menu2 = false"
            locale="de-at"
            :events="testEvents.map(x => x.date)"
            :event-color="reportColor"
        ></v-date-picker>
      </v-menu>
      <v-row class="mt-2">
        <v-col cols="3">
          <v-card>
            <v-card-title>Checkin</v-card-title>
            <v-card-text>08:31 Uhr</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card-title>Checkout</v-card-title>
            <v-card-text>12:37 Uhr</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card-title>Checkin</v-card-title>
            <v-card-text>13:03 Uhr</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card-title>Checkout</v-card-title>
            <v-card-text>16:48 Uhr</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-1">
        <v-col>
          <v-btn fab>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-card>
      <v-card-title>Aufgabenübersicht</v-card-title>
      <v-data-table
          :headers="taskHeaders"
          :items="tasks"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editTask(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
      <v-card-text>
        <v-row class="mb-1">
          <v-col>
            <v-btn fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "ReportDaily",
  data: () => ({
    isEditing: null,
    menu2: false,
    date: '2021-11-13',
    reports: [
      {"2021-11-10": "red"},
      {"2021-11-09": "green"},
      {"2021-11-08": "secondary"},
      {"2021-11-07": "secondary"},
    ],
    reportsColors: ["red", "#00f"],
    testEvents: [
      {
        date: "2021-11-10",
        color: "#ffff00",
        state: "admitted",
      },
      {
        date: "2021-11-09",
        color: "primary",
        state: "accepted",
      },
      {
        date: "2021-11-07",
        color: "secondary",
        state: "finished",
      },
      {
        date: "2021-11-08",
        color: "secondary",
        state: "pending",
      },

    ],
    tasks: [
      {
        id: 1,
        title: 'Reinigung',
        start: '08:33',
        stop: '09:04',
      },
    ],
    taskHeaders: [
      {text: 'Aufgabe', value: 'title'},
      {text: 'Start', value: 'start'},
      {text: 'Ende', value: 'stop'},
      {text: 'Aktionen', value: 'actions', sortable: false},
    ],
  }),
  methods: {
    editTask(task) {

    },
    reportColor(date) {
      let event = this.testEvents.filter(d => d.date == date);
      if (event !== null) {
        if(event[0].state == 'finished')
          return 'secondary'
        if(event[0].state == 'accepted')
          return 'secondary'
        if(event[0].state == 'admitted')
          return '#ff00ff'
        if(event[0].state == 'pending')
          return '#d50e3d'
      }
      return false;
    },
  },
  created() {

  }
};
</script>